import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Drawer } from '@mui/material';
import '../styles/components/GameMainSideBar.scss';

const mapStateToProps = (state) => {
  return {
    memberInfo: state.memberInfo
  };
};

class GameMainSideBar extends React.Component {
  state = {
    open: false,
    showAmount: false,
    currLang: '',
    toggleMenu: false
  };

  componentDidMount() {
    let t_label = this.props.memberInfo.MemberInfo.language;
    switch (t_label) {
      case 'en':
        t_label = 'EN'
        break;
      case 'th':
        t_label = 'TH'
        break;
      case 'vi':
        t_label = 'VI'
        break;
      case 'in':
        t_label = 'IN'
        break;
       case 'id':
        t_label = 'ID'
        break;        
      case 'pt':
        t_label = 'PT'
        break;        
      case 'es':
        t_label = 'ES'
        break;        
      case 'fr':
        t_label = 'FR'
        break;
      case 'ru':
        t_label = 'RU'
        break;
      default:
        t_label = '中文'
        break;
    }
    this.setState({ currLang: t_label });
  }

  toggleGameDrawer = (exp) => (event) => {
    this.setState({ open: exp });
    if (!exp) this.setState({ toggleMenu: false });
  };

  showAmount() {
    this.setState({ showAmount: !this.state.showAmount });
  }

  refresh = () => {
    window.location.href = window.location.href.split('#')[0]+ "#/?gametoken=" + this.props.memberInfo.token;
  }

  toggleLangMenu = () => {
    var x = document.getElementById("langMenu");
    if (x.style.display === "none") {
      x.style.display = "block";
      this.setState({ toggleMenu: true });
    } else {
      x.style.display = "none";
      this.setState({ toggleMenu: false });
    }
  }

  sidebarChangeGame(gameName){
    this.props.openGame(gameName);
  }

  render() {
    let Lang = this.props.memberInfo.MemberInfo.language;
    // console.log("game main side bar game list : ");
    // console.log(this.props.gameList);
    return (
      <div className='hide-scroller'>
        <img src={require('assets/images/menu-burger.png')} alt="menu" style={{width:'25px',marginLeft: 15, cursor: 'pointer'}} onClick={this.toggleGameDrawer(true)}/>
        <Drawer anchor="left" open={this.state.open} onClose={this.toggleGameDrawer(false)} className="sidedrawer">
              <div>
              <Row>
                <Col className="mdrawer_header">ALL Game</Col>
              </Row>
              <Row className="gameside-hall-row">
                  {this.props.gameList.length>0?
                    this.props.gameList.map((item, index) => {
                      let imageUrl;
                      try{
                        if(Lang === 'zh'){
                          imageUrl = require(`../assets/images/zh/${item.id}.png`);
                        }else{
                          imageUrl = require(`../assets/images/en/${item.id}.png`);
                        }
                      }catch(ex){
                        imageUrl = imageUrl = require(`../assets/images/h5/zh/crash.png`);;
                      }
                      
                      return(
                          <Col key={index} md={4} lg={5} >
                            <div style={{textAlign:'center', margin:"8px 0px 10px"}} onClick={this.sidebarChangeGame.bind(this,item.id)}>
                              <img src={imageUrl} alt={""} className="sidebar-hall-col-card-img"/>
                            </div>
                          </Col>
                     )})
                :<span>no game now</span>
                }
              </Row>
              </div>
        </Drawer>
      </div>
    );
  }
}

const ConnectedGameMainSideBarSidebar = connect(mapStateToProps)(GameMainSideBar);
export default withTranslation('common')(ConnectedGameMainSideBarSidebar);