import React from "react";
import Stack from "react-bootstrap/Stack";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation, withTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Buttons from "../../components/dice/Buttons";
import Button from "../../components/default/Button";
import GameAmount from "../GameAmount";
import bet_amount from '../../assets/images/dice/bet_amount.png'

function GameButton(props) {
  const { t } = useTranslation();
  function handleOnMinScore() {
    props.onMinScore();
  }

  function handleOnMaxScore() {
    props.onMaxScore();
  }

  function handleOnHalfScore() {
    props.onHalfScore();
  }

  function handleOnTimeScore() {
    props.onTimeScore();
  }

  return (
    <>
      <Buttons.Container>
        <Container>
          <Row>
          
            <Col style={{maxWidth: 'max-content',padding: '0px',width: '10%',marginTop: 'auto',marginBottom: 'auto'}}>
            {props.isLock || props.autoBet?
              <div disabled={props.isLock}>
                <img style={{width: '50px'}}
                    src={require(`../../assets/images/dice/btn_min.png`)}
                  />
              </div>
            :
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.75 }}>
              <div disabled={props.isLock} onClick={handleOnMinScore}>
              <img style={{width: '50px'}}
                    src={require(`../../assets/images/dice/btndim_min.png`)}
                  />
              </div>
            </motion.div>
            }
            </Col>
            <Col style={{maxWidth: 'max-content',padding: '0px',width: '10%',marginTop: 'auto',marginBottom: 'auto'}}>
              {props.isLock || props.autoBet?
                <div disabled={props.isLock}>
                  <img style={{width: '50px'}}
                      src={require(`../../assets/images/dice/btn_minus.png`)}
                    />
                </div>
              :
              <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.75 }}>
                <div disabled={props.isLock} onClick={handleOnHalfScore}>
                <img style={{width: '50px'}}
                      src={require(`../../assets/images/dice/btndim_minus.png`)}
                    />
                </div>
              </motion.div>
              }
            </Col>
            <Col
              style={{
                backgroundImage:`url(${bet_amount})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',
                width: '60%',
                height: '60px',
                backgroundPosition: 'center',
                display: "inline-flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center" }}
            >
              <GameAmount
                style={{width: '60%' }}
                amt={props.betAmount}
                onUpdateBetAmount={props.onUpdateBetAmount}
                islock={props.isLock}
              />
            </Col>
            <Col style={{maxWidth: 'max-content',padding: '0px',width: '10%',marginTop: 'auto',marginBottom: 'auto'}}>
              {props.isLock || props.autoBet?
                <div disabled={props.isLock}>
                  <img style={{width: '50px'}}
                      src={require(`../../assets/images/dice/btn_plus.png`)}
                    />
                </div>
              :
              <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.75 }}>
                <div disabled={props.isLock} onClick={handleOnTimeScore}>
                <img style={{width: '50px'}}
                      src={require(`../../assets/images/dice/btndim_plus.png`)}
                    />
                </div>
              </motion.div>
              }
            </Col>
            <Col style={{maxWidth: 'max-content',padding: '0px',width: '10%',marginTop: 'auto',marginBottom: 'auto'}}>
              {props.isLock || props.autoBet?
                <div disabled={props.isLock}>
                  <img style={{width: '50px'}}
                      src={require(`../../assets/images/dice/btn_max.png`)}
                    />
                </div>
              :
              <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.75 }}>
                <div disabled={props.isLock} onClick={handleOnMaxScore}>
                <img style={{width: '50px'}}
                      src={require(`../../assets/images/dice/btndim_max.png`)}
                    />
                </div>
              </motion.div>
              }
            </Col>
          </Row>
        </Container>
      </Buttons.Container>
    </>
  );
}

export default withTranslation("common")(GameButton);
