import {useState, useEffect} from "react";
import {withTranslation, useTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {TextField, Button} from '@mui/material';
import {useSelector, shallowEqual} from "react-redux";
import {store} from "../index";
import '../styles/Triple/Triple.css';
import io from 'socket.io-client';
import {getMemberBalance} from "../services/HomeServices";
import GameMoney from "../containers/GameMoney";
import gameConstants, {betAmtConstants} from "../utils/utilConstants";
import {getQuota} from "../services/QuotaServices";
import ReactLoading from 'react-loading';
import {isMobile} from "react-device-detect";
import h5_bg from "../assets/images/triple/triple_bg.png";
import web_bg from "../assets/images/triple/triple_bg_web.png";


const AppContainer = styled('div')({
    width: '100vw',
    height: '100vh',

    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
});

const InnerContainer = styled('div')({
    // width: '1250px',
    height: 'fit-content',
    // backgroundColor: '#111923',
});

const GameButton = styled(Button)({
    backgroundColor: '#737785!important',
    borderRadius: '15px',
    height: '25px',
});

// const socket = io('http://localhost:1219/',{
//   transports: ["websocket"],
// });
let socket;
if (global.config.testEnv === true) {
    socket = io(global.config.minigame.socket.protocol + '://' + global.config.domain.instantaward + ':' + global.config.minigame.socket.port.triple + '/', {
        transports: ["websocket"],
    });
} else {
    socket = io(global.config.minigame.socket.protocol + '://' + global.config.minigame.socket.domain.triple + '/', {
        transports: ["websocket"],
    });
}
const lowRiskFlagOdds = [0.5, 0.8, 1.2, 1.5, 2.1, 3.5, 4.5, 7, 10, 15];
const midRiskFlagOdds = [0, 0.5, 1.5, 2.4, 3, 6.7, 8, 10, 15, 25];
const highRiskFlagOdds = [0, 0, 0.5, 3, 4.2, 9, 10, 15, 25, 35];

const maxBallNumber = 36;
const maxRewardPanelNumber = 10;

function Triple() {

    // const [walletMoney, setWalletMoney] = useState(10000);

    const balanceMoney = useSelector(state => store.getState().memberInfo.MemberInfo.balanceMoney, shallowEqual);
    const clientSeed = useSelector(state => store.getState().memberInfo.MemberInfo.clientSeed, shallowEqual);
    const nonce = useSelector(state => store.getState().memberInfo.MemberInfo.nonce, shallowEqual);
    const [betAmount, setBetAmount] = useState(Number(10.00).toFixed(2));
    const [minBetAmount, setMinBetAmount] = useState(Number(betAmtConstants.minAmt).toFixed(2));
    const [maxBetAmount, setMaxBetAmount] = useState(Number(betAmtConstants.maxAmt).toFixed(2));
    const [tempBallArray, setTempBallArray] = useState([]);
    const [ballColorArray, setBallColorArray] = useState([]);
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [ballClassNameArray, setBallClassNameArray] = useState([]);
    const [ballSelectedArray, setBallSelectedArray] = useState([]);
    const [resultText, setResultText] = useState("");
    const [riskFlag, setRiskFlag] = useState(1);
    const [riskFlagOddArray, setRiskFlagOddArray] = useState([]);
    const [winPanelArray, setWinPanelArray] = useState([]);
    const [isEndGame, setIsEndGame] = useState(false);
    const {t} = useTranslation();
    const [isLock, setIsLock] = useState(false);
    const [autoBet, setAutoBet] = useState(false);
    const [selectedResultArray, setSelectedResultArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // console.log("render once");
        let timeout;
        socket.extraHeaders = {gametoken: store.getState().memberInfo.token};

        resetWinPanel();
        let tempAry = [];
        let tempBallColorArray = [];
        let tempBallClassNameArray = [];

        for (let x = 0; x < maxBallNumber; x++) {
            tempAry.push(x);
            tempBallClassNameArray.push(false);
            tempBallColorArray.push(false);
        }
        setBallColorArray(tempBallColorArray);
        setTempBallArray(tempAry);
        setBallClassNameArray(tempBallClassNameArray);

        socket.on('connect', (e) => {
            console.log("socket on connect event listener");
            // console.log("web socket id : " + socket.id);
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            console.log("socket on disconnect event listener");
            setIsConnected(false);
        });

        socket.on('error message', (res) => {
            console.log("SOCKET ERROR MSG : " + JSON.stringify(res));
            if (res.errMessage === "invalid_token_id") {
                window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
            } else if (res.errMessage.startsWith("facade_Max_balance")) {
                setAutoBet(false);
                setResultText(t('triple_Max_balance'));
                setIsLock(false);
                setIsLoading(false);
                setIsEndGame(true);
            } else if (res.errMessage.startsWith("facade_Min_balance")) {
                setAutoBet(false);
                setResultText(t('triple_Min_balance'));
                setIsLock(false);
                setIsLoading(false);
                setIsEndGame(true);
            }else{
                setAutoBet(false);
                setResultText(t(res.errMessage));
                setIsLock(false);
                setIsLoading(false);
                setIsEndGame(true);
            }
        });

        socket.on('connect_error', (e) => {
            console.log("socket on connect error event listener");
            console.log("web socket connect error : " + e);
            setIsConnected(false);
        });

        socket.io.on("error", (error) => {
            console.log("socket error event listener");
            console.log("web socket error : " + error);
        });

        socket.on('bet result', (data) => {
            console.log("socket on bet result event listener" + JSON.stringify(data));

            timeout = setTimeout(() => {
                // setIsEndGame(true)
                handleBetResult(data);
            }, 500);
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('error message');
            socket.off('connect_error');
            socket.off('error');
            socket.off('bet result');
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {

        if (riskFlag === 0) {
            setRiskFlagOddArray(lowRiskFlagOdds);
        } else if (riskFlag === 2) {
            setRiskFlagOddArray(highRiskFlagOdds);
        } else {
            setRiskFlagOddArray(midRiskFlagOdds);
        }

    }, [riskFlag]);

    useEffect(() => {
        if (isLoading !== false && isEndGame === true) {
            setIsLoading(false);
            setIsLock(autoBet);
        }
        var interval =null;
        if(autoBet){
            interval = setInterval(() => {
                if(autoBet){
                    handlePlay();
                    clearInterval(interval);
                }else{
                    clearInterval(interval);
                }
            }, 6000);
            return () => clearInterval(interval);
        }else{
            clearInterval(interval);
        }
    }, [isEndGame]);

    useEffect(() => {
        setResultText("");
    }, [betAmount]);

    const handleBallClick = (selectedIndex) => {
        if(!autoBet){
            if (isEndGame) {
                handleClearTable();
                setIsEndGame(false);
                return;
            }
            if (ballSelectedArray.includes(selectedIndex)) {
                ballClassNameArray[selectedIndex] = !ballClassNameArray[selectedIndex];
                setBallClassNameArray(ballClassNameArray);
    
                let tempArry = [...ballSelectedArray];
                var index = tempArry.indexOf(selectedIndex)
                if (index !== -1) {
                    tempArry.splice(index, 1);
                    setBallSelectedArray(tempArry);
                }
    
                setResultText("");
    
            } else if (ballSelectedArray.length <= 2) {
    
                ballClassNameArray[selectedIndex] = !ballClassNameArray[selectedIndex];
                setBallClassNameArray(ballClassNameArray);
                setBallSelectedArray([...ballSelectedArray, selectedIndex]);
    
            } else {
                setResultText(t('triple_only3card'));
            }
        }
    }

    const handlePlay = () => {
        // to reset panel back to default
        handleAutoBetReset();
        let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
        if (Number(betAmount) > Number(latestBalance)) {
            setAutoBet(false);
            setResultText(t('triple_insufficient_funds'));
            setTimeout(() => {
                setResultText("");
            }, 3000);
            return;
        }

        if (Number(betAmount) < Number(minBetAmount)) {
            setAutoBet(false);
            setResultText(t('triple_Min_balance') + Number(minBetAmount).toFixed(2));
            setTimeout(() => {
                setResultText("");
            }, 3000);
            return;
        }

        if (ballSelectedArray.length === 3 && isConnected === true) {
            if (isEndGame === true) {
                setIsEndGame(false);
            }

            setIsLoading(true);
            setResultText("");
            let memberInfo = store.getState().memberInfo;
            let token = memberInfo.token;

            let jsonData = {
                token: token,
                betAmount: betAmount,
                amount: latestBalance,
                selectedCardArray: ballSelectedArray,
                riskFlag: riskFlag,
                clientSeed: clientSeed,
                nonce: nonce
            };

            socket.emit("bet info", jsonData);
            setIsLock(autoBet);
        } else {

            if (isConnected === false) {
                setAutoBet(false);
                setResultText(t('triple_cant_connect_server'));
            } else {
                setAutoBet(false);
                setResultText(t('triple_please_place_cards'));

            }
            // setTimeout(() => {
            //     setResultText("");
            // }, 3000);
        }
    };

    useEffect(() => {
        var interval =null;
        if(autoBet){
            if(!isLock){
                if(!isEndGame){
                    handlePlay();
                }else{
                     interval = setInterval(() => {
                         if(autoBet){
                             handlePlay();
                             clearInterval(interval);
                         }else{
                             clearInterval(interval);
                         }
                     }, 5000);
                     return () => clearInterval(interval);
                }
            }
        }else{
            clearInterval(interval);
        }
    }, [autoBet]);

    const handleAutoBet = () => {
        var auto = !autoBet;
        var valid = true;
        if(!auto){
            setAutoBet(auto);

        }else{
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            if (Number(betAmount) > Number(latestBalance)) {
                valid = false;
            }
    
            if (Number(betAmount) < Number(minBetAmount)) {
                valid = false;
            }
            
            if (ballSelectedArray.length === 3 && isConnected === true && valid) {
                setAutoBet(auto);
            }else{
                setAutoBet(auto);
            }
        }
    };

    const minBet = () => {
        if (!isLock && !autoBet) {
            setBetAmount(Number(minBetAmount).toFixed(2));
        }
    };

    const maxBet = () => {
        if (!isLock && !autoBet) {
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            if (Number(latestBalance) <= Number(maxBetAmount)) {
                setBetAmount(Number(latestBalance).toFixed(2));
            } else {
                setBetAmount(Number(maxBetAmount).toFixed(2));
            }
        }
    };

    const doubleBet = () => {
        if (!isLock && !autoBet) {
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            let calculateAmount = Number(betAmount) * 2;
            if (calculateAmount <= Number(maxBetAmount)) {
                if (calculateAmount > Number(latestBalance)) {
                    calculateAmount = Number(latestBalance).toFixed(2);
                }
            } else {
                calculateAmount = Number(maxBetAmount).toFixed(2);
            }
            setBetAmount(Number(calculateAmount).toFixed(2));
        }
    };

    const halfBet = () => {
        if (!isLock && !autoBet) {
            let calculateAmount = betAmount / 2;
            if (calculateAmount < minBetAmount) {
                calculateAmount = Number(minBetAmount);
            }
            setBetAmount(Number(calculateAmount).toFixed(2));
        }
    };

    const handleRiskFlag = (riskFlag) => {
        setRiskFlag(riskFlag);
    };

    const handleBetResult = (res) => {
        setTimeout(() => {
            if(res.earnAmount === 0) {
                setResultText(t('triple_lose'));
            }
            else {
                setResultText(t('triple_win') + res.earnAmount.toFixed(2))
            }
            setSelectedResultArray(res.selectedCardArray);
            setBallColorArray(res.cardPosition);
            let returnPanelIndex = res.panelIndex;
            let tempWinPanelArray = [];
            for (let x = 0; x < maxRewardPanelNumber; x++) {
                if (x === returnPanelIndex) {
                    tempWinPanelArray.push(true);
                } else {
                    tempWinPanelArray.push(false);
                }
            }
            setWinPanelArray(tempWinPanelArray);
            getMemberBalance();
            setIsEndGame(true);
        }, 1000);
    };

    const handleClearTable = () => {
        resetWinPanel();
        resetBallColor();
        setBallSelectedArray([]);
        setResultText('');
        setSelectedResultArray([]);
    };

    const handleAutoBetReset = () => {
        resetWinPanel();
        autoBetResetBallColor();
        setResultText('');
        setSelectedResultArray([]);
    }

    const resetWinPanel = () => {
        let tempWinArray = [];
        for (let x = 0; x < 10; x++) {
            tempWinArray.push(false);
        }
        setWinPanelArray(tempWinArray);
    };

    const resetBallColor = () => {
        let tempBallColorArray = [];
        let tempBallClassNameArray = [];
        for (let x = 0; x < maxBallNumber; x++) {
            tempBallColorArray.push(false);
            tempBallClassNameArray.push(false);
        }
        setBallColorArray(tempBallColorArray);
        setBallClassNameArray(tempBallClassNameArray);
    };

    const autoBetResetBallColor = () => {
        let tempBallColorArray = [];
        let tempBallClassNameArray = [];
        for(let x = 0; x < maxBallNumber; x++) {
            tempBallColorArray.push(false);
            if(ballClassNameArray[x] === true){
                tempBallClassNameArray.push(true);
            }
            else{
                tempBallClassNameArray.push(false);
            }
        }
        setBallColorArray(tempBallColorArray);
        setBallClassNameArray(tempBallClassNameArray);
    }

    useEffect(() => {
        getQuota("triple", updateQuotaCallback);
    }, []);

    const updateQuotaCallback = (res) => {
        if(res !== '' && res !== undefined) {
            if(res.minBetAmount !== null) {
                setBetAmount(Number(res.minBetAmount).toFixed(2));
                setMinBetAmount(Number(res.minBetAmount).toFixed(2));
            }
            if(res.maxBetAmount !== null) setMaxBetAmount(Number(res.maxBetAmount).toFixed(2));
        } else {
            setBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMinBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMaxBetAmount(Number(betAmtConstants.maxAmt).toFixed(2));
        }
    };

    const handleBetAmt = (event) => {
        const regex = /^(\d*\.{0,1}\d{0,2}$)/;
        if(regex.test(event.target.value)) {
            setBetAmount(event.target.value);
        }
    };

    return (

        <>
            <AppContainer id="gameTriple" className="game-bg-web" style={{ backgroundSize: '100vw '+window.innerHeight+'px', backgroundImage:`url(${isMobile?h5_bg:web_bg})`}}>
                <InnerContainer className="innerContainer">
                    <div className="react-game-triple">
                        <Container fluid className="game-main-container">
                        {isLoading === true ?
                            <ReactLoading type={'spin'} className={'loadingSpinner'}
                                    style={{ width: '49px', height: '49px' }}/>
                        : false}
                            {/* <Row >
              <Col>&nbsp;</Col>
            </Row> */}
                            <GameMoney gameName={"triple"}/>
                            <Row className="game-box">
                                <Col style={{ paddingTop:'10%', paddingBottom:'2%' }}>
                                    <Container fluid className="center-ball-panel">
                                        <Row>
                                            <Col>
                                                {tempBallArray.filter((filterItem, filterIndex) => (filterIndex + 1) <= 6).map((item, index) => {
                                                    return (
                                                        <div className={(ballClassNameArray[index] === true && isEndGame === false ? `odd-box-glow-in-out` : ``)
                                                                        + (isEndGame === true ? !selectedResultArray.includes(index) ? `delay3s-animation` : `after-win-box-animation` : ``)}>
                                                            <img key={item}
                                                                alt={index}
                                                                onClick={() => handleBallClick(index)}
                                                                className={(isEndGame === true ? !selectedResultArray.includes(index) ? `delay3s-animation` : `after-win-animation` : ``)}
                                                                src={require(`../assets/images/triple/ball${ballColorArray[index] !== false ? '_' + ballColorArray[index] : ballClassNameArray[index] === true ? '_3' : ''}.png`)}/>
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {tempBallArray.filter((filterItem, filterIndex) => (filterIndex + 1) > 6 && (filterIndex + 1) <= 12).map((item, index) => {
                                                    return (
                                                        <div className={(ballClassNameArray[index + 6] === true && isEndGame === false ? `odd-box-glow-in-out` : ``)
                                                                        + (isEndGame === true ? !selectedResultArray.includes(index + 6) ? `delay3s-animation` : `after-win-box-animation` : ``)}>
                                                            <img key={item}
                                                                alt={index}
                                                                onClick={() => handleBallClick(index + 6)}
                                                                className={(isEndGame === true ? !selectedResultArray.includes(index + 6) ? `delay3s-animation` : `after-win-animation` : ``)}
                                                                src={require(`../assets/images/triple/ball${ballColorArray[index + 6] !== false ? '_' + ballColorArray[index + 6] : ballClassNameArray[index + 6] === true ? '_3' : ''}.png`)}/>
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {tempBallArray.filter((filterItem, filterIndex) => (filterIndex + 1) > 12 && (filterIndex + 1) <= 18).map((item, index) => {
                                                    return (
                                                        <div className={(ballClassNameArray[index + 12] === true && isEndGame === false ? `odd-box-glow-in-out` : ``)
                                                                        + (isEndGame === true ? !selectedResultArray.includes(index + 12) ? `delay3s-animation` : `after-win-box-animation` : ``)}>
                                                            <img key={item}
                                                                alt={index}
                                                                onClick={() => handleBallClick(index + 12)}
                                                                className={(isEndGame === true ? !selectedResultArray.includes(index + 12) ? `delay3s-animation` : `after-win-animation` : ``)}
                                                                src={require(`../assets/images/triple/ball${ballColorArray[index + 12] !== false ? '_' + ballColorArray[index + 12] : ballClassNameArray[index + 12] === true ? '_3' : ''}.png`)}/>
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {tempBallArray.filter((filterItem, filterIndex) => (filterIndex + 1) > 18 && (filterIndex + 1) <= 24).map((item, index) => {
                                                    return (
                                                        <div className={(ballClassNameArray[index + 18] === true && isEndGame === false ? `odd-box-glow-in-out` : ``)
                                                                        + (isEndGame === true ? !selectedResultArray.includes(index + 18) ? `delay3s-animation` : `after-win-box-animation` : ``)}>
                                                            <img key={item}
                                                                alt={index}
                                                                onClick={() => handleBallClick(index + 18)}
                                                                className={(isEndGame === true ? !selectedResultArray.includes(index + 18) ? `delay3s-animation` : `after-win-animation` : ``)}
                                                                src={require(`../assets/images/triple/ball${ballColorArray[index + 18] !== false ? '_' + ballColorArray[index + 18] : ballClassNameArray[index + 18] === true ? '_3' : ''}.png`)}/>
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {tempBallArray.filter((filterItem, filterIndex) => (filterIndex + 1) > 24 && (filterIndex + 1) <= 30).map((item, index) => {
                                                    return (
                                                        <div className={(ballClassNameArray[index + 24] === true && isEndGame === false ? `odd-box-glow-in-out` : ``)
                                                                        + (isEndGame === true ? !selectedResultArray.includes(index + 24) ? `delay3s-animation` : `after-win-box-animation` : ``)}>
                                                            <img key={item}
                                                                alt={index}
                                                                onClick={() => handleBallClick(index + 24)}
                                                                className={(isEndGame === true ? !selectedResultArray.includes(index + 24) ? `delay3s-animation` : `after-win-animation` : ``)}
                                                                src={require(`../assets/images/triple/ball${ballColorArray[index + 24] !== false ? '_' + ballColorArray[index + 24] : ballClassNameArray[index + 24] === true ? '_3' : ''}.png`)}/>
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {tempBallArray.filter((filterItem, filterIndex) => (filterIndex + 1) > 30 && (filterIndex + 1) <= 36).map((item, index) => {
                                                    return (
                                                        <div className={(ballClassNameArray[index + 30] === true && isEndGame === false ? `odd-box-glow-in-out` : ``)
                                                                        + (isEndGame === true ? !selectedResultArray.includes(index + 30) ? `delay3s-animation` : `after-win-box-animation` : ``)}>
                                                            <img key={item}
                                                                alt={index}
                                                                onClick={() => handleBallClick(index + 30)}
                                                                className={(isEndGame === true ? !selectedResultArray.includes(index + 30) ? `delay3s-animation` : `after-win-animation` : ``)}
                                                                src={require(`../assets/images/triple/ball${ballColorArray[index + 30] !== false ? '_' + ballColorArray[index + 30] : ballClassNameArray[index + 30] === true ? '_3' : ''}.png`)}/>
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                    </Container>

                                </Col>
                            </Row>
                            <Container style={{ paddingTop: '10px', paddingBottom:'10px', position:'relative' }}>
                                <img className="odd-box-background" src={require(`../assets/images/triple/odds_container.png`)}/>
                                <div style={{ position:'absolute', top:'8%', paddingRight: '3%' }}>
                                    <Row style={{ paddingTop: '5px', margin:'0 5px' }}>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_0_00'} 
                                                className={"border-gradient " + (winPanelArray[0] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_0_00_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[0]).toFixed(1)}</span>
                                        </Col>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_0_50'} 
                                                className={"border-gradient " + (winPanelArray[1] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_0_50_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[1]).toFixed(1)}</span>
                                        </Col>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_1_50'} 
                                                className={"border-gradient " + (winPanelArray[2] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_1_50_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[2]).toFixed(1)}</span>
                                        </Col>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_2_40'} 
                                                className={"border-gradient " + (winPanelArray[3] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_2_40_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[3]).toFixed(1)}</span>
                                        </Col>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_3_00'} 
                                                className={"border-gradient " + (winPanelArray[4] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_3_00_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[4]).toFixed(1)}</span>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingTop: '5px' }}>
                                    </Row>
                                    <Row style={{ paddingTop: '5px', margin:'0 5px' }}>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_6_70'}
                                                className={"border-gradient " + (winPanelArray[5] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_6_70_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[5]).toFixed(1)}</span>
                                        </Col>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_10_00'} 
                                                className={"border-gradient " + (winPanelArray[6] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_10_00_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[6]).toFixed(1)}</span>
                                        </Col>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_15_00'} 
                                                className={"border-gradient " + (winPanelArray[7] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_15_00_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[7]).toFixed(1)}</span>
                                        </Col>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_30_00'} 
                                                className={"border-gradient " + (winPanelArray[8] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_30_00_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[8]).toFixed(1)}</span>
                                        </Col>
                                        <Col
                                            className='reward-guide-space'>
                                            <img alt={'reward_guide_80_00'}
                                                className={"border-gradient " + (winPanelArray[9] === true && "odd-box-glow-in-out")}
                                                src={require(`../assets/images/triple/reward_guide_80_00_new.png`)}/>
                                            <span
                                                className="reward-dynamic-odd">X{Number(riskFlagOddArray[9]).toFixed(1)}</span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col style={{padding:0}}>
                                            <Button onClick={() => handleRiskFlag(0)} disabled={isLoading?isLoading:autoBet?autoBet:isLock} className="risk-button" disableRipple>
                                                <img className="risk-img" src={require(`../assets/images/triple/${riskFlag === 0 ? 'btn_mediumselect.png' : 'btn_mediumdisplay.png'}`)}/>
                                                <span style={{ position:'absolute' }}>{t('triple_low')}</span>
                                            </Button>
                                            <Button onClick={() => handleRiskFlag(1)} disabled={isLoading?isLoading:autoBet?autoBet:isLock} className="risk-button" disableRipple>
                                                <img className="risk-img" src={require(`../assets/images/triple/${riskFlag === 1 ? 'btn_mediumselect.png' : 'btn_mediumdisplay.png'}`)}/>
                                                <span style={{ position:'absolute' }}>{t('triple_medium')}</span>
                                            </Button>
                                            <Button onClick={() => handleRiskFlag(2)} disabled={isLoading?isLoading:autoBet?autoBet:isLock} className="risk-button" disableRipple>
                                                <img className="risk-img" src={require(`../assets/images/triple/${riskFlag === 2 ? 'btn_mediumselect.png' : 'btn_mediumdisplay.png'}`)}/>
                                                <span style={{ position:'absolute' }}>{t('triple_high')}</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                            <Row style={{ padding:'10px 0px' }}>
                                <Col style={{ padding: 0 }}>
                                    <div style={{ color:'white', height:'25px', fontSize:'16px' }}>{resultText}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button onClick={minBet} disabled={isLoading?isLoading:autoBet?autoBet:isLock} className="bet-button btn-click-effect" disableRipple>
                                        <img className="number-button" src={require(`../assets/images/triple/btn_min.png`)}/>
                                    </Button>
                                    <Button onClick={halfBet} disabled={isLoading?isLoading:autoBet?autoBet:isLock} className="bet-button btn-click-effect" disableRipple>
                                        <img className="number-button" src={require(`../assets/images/triple/btn_minus.png`)}/>
                                    </Button>
                                    <TextField InputProps={{readOnly: isLoading?isLoading: isLock}} style={{border:'none'}} value={betAmount} onChange={(event) => handleBetAmt(event)} className="bet-input-background button-background-setting" />
                                    <Button onClick={doubleBet} disabled={isLoading?isLoading:autoBet?autoBet:isLock} className="bet-button btn-click-effect" disableRipple>
                                        <img className="number-button" src={require(`../assets/images/triple/btn_plus.png`)}/>
                                    </Button>
                                    <Button onClick={maxBet} disabled={isLoading?isLoading:autoBet?autoBet:isLock} className="bet-button btn-click-effect" disableRipple>
                                        <img className="number-button" src={require(`../assets/images/triple/btn_max.png`)}/>
                                    </Button>
                                </Col>
                            </Row>

                            <Row style={{ paddingBottom:'15px'}}>
                                <Col style={{ width:'10%' }}/>
                                <Col style={{ padding: '0px' }}>
                                    {!isConnected === true ?
                                        <ReactLoading type={'spin'} className={'loadingSpinner'}
                                                style={{ width: '49px', height: '49px', 
                                                        marginTop: isMobile ? '70%' : '0%' }}/>
                                    :   null
                                    }
                                    <Button onClick={handlePlay} disabled={isConnected ? (isLoading?isLoading:autoBet?autoBet:isLock) : true} disableRipple style={{ padding: '0px'}}>
                                        <img src={require(`../assets/images/triple/btn_play.png`)} className="btn-click-effect" width='150'/>
                                        <span style={{ position:'absolute', fontSize:'20px' }}>{t('triple_play')}</span>
                                    </Button>
                                </Col>
                                <Col style={{ paddingLeft:'0px', paddingRight:'20px'}}>
                                    <Button style={{ padding: '0px 20px 0px 0px' }} onClick={handleAutoBet} className="btn-click-effect" disableRipple>
                                        <img src={require(`../assets/images/triple/${autoBet ? 'btn_autobet_select' : 'btn_autobet'}.png`)} style={{ width:'75%' }} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </InnerContainer>
            </AppContainer>
        </>
    );
}


export default withTranslation('common')(Triple);