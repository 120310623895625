import React from "react";
import Stack from "react-bootstrap/Stack"
import {useTranslation, withTranslation} from 'react-i18next';

import Chance from "../../components/dice/Chance";
import box_chance from '../../assets/images/dice/box_chance.png'

function GameChance(props) {
    const {t} = useTranslation();

    return (
        <>
            <Chance.Container style={{backgroundImage:`url(${box_chance})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',display: 'flex',width: '100%',height: '84%',marginLeft:'-20%'}}>
                <Stack style={{paddingTop:'17%'}}>
                    <div style={{paddingLeft:'8%'}}>
                        <Chance.Value>{t('dice_chance_win')}</Chance.Value>
                    </div>
                    <div style={{paddingTop:'8%',paddingLeft:'8%'}}>
                        <Chance.Header>{props.rollOver === true ? (99 - props.predictionValue) : props.predictionValue} {"%"}</Chance.Header>
                    </div>
                </Stack>
            </Chance.Container>
        </>
    );
}

export default withTranslation('common')(GameChance);
