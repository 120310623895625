import update from "immutability-helper";
import gameConstants from "../utils/utilConstants";

const gameInitial = {
    predictionValue: 48,
    multiplierValue: 1.96,
    chanceValue: 48,
    loading: false,
    randomNumber: 0,
    earnAmount: 0.0,
    showMessage: false,
    message: '',
    messageType: 0
};

const storeGameDice = (state = gameInitial, action) => {
    switch (action.type) {

        case gameConstants.SHOW_MESSAGE: {
            return update(state, {
               showMessage: { $set: true },
               message: { $set: action.message },
               messageType: { $set: action.messageType }
            });
        }

        case gameConstants.HIDE_MESSAGE: {
            return update(state, {
                showMessage: { $set: false },
                message: { $set: '' },
                messageType: { $set: 0 }
            });
        }

        case gameConstants.UPDATE_PREDICTION_VALUES: {
            return update(state, {
                predictionValue: { $set: action.payload }
            });
        }

        case gameConstants.SET_LOADING: {
            return update(state, {
                loading: { $set: true }
            });
        }

        case gameConstants.UPDATE_DICE_DATA: {
            return update(state, {
                randomNumber: {
                    $set: action.randomNumber
                },
                earnAmount: {
                    $set: action.earnAmount
                }
            });
        }

        case gameConstants.RESTART:
        default:
            return gameInitial;
    }
};

export { storeGameDice }