import React from 'react';
import stopimage from 'assets/images/error/403.png';
import timeoutImage from 'assets/images/error/Session-timeout-app.png';
import blockImage from 'assets/images/error/whitelist.png';
import maintainImage from 'assets/images/error/maintenance.png';
import loginImage from 'assets/images/error/login.png';
import reloadImage from 'assets/images/error/reload.png';
import backImage from 'assets/images/error/back.png';
import eror404image from 'assets/images/error/404.png';

class Stopuser extends React.Component {

  render() {
    return(
      <div className='error-page'>
        <table>
          <tbody>
            <tr>
              <td>
                <p><img alt="" src={stopimage} style={{width:'220px'}} /></p>
                <p>用户权限不足</p>
                <p>Deny due to insufficient access right</p>
                <p>Từ chối do không đủ quyền truy cập</p>
                <p>ปฏิเสธเนื่องจากสิทธิ์ในการเข้าถึงไม่เพียงพอ</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

}

function TimeoutPage(props) {
  return (
    <img alt="" src={timeoutImage} style={{width:'100%',height:'100%'}}/>
  )
}

function BlockPage(props) {
  return (
    <div className='error-page'>
      <table>
        <tbody>
          <tr>
            <td>
              <p><img alt="" src={blockImage} style={{width:'220px'}} /></p>
              <p>抱歉，您的IP地址不在允许范围内，无法访问本站</p>
              <p>Sorry, your IP address is not under our service coverage, unable to visit.</p>
              <p>Xin lỗi, địa chỉ IP của bạn không thuộc phạm vi dịch vụ của chúng tôi, không thể truy cập.</p>
              <p>ขออภัยที่อยู่ IP ของคุณไม่อยู่ภายใต้การให้บริการของเราไม่สามารถเยี่ยมชมได้</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function MaintenancePage(props) {
  return (
    <div className='error-page'>
      <table>
        <tbody>
          <tr>
            <td>
              <p><img alt="" src={maintainImage} style={{width:'220px'}} /></p>
              <p>预计于北京时间<span className="time">[ ]</span>完成</p>
              <p>Target completion by <span className="time">[ ]</span> Beijing Time</p>
              <p>Mục tiêu hoàn thành trước <span className="time">[ ]</span> Giờ Bắc Kinh</p>
              <p>กำหนดเป้าหมายให้เสร็จภายใน <span className="time">[ ]</span> เวลาปักกิ่ง</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function LoginExpiredPage(props) {
  return (
    <div className='error-page'>
      <table>
        <tbody>
          <tr>
            <td>
              <p><img alt="" src={loginImage} style={{width:'220px'}} /></p>
              <p>您还未登录或者登录超时</p>
              <p>You have not login or login time-out</p>
              <p>Bạn chưa đăng nhập hoặc đăng nhập hết thời gian</p>
              <p>คุณไม่ได้เข้าสู่ระบบหรือหมดเวลาเข้าสู่ระบบ</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function Error404Page(props) {
  return (
    <div className='error-404-page'>
      <table>
        <tbody>
          <tr>
            <td>
              <p><img alt="" src={eror404image}/></p>
              <p>无法显示此网页</p>
              <p>Unable to display this site</p>
              <p>Không thể hiển thị trang web này</p>
              <p>ไม่สามารถแสดงไซต์นี้</p>
              <p><img id="reload" alt="" src={reloadImage} className="icon" /> 
              <img id="back" alt=""src={backImage} className="icon" /></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function StopSitePage(props) {
  return (
    <div className='error-page'>
      <table>
        <tbody>
          <tr>
            <td>
              <p><img alt="" src={eror404image}/></p>
              <p>站点停用</p>
              <p>Site disabled</p>
              <p>Trang web bị vô hiệu hóa</p>
              <p>ไซต์ถูกปิดใช้งาน</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export { TimeoutPage, BlockPage, MaintenancePage, LoginExpiredPage, Error404Page, StopSitePage };
export default Stopuser;