import React, {useEffect, useRef} from "react";
import { withTranslation } from 'react-i18next';
import {animate} from "framer-motion";

function GameAward({ from, to }) {
    const ref = useRef();

    useEffect(() => {
        const controls = animate(from, to, {
            duration: 0.6,
            onUpdate(value) {
                ref.current.textContent = value.toFixed(2);
            }
        });
        return () => controls.stop();
    }, [from, to]);

    return <p style={{"margin":"0px"}} ref={ref} />;
}

export default withTranslation('common')(GameAward);
