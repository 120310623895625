import { animated as a } from "react-spring";
import styled from "styled-components";

import { device } from "../../utils/utilConstants";

const Container = styled(a.div)`
  z-index: 10;
`;

const Image = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  img {
    width: 50%;
  }
 
`;

export default { Container, Image };
