import {useState, useEffect, useRef} from "react";
import {withTranslation, useTranslation} from 'react-i18next';
import {store} from '../index';
import {styled} from '@stitches/react'
import {TextField, Button} from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {styled as styledMui} from '@mui/material/styles';
import RollingNumberBox from "../components/hashdice/RollingNumberBox";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useSelector, shallowEqual} from "react-redux";
import {getMemberBalance} from "../services/HomeServices";
import '../styles/HashDice/HashDice.css';
import io from 'socket.io-client';
import GameMoney from "../containers/GameMoney";
import gameConstants, {betAmtConstants} from "../utils/utilConstants";
import {SaveMemberInfo, UpdateAlert, saveIndexInit} from '../actions/HomePageAction';
import {getQuota} from "../services/QuotaServices";

const AppContainer = styled('div', {
    width: '100vw',
    height: '100vh',

    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
})

const InnerContainer = styled('div', {
    height: 'fit-content',
})

const StartButton = styledMui(Button)({
    color: 'white',
    border: '2px solid',
    borderColor: '#F24BF7',
    borderRadius: '15px',
    // width: '90px',
});
const AutoButton = styledMui(Button)({
    color: 'white',
    border: '2px solid',
    borderColor: '#F24BF7',
    borderRadius: '15px',
    // width: '90px',
});
const StopButton = styledMui(Button)({
    color: 'white',
    border: '2px solid',
    borderColor: '#F24BF7',
    borderRadius: '15px',
    // width: '90px',
});

const BlueButton = styledMui(Button)({
    color: 'white',
    backgroundColor: '#006ACE!important',
    borderRadius: '15px',
    // width: '90px',
});

const minCompareNum = 2000;
const maxCompareNum = 8000;

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

//const socket = io('http://localhost:1209/',{
  //transports: ["websocket"],
//});

let socket;

if (global.config.testEnv === true) {
    socket = io(global.config.minigame.socket.protocol + '://' + global.config.domain.instantaward + ':' + global.config.minigame.socket.port.hashdice + '/', {
        transports: ["websocket"],
    });
} else {
    socket = io(global.config.minigame.socket.protocol + '://' + global.config.minigame.socket.domain.hashdice + '/', {
        transports: ["websocket"],
    });
}

function HashDice() {

    const balanceMoney = useSelector(state => store.getState().memberInfo.MemberInfo.balanceMoney, shallowEqual);
    const [bigger, setBigger] = useState(4999);
    const [smaller, setSmaller] = useState(5000);
    const [randomNumber, setRandomNumber] = useState(0);
    const [profit, setProfit] = useState(0);
    const [odd, setOdd] = useState(2.02);
    const [winRate, setWinRate] = useState(50.0);
    const [betAmount, setBetAmount] = useState(Number(10.00).toFixed(2));
    const [minBetAmount, setMinBetAmount] = useState(Number(betAmtConstants.minAmt).toFixed(2));
    const [maxBetAmount, setMaxBetAmount] = useState(Number(betAmtConstants.maxAmt).toFixed(2));
    const [stateFlag, setStateFlag] = useState(0);
    const [isRolling, setIsRolling] = useState(false);
    const [betContent, setBetContent] = useState(0);
    const [resultText, setResultText] = useState("");
    const [gameResult, setGameResult] = useState("");
    const {t} = useTranslation();
    const isFirstRunBigger = useRef(true);
    const isFirstRunSmaller = useRef(true);
    const isFirstGameResult = useRef(true);
    const [isConnected, setIsConnected] = useState(socket.connected);

    const [isLock, setIsLock] = useState(false);
    const [autoBet, setAutoBet] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        socket.extraHeaders = {gametoken: store.getState().memberInfo.token};

        socket.on('connect', (e) => {
            // console.log("render once socket connected testing");
            console.log("socket on connect event listener");
            // console.log("web socket id : " + socket.id);
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            console.log("socket on disconnect event listener");
            setIsConnected(false);
        });

        socket.on('error message', (res) => {
            console.log("SOCKET ERROR MSG : " + JSON.stringify(res));
            setIsRolling(false);
            if (res.errMessage === "invalid_token_id") {
                window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
            } else if (res.errMessage.startsWith("facade_Max_balance")) {
                setAutoBet(false);
                setResultText(t('facade_Max_balance'));
                setIsLock(false);
            } else if (res.errMessage.startsWith("facade_Min_balance")) {
                setAutoBet(false);
                setResultText(t('facade_Min_balance'));
                setIsLock(false);
            }else{
                setAutoBet(false);
                setResultText(t(res.errMessage));
                setIsLock(false);
            }
        });

        socket.on('connect_error', (e) => {
            console.log("socket on connect error event listener");
            console.log("web socket connect error : " + e);
            setIsConnected(false);
            setIsRolling(false);
        });

        socket.io.on("error", (error) => {
            console.log("socket error event listener");
            console.log("web socket error : " + error);
            setIsRolling(false);
        });

        socket.on('bet result', (data) => {
            setTimeout(() => {
                handleBetResult(data);
            }, 2000);

        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('error message');
            socket.off('connect_error');
            socket.off('error');
            socket.off('bet result');
        };
    }, []);

    useEffect(() => {

        // console.log("detect changes on bigger");

        if (isFirstRunBigger.current) {
            isFirstRunBigger.current = false;
            return;
        }
        //detect changes start here

        // console.log("bigger test first run");

        setBetContent(bigger);
        setStateFlag(0);
    }, [bigger]);

    useEffect(() => {

        // console.log("detect changes on smaller");

        if (isFirstRunSmaller.current) {
            isFirstRunSmaller.current = false;
            return;
        }
        //detect changes start here

        // console.log("smaller test first run");

        setBetContent(smaller);
        setStateFlag(1);
    }, [smaller]);

    useEffect(() => {
        var localWinRate = 0;
        if (stateFlag === 0) {
            localWinRate = (9999 - bigger) / 100;
        } else {
            localWinRate = smaller / 100;
        }
        setWinRate(localWinRate);
    }, [betContent, stateFlag]);

    useEffect(() => {
        var localProfit = (betAmount * odd) - betAmount;
        setProfit(localProfit);
    }, [betAmount, odd]);

    useEffect(() => {
        calculateOdd();
    }, [winRate]);

    useEffect(() => {

        if (isFirstGameResult.current) {
            isFirstGameResult.current = false;
            return;
        }

        let resultTextTemp;
        if (gameResult.gameResult) {
            resultTextTemp = t('hashdice_win') + " " + betAmount + " + " + Number(gameResult.earnAmount - betAmount).toFixed(2) + "!";
        } else {
            resultTextTemp = t('hashdice_lose');
        }
        setResultText(resultTextTemp);
    }, [gameResult]);

    useEffect(() => {
        const autoPlay = async () => {
            await delay(3000);
            handleStart()
          };
        if(autoBet){
            autoPlay();
        }
    }, [resultText]);

    useEffect(() => {
        setResultText("");
    }, [betAmount]);

    const handleBetResult = (data) => {
        setRandomNumber(data.randomNumber);
        setIsRolling(false);
        getMemberBalance();
        setGameResult(data);
        if(!autoBet){
            setIsLock(false);
        }else{

        }
    };

    useEffect(() => {
        if(autoBet){
            setIsLock(true);
        }
    }, [isLock]);

    const handleStart = () => {
        if (stateFlag === 0) {
            if (bigger < Number(minCompareNum) || bigger > Number(maxCompareNum)) {
                setResultText(t('hashdice_enter_number_error'));
                return;
            }
        } else {
            if (smaller < Number(minCompareNum) || smaller > Number(maxCompareNum)) {
                setResultText(t('hashdice_enter_number_error'));
                return;
            }
        }

        if (Number(betAmount) < Number(minBetAmount)) {
            setResultText(t('facade_Min_balance') + Number(minBetAmount).toFixed(2));
            return;
        }

        if (isConnected === true) {
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            if (Number(latestBalance) < Number(betAmount)) {
                setAutoBet(false);
                setResultText(t('hashdice_not_enough_balance'));
                return;
            }else{
                if (isRolling === false) {
                    setIsRolling(true);
    
                    let memberInfo = store.getState().memberInfo;
    
                    let token = memberInfo.token;
                    let loginName = memberInfo.MemberInfo.loginName;
    
                    let jsonData = {
                        token: token,
                        betAmount: betAmount,
                        stateFlag: stateFlag,
                        compareNum: stateFlag === 0 ? bigger : smaller,
                        chance: winRate,
                        amount: latestBalance,
                        username: loginName
                    };
                    setResultText(t(''));
                    socket.emit("bet info", jsonData);
                    setIsLock(true);
                    
                    let memberObj = store.getState().memberInfo.MemberInfo;
                    memberObj.balanceMoney = memberObj.balanceMoney - betAmount;
                    store.dispatch(SaveMemberInfo(memberObj));
                }
            }
        } else {
            setResultText(t('hashdice_cant_find_server'));
        }
    };

    const handleAutoBet = () => {
        var auto = !autoBet;
        if(auto){
            setAutoBet(auto);
        }else{
            setAutoBet(auto);
        }
        
    };

    useEffect(() => {
        const setautoPlayBlock = async () => {
            await delay(3500);
            setPending(false);
            setIsLock(false);
          };
        if(autoBet) {
            if(!pending){
                setPending(true);
                handleStart();
            }
        }else{
            setautoPlayBlock();
        }
    }, [autoBet]);

    const minBet = () => {
        if (!isRolling) {
            setBetAmount(Number(minBetAmount).toFixed(2));
        }
    };

    const maxBet = () => {
        if (!isRolling) {
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            if (latestBalance <= maxBetAmount) {
                setBetAmount(latestBalance.toFixed(2));
            } else {
                setBetAmount(Number(maxBetAmount).toFixed(2));
            }
        }
    };

    const doubleBet = () => {
        if (!isRolling) {
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            let calculateAmount = betAmount * 2;
            if (calculateAmount <= maxBetAmount) {
                if (calculateAmount > Number(latestBalance)) {
                    calculateAmount = Number(latestBalance);
                }
            } else {
                calculateAmount = Number(maxBetAmount).toFixed(2);
            }
            setBetAmount(Number(calculateAmount).toFixed(2));
        }
    };

    const halfBet = () => {
        if (!isRolling) {
            let calculateAmount = betAmount / 2;
            if (calculateAmount < minBetAmount) {
                calculateAmount = Number(minBetAmount).toFixed(2);
            }
            setBetAmount(Number(calculateAmount).toFixed(2));
        }
    };

    const handleSmaller = (event) => {
        if (event.target.value.match(/^-?\d{0,4}$/g)) {
            setSmaller(event.target.value);
        }
    };

    const handleBigger = (event) => {
        if (event.target.value.match(/^-?\d{0,4}$/g)) {
            setBigger(event.target.value);
        }
    };

    const handleBetAmt = (event) => {
        const regex = /^(\d*\.{0,1}\d{0,2}$)/;
        if(regex.test(event.target.value)) {
            setBetAmount(event.target.value);
        }
    };

    const calculateOdd = () => {
        // console.log("--- calculateOdd --winRate: " + winRate);
        let calcOdd = Math.round((101.0 / winRate) * 100) / 100;
        setOdd(calcOdd);
        // console.log("--- calculateOdd -- new winRate: " + winRate);
    };

    useEffect(() => {
        getQuota("hashdice", updateQuotaCallback);
    }, []);

    const updateQuotaCallback = (res) => {
        if(res !== '' && res !== undefined) {
            if(res.minBetAmount !== null) {
                setBetAmount(Number(res.minBetAmount).toFixed(2));
                setMinBetAmount(Number(res.minBetAmount).toFixed(2));
            }
            if(res.maxBetAmount !== null) setMaxBetAmount(Number(res.maxBetAmount).toFixed(2));
        } else {
            setBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMinBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMaxBetAmount(Number(betAmtConstants.maxAmt).toFixed(2));
        }
    };

    return (
        <>
            <AppContainer id="gameHashdice" className="game-bg-web">
                <InnerContainer className="innerContainer">

                    <div className="react-game-hashdice">
                        <Container fluid>
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                            </Row>
                            <GameMoney gameName={"hashdice"}/>

                            <Row>
                                <Col>
                                    {stateFlag === 1 ?
                                        <div className="up-smaller">
                                            <div className="btn-up-bg-image">
                                                <TextField type='text'
                                                           autoComplete="off"
                                                           InputProps={{
                                                               disableUnderline: true, readOnly:isLock
                                                           }}
                                                           onChange={(event) => handleSmaller(event)}
                                                           hiddenLabel variant="standard" value={smaller}/>
                                            </div>
                                            <div>
                                                <KeyboardArrowDownIcon/>
                                            </div>
                                        </div>
                                        :
                                        <div style={{height: '79.84px'}}><span> &nbsp; </span></div>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="center-random-number">
                                    <RollingNumberBox isRolling={isRolling}
                                                      randomNumber={randomNumber}></RollingNumberBox>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {stateFlag === 0 ?
                                        <div className="down-bigger">
                                            <div>
                                                <KeyboardArrowDownIcon/>
                                            </div>
                                            <div className="btn-down-bg-image">
                                                <TextField type='text'
                                                           autoComplete="off"
                                                           InputProps={{
                                                               disableUnderline: true, readOnly:isLock
                                                           }}
                                                           onChange={(event) => handleBigger(event)}
                                                           hiddenLabel variant="standard" value={bigger}/>
                                            </div>
                                        </div>
                                        :
                                        <div style={{height: '79.84px'}}><span> &nbsp; </span></div>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span> &nbsp; </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="result-text-col">
                                    <span> {resultText} </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span> &nbsp; </span>
                                </Col>
                            </Row>
                            <Row className="buttons-group">
                                <Col className="left-bigger-button"><BlueButton onClick={() => isRolling ? null : setStateFlag(0)}
                                                                                endIcon={
                                                                                    <KeyboardDoubleArrowUpIcon/>} disabled={isLock}>{t('hashdice_high')}</BlueButton></Col>
                                <Col className="center-random-number-button"><StartButton disabled={isLock}
                                                                                          onClick={handleStart}>{t('play')}</StartButton></Col>
                                <Col className="right-smaller-button"><BlueButton onClick={() => isRolling ? null : setStateFlag(1)}
                                                                                  startIcon={
                                                                                      <KeyboardDoubleArrowDownIcon/>} disabled={isLock}>{t('hashdice_low')}</BlueButton></Col>
                            </Row>
                            <Row className="buttons-group2" style={{marginTop:'30px'}}>
                                {!autoBet?<>
                                    {!pending?
                                    <Col className="center-random-number-button2"><AutoButton onClick={handleAutoBet}>{t('auto_bets')}</AutoButton></Col>
                                    :<Col className="center-random-number-button2"><AutoButton >{t('auto_bets')}</AutoButton></Col>
                                    }</>
                                :
                                    <Col className="center-random-number-button3"><StopButton onClick={handleAutoBet}>{t('stop_bets')}</StopButton></Col>
                                }
                            </Row>
                            <Row>
                                <Col className="score-odd-panel">
                                    <Container fluid className="bottom-panel">
                                        <Row>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                        <Row>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div
                                                    className="bottom-left-money-label">{t('hashdice_play_amount')}</div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col className="bottom-left">
                                                <div className="bottom-left-bg">
                                                    <div className="bottom-left-money-value">
                                                        <TextField type='text'
                                                                   InputProps={{disableUnderline: true, readOnly:isLock}}
                                                                   autoComplete="off"
                                                                   onChange={(event) => handleBetAmt(event)}
                                                                   hiddenLabel
                                                                   variant="standard"
                                                                   value={betAmount}/>
                                                    </div>
                                                    <div className="bet-option">
                                                        <BlueButton size="small"
                                                                    onClick={minBet} disabled={isLock}>{t('hashdice_min')}</BlueButton>
                                                        <BlueButton size="small" onClick={halfBet} disabled={isLock}>1/2</BlueButton>
                                                        <BlueButton size="small" onClick={doubleBet} disabled={isLock}>2x</BlueButton>
                                                        <BlueButton size="small"
                                                                    onClick={maxBet} disabled={isLock}>{t('hashdice_max')}</BlueButton>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div
                                                    className="bottom-right-stat-label">{t('hashdice_profit_on_win')}</div>
                                            </Col>
                                            <Col>
                                                <div className="bottom-right-stat-label">{t('hashdice_odds')}</div>
                                            </Col>
                                            <Col>
                                                <div className="bottom-right-stat-label">{t('hashdice_chance')}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="bottom-right-stat">{profit.toFixed(2)}</div>
                                            </Col>
                                            <Col>
                                                <div className="bottom-right-stat"><span>{Number(odd).toFixed(2)}</span>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="bottom-right-stat">
                                                    <span>{Number(winRate).toFixed(2)}%</span></div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                        <Row>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                        </Container>
                    </div>
                </InnerContainer>

            </AppContainer>
        </>
    );
}

export default withTranslation('common')(HashDice);