import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../locales/en/common.json';
import vi from '../locales/vi/common.json';
import zh from '../locales/zh/common.json';
import th from '../locales/th/common.json';
import hi from '../locales/in/common.json';
import id from '../locales/id/common.json';
import pt from '../locales/pt/common.json';
import es from '../locales/es/common.json';
import fr from '../locales/fr/common.json';
import ru from '../locales/ru/common.json';

const Detector = new LanguageDetector();

const i18nextConfig = {
  whitelist: ['en', 'zh', 'vi', 'th', 'in', 'id', 'pt', 'es', 'fr', 'ru'],
  ns: ['common'],
  defaultNS: 'common',
  fallbackLng: 'zh'
};

function configureI18n() {
  i18n
    .use(Detector)
    .use(initReactI18next)
    .init({
      resources: {
        en: { common: en },
        zh: { common: zh },
		    vi: { common: vi },
        th: { common: th },
        in: { common: hi },
        id: { common: id },
        pt: { common: pt },
        es: { common: es },
        fr: { common: fr },
        ru: { common: ru },
      },
      cache: {
        enable: true,
        expirationTime: Infinity,
      },
      detection: {
        lookupLocalStorage: 'i18nextLng',
        caches: ['localStorage'],
        excludeCacheFor: ['cimode']
      },
      fallbackLng: i18nextConfig.fallbackLng,
      ns: i18nextConfig.ns,
      defaultNS: i18nextConfig.defaultNS,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
      react: {
        useSuspense: false
      },
      nonExplicitWhitelist: true,
      load: 'currentOnly',
    });
}

export default configureI18n;
