import { animated as a } from "react-spring";
import styled from "styled-components";

import { device } from "../../utils/utilConstants";
import desk4 from "../../assets/images/seven1121/desk4.png"

const Container = styled.div`
  display: flex;
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
  bottom: 2%;
  width: 100%;
  z-index: 11;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  gap: 10px;
`;

const Item = styled(a.div)`
  position: initial;
  width: 17%;
  cursor: pointer;
  margin-left: -10%;
  margin-top: 12%;

  &:active {
    cursor: pointer;
  }
  
  img {
    will-change: transform;
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
  }
  img:hover {
    margin-top: -10%;
  }
`;

export default { Container, Item };
