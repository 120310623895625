import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, shallowEqual} from "react-redux";

import Result from "../../components/seven1121/Result";
import ImageNumber from "../../components/seven1121/ImageNumber";
import GameAward from "../../containers/GameAward";
import {store} from "../../index";
import helpers from "../../utils/helpers";
import '../../styles/Seven1121/Seven1121.css'
import {useTranslation} from "react-i18next";
import card_front_purple from "../../assets/images/seven1121/card_front_purple.png"
import card_front_green from "../../assets/images/seven1121/card_front_green.png"
import card_front_blue from "../../assets/images/seven1121/card_front_blue.png"
import card_back_purple from "../../assets/images/seven1121/card_back_purple.png"
import card_back_green from "../../assets/images/seven1121/card_back_green.png"
import card_back_blue from "../../assets/images/seven1121/card_back_blue.png"
import Plus from "../../assets/images/seven1121/Plus.png"
import Minus from "../../assets/images/seven1121/Minus.png"
import box_prize from "../../assets/images/seven1121/box_prize.png"
import box_winprize from "../../assets/images/seven1121/box_winprize.png"
import ReactCardFlip from "react-card-flip";

function GameResult() {
    const {result1, result2, result3, sumArray, prize, sum1, sum2, sum3} = useSelector(state => store.getState().gameSeven1121, shallowEqual);
    const {t} = useTranslation();
    function renderResultNumberImage(desk,result) {
        let windowWidth = window.innerWidth<=380?true:false;
        return result.map((ing, j) => (
            <Col key={j} style={{padding:"0px",display: 'contents',height: 'fit-content'}}>
                <ReactCardFlip isFlipped={ing === -1 ? false:true} flipDirection="horizontal">
                    <ImageNumber.Container>
                        <ImageNumber.image2>
                            <img src={desk==1?card_front_purple:desk==2?card_front_blue:card_front_green} alt={'Star2'} style={{width: '50px',height: '70px'}}/>
                        </ImageNumber.image2>
                    </ImageNumber.Container>
                    <ImageNumber.Container>
                        <ImageNumber.image2>
                        {ing != -1 ? 
                            <div style={{backgroundImage:`url(${desk==1?card_back_purple:desk==2?card_back_blue:card_back_green})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', width: '50px', height:'70px'}}>
                                <div style={{display: 'inline-block',width: '50px',textAlign:'center'}}>
                                    <img src={require("../../assets/images/seven1121/number 0-28/no"+ing+".png")} alt={'Star2'} 
                                        style={{width: '50px',paddingTop:'20%'}}/>
                                </div>
                            </div>
                            :
                            <div style={{backgroundImage:`url(${desk==1?card_back_purple:desk==2?card_back_blue:card_back_green})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', width: '50px', height:'70px'}}>
                                <div style={{display: 'inline-block',width: '50px'}}>
                                </div>
                            </div>
                        }
                        </ImageNumber.image2>
                    </ImageNumber.Container>
                </ReactCardFlip>
                <ImageNumber.Container>
                    {j < 2?
                        <img src={Plus} alt={'Plus'} style={{width: '30px',height: '30px',margin: '22px 0px'}}/>  
                        :
                        <img src={Minus} alt={'Plus'} style={{width: '30px',height: '30px',margin: '22px 0px'}}/>  
                    }
                </ImageNumber.Container>
            </Col>
        ));
    };

    function renderNumbersSum(idx, inputSum,result) {
        let num = sumArray[idx] === undefined ? "" : sumArray[idx];
        return ((num === inputSum) && !result.includes(-1)) ? 
        !(inputSum == 7 || inputSum == 11 || inputSum == 21)?
        <>
        {/* <img src={require("../../assets/images/seven1121/light.png")} alt={'Star2'} className="lightRotate"
            style={{width: windowWidth?'70px':'78px',marginLeft: '5%'}}/> */}
        {/* <img src={require("../../assets/images/seven1121/number 0-28/no"+inputSum+".png")} alt={'Star2'} 
            style={{width: windowWidth?'60px':'70px',paddingTop:windowWidth?'50%':'50%',marginTop: '-120%',marginLeft: '10%',zIndex: 2,position: 'relative',height:'fit-content',paddingBottom: '22%'}}/> */}
        <div style={{paddingBottom: '18%'}}>
            <img src={require("../../assets/images/seven1121/light_effect.gif")} alt="Star2"
                style={{width: '65px',zIndex: 100,mixBlendMode: 'lighten',position: 'relative'}}/>
            <img src={require("../../assets/images/seven1121/number 0-28/no"+inputSum+".png")} alt={'Star2'} 
                style={{width: '60px',marginLeft: '-65px'}}/>
        </div>
        </>
        :
        <div style={{paddingBottom: '18%'}}>
            <img src={require("../../assets/images/seven1121/number 0-28/no"+inputSum+".png")} alt={'Star2'} 
                style={{width: '60px'}}/>
        </div>
        : 
        <div style={{paddingBottom: '18%'}}>
            <img src={require("../../assets/images/seven1121/question_mark.png")} alt={'Star2'} 
                style={{width: '60px'}}/>
        </div>
        ;
    };

    function renderPrizes(idx, inputSum, result) {
        let pz = prize[idx] === undefined ? 0 : prize[idx];
        let filterResult = helpers.filterItems(-1, result);
        let windowWidth = window.innerWidth<=380?true:false;
        return (
                ((inputSum === 7 || inputSum === 11 || inputSum === 21) && filterResult.length === 3) ?
                    <div style={{backgroundImage:`url(${box_winprize})`, backgroundSize:'cover', backgroundRepeat:'no-repeat',width: window.innerWidth>=360?'34%':window.innerWidth>=350?'35%':window.innerWidth>=340?'36%':window.innerWidth>=330?'38%':window.innerWidth>=320?'39%':window.innerWidth>=300?'40%':'44%',height: '60px',marginTop: windowWidth?'-44px':'-47px',position: 'relative',float: 'right',zIndex: 3,height: 'fit-content',marginRight: '-3%',textAlign:'center'}}>
                        <div style={{paddingLeft: '10%',lineHeight: '16px',fontSize: '14px', marginTop: '13%'}}>
                            <span style={{fontWeight: 'bold'}}>{t('winner')}</span><GameAward from={0} to={pz} />
                        </div>
                    </div>
                     :
                    <div style={{backgroundImage:`url(${box_prize})`, backgroundSize:'cover', backgroundRepeat:'no-repeat',width: window.innerWidth>=360?'34%':window.innerWidth>=350?'35%':window.innerWidth>=340?'36%':window.innerWidth>=330?'38%':window.innerWidth>=320?'39%':window.innerWidth>=300?'40%':'44%',height: '60px',marginTop: windowWidth?'-44px':'-47px',position: 'relative',float: 'right',zIndex: 3,color:'white',height: 'fit-content',marginRight: '-3%',textAlign:'center'}}>
                        <div style={{paddingLeft: '10%',lineHeight: '16px',fontSize: '14px', marginTop: '13%'}}>
                        <span style={{fontWeight: 'bold'}}>{t('prize')}</span><GameAward from={0} to={pz} />
                        </div>
                    </div>
        )
    };

    return (
        <>
            <Result.Container>
                <div style={{backgroundColor:'black',borderWidth: '4px',borderStyle: 'solid',borderImage: 'linear-gradient(to right, #d281fa, #da89f8, #e292f7, #e89af6, #eea3f5) 1',borderBottom: 'none',zoom:window.innerWidth>=360?'1':window.innerWidth>=320?'0.9':window.innerWidth>=300?'0.8':window.innerWidth>=280?'0.75':'0.7'}}>
                    <Row style={{width: '100%',marginTop: '3%',marginLeft: '3%',marginBottom: '3%'}}>
                        {renderResultNumberImage(1,result1)}
                        <Col style={{padding:'0px',height:'fit-content',textAlign: 'center'}}>{renderNumbersSum(0, sum1,result1)}</Col>
                    </Row>
                </div>
                <Row style={{marginTop: '-3.5%',width: '100%',marginLeft: '0.2px',}}>
                    <Col style={{backgroundImage: 'linear-gradient(to right, #d281fa, #da89f8, #e292f7, #e89af6, #eea3f5)',fontSize: '12px',height: '15px',textAlign: 'left',fontWeight: '800', paddingLeft:'35%'}}>{t('deck1')}</Col>
                </Row>
                {renderPrizes(0, sum1, result1)}
                <div style={{backgroundColor:'black',borderWidth: '4px',borderStyle: 'solid',borderImage: 'linear-gradient(to right, #d281fa, #da89f8, #e292f7, #e89af6, #eea3f5) 1',marginTop:'10px',borderBottom: 'none',zoom:window.innerWidth>=360?'1':window.innerWidth>=320?'0.9':window.innerWidth>=300?'0.8':window.innerWidth>=280?'0.75':'0.7'}}>
                    <Row style={{width: '100%',marginTop: '3%',marginLeft: '3%',marginBottom: '3%'}}>
                        {renderResultNumberImage(2,result2)}
                        <Col style={{padding:'0px',height:'fit-content',textAlign: 'center'}}>{renderNumbersSum(1, sum2,result2)}</Col>
                    </Row>
                </div>
                <Row style={{marginTop: '-3.5%',width: '100%',marginLeft: '0.2px',}}>
                    <Col style={{backgroundImage: 'linear-gradient(to right, #d281fa, #da89f8, #e292f7, #e89af6, #eea3f5)',fontSize: '12px',height: '15px',textAlign: 'left',fontWeight: '800', paddingLeft:'35%'}}>{t('deck2')}</Col>
                </Row>
                {renderPrizes(1, sum2, result2)}
                <div style={{backgroundColor:'black',borderWidth: '4px',borderStyle: 'solid',borderImage: 'linear-gradient(to right, #d281fa, #da89f8, #e292f7, #e89af6, #eea3f5) 1',marginTop:'10px',borderBottom: 'none',zoom:window.innerWidth>=360?'1':window.innerWidth>=320?'0.9':window.innerWidth>=300?'0.8':window.innerWidth>=280?'0.75':'0.7'}}>
                    <Row style={{width: '100%',marginTop: '3%',marginLeft: '3%',marginBottom: '3%'}}>
                        {renderResultNumberImage(3,result3)}
                        <Col style={{padding:'0px',height:'fit-content',textAlign: 'center'}}>{renderNumbersSum(2, sum3,result3)}</Col>
                    </Row>
                </div>
                <Row style={{marginTop: '-3.5%',width: '100%',marginLeft: '0.2px',}}>
                    <Col style={{backgroundImage: 'linear-gradient(to right, #d281fa, #da89f8, #e292f7, #e89af6, #eea3f5)',fontSize: '12px',height: '15px',textAlign: 'left',fontWeight: '800', paddingLeft:'35%'}}>{t('deck3')}</Col>
                </Row>
                {renderPrizes(2, sum3, result3)}
            </Result.Container>
        </>
    );
}

export default GameResult;
