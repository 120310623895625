import React, {useEffect} from "react";
import {useTranslation, withTranslation} from 'react-i18next';

import Message from "../../components/default/Message";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {store} from "../../index";
import gameConstants from "../../utils/utilConstants";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

function GameMessage() {
    const {t} = useTranslation();
    const {showMessage, message, messageType, earnAmount} = useSelector(state => store.getState().gameSeven1121, shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        if(showMessage) {
            // makeMessage();
        }
        async function makeMessage() {
            await delay(2000);
            dispatch({ type: gameConstants.HIDE_MESSAGE });
        }
    });

    return (
        <>
            {messageType === 1 ?
                <>
                <Message.Container>
                        <Message.Header smallfont wht>{showMessage === true ? message : ""} </Message.Header>
                </Message.Container>
                </>
                :
                messageType === 2 ?
                <>
                <Message.Container>
                    <Message.Header smallfont wht>{showMessage === true ? message : ""} <br/> {earnAmount.toFixed(2)}
                    </Message.Header>
                </Message.Container>
                </>
                 : ""
            }
        </>
    );
}

export default withTranslation('common')(GameMessage);
