import {store} from '../index';
import gameConstants from "../utils/utilConstants";
import i18next from "i18next";
import helpers from "../utils/helpers";

const tran = (input) => {
    return i18next.t(input);
};

const playGame = function (start, betAmt, selectedNum, clickNumbers, cb) {
    const memberInfo = store.getState().memberInfo;
    const memberObj = store.getState().memberInfo.MemberInfo;

    if (start === true) {
        let dataParam = new URLSearchParams();
        dataParam.append('betValue', betAmt);
        dataParam.append('ChooseNumbers', clickNumbers.join(','));
        dataParam.append('ClickNumber', selectedNum.toString());
        dataParam.append('clientSeed', memberObj.clientSeed);
        dataParam.append('nonce', memberObj.nonce);

        fetch(global.config.domain.member + "/Keno/Play", {
            method: "Post",
            headers: new Headers({
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'gametoken': memberInfo.token,
            }),
            body: dataParam,
            credentials: 'include'
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.code === 200) {
                    store.dispatch({ type: gameConstants.UPDATE_KENO_DATA,
                        ballNumbers: res.BallNumbers,
                        earnAmount: res.WinMoney,
                        clickNumbers: clickNumbers,
                        selectedNum: selectedNum});
                    res.WinMoney > 0 ?
                            store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: '', messageType: 2 }) :
                            store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: '', messageType: 3 });
                    cb(true);
                } else {
                    const strArr = helpers.stringSplit(res.message, "=");
                    const msg = strArr.length > 1 ? tran(strArr[0]) + tran(strArr[1]) : tran(res.message);
                    store.dispatch({type: gameConstants.SHOW_MESSAGE, message: msg, messageType: 1});
                    cb(false);
                }
            }).catch((error) => {
                cb(false);
                store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: tran("server_error"), messageType: 1 });
                console.log(error);
        })
    }
};

export {playGame}