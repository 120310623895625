import {useState, useEffect, useRef} from "react";
import {withTranslation, useTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {TextField, Button} from '@mui/material';
import {useSelector, shallowEqual} from "react-redux";
import '../styles/LuckyCircle/LuckyCircle.css'
import _ from 'lodash';
import io from 'socket.io-client';
import {store} from "../index";
import {motion, useTime, useTransform} from "framer-motion";
import {getMemberBalance} from "../services/HomeServices";
import GameMoney from "../containers/GameMoney";
import gameConstants, {betAmtConstants} from "../utils/utilConstants";
import {getQuota} from "../services/QuotaServices";

const AppContainer = styled('div')({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
});

const InnerContainer = styled('div')({
    // width: '1250px',
    height: 'fit-content',
});

const GridContainer = styled('div')({
    display: 'grid',
});

const GameButtonBlack = styled(Button)({
    backgroundColor: '#121D23!important',
    borderRadius: '5px',
    height: '28px',
    margin: '8px 5px',
    fontSize: '10px',
    padding: '0 0 0 0',
});

// const socket = io('http://localhost:1211/',{
//   transports: ["websocket"],
// });

let socket;
if (global.config.testEnv === true) {
    socket = io(global.config.minigame.socket.protocol + '://' + global.config.domain.instantaward + ':' + global.config.minigame.socket.port.luckycircle + '/', {
        transports: ["websocket"],
    });
} else {
    socket = io(global.config.minigame.socket.protocol + '://' + global.config.minigame.socket.domain.luckycircle + '/', {
        transports: ["websocket"],
    });
}

const totalTile = 135;

function LuckyCircle() {

    const balanceMoney = useSelector(state => store.getState().memberInfo.MemberInfo.balanceMoney, shallowEqual);
    const [tempArray, setTempArray] = useState([]);
    const [cellToOpen, setCellToOpen] = useState(55);
    const [timeLeft, setTimeLeft] = useState(300);
    const [betAmount, setBetAmount] = useState(Number(10.00).toFixed(2));
    const [minBetAmount, setMinBetAmount] = useState(Number(betAmtConstants.minAmt).toFixed(2));
    const [maxBetAmount, setMaxBetAmount] = useState(Number(betAmtConstants.maxAmt).toFixed(2));
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [gameState, setGameState] = useState(2);
    const [totalTileValue, setTotalTileValue] = useState([]);
    const [pressTileValue, setPressTileValue] = useState([]);
    const [pressTilePosition, setPressTilePosition] = useState([]);
    const isFirstRunInitTileState = useRef(true);
    const [rows, setRows] = useState([]);
    const [isLCGameStarted, setIsLCGameStarted] = useState(false);
    //check is game reset
    const isGameResetting = useRef(false);
    //animation
    const time = useTime();
    const rotate = useTransform(time, [0, 4000], [0, 360], {clamp: false});
    const [usersList, setUsersList] = useState([]);
    const {t} = useTranslation();
    const [resultText, setResultText] = useState("");

    useEffect(() => {
        //console.log("--- useEffect -- []");

        let intervalObj;

        handleInitArrayGame();

        socket.extraHeaders = {gametoken: store.getState().memberInfo.token};

        socket.on('connect', (e) => {
            //console.log("socket on connect event listener");
            // console.log("web socket id : " + socket.id);
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            //console.log("socket on disconnect event listener");
            setIsConnected(false);
        });

        socket.on('error message', (res) => {
            //console.log("SOCKET ERROR MSG : " + JSON.stringify(res));
            if (res.errMessage === "invalid_token_id") {
                window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
            } else if (res.errMessage.startsWith("facade_Max_balance")) {
                setResultText(t('facade_Max_balance') + Number(maxBetAmount).toFixed(2));
                // setIsLock(false);
            } else if (res.errMessage.startsWith("facade_Min_balance")) {
                setResultText(t('facade_Min_balance') + Number(minBetAmount).toFixed(2));
                // setIsLock(false);
            }else{
                setResultText(t(res.errMessage));
            }
        });

        socket.on('connect_error', (e) => {
            //console.log("socket on connect error event listener");
            //console.log("web socket connect error : " + e);
            setIsConnected(false);
        });

        socket.io.on("error", (error) => {
            //console.log("socket error event listener");
            //console.log("web socket error : " + error);
        });

        socket.on('init tileposition', (data) => {
            //console.log("socket on init tileposition event listener");
            // console.log("tileposition : " + JSON.stringify(data));
            handleInitTilePosition(data);
        });

        socket.on('update state', (data) => {
            // console.log("socket on game result event listener");
            // console.log("update state jsonData: " + JSON.stringify(data));
            handleUpdateState(data);
        });

        socket.on('press tile', (data) => {
            //console.log("socket on press tile event listener");
            // console.log("socket data press tile : " + JSON.stringify(data));
            handlePressTile(data);
        });

        socket.on('userslist', (data) => {
            //console.log("socket on userslist event listener");
            // console.log("socket data userslist : " + JSON.stringify(data));
            handleUsersList(data);
        });

        handleEnterRoom();

        intervalObj = handleUpdateStateInterval();

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('error message');
            socket.off('connect_error');
            socket.off('error');
            socket.off('init tileposition');
            socket.off('update state');
            socket.off('press tile');
            socket.off('userslist');
            clearInterval(intervalObj);
        };

    }, []);

    useEffect(() => {

        if (isConnected === true) {

            if (isFirstRunInitTileState.current) {

                let memberInfo = store.getState().memberInfo;
                let loginName = memberInfo.MemberInfo.loginName;
                let token = memberInfo.token;
                let lang = memberInfo.language;
                let url = window.location.href;

                let jsonData = {
                    username: loginName,
                    token: token,
                    url: url,
                    lang: lang
                };

                // console.log("init tilestate jsonData: " + JSON.stringify(jsonData));

                socket.emit("init tilestate", jsonData);

                isFirstRunInitTileState.current = false;
                return;
            }

        }

    }, [isConnected]);

    useEffect(() => {

        initGameTiles();

    }, [tempArray]);

    useEffect(() => {
        //console.log("--- useEffect --- totalTileValue");
        let newPressTilePosition = _.cloneDeep(tempArray);
        let newPressTileValue = _.cloneDeep(totalTileValue);

        let newData = {
            pressTilePosition: newPressTilePosition,
            pressTileValue: newPressTileValue,
        };

        handleInitTilePosition(newData);

    }, [totalTileValue]);

    useEffect(() => {
        if (pressTileValue?.length === pressTilePosition?.length) {
            initGameTiles();
        }
    }, [pressTileValue, pressTilePosition]);

    useEffect(() => {
        //console.log("isLCGameStarted :" + isLCGameStarted);
        if (pressTileValue?.length === pressTilePosition?.length) {
            initGameTiles();
        }
    }, [isLCGameStarted]);

    useEffect(() => {
        if (pressTileValue?.length === pressTilePosition?.length) {
            initGameTiles();
        }
    }, [cellToOpen]);

    useEffect(() => {
        setResultText("");
        if (pressTileValue?.length === pressTilePosition?.length) {
            initGameTiles();
        }
    }, [betAmount]);

    const handleInitTilePosition = (data) => {

        let newPressTileValue = data.pressTileValue;
        let newPressTilePosition = data.pressTilePosition;

        setPressTilePosition(newPressTilePosition);
        setPressTileValue(newPressTileValue);
    }

    const handlePressTile = (data) => {
        let tileValue = data.tileValue;
        let tileIndex = data.tileIndex;

        getMemberBalance();

        setPressTilePosition(prevState => [...prevState, tileIndex]);
        setPressTileValue(prevState => [...prevState, tileValue]);
    }

    const handleInitArrayGame = () => {
        let tempAry = [];
        for (let x = 0; x < totalTile; x++) {
            tempAry.push(x);
        }
        setTempArray(tempAry);
    }

    const handleStartButton = () => {
        //console.log("--- handleStartButton --- : " + isLCGameStarted);
        setResultText('');
        let memberInfo = store.getState().memberInfo;
        let loginName = memberInfo.MemberInfo.loginName;
        let token = memberInfo.token;

        let jsonData = {
            username: loginName,
            amount: balanceMoney,
            betAmount: betAmount,
            token: token
        };

        socket.emit("validateGame", jsonData, (callbackData) => {
            if (callbackData != null) {
                setIsLCGameStarted(prevState => !prevState);
            }
        });
        
    }

    const handleEndButton = () => {
        //console.log("--- handleEndButton ---" + isLCGameStarted);
        setIsLCGameStarted(prevState => !prevState);
    }

    const handleUpdateState = (data) => {

        if (data.gamestate === 0) {
            let timeLeft = parseInt(data.leftTime / 1000);
            setTimeLeft(timeLeft);
            setCellToOpen(data.tileNum);
            setGameState(0);

            if (isGameResetting.current === true) {

                resetGameTiles();

                isGameResetting.current = false;
            }

        } else if (data.gamestate === 1) {
            //console.log("---handleUpdateState --- game resetting");
            // console.log("---data : " + JSON.stringify(data));
            setTotalTileValue(data.totalTileValue);
            setGameState(1);
            isGameResetting.current = true;
        }
    }

    const handleEnterRoom = () => {
        //console.log("--- handleEnterRoom ---");
        let memberInfo = store.getState().memberInfo;
        let loginName = memberInfo.MemberInfo.loginName;
        let token = memberInfo.token;
        let lang = memberInfo.language;
        let url = window.location.href;

        let jsonData = {
            username: loginName,
            token: token,
            url: url,
            lang: lang
        };

        socket.emit("enterroom", jsonData, (callbackData) => {

            if (callbackData.status === 1) {

                setPressTilePosition(callbackData.pressTilePosition);
                setPressTileValue(callbackData.pressTileValue);
            }
        });
    }

    const handleUpdateStateInterval = () => {
        //console.log("--- handleUpdateStateInterval ---");

        return setInterval(() => {

            let memberInfo = store.getState().memberInfo;
            let loginName = memberInfo.MemberInfo.loginName;
            let token = memberInfo.token;
            let lang = memberInfo.language;
            let url = window.location.href;

            let jsonData = {
                username: loginName,
                token: token,
                url: url,
                lang: lang
            };

            // console.log("jsonData: " + JSON.stringify(jsonData));

            socket.emit("update state", jsonData);
        }, 1000);
    }

    const initGameTiles = () => {
        // console.log("--- initGameTiles ---");
        if (tempArray.length !== 0) {

            let columns = [];
            let colorToggle = true;
            setRows([]);
            let rowCounter = 0;

            tempArray.forEach((data, index) => {

                if (index !== 0 && index % 10 === 0) {
                    let newArray = _.cloneDeep(columns);
                    setRows(prevRows => [...prevRows,
                        <Row key={rowCounter++} className="center-ball-row"><Col>{newArray}</Col></Row>]);
                    columns = [];
                    colorToggle = !colorToggle;
                }

                let ballColor;
                if (colorToggle === false) {
                    ballColor = 'red';
                } else {
                    ballColor = 'green';
                }

                let isBallOpen = "";
                let ballOpenValue = "";

                if (pressTilePosition.includes(index)) {
                    if (pressTileValue?.length === pressTilePosition?.length) {
                        isBallOpen = "_open"
                        ballOpenValue = Number(pressTileValue[pressTilePosition.indexOf(index)]).toFixed(1);
                    }
                }

                let ballImg = <GridContainer key={index + '_grid'}>
                    <img key={index + '_img'} alt={index + '_img'} className="tile-open-image"
                         onClick={() => handleTileClick(index)}
                         src={require(`../assets/images/luckycircle/lucky_circle_ball_${ballColor}${isBallOpen}.png`)}/>
                    {isBallOpen !== "" ?
                        <span key={index + '_span'} className="tile-open-value">{ballOpenValue}</span>
                        : null}
                </GridContainer>;

                columns.push(ballImg);
            });

            // to push the remaining columns
            if (columns.length) {
                let newArray = _.cloneDeep(columns);
                setRows(prevRows => [...prevRows,
                    <Row key={rowCounter++} className="center-ball-row"><Col>{newArray}</Col></Row>]);
            }

        }
    }

    const resetGameTiles = () => {
        setPressTilePosition(prevState => []);
        setPressTileValue(prevState => []);
        setUsersList(prevState => []);
    }

    const handleTileClick = (index) => {
        //console.log("--- handleTileClick ---");

        setResultText("");

        if (isLCGameStarted === true && isConnected === true) {

            if (betAmount > balanceMoney) {
                setResultText(t('luckycircle_not_enough_balance'));
                return;
            }

            if (Number(betAmount) < minBetAmount) {
                setResultText(t('facade_Min_balance') + Number(minBetAmount).toFixed(2));
                return;
            }

            let memberInfo = store.getState().memberInfo;
            let loginName = memberInfo.MemberInfo.loginName;
            let token = memberInfo.token;

            let jsonData = {
                tileIndex: index,
                username: loginName,
                amount: balanceMoney,
                betAmount: betAmount,
                token: token
            };

            //tile click
            socket.emit("tile click", jsonData, (callbackData) => {
                //console.log("--- tile click -- callbackData");
                // console.log("data: " + JSON.stringify(callbackData));
                if (callbackData) {
                    handlePressTile(callbackData);
                }
            });
        } else {
            if (isConnected === false) {
                setResultText(t('luckycircle_cannot_find_server'));
            }
        }

    }

    const handleUsersList = (data) => {
        //console.log("--- handleUsersList ---");
        let usersList = data.users;

        if (usersList.length > 0) {
            let arySize = usersList.length;
            let name = usersList[arySize - 1].name;
            let payOut = usersList[arySize - 1].payOut;
            let betAmount = usersList[arySize - 1].betAmount;
            let winAmount = Number(betAmount) * Number(payOut);

            let userObj = {
                name: name,
                payOut: payOut,
                betAmount: betAmount,
                winAmount: winAmount
            };

            setUsersList(prevState => [userObj, ...prevState]);
        }
    }

    const minBet = () => {
        if(!isLCGameStarted){
            setBetAmount(Number(minBetAmount).toFixed(2));
        }
    };

    const maxBet = () => {
        if(!isLCGameStarted){
            if (balanceMoney <= maxBetAmount) {
                setBetAmount(balanceMoney.toFixed(2));
            } else {
                setBetAmount(Number(maxBetAmount).toFixed(2));
            }
        }
    };

    const doubleBet = () => {
        if(!isLCGameStarted){
            let calculateAmount = betAmount * 2;
            if (calculateAmount <= maxBetAmount) {
                if (calculateAmount > balanceMoney) {
                    calculateAmount = balanceMoney;
                }
            } else {
                calculateAmount = Number(maxBetAmount).toFixed(2);
            }
            setBetAmount(Number(calculateAmount).toFixed(2));
        }
    };

    const halfBet = () => {
        if(!isLCGameStarted){
            let calculateAmount = betAmount / 2;

            if (calculateAmount < minBetAmount) {
                calculateAmount = Number(minBetAmount).toFixed(2);
            }
            setBetAmount(Number(calculateAmount).toFixed(2));
        }
    };

    useEffect(() => {
        getQuota("luckycircle", updateQuotaCallback);
    }, []);

    const updateQuotaCallback = (res) => {
        if(res !== '' && res !== undefined) {
            if(res.minBetAmount !== null) {
                setBetAmount(Number(res.minBetAmount).toFixed(2));
                setMinBetAmount(Number(res.minBetAmount).toFixed(2));
            }
            if(res.maxBetAmount !== null) setMaxBetAmount(Number(res.maxBetAmount).toFixed(2));
        } else {
            setBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMinBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMaxBetAmount(Number(betAmtConstants.maxAmt).toFixed(2));
        }
    };

    const handleBetAmt = (event) => {
        const regex = /^(\d*\.{0,1}\d{0,2}$)/;
        if(regex.test(event.target.value)) {
            setBetAmount(event.target.value);
        }
    };

    return (
        <>
            <AppContainer id="gameLuckycircle" className="game-bg-web">
                <InnerContainer className="innerContainer">
                    <div className="react-game-lucky-circle">
                        <Container fluid className="game-main-container">
                            <GameMoney gameName={"luckycircle"}/>
                            <Row className="top-money-row">
                                {/* <Col> */}
                                {/* <img key={'luckyCircleCoin'} alt={'luckyCircleCoin'} src={require(`../assets/images/luckycircle/lucky_circle_coin.png`)} />
              <span className="top-label-money">{Number(balanceMoney).toFixed(2)}</span> */}
                                {/* </Col> */}
                                <Col>
                                    <span className="top-label-other"> {t('luckycircle_cells')} </span><span
                                    className="top-label-value">{cellToOpen}</span>
                                </Col>
                                <Col>
                                    <span className="top-label-other"> {t('luckycircle_time_left')} </span> <span
                                    className="top-label-value">{timeLeft}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Container fluid className="lucky-circle-center-balls">{rows}</Container>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="lucky-circle-bottom-panel-col">

                                    <Container fluid className="lucky-circle-bottom-right-panel">
                                        <Row>
                                            <Col>{resultText}</Col>
                                        </Row>
                                        <Row className="">
                                            <Col xs={2} className="lucky-circle-bottom-col1">
                                                <GameButtonBlack
                                                    onClick={minBet}>{t('luckycircle_min')}</GameButtonBlack>
                                            </Col>
                                            <Col className="lucky-circle-bottom-col-amount-value">
                                                <TextField type='text'
                                                           InputProps={{disableUnderline: true, disabled:isLCGameStarted}}
                                                           autoComplete="off"
                                                           onChange={(event) => handleBetAmt(event)}
                                                           hiddenLabel
                                                           variant="standard"
                                                           value={betAmount}/>
                                            </Col>
                                            <Col xs={2} className="lucky-circle-bottom-col3">
                                                <GameButtonBlack onClick={halfBet}>1/2</GameButtonBlack>
                                            </Col>
                                        </Row>
                                        <Row className="">
                                            <Col xs={2} className="lucky-circle-bottom-col1">
                                                <GameButtonBlack
                                                    onClick={maxBet}>{t('luckycircle_max')}</GameButtonBlack>
                                            </Col>
                                            <Col className="lucky-circle-bottom-col-amount-label">
                                                <span>{t('luckycircle_play_amount')}</span>
                                            </Col>
                                            <Col xs={2} className="lucky-circle-bottom-col3">
                                                <GameButtonBlack onClick={doubleBet}>2x</GameButtonBlack>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="bottom-contGameButtonYellowrol-start-col">
                                                {isLCGameStarted === false ?
                                                    <motion.button onClick={() => handleStartButton()}
                                                                   className="bottom-control-start"
                                                                   whileHover={{scale: 1.1}}
                                                                   whileTap={{scale: 0.9}}
                                                    >
                                                        {t('luckycircle_start')}
                                                    </motion.button>
                                                    :
                                                    <motion.button onClick={() => handleEndButton()}
                                                                   className="bottom-control-end"
                                                                   whileHover={{scale: 1.1}}
                                                                   whileTap={{scale: 0.9}}
                                                    >
                                                        {t('luckycircle_end')}
                                                    </motion.button>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>

                                </Col>
                            </Row>
                            <Row>
                                <Col className="lucky-circle-bottom-panel-col">
                                    <div className="scrollable-users-list">
                                        <Container fluid className="lucky-circle-bottom-left-panel">
                                            <Row className="bottom-star-list-row">
                                                {/* <Col></Col> */}
                                                <Col>
                                                    <img key={'luckyCircleStar'} alt={'luckyCircleStar'}
                                                         src={require(`../assets/images/luckycircle/star_transparent.png`)}/>
                                                    <span>{t('luckycircle_list')}</span>
                                                </Col>
                                                {/* <Col style={{display:'flex', justifyContent:'right', alignItems:'center'}}> */}
                                                {/* <GameRules gameName={"luckycircle"} />
                      <PlayHistory gameName={"luckycircle"} /> */}
                                                {/* </Col> */}
                                            </Row>
                                            <Row className="bottom-name-amount-payout-win-row">
                                                <Col>
                                                    <span>{t('luckycircle_name')}</span>
                                                </Col>
                                                <Col xs={2} md={3}>
                                                    <span>{t('luckycircle_Amount')}</span>
                                                </Col>
                                                <Col xs={2} md={3}>
                                                    <span>{t('luckycircle_payout')}</span>
                                                </Col>
                                                <Col xs={2} md={3}>
                                                    <span>{t('luckycircle_win')}</span>
                                                </Col>
                                            </Row>
                                            {usersList.length > 0 ?
                                                usersList.map((item, index) => (
                                                    <Row key={index} className="bottom-list-result-row">
                                                        <Col>
                                                            <span>{item.name}</span>
                                                        </Col>
                                                        <Col xs={2} md={3}>
                                                            <span>{Number(item.betAmount).toFixed(1)}</span>
                                                        </Col>
                                                        <Col xs={2} md={3}>
                                                            <span>{Number(item.payOut).toFixed(1)}x</span>
                                                        </Col>
                                                        <Col xs={2} md={3}>
                                                            <span>{Number(item.winAmount).toFixed(1)}</span>
                                                        </Col>
                                                    </Row>
                                                ))
                                                :
                                                <Row className="bottom-list-result-row">
                                                    <Col></Col>
                                                </Row>
                                            }
                                            <Row className="">
                                                <Col></Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col></Col>
                            </Row>
                        </Container>
                    </div>
                </InnerContainer>
                <motion.div
                    className="reset-game-layer"
                    style={{rotate, display: gameState === 1 ? 'flex' : 'none'}}
                >
                    <img key={'resetting'} alt={'resetting'}
                         src={require(`../assets/images/luckycircle/resetting.png`)}/>
                </motion.div>
            </AppContainer>
        </>
    )
}

export default withTranslation('common')(LuckyCircle);