import React from "react";
import {useTranslation, withTranslation} from 'react-i18next';

import Numbers from "../../components/keno/Numbers";
import ImageNumber from "../../components/keno/ImageNumber";
import Place from "../../assets/images/keno/Place.png";
import PlaceSelected from "../../assets/images/keno/PlaceSelected.png";
import PlaceSuccess from "../../assets/images/keno/PlaceSuccess.png";
import {GameKenoArray, kenoConstants} from "../../utils/utilConstants";
import {motion} from "framer-motion";


function GamePlaceNumbers(props) {
    const {t} = useTranslation();

    function handleOnClick(id) {
        if(props.clickNumbers.length < kenoConstants.maxCheckedNumber || props.clickNumbers.includes(id)) {
            props.onClickNumber(id);
        }
    }

    function renderNumbers(props) {
        return GameKenoArray.map((ing, i) => (
            <Numbers.Item key={i}>
                <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                <ImageNumber.Container onClick={() => {
                    handleOnClick(ing);
                }}>
                    <img src={(props.clickNumbers.includes(ing) && Object.values(props.ballNumbers).includes(ing)) ? PlaceSuccess : props.clickNumbers.includes(ing) ? PlaceSelected : Place} alt={ing}/>
                    <ImageNumber.Header>
                        {ing}
                    </ImageNumber.Header>
                </ImageNumber.Container>
                </motion.div>
            </Numbers.Item>
        ));
    }

    return <Numbers.Container>{renderNumbers(props)}</Numbers.Container>;
}

export default withTranslation('common')(GamePlaceNumbers);
