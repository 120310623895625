import React, {useState, useEffect, useRef} from "react";
import { useSpring, animated as a } from 'react-spring'
import {easeBackOut} from "d3-ease";
import styled from "styled-components";
import box_amount from '../../assets/images/seven1121/coin_amount.png'

import {device} from "../../utils/utilConstants";

const Container = styled(a.div)`
  width: 100%;
  z-index: 10;
  
  @media ${device.mobileL} {
    width: 100%;
  }
  @media ${device.surface} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

const ItemWrapper = styled(a.div)`
  width: 50%;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  overflow: visible;
  will-change: transform, height, opacity;
  padding: 7px;
`;

const ItemContent = styled(a.div)`
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin: 4px 0px 0px 10px;
`;

const Label = styled.div`
  font-size: 15px;
  color: white;
  @media ${device.surface} {
    font-size: medium;
  }
  @media ${device.tablet} {
    font-size: medium
  }
`;

const Image = styled.div`
  width: 25%;
  // margin-right: 20px;

  img {
    width: 100%;
  }
  
  @media ${device.surface} {
    width: 25%;
  }
  @media ${device.tablet} {
    width: 25%;
  }
`;

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

function Item(props) {
    const [animate, setAnimate] = useState(false);
    const prevCount = usePrevious(props.count);

    const contentProps = useSpring({
        config: {
            duration: 300,
            easing: easeBackOut
        },
        transform: animate ? "scale(1.3)" : "scale(1)"
    });

    useEffect(() => {
        let timeout = null;

        if (animate) clearTimeout(timeout);

        if (prevCount > props.count && props.count !== 0) {
            setAnimate(true);
        }

        timeout = setTimeout(() => {
            setAnimate(false);
        }, 200);

        return () => clearTimeout(timeout);
    }, [props.count]);

    return (
        <ItemWrapper {...props} style={{backgroundImage: `url(${box_amount})`,backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <ItemContent style={contentProps}>{props.children}</ItemContent>
        </ItemWrapper>
    );
}

export default {Container, Item, Label, Image};
