import React, {useCallback, useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";

import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';

import {store} from "../index";

import GameModal from "../containers/keno/GameModal"
import GameMoney from "../containers/GameMoney";
import GameResult from "../containers/keno/GameResult";
import GameResultRow2 from "../containers/keno/GameResultRow2"
import GamePlaceNumbers from "../containers/keno/GamePlaceNumbers";
import GameTimesTable from "../containers/keno/GameTimesTable";
import GameButtons from "../containers/keno/GameButtons";
import GameAwardBetAmount from "../containers/keno/GameAwardBetAmount";
import {getMemberBalance} from "../services/HomeServices";
import { playGame } from "../services/GameKenoServices";
import '../styles/Keno/Keno.css'
import gameConstants, {betAmtConstants, GameKenoArray, GameKenoBallArray} from "../utils/utilConstants";
import helpers from "../utils/helpers";
import GameMessage from "../containers/keno/GameMessage";
import {getQuota} from "../services/QuotaServices";
import GameLoading from "../containers/GameLoading";

const GameMainContainer = styled.div`
  background-color: #111923;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: scroll;
`;

const InnerContainer = styled.div`
    backgroundColor: #111923;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
`;

const InnerContainer2 = styled.div`
    display: flex;
    justify-content: center;
    background-color: #111923;
    width: 100%;
    padding-bottom: 10px;
`;

function GameKeno() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const balanceMoney = useSelector(state => store.getState().memberInfo.MemberInfo.balanceMoney, shallowEqual);
    const {resultBallNumbers, showResult} = useSelector(state => store.getState().gameKeno, shallowEqual);
    const [start, setStart] = useState(false);
    const [betAmount, setBetAmount] = useState(Number(10.00).toFixed(2));
    const [selectedNum, setSelectedNum] = useState(0);
    const [clickNumbers, setClickNumbers] = useState([]);
    const [ballNumbers, setBallNumbers] = useState(helpers.randomizeNumber(20, GameKenoBallArray));
    const [isLock, setIsLock] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [minBetAmount, setMinBetAmount] = useState(Number(betAmtConstants.minAmt).toFixed(2));
    const [maxBetAmount, setMaxBetAmount] = useState(Number(betAmtConstants.maxAmt).toFixed(2));
    const [isDisplay, setIsDisplay] = useState(false);
    const [autoBet, setAutoBet] = useState(false);
    const [pending, setPending] = useState(false);

    const handleIncreaseScore = () => {
        if(start === false) {
            let calculateAmount = Number(betAmount) + 10;
            if(calculateAmount <= Number(maxBetAmount)) {
                setBetAmount(calculateAmount.toFixed(2));
            } else {
                setBetAmount(Number(maxBetAmount).toFixed(2));
            }
        }
    };

    const handleDecreaseScore = () => {
        if(start === false) {
            if (Number(betAmount) > Number(minBetAmount)) {
                let calculateAmount = Number(betAmount) - 10;
                setBetAmount(calculateAmount<=Number(minBetAmount) ?
                    Number(minBetAmount).toFixed(2) : calculateAmount.toFixed(2));
            } else {
                dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_Min_balance") + Number(minBetAmount).toFixed(2), messageType: 1 });
            }
        }
    };

    const onUpdateBetAmount = (amt) => {
        if(start === false) {
            const regex = /^(\d*\.{0,1}\d{0,2}$)/;
            if(regex.test(amt)) {
                setBetAmount(amt);
            }
        }
    };

    const handlePlaceNumber = (newNumber) => {
        if(!autoBet){
            if(start === false) {
                const index = clickNumbers.findIndex(
                    item => item === newNumber
                );
                if (index !== -1) {
                    setClickNumbers(prevNumbers => prevNumbers.filter(number => number !== newNumber))
                } else {
                    setClickNumbers(prevNumbers => [...prevNumbers, newNumber]);
                }
            }
        }
    };

    const handleOnClear = () => {
        if(start === false) {
            dispatch({type: gameConstants.RESET_CHECKED_NUMBERS});
            setSelectedNum(0);
            setClickNumbers([]);
            setBallNumbers(helpers.randomizeNumber(20, GameKenoBallArray));
        }
    };

    const handleOnQuick = () => {
        if(start === false) {
            //Reset First
            dispatch({type: gameConstants.RESET_CHECKED_NUMBERS});
            setSelectedNum(0);
            setClickNumbers([]);
            setBallNumbers(helpers.randomizeNumber(20, GameKenoBallArray));
            //Random Set
            setSelectedNum(10);
            setClickNumbers(helpers.randomizeNumber(10, GameKenoArray));
        }
    };

    const handleOnClick = () => {
        if(clickNumbers.length > 1) {
            if (start === false || autoBet) {
                if(Number(betAmount) < Number(minBetAmount)) {
                    setAutoBet(false);
                    dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_Min_balance") + Number(minBetAmount).toFixed(2), messageType: 1 });
                } else {
                    let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
                    if(Number(latestBalance) < Number(betAmount)) {
                        setAutoBet(false);
                        dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_insufficient_balance"), messageType: 1 });
                    } else {
                        setStart(true);
                        setIsLock(true);
                        setIsLoading(true);
                        setIsDisplay(false);
                        setBallNumbers(helpers.randomizeNumber(20, GameKenoBallArray));
                        dispatch({type: gameConstants.RUN_GAME});
                    }
                }
            }
        } else {
            setAutoBet(false);
            dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("keno_select_number"), messageType: 1 });
        }
    };

    const handleOnAutoBet = () => {
        var auto = !autoBet;
        if(auto){
            if(clickNumbers.length > 1) {
                if(!start){
                    if(Number(betAmount) < Number(minBetAmount)) {
                        setAutoBet(auto);
                    } else {
                        let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
                        if(Number(latestBalance) < Number(betAmount)) {
                            setAutoBet(auto);
                        } else {
                            if(!pending){
                                setAutoBet(auto);
                            }
                        }
                    }
                }else{
                    setAutoBet(auto);
                }
            } else {
                setAutoBet(auto);
            }
        }else{
            setAutoBet(auto);
        }
    };

    useEffect(() => {
        const setautoPlayBlock = async () => {
            await delay(4500);
            setPending(false);
          };
        if(autoBet){
            if(!pending){
                setPending(true);
                if(!start){
                    handleOnClick();
                }
                setautoPlayBlock();
            }else{
                setautoPlayBlock();
                setIsLock(autoBet);
            }
        }
    }, [autoBet]);

    const handleEndGame = () => {
        setStart(false);
        setIsLock(false);
        setIsDisplay(true);
        getMemberBalance();
    };

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        let clickNumbersArray = clickNumbers;
        if(clickNumbersArray.length > 0) {
            setSelectedNum(clickNumbersArray.length);
        }
    }, [clickNumbers]);

    useEffect(() => {
        const makeResult = async () => {
            let ballNumbersArray = ballNumbers;
            for (let i = 0; i < ballNumbersArray.length; i++) {
                await delay(1000);
                if(resultBallNumbers[i] === undefined || resultBallNumbers[i] < 1){
                    break;
                }
                setBallNumbers(numbers => ({
                    ...numbers,
                    [i]: resultBallNumbers[i]
                }));
                //dispatch({type: gameConstants.UPDATE_KENO_RESULT_INDEX, index: i, number: resultBallNumbers[i]});
                if (i === 19) {
                    dispatch({type: gameConstants.UPDATE_SHOW_KENO_RESULT, show: false});
                    handleEndGame();
                }
            }
        };

        if(showResult === false){
            makeResult();
        }

        if(resultBallNumbers.length === 20 && showResult === true) {
            makeResult();
        }
    }, [resultBallNumbers, showResult, ballNumbers, dispatch]);

    useEffect(() => {
        if(start === true) {
            fetchGame().then(()=>{
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                getMemberBalance();
            });
        }else{
            const autoPlay = async () => {
                await delay(3000);
                if(autoBet){
                    handleOnClick();
                }
            };
            autoPlay();
        }
        
    }, [start, isLock]);

    const handleLoading = () => {
        setIsLoading(false);
    };

    const updateCallback = (res) => {
        if(!res) {
            setAutoBet(false);
            handleEndGame();
            handleLoading();
        } else {
            handleLoading();
        }
    };

    useEffect( () => {
        getQuota("keno", updateQuotaCallback);
    }, []);

    const updateQuotaCallback = (res) => {
        if(res !== '' && res !== undefined) {
            if(res.minBetAmount !== null) {
                setBetAmount(Number(res.minBetAmount).toFixed(2));
                setMinBetAmount(Number(res.minBetAmount).toFixed(2));
            }
            if(res.maxBetAmount !== null) setMaxBetAmount(Number(res.maxBetAmount).toFixed(2));
        } else {
            setBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMinBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMaxBetAmount(Number(betAmtConstants.maxAmt).toFixed(2));
        }
    };

    const fetchGame = useCallback(async () =>{
        await playGame(start, betAmount, selectedNum, clickNumbers, updateCallback);
    }, [start, betAmount, selectedNum, clickNumbers]);

    return (
        <GameMainContainer>
            <InnerContainer className={"content-container-inner-keno"}>
                <Container fluid className={"content-container-keno"}>
                    <GameMoney  gameName={"keno"}/>
                    {/*<GameModal onEndGame={handleEndGame} ballNumbers={ballNumbers}/>*/}
                    <Row>
                        <Col className="row-col-title-keno">
                            <InnerContainer2>
                            {t("keno_odds")}
                            </InnerContainer2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="row-col-table-keno">
                            <InnerContainer2>
                                <GameTimesTable selectedNum={selectedNum}/>
                            </InnerContainer2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="row-col-content-keno"><GamePlaceNumbers clickNumbers={clickNumbers} ballNumbers={ballNumbers} onClickNumber={handlePlaceNumber} /></Col>
                    </Row>
                    <Row>
                        <Col className="row-col-result-keno"><GameResult ballNumbers={ballNumbers}/></Col>
                    </Row>
                    <Row>
                        <Col className="row-col-result2-keno"><GameResultRow2 ballNumbers={ballNumbers}/></Col>
                    </Row>
                    <Row>
                        <Col><span style={{paddingLeft: "30px"}}>{t('mine_win_amount')}</span></Col>
                        <Col><span>{t('mine_play_amt')}</span></Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="row-col-button-keno">
                            <GameAwardBetAmount onIncreaseScore={handleIncreaseScore}
                                                onDescreaseScore={handleDecreaseScore}
                                                betAmount={betAmount}
                                                start={start}
                                                onUpdateBetAmount={onUpdateBetAmount}
                                                isDisplay={isDisplay}
                                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col><GameMessage onEndGame={handleEndGame}/></Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="row-col-button-keno">
                            <GameButtons onClick={handleOnClick}
                                         onClear={handleOnClear}
                                         onQuick={handleOnQuick}
                                         onAutoBet={handleOnAutoBet}
                                         start={start}
                                         isLock={isLock}
                                         autoBet={autoBet}
                                         pending={pending}
                            />
                        </Col>
                    </Row>
                </Container>
            </InnerContainer>
            {isLoading &&
                <GameLoading />
            }
        </GameMainContainer>
    );
};

export default withTranslation('common')(GameKeno);
