import {store} from '../index';
import gameConstants from "../utils/utilConstants";
import i18next from "i18next";
import helpers from "../utils/helpers";
import { bool } from 'prop-types';
import {getMemberBalance} from "../services/HomeServices";

const tran = (input) => {
    return i18next.t(input);
};

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

const playGame = function (start, betAmt, rollOver, predictionValue, cb) {
    const memberInfo = store.getState().memberInfo;
    const memberObj = store.getState().memberInfo.MemberInfo;
    if (start === true) {
        let dataParam = new URLSearchParams();
        dataParam.append('betAmount', betAmt);
        dataParam.append('slideValue', predictionValue.toString());
        dataParam.append('roll', rollOver === true ? "TRUE" : "FALSE");
        dataParam.append('clientSeed', memberObj.clientSeed);
        dataParam.append('nonce', memberObj.nonce);

        fetch(global.config.domain.member + "/Dices/Play", {
            method: "Post",
            headers: new Headers({
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'gametoken': memberInfo.token,
            }),
            body: dataParam,
            credentials: 'include'
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.code === 200) {
                      store.dispatch({ type: gameConstants.UPDATE_DICE_DATA,
                        randomNumber: res.randomNumber,
                        earnAmount: res.earnAmount });
                    res.earnAmount > 0 ?
                        store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: tran("dice_win"), messageType: 2 , showMessage: true}) :
                        store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: tran("dice_lose"), messageType: 3 , showMessage: true});
                    
                    cb(true);
                    const membalance = async () => {
                        await delay(3000);
                        getMemberBalance();
                      };
                      membalance();
                    
                } else {
                    // const strArr = helpers.stringSplit(res.message, "=");
                    const errorMsg = res.message !=null?res.message:res.msg;
                    const strArr = helpers.stringSplit(errorMsg, "=");
                    const msg = strArr.length > 1 ? tran(strArr[0]) + tran(strArr[1]) : tran(errorMsg);
                    store.dispatch({type: gameConstants.SHOW_MESSAGE, message: msg, messageType: 1, showMessage: true});
                    cb(false);
                }
            }).catch((error) => {
                cb(false);
                store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: tran("dice_server_error"), messageType: 1 });
                console.log(error);
        })
    }
};

export {playGame}