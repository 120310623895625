import styled from "styled-components";
import { device } from "../../utils/utilConstants";
import {animated as a} from "react-spring";

const Container = styled(a.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
`;
export default { Container};
