import React from 'react';
import { Button, Nav, Navbar, NavItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import bn from './../../utils/bemnames';
import { MdArrowBack } from 'react-icons/md';
import { store } from '../../index';

const bem = bn.create('header');

class HeaderWithBack extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      toggleEvent: Date.now(),
      condition: false
    };
  }

  goBack = () => {
  //   let memberObj = store.getState().memberInfo.MemberInfo;
  //   this.props.history.push({
  //     pathname: '/MainPage',
  //     search: '?gametoken=' + store.getState().memberInfo.token
  // }); 
    window.location.href = window.location.href.split('#')[0] + "#/?gametoken=" + store.getState().memberInfo.token;
    window.location.reload(false);
  };

  render() {
    return (
      <div>
      <Navbar className="theme-background-color" light expand style={{paddingLeft:0,paddingRight:0}}>
        <Nav navbar className={bem.e('nav-left')} style={{position:'absolute'}}>
          <NavItem className="mr-2" style={{flexDirection:'row'}}>
            <Button outline style={{outline:'none',border:0,color: '#fff'}} onClick={this.goBack}>
              <MdArrowBack size={24} />
            </Button>
            {this.props.backText ? <span style={{top:3,position:'relative',color:'white'}}>{this.props.backText}</span> : null}
          </NavItem>
        </Nav>

        <Nav navbar className={bem.e('nav-left')} style={{margin:'auto'}}>
          <NavItem>
            <p className="p text-white top-title" style={{fontSize:'14px',margin:'auto'}}>{this.props.headerName}</p>
          </NavItem>
        </Nav>
      </Navbar>
      </div>
    );
  }
}

export default withTranslation('common')(HeaderWithBack);
