import './styles/main.scss';
import PageSpinner from './components/PageSpinner';
import React from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import { LoginExpiredPage,Error404Page } from './pages/StopUserPage';
import i18next from 'i18next';
import DeviceOrientation, { Orientation } from './components/Orientation';
import { BrowserView, MobileView } from "react-device-detect";
import { store } from './index';
import { checkLogin,doLogin,getMemberInfo,getMemberBalance} from './services/HomeServices'
import LoginPage from './pages/LoginPage';
import Game71121Page from './pages/Game71121Page';
import GameDicePage from "./pages/GameDicePage";
import GameKenoPage from "./pages/GameKenoPage";
import GameSpheresPage from "./pages/GameSpheresPage";
import DiamondPage from './pages/DiamondPage';
import LimboPage from './pages/LimboPage';
import CrashPage from './pages/CrashPage';
import HashDicePage from 'pages/HashDicePage';
import TriplePage from 'pages/TriplePage';
import MinePage from 'pages/MinePage';
import LuckyCirclePage from 'pages/LuckyCirclePage';

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loggined: false,
      url: window.location.href,
      isReady: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      isError:false
    };
  }

  componentDidMount() {
    this.indexInit();
  }

  logout = (token) => {
    const data = new URLSearchParams();
    data.set('targetTokenValue', token);

    fetch(global.config.domain.member + "/rest/minigame/token-invalidate", {
      method: "post",
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'gametoken': token,
      }),
      credentials: 'include',
      body: data,
      referrer: 'h5'
    }).then((res) => {
      // console.log("response : ", res);
    })
    .catch((error) => {
        console.error(error);
      })
  };

  indexInit = async () => {
    let urlParameter = this.state.url;
    //console.log(this.state.url);
    //console.log(window.location.href);

    if ( this.state.url.includes("loginaction")||this.state.url.includes("MainPage") ) {
      this.setState({ isReady: true });
      return;
    }

    urlParameter = urlParameter.split('#')[1];
    let vars = {};
    if (urlParameter!== undefined) {
      urlParameter.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
      });
    }

    if (typeof vars['error'] !== 'undefined') {
      window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
      this.setState({ isReady: true });
      this.setState({ isError: true });
      return { json: () => { return 'MAINTAIN'; } };
    }
    else{
      if (typeof vars['key'] === 'undefined' && typeof vars['gametoken'] === 'undefined') {
        window.location.href = window.location.href.split('#')[0] + '#/error404Page';
        this.setState({ isReady: true });
        this.setState({ isError: true });
        return { json: () => { return 'MAINTAIN'; } };
      }
      if (typeof vars['lang'] !== 'undefined') {
        console.log("Init Lang:" + vars['lang']);
        i18next.changeLanguage(vars['lang'], (err) => {
          if (err) console.log(err);
        })
      }
    }

    if(vars['gametoken']){
      if(vars['gametoken'] !== store.getState().memberInfo.token ){
        console.log("different token found:"+store.getState().memberInfo.token);
        this.logout(vars['gametoken']);
        window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
        this.setState({ isReady: true });
        this.setState({ isError: true });
        return { json: () => { return 'MAINTAIN'; } };
      }
      else{
        if(store.getState().memberInfo.token ){
          let isLogin = await checkLogin(store.getState().memberInfo.token);
          if(!isLogin){
            this.logout(store.getState().memberInfo.token);
            window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
            this.setState({ isReady: true });
            this.setState({ isError: true });
            return { json: () => { return 'MAINTAIN'; } };
          }
        }
      }
    }
    else{
      let platform = typeof vars['key'] === 'undefined' ? 'w' : vars['platform'];
      let loginSuccess = await doLogin(vars['key'], platform);
      if(!loginSuccess){
        window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
        this.setState({ isReady: true });
        this.setState({ isError: true });
        return { json: () => { return 'MAINTAIN'; } };
      }
    }

    await getMemberInfo();
    await getMemberBalance();

    let memberObj = store.getState().memberInfo.MemberInfo;
    console.log(" --- index init --- login name : " + memberObj.loginName);
    if(memberObj.loginName !== ''){
        
      this.setState({ isReady: true });

      let gameName = vars['gameName'];
      if(gameName !== undefined) {
        window.location.href = window.location.href.split('#')[0] + "#/"+gameName+"?gametoken=" + store.getState().memberInfo.token;
      } else {
        window.location.href = window.location.href.split('#')[0] + "#/?gametoken=" + store.getState().memberInfo.token;
      }

      // window.location.href = window.location.href.split('#')[0] + "#/?gametoken=" + store.getState().memberInfo.token;
    }
    else{
        window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
        this.setState({ isReady: true });
        this.setState({ isError: true });
        return { json: () => { return 'MAINTAIN'; } };
    }
  };

  switchRoute() {
    if(this.state.isError){
      return (
        <>
        <React.Suspense fallback={<PageSpinner />}>
        <Routes>
          <Route exact path="/spin" element={<PageSpinner />} />
          <Route exact path="/loginaction" element={<LoginPage />} />
          <Route exact path="/MainPage" element={<MainPage/>} />
          <Route exact path="/spheres" element={<GameSpheresPage />} />
          <Route exact path="/7-11-21" element={<Game71121Page />} />
          <Route exact path="/dices" element={<GameDicePage />} />
          <Route exact path="/keno" element={<GameKenoPage />} />
          <Route exact path="/limbo" element={<LimboPage />} />
          <Route exact path="/crash" element={<CrashPage />} />
          <Route exact path="/diamond" element={<DiamondPage />} />
          <Route exact path="/hashdice" element={<HashDicePage/>} />
          <Route exact path="/triple" element={<TriplePage/>} />
          <Route exact path="/mine" element={<MinePage/>} />
          <Route exact path="/luckycircle" element={<LuckyCirclePage/>} />
          <Route exact path="/loginExpiredPage" element={<LoginExpiredPage/>} />
          <Route exact path="/error404Page" element={<Error404Page/>} />
          <Route exact path="/" element={<LoginExpiredPage/>} />
        </Routes>
        </React.Suspense>
        </>
      );
    } else{
      return (
        <>
        <React.Suspense fallback={<PageSpinner />}>
        <Routes>
          <Route exact path="/spin" element={<PageSpinner />} />
          <Route exact path="/loginaction" element={<LoginPage/>} />
          <Route exact path="/MainPage" element={<MainPage/>} />
          <Route exact path="/spheres" element={<GameSpheresPage />} />
          <Route exact path="/7-11-21" element={<Game71121Page />} />
          <Route exact path="/dices" element={<GameDicePage />} />
          <Route exact path="/keno" element={<GameKenoPage />} />
          <Route exact path="/limbo" element={<LimboPage />} />
          <Route exact path="/crash" element={<CrashPage />} />
          <Route exact path="/diamond" element={<DiamondPage />} />
          <Route exact path="/loginExpiredPage" element={<LoginExpiredPage/>} />
          <Route exact path="/error404Page" element={<Error404Page/>} />
          <Route exact path="/hashdice" element={<HashDicePage/>} />
          <Route exact path="/triple" element={<TriplePage/>} />
          <Route exact path="/mine" element={<MinePage/>} />
          <Route exact path="/luckycircle" element={<LuckyCirclePage/>} />
          <Route path="/" element={<Navigate replace to="/MainPage" />} />
        </Routes>
        </React.Suspense>
        </>
      );
    }
  }

  render() {
    if (!this.state.isReady) {
      return (
        <HashRouter basename={getBasename()}>
        <div id="div2" className="load_screen">
          <div className="loading">
            <div className="lds-css ng-scope">
              <div style={{ width: '100%', height: '100%',top:'50px'  }} className="lds-double-ring">
                <div style={{ left: '32px', width: '48px', height: '48px' }}></div>
              </div>
            </div>
          </div>
        </div>
        </HashRouter>
      )
    } else {
      return (
        <HashRouter basename={getBasename()}>
            <MobileView>
              {<DeviceOrientation lockOrientation={'portrait'}>
                <Orientation orientation='portrait' alwaysRender={false}>
                {this.switchRoute()}
                </Orientation>
                <Orientation orientation='landscape' alwaysRender={false}>
                  <div className="orientation-container">
                    <div className="phone">
                    </div>
                    <div className="message">
                      {i18next.t('orientation_message')}
                    </div>
                  </div>
                </Orientation>
              </DeviceOrientation>}
            </MobileView>
            <BrowserView>
              {this.switchRoute()}
            </BrowserView>
        </HashRouter>
      );
    }
  }
}


export default App;
