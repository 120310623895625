import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';
import { AnimatePresence, motion } from "framer-motion";
import {store} from "../../index";
import Results from "../../components/spheres/Results"
import gameConstants, {ballColor} from "../../utils/utilConstants";
import helpers from "../../utils/helpers";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

function GameResultHrz(props) {
    const dispatch = useDispatch();
    const {userColor, robotColor, showResults, displayUserColor, displayRobotColor} = useSelector(state => store.getState().gameSpheres, shallowEqual);
    const {t} = useTranslation();

    useEffect(() => {
        async function makeResult() {
            for (let i = 0; i < 5; i++) {
                await delay(400);
                dispatch({type: gameConstants.UPDATE_SPHERES_RESULT_INDEX,
                    index: i,
                    uid: userColor[i].id,
                    uColor: ballColor[userColor[i].num].colorName,
                    uNum: userColor[i].num,
                    rid: robotColor[i].id,
                    rColor: ballColor[robotColor[i].num].colorName,
                    rNum: robotColor[i].num
                });
            }
        };

        async function pushBall(){
            await delay(2500);
            const groupByUserColor = helpers.groupBy(userColor, x => x.num);
            for (let i = 0; i < 7; i++) {
                let userColorItem = groupByUserColor.get(i);
                if(userColorItem !== undefined && userColorItem.length > 1) {
                    for (let i = 0; i < userColorItem.length; i++) {
                        dispatch({type: gameConstants.UPDATE_SPHERES_USER_SHOW_INDEX, index: userColorItem[i].id});
                    }
                }
            }

            const groupByRobotColor = helpers.groupBy(robotColor, x => x.num);
            for (let i = 0; i < 7; i++) {
                let robotColorItem = groupByRobotColor.get(i);
                if(robotColorItem !== undefined && robotColorItem.length > 1) {
                    for (let i = 0; i < robotColorItem.length; i++) {
                        dispatch({type: gameConstants.UPDATE_SPHERES_ROBOT_SHOW_INDEX, index: robotColorItem[i].id});
                    }
                }
                if(i === 6) {
                    await delay(1000);
                    props.onEndGame();
                }
            }
        }

        if(userColor.length === 5 && robotColor.length === 5 && showResults === true) {
            dispatch({type: gameConstants.RESET_SPHERES_RESULT});
            makeResult();
            pushBall();
        }
    });

    function renderItem2(isUser, colorArray) {
        return colorArray.map((ing,index) => (
            <Row key={index}>
                <Col className={"background-results-container-spheres-x"} style={{position:'relative'}}>
                    <AnimatePresence>
                        {isUser ? (
                            ing.isShow === true ?
                                <motion.div style={{paddingTop: "2px",paddingLeft: "4px",position:'relative'}}
                                            initial={{ x: 60, opacity: 0 }}
                                            animate={{ x: -10, opacity: 1 }}
                                            transition={{ duration: 1 }}
                                            className="award-active"
                                >
                                    <span className={ing.color}><img src={require(`../../assets/images/spheres/${ing.color}_img.png`)} width={60} height={60}/></span>
                                    {/* <span className={ing.color}></span> */}
                                    <span className={ing.color + ` active`}></span>
                                    
                                </motion.div>
                                :
                                <motion.div style={{marginTop: "-2px",paddingLeft: "8%",position:'absolute'}}
                                            initial={{ x: 0, opacity: 0 }}
                                            animate={{ x: 60, opacity: 1 }}
                                            transition={{ duration: 0 }}
                                >
                                    <span className={ing.color}><img src={require(`../../assets/images/spheres/${ing.color}_img.png`)} width={60} height={60}/></span>
                                </motion.div>
                        ) : (
                            ing.isShow === true ?
                                <motion.div style={{paddingTop: "2px",position:'relative'}}
                                            initial={{ x: 0, opacity: 0 }}
                                            animate={{ x: 70, opacity: 1 }}
                                            transition={{ duration: 1 }}
                                            className="award-active"
                                >
                                    <span className={ing.color}><img src={require(`../../assets/images/spheres/${ing.color}_img.png`)} width={60} height={60}/></span>
                                    <span className={ing.color + ` active`}></span>                                
                                </motion.div>
                                :
                                <motion.div style={{paddingTop: "2px"}}
                                            initial={{ x: 80, opacity: 0 }}
                                            animate={{ x: -6, opacity: 1 }}
                                            transition={{ duration: 0 }}
                                >
                                    <span className={ing.color}><img src={require(`../../assets/images/spheres/${ing.color}_img.png`)} width={60} height={60}/></span>
                                </motion.div>
                        )}
                    </AnimatePresence>
                </Col>
            </Row>
        ));
    }

    return (
        <>
            <Results.Container>
                <Container>
                    <Row style={{color:'white',textAlign:'center'}}>
                        <Col>{t('system')}</Col>
                        <Col>{t('player')}</Col>
                    </Row>
                    <Row>
                        <Col>
                            {renderItem2(false, displayRobotColor)}
                        </Col>
                        <Col>
                            {renderItem2(true, displayUserColor)}
                        </Col>
                    </Row>
                </Container>
            </Results.Container>
        </>
    );
}

export default withTranslation('common')(GameResultHrz);
