import  * as ActionType from '../actions/ActionType';

const gameListInit = [
    {
        id: "LT00",
        gameName: "全部",
    }
];

const storeHomePageGameList = (state = { gameList: gameListInit, noticeHistory: [] }, action) => {
    if (action.type === 'STORE_GAMELIST_OBJ') {
        let result = state.gameList;
        if (result.length > 1){//reset and get the latest gameList
            let id = result[0].id;
            let gameName = result[0].gameName;
            result = [];
            result.push({id,gameName});
        }
        result = result.concat(action.input);
        
        action.input.foreach((item) => {
            if (result[0].gameList instanceof Array) {
                result[0].gameList = result[0].gameList.concat(item.gameList);
            } else {
                result[0].gameList = item.gameList;
            }
        }); 
        return { ...state, gameList: result }; 
    } else if (action.type === 'STORE_NOTICE_OBJ') {
        return { ...state, noticeHistory: action.input }; 
    } else {
        return state
    }
};

const storeIndexData = (state = { indexData: {}}, action) => {
    if (action.type === 'STORE_INDEX_DATA') {
        let result = action.input;
        return { ...state, indexData: result };
    }else {
        return state
    }
};

const storeIndexInit = (state = { indexInit: {}}, action) => {
    if (action.type === ActionType.STORE_INDEX_INIT) {
        let result = action.input;
        return { ...state, indexInit: result };
    }else if (action.type === ActionType.REFRESH_USER) {
        // DO NOTHING
        let result = state.indexInit;
        //result.loginUser = action.input;
        return {...state, indexInit: result};
    }else {
        return state
    }
};

const storeGameListPage = (state = { gameListPage: {}}, action) => {
    if (action.type === ActionType.STORE_GAME_LIST_PAGE) {
        let result = action.input;
        return { ...state, gameListPage: result };
    }else {
        return state
    }
};

const storeMemberInfo = (state = { MemberInfo: {}, RememberPassword:{}, GesturePassword:{},GestureUserInfo:{}, SecUsername:{}, UserInfo:{}, AccountConfigs:{}, AccountConfigsDefault:{}, DemoUser:{}}, action) => {
    if (action.type === ActionType.GET_BALANCE) {
        let result = state.MemberInfo;
        return { ...state, MemberInfo: result };
    } else if (action.type === ActionType.SET_GESTURE_PASSWORD) {
        let result = action.input;
        return { ...state, GesturePassword: result };
    } else if (action.type === ActionType.SET_GESTURE_USER_INFO) {
        let result = action.input;
        return { ...state, GestureUserInfo: result };
    } else if (action.type === ActionType.SET_REMEMBER_PASSWORD) {
        let result = action.input;
        return { ...state, RememberPassword: result };
    } else if (action.type === ActionType.SET_SEC_USERNAME) {
        let result = action.input;
        return { ...state, SecUsername: result };
    } else if (action.type === ActionType.SET_USER_INFO) {
        let result = action.input;
        return { ...state, UserInfo: result };
    } else if (action.type === ActionType.SET_ACCOUNT_CONFIGS) {
        let result = action.input;
        return { ...state, AccountConfigs: result };
    } else if (action.type === ActionType.SET_ACCOUNT_CONFIGS_DEFAULT) {
        let result = action.input;
        return { ...state, AccountConfigsDefault: result };
    } else if (action.type === ActionType.SET_DEMO_USER) {
        let result = action.input;
        return { ...state, DemoUser: result };
    } else {
        return state
    }
};

const storeUtilsInfo = (state = { isSuccessLoad: true, jumpGame: null, siteName: null, maintainTime: null }, action) => {
    if (action.type === 'SUCCESS_LOAD') {
        return { ...state, isSuccessLoad: true}
    } else if (action.type === 'RESET_LOAD') {
        return { ...state, isSuccessLoad: false}
    } else if (action.type === 'SET_JUMP_GAME') {
        return { ...state, jumpGame: action.input }
    } else if (action.type === 'SET_SITE_NAME') {
        return { ...state, siteName: action.input }
    } else if (action.type === 'SET_MAINTAIN_TIME') {
        return { ...state, maintainTime: action.input }
    } else {
        return state
    }
};

const updateAlert = (state = { alert: {}}, action) =>{
    if (action.type === 'UPDATE_ALERT') {
        let result = state.alert;
        return { ...state, alert: result };
    }else {
        return state
    }
};

const updateModal = (state = { loginModal:{}, registerModal: {}, forgotPasswordModal:{}, safePasswordModal:{}, addBankModal:{}, securityResetModal:{} }, action) =>{
    if (action.type === 'SHOW_LOGIN_MODAL') {
        let result = state.loginModal;
        return { ...state, loginModal: result };
    }else if (action.type === 'SHOW_REGISTER_MODAL') {
        let result = state.registerModal;
        return { ...state, registerModal: result };
    }else if (action.type === 'SHOW_FORGOTPASSWORD_MODAL') {
        let result = state.forgotPasswordModal;
        return { ...state, forgotPasswordModal: result };
    }else if (action.type === 'SHOW_SAFEPASSWORD_MODAL') {
        let result = state.safePasswordModal;
        return { ...state, safePasswordModal: result };
    }else if (action.type === 'SHOW_ADDBANK_MODAL') {
        let result = state.addBankModal;
        return { ...state, addBankModal: result };
    }else if (action.type === 'SHOW_SECURITY_RESET_MODAL') {
        let result = state.securityResetModal;
        return { ...state, securityResetModal: result };
    }else {
        return state
    }
};

const storeFindGameJSON = (state = { findGameJSON: {}}, action) => {
    if (action.type === 'SAVE_FIND_GAME_JSON') {
        let result = action.input;
        return { ...state, findGameJSON: result };
    }else {
        return state
    }
};

const storeAwardResultListPage = (state = { awardResultListPage: {}, time: ""}, action) => {
    if (action.type === ActionType.STORE_AWARD_RESULT_LIST_PAGE) {
        let result = action.input;
        let time = action.time;
        return { ...state, awardResultListPage: result, time: time };
    }else {
        return state
    }
};

const storeSelectedGame = (state = { selectedItems: []}, action) => {
    if (action.type === 'STORE_SELECTED_GAMES') {
        let result = action.input;
        return { ...state, selectedItems: result };
    }else {
        return state
    }
};

const storeMatchNumber = (state = { MatchNumberInfo: {}}, action) => {
    if (action.type === 'STORE_Match_Number') {
        let result = action.input;
        return { ...state, MatchNumberInfo: result };
    }else {
        return state
    }
};

const storeSelectedOddDetailsId = (state = { selectedMatchId: [],selectedSportId: [],selectedLeagueId: [],selectedHomeId:[],selectedAwayId:[], marketType: [], leagueId: []}, action) => {
    if (action.type === 'STORE_SELECTED_ODD_DETAILS_ID') {
        let result = action.input;
        return { ...state, selectedMatchId: result.selectedMatchId,selectedSportId: result.selectedSportId,selectedLeagueId: result.selectedLeagueId
        , selectedHomeId:result.selectedHomeId,selectedAwayId:result.selectedAwayId, marketType:result.marketType, leagueId: result.leagueId};
    }else {
        return state
    }
};

export { storeHomePageGameList,storeIndexData,storeIndexInit,storeGameListPage,storeMemberInfo, 
    updateAlert, storeUtilsInfo, updateModal, storeFindGameJSON, storeAwardResultListPage, storeSelectedGame
    ,storeMatchNumber,storeSelectedOddDetailsId
    }