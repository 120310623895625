import React, {useEffect, useRef} from "react";
import { withTranslation } from 'react-i18next';
import {animate} from "framer-motion";
import helpers from "../../utils/helpers";

function GameResultAnime({ from, to }) {
    const ref = useRef();

    useEffect(() => {
        const controls = animate(from, to, {
            duration: 0.6,
            onUpdate(value) {
                ref.current.textContent = helpers.padDigits(Math.trunc(value), 2);
            }
        });
        return () => controls.stop();
    }, [from, to]);

    return <p style={{margin:"0px",textAlign: 'center',fontSize: '25px',color: 'black',fontWeight: 'bold'}} ref={ref} />;
}

export default withTranslation('common')(GameResultAnime);
