import {animated as a} from "react-spring";
import styled, {keyframes} from "styled-components";

const Container = styled(a.div)`
   position: relative;
   overflow: hidden;
   display: grid;
   grid-template-columns: 150px 150px;
   grid-gap: 1px;
   
   img {
      width: 70%;
   }
   
   .box {
     width: 100%;
     display: flex;
     justify-content: center;
   }
   
   .column {
      float: left;
      height: 100%;
      padding-right: 10px;
      padding-left: 10px;
   }
    
    .left {
      width: 45%;
      background-color: rgba(22,119,255,255);
      text-align: center;
      vertical-align: middle;
      border-radius: 5px;
      font-size: 14px;
    }

    .right {
      width: 55%;
      background-color: rgba(253,246,103,255);
      text-align: center;
      vertical-align: middle;
      border-radius: 5px;
      font-size: 14px;
      color : black;
    }

`;

const Header = styled.h1`
    font-size: 17px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
`;

export default {Container, Header};
