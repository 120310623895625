import Default from "./default";
import styled from "styled-components";

import { device } from "../../utils/utilConstants";
import {animated as a} from "react-spring";

const Container = styled(a.div)`
  width: 100%;
  border-radius: 8px;
  text-align: center;
  align-self: center;
  z-index: 10;
  
  @media ${device.tablet} {
  }
`;

const Header = styled.h1`
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  
  @media ${device.tablet} {
    font-size: 30px;
  }
`;

const Value = styled(a.span)`
  text-align: center;
  display: block;
  font-size: 15px;
  color: #fff;
`;

export default { Container, Header, Value };
