import styled from "styled-components";
import { device } from "../../utils/utilConstants";
import {animated as a} from "react-spring";


const Container = styled(a.div)`
  display: flex;
  justify-content: center;
  width: 100%;
  color: rgba(59,50,149,255);
  align-self: center;
  align-items: center;
  
  .container {
      display: grid;
      height: 70%;
      width: 80%;
      .row {
        .col {
          display: flex;
          align-items: center;
          justify-content: center;
        } 
      }
  }
`;

const Header = styled.h1`
  text-align: center;
  font-size: 50px;
  color: #fff;
  font-weight: bold;
`;

const Value = styled(a.span)`
  text-align: center;
  display: block;
  font-size: 20px;
  color: #fff;
`;

export default { Container, Header, Value };
