import styled from "styled-components";
import { device } from "../../utils/utilConstants";
import {animated as a} from "react-spring";

const Container = styled(a.div)`
  display: flex;
  justify-content: center;
  width: 100%;
  color: #ffffff;
  align-self: center;
  height: 320px;
`;

const Header = styled.h1`
  text-align: center;
  font-size: 50px;
  color: #fff;
  font-weight: bold;
`;

const Value = styled(a.span)`
  text-align: center;
  display: block;
  font-size: 20px;
  color: #fff;
`;

export default { Container, Header, Value };
