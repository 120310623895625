import { Component } from 'react';
import IdleTimer from 'react-idle-timer'
import {store} from '../index'
import { UpdateAlert } from '../actions/HomePageAction';
import { withTranslation } from 'react-i18next';

class IdleCheck extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
  }
  render() {
    return (
      <div className={'idleTimer'}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onIdle={this.onIdle}
          timeout={1000 * 60 * 30}
          events={
            [
              'mousemove',
              'keydown',
              'wheel',
              'DOMMouseScroll',
              'mouseWheel',
              'mousedown',
              'touchstart',
              'touchmove',
              'MSPointerDown',
              'MSPointerMove'
            ]
          }
        />
        {this.props.children}
      </div>
    )
  }

  async _onIdle(e) {
    const { t } = this.props;
    let alertObj = store.getState().alert;
    alertObj.alert.content = t('login_time_out');
    alertObj.alert.title = t('notice');
    alertObj.alert.showAlert = true;
    let memberInfo = store.getState().memberInfo;
    store.dispatch(UpdateAlert(alertObj));

    await fetch(global.config.domain.member + "/rest/minigame/idle-logout", {
      method: "get",
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'gametoken': memberInfo.token,
      }),
      credentials: 'include',
      referrer: 'h5'
    }).then((res) => {
      
    })
    .catch((error) => {
      console.error(error);
    })

  }
}

export default withTranslation('common')(IdleCheck)