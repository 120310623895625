import styled from "styled-components";
import { device } from "../../utils/utilConstants";
import {animated as a} from "react-spring";

const Container = styled(a.div)`
  width: 100%;
  color: white;
  align-self: center;
`;

const Header = styled.h1`
  text-align: center;
  font-size: 25px;
  color: #fff;
  font-weight: bold;
`;

const Value = styled(a.span)`
  text-align: center;
  display: block;
  font-size: 15px;
  color: #fff;
`;

export default { Container, Header, Value };
