import Stack from "react-bootstrap/Stack"
import {useTranslation, withTranslation} from 'react-i18next';

import Prediction from "../../components/dice/Prediction";
import {shallowEqual, useSelector} from "react-redux";
import {store} from "../../index";
import helpers from "../../utils/helpers";
import box_luckyno from '../../assets/images/dice/box_luckyno.png'
import React, {useEffect, useRef, useState} from "react";
import {animate} from "framer-motion";
import GameResultAnime from "../dice/GameResultAnime";

function GameResultNumber(props) {
    const {t} = useTranslation();
    const result = useState(props.resultNum);
    const ref = useRef();

    useEffect(() => {
    });

    return (
        <>
            <Prediction.Container style={{backgroundImage:`url(${box_luckyno})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',display: 'flex',width: '40%',height: '70%'}}>
                <Stack style={{paddingTop: '12%'}}>
                    <div>
                        <Prediction.Value>{t('dice_lucky_num')}</Prediction.Value>
                    </div>
                    <div>
                        <Prediction.Header><GameResultAnime from={0} to={props.resultNum} /></Prediction.Header>
                    </div>
                </Stack>
            </Prediction.Container>
        </>
    );
}

export default withTranslation('common')(GameResultNumber);
