import styled from "styled-components";
import { device } from "../../utils/utilConstants";
import {animated as a} from "react-spring";

const Container = styled(a.div)`
  width: 100%;
  height: 90%;
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export default { Container };
