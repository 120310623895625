import React, {useEffect} from "react";
import {useTranslation, withTranslation} from 'react-i18next';

import Message from "../../components/default/Message";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {store} from "../../index";
import gameConstants from "../../utils/utilConstants";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

function GameMessage(props) {
    const {t} = useTranslation();
    const {showMessage, message, messageType} = useSelector(state => store.getState().gameKeno, shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        if(showMessage) {
            makeMessage();
        }
        async function makeMessage() {
            await delay(3000);
            dispatch({ type: gameConstants.HIDE_MESSAGE });
            // props.onEndGame();
        }
    }, [showMessage]);

    return (
        <>
            <Message.Container>
                { messageType === 1 ?
                    <Message.Header mediumfont warning>{ showMessage === true ? message : ""}</Message.Header>
                    : messageType === 2 ?
                    <Message.Header mediumfont success>{ showMessage === true ? message : ""}</Message.Header>
                    : <Message.Header>{ showMessage === true ? message : ""}</Message.Header>
                }
            </Message.Container>
        </>
    );
}

export default withTranslation('common')(GameMessage);
