import {store} from '../index';
import gameConstants from "../utils/utilConstants";
import i18next from "i18next";
import helpers from "../utils/helpers";

const tran = (input) => {
    return i18next.t(input);
};

const playGame = function (start, betAmount, cb) {
    const memberInfo = store.getState().memberInfo;
    const memberObj = store.getState().memberInfo.MemberInfo;

    if (start === true) {
        let dataParam = new URLSearchParams();
        dataParam.append('betAmount', betAmount);
        dataParam.append('clientSeed', memberObj.clientSeed);
        dataParam.append('nonce', memberObj.nonce);

        fetch(global.config.domain.member + "/Spheres/Play", {
            method: "Post",
            headers: new Headers({
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'gametoken': memberInfo.token,
            }),
            body: dataParam,
            credentials: 'include'
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.code === 200) {
                    store.dispatch({ type: gameConstants.UPDATE_SPHERES_DATA,
                        reward: res.reward,
                        earnAmount: res.earnAmount + Number(betAmount),
                        userColor: res.User_color,
                        robotColor: res.Robot_color});
                    (res.earnAmount + Number(betAmount)) > 0 ?
                        store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: tran("diamond_win")+(res.earnAmount + Number(betAmount)).toFixed(2), messageType:  8}) :
                        store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: tran("dice_lose"), messageType: 9 });
                    cb(true);
                } else {
                    const strArr = helpers.stringSplit(res.message, "=");
                    const msg = strArr.length > 1 ? tran(strArr[0]) + tran(strArr[1]) : tran(res.message);
                    store.dispatch({type: gameConstants.SHOW_MESSAGE, message: msg, messageType: 1});
                    cb(false);
                }
            }).catch((error) => {
                cb(false);
                store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: tran("server_error"), messageType: 1 });
                console.log(error);
        })
    }
};

export {playGame}