import update from "immutability-helper";
import gameConstants, { GameSeven1121Array } from "../utils/utilConstants";

import helpers from "../utils/helpers";

const gameInitial = {
    starButtons: 9,
    loading: false,
    winStreak: 1,
    ordersOrigin: [],
    orders: helpers.randomizeOrder(9, GameSeven1121Array),
    result1: [-1,-1,-1],
    result2: [-1,-1,-1],
    result3: [-1,-1,-1],
    sum1: 0,
    sum2: 0,
    sum3: 0,
    isResult1Win: false,
    isResult2Win: false,
    isResult3Win: false,
    click: 1,
    earnAmount: -1,
    prize: [],
    sumArray: [],
    showMessage: false,
    message: '',
    messageType: 0
};

const storeGameSeven1121 = (state = gameInitial, action) => {
    switch (action.type) {
        case gameConstants.SHOW_MESSAGE: {
            return update(state, {
                showMessage: { $set: true },
                message: { $set: action.message },
                messageType: { $set: action.messageType }
            });
        }

        case gameConstants.HIDE_MESSAGE: {
            return update(state, {
                showMessage: { $set: false },
                message: { $set: '' },
                messageType: { $set: 0 }
            });
        }

        case gameConstants.CLICK_NUMBER: {
            return update(state, {
                click: { $set: state.click + 1 }
            });
        }

        case gameConstants.UPDATE_RESULT_1: {
            return update(state, {
                result1: { [action.index]: {$set: action.payload} },
                sum1: { $set: state.sum1 + action.payload }
            });
        }

        case gameConstants.UPDATE_RESULT_2: {
            return update(state, {
                result2: { [action.index]: {$set: action.payload} },
                sum2: { $set: state.sum2 + action.payload }
            });
        }

        case gameConstants.UPDATE_RESULT_3: {
            return update(state, {
                result3: { [action.index]: {$set: action.payload} },
                sum3: { $set: state.sum3 + action.payload }
            });
        }

        case gameConstants.RESET_RESULT_1: {
            return update(state, {
                result1: { $set: [] }
            });
        }

        case gameConstants.RESET_RESULT_2: {
            return update(state, {
                result2: { $set: [] }
            });
        }

        case gameConstants.RESET_RESULT_3: {
            return update(state, {
                result3: { $set: [] }
            });
        }

        case gameConstants.SET_LOADING: {
            return update(state, {
                loading: { $set: true }
            });
        }


        case gameConstants.SWAP_ORDERS: {
            return update(state, {
                orders: {
                    [action.index]: {
                        name: { $set: action.name }
                    }}
            });
        }

        case gameConstants.UPDATE_ORDERS: {
            return update(state, {
                orders: {
                    [action.index]: {
                        flipped: { $set: true }
                    }
                }
            });
        }

        case gameConstants.RUN_GAME: {
            return update(state, {
                orders: {
                    $set: helpers.randomizeOrder(9, GameSeven1121Array)
                }
            });
        }

        case gameConstants.UPDATE_SEVEN_DATA: {
            return update(state, {
                ordersOrigin: {
                    $set: helpers.itemOrder(9, action.orders)
                },
                prize: {
                    $set: action.prize
                },
                sumArray: {
                    $set: action.sumArray
                },
                earnAmount: {
                    $set: action.earnAmount
                }
            });
        }
        case "GET_BALANCE":{
            return update(state, {
            });
        }
        case gameConstants.RESTART:
        default:
            return gameInitial;
    }
};

export { storeGameSeven1121 }