import React, {useEffect, useState} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation, withTranslation} from 'react-i18next';
import {motion} from "framer-motion";
import Play from "../../components/seven1121/Play";
import Button from "../../components/default/Button";
import {TextField} from '@mui/material';
import keyin_amount from '../../assets/images/seven1121/keyin_amount.png'
import btn_play from '../../assets/images/seven1121/btn_play.png'
import btn_reveal from '../../assets/images/seven1121/btn_reveal.png'

function GamePlay(props) {
    const {t} = useTranslation();
    const [startPlay, setStartPlay] = useState(false);

    function handleIncreaseScore() {
        props.onIncreaseScore();
    };

    function handleDecreaseScore() {
        props.onDescreaseScore();
    };

    function handleOnMinScore() {
        props.onMinScore();
    };

    function handleOnMaxScore() {
        props.onMaxScore();
    };

    function handleClick(input, event) {
        if(input === false) {
            props.onClick();
        } else {
            if (event.detail === 1 && props.manual === false) {
                props.onReveal();
            }
        }
    };

    useEffect(() => {
        if (props.start === true) {
            setStartPlay(true);
        } else {
            setStartPlay(false)
        }
    }, [props.start]);

    return (
        <>
            <Play.Container>
                <Row style={{zoom:window.innerWidth>=360?'1':window.innerWidth>=330?'0.9':window.innerWidth>=300?'0.8':'0.75'}}>
                    <Col style={{padding: '0px',display: 'contents'}}>
                        <motion.div whileHover={{scale: 1.2}} whileTap={{scale: 0.8}}>
                            <div disabled={props.isLock} onClick={handleOnMinScore} style={{display:'flex',marginTop: '8px'}}>
                                <img style={{width: '50px'}}
                                    src={require(`../../assets/images/seven1121/btn_min.png`)}
                                />
                            </div>
                        </motion.div>
                    </Col>
                    <Col style={{padding: '0px',display: 'contents'}}>
                        <motion.div whileHover={{scale: 1.2}} whileTap={{scale: 0.8}}>
                            <div disabled={props.isLock} onClick={handleDecreaseScore} style={{display:'flex',marginTop: '8px'}}>
                                <img style={{width: '50px'}}
                                    src={require(`../../assets/images/seven1121/btn_minus.png`)}
                                />
                            </div>
                        </motion.div>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center',backgroundImage:`url(${keyin_amount})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',height: '58px'
                        ,marginLeft: window.innerWidth<=380?'-4px':'0px',
                        marginTop: window.innerWidth<=380?'5px':'0px'}}>
                        <TextField
                            sx={{input: {color: 'white', textAlign: 'center'}}}
                            type='text'
                            InputProps={{disableUnderline: true}}
                            autoComplete="off"
                            onChange={(event) => props.onUpdateBetAmount(event.target.value)}
                            hiddenLabel
                            variant="standard"
                            value={props.betAmount}/>
                    </Col>
                    <Col style={{padding: '0px',display: 'contents'}}>
                        <motion.div whileHover={{scale: 1.1}} whileTap={{scale: 0.8}}>
                            <div disabled={props.isLock} onClick={handleIncreaseScore} style={{display:'flex',marginTop: '8px'}}>
                                <img style={{width: '50px'}}
                                    src={require(`../../assets/images/seven1121/btn_plus.png`)}
                                />
                            </div>
                        </motion.div>
                    </Col>
                    <Col style={{padding: '0px',display: 'contents'}}>
                        <motion.div whileHover={{scale: 1.2}} whileTap={{scale: 0.8}}>
                            <div disabled={props.isLock} onClick={handleOnMaxScore} style={{display:'flex',marginTop: '8px'}}>
                                <img style={{width: '50px'}}
                                    src={require(`../../assets/images/seven1121/btn_max.png`)}
                                />
                            </div>
                        </motion.div>
                    </Col>
                </Row>
                <Row style={{marginTop:'10px'}}>
                    <Col style={{marginleft:'25%'}}>
                        <motion.div whileHover={{scale: 1.1}} whileTap={{scale: 0.8}}>
                        {startPlay === false ? 
                            <div disabled={props.isLock} onClick={(event) => handleClick(startPlay,event)}
                                style={{backgroundImage:`url(${btn_play})`,backgroundRepeat: 'no-repeat',backgroundSize: 'contain',width: '50%',height: '60px',marginLeft: '25%'}}>
                                <div style={{paddingTop:'10%'}}>{t("play")}</div>
                            </div> 
                            : 
                            <div disabled={props.isLock} onClick={(event) => handleClick(startPlay,event)}
                            style={{backgroundImage:`url(${btn_reveal})`,backgroundRepeat: 'no-repeat',backgroundSize: 'contain',width: '50%',height: '60px',marginLeft: '25%'}}>
                                <div style={{paddingTop:'10%'}}>{t("reveal")}</div>
                            </div>
                        }
                        </motion.div>
                    </Col>
                </Row>
            </Play.Container>
        </>
    );
}

export default withTranslation('common')(GamePlay);
