import {store} from '../index';
import gameConstants from "../utils/utilConstants";
import helpers from "../utils/helpers";
import i18next from "i18next";

const tran = (input) => {
    return i18next.t(input);
};

const playGame = function (betAmt, start, cb) {
    const memberInfo = store.getState().memberInfo;
    const memberObj = store.getState().memberInfo.MemberInfo;
    if (start === true) {
        let dataParam = new URLSearchParams();
        dataParam.append('betAmount', betAmt);
        dataParam.append('clientSeed', memberObj.clientSeed);
        dataParam.append('nonce', memberObj.nonce);

        fetch(global.config.domain.member + "/71121/Play", {
            method: "Post",
            headers: new Headers({
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'gametoken': memberInfo.token,
            }),
            body: dataParam,
            credentials: 'include'
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.code === 200) {
                    store.dispatch({
                        type: gameConstants.UPDATE_SEVEN_DATA,
                        orders: res.NumbersArray,
                        prize: res.prize,
                        sumArray: res.SumsArray,
                        earnAmount: res.earnAmount
                    });
                    cb(true);
                } else {
                    const strArr = helpers.stringSplit(res.message, "=");
                    const msg = strArr.length > 1 ? tran(strArr[0]) + tran(strArr[1]) : tran(res.message);
                    store.dispatch({type: gameConstants.SHOW_MESSAGE, message: msg, messageType: 1});
                    cb(false);
                }
            }).catch((error) => {
                cb(false);
                store.dispatch({ type: gameConstants.SHOW_MESSAGE, message: tran("server_error"), messageType: 1 });
                console.log(error);
        })
    }
};

export {playGame}