import * as actionType from './ActionType';

const SaveMemberInfo = (memberInfo) => {
    return {
        type: actionType.GET_BALANCE,
        input: memberInfo,
    }
}

const UpdateAlert = (alert) => {
    return {
        type: 'UPDATE_ALERT',
        input: alert,
    }
}

const saveIndexInit = (obj) => {
    return {
        type: actionType.STORE_INDEX_INIT,
        input: obj,
    }
}

const SaveSelectedGames = (selectedGames) => {
    return {
        type: actionType.STORE_SELECTED_GAMES,
        input: selectedGames,
    }
}

const SaveOddDetailsId = (obj) => {
    return {
        type: actionType.STORE_SELECTED_ODD_DETAILS_ID,
        input: obj,
    }
}

export {SaveMemberInfo, UpdateAlert, saveIndexInit, SaveSelectedGames,SaveOddDetailsId}