import '../styles/Default/Default.css'

function GameLoading() {
    function renderLoading() {
        return (
            <div className="loading-page-outside">
                <div className="loading-page-word" style={{height:window.innerHeight,position: 'fixed'}}>
                    <div className="loading-page" style={{height:window.innerHeight}}>
                        <div className="lds-ellipsis" style={{height:window.innerHeight}}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return <>{renderLoading()}</>;
}
export default (GameLoading);
