import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";

import Money from "../components/default/Money";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GameRules from "../containers/GameRules";
import PlayHistory from "../components/default/PlayHistory";
import { store } from "../index";
import { useState,useEffect,useRef } from "react";

function GameMoney(props) {
  const { t } = useTranslation();

  const currency = useSelector(
    (state) => store.getState().memberInfo.MemberInfo.currency,
    shallowEqual
  );
  const balanceMoney = useSelector(
    (state) => store.getState().memberInfo.MemberInfo.balanceMoney,
    shallowEqual
  );
  const [image, setImage] = useState();
  useEffect(() => {
    if (props.gameName == 'diamond') {
      setImage(require('../assets/images/diamond/money.png'));
     } else if(props.gameName == 'triple') {
      setImage(require('../assets/images/triple/coin.png'))
     } else if(props.gameName == 'spheres') {
      setImage(null);
     } else if(props.gameName == 'mine') {
      setImage(null);
     } else {
      setImage(require('../assets/images/default/Coin.png'));
     }
  }, [image])

  const [paddingRight, setpaddingRight] = useState();
  useEffect(() => {
    if (props.gameName == 'diamond') {
      setpaddingRight('0px');
     }else {
      setpaddingRight('40px');
     }
  }, [paddingRight])

  const [paddingTop, setpaddingTop] = useState();
  useEffect(() => {
    if (props.gameName == 'spheres') {
      setpaddingTop('10px');
     }else {
      setpaddingTop('0px');
     }
  }, [paddingTop])

  function renderMoney() {
    return (
      <Row style={{paddingTop: paddingTop}}>
        <Col xs={10} className="row-col-money-dice">
          <Money.Item gameName={props.gameName}>
            <Money.Image>
              {image?
                <img
                src={image}
                alt={"Money"}
                />
                :null
              }
            </Money.Image>
            <Money.Label>
              {currency === undefined ? "" : currency}
              {balanceMoney === undefined ? "0.00" : balanceMoney.toFixed(2)}
            </Money.Label>
          </Money.Item>
        </Col>
        <Col xs={1} className="row-col-money-dice" style={{paddingRight: paddingRight}}>
          <GameRules gameName={props.gameName} />
          <PlayHistory gameName={props.gameName} />
        </Col>
      </Row>
    );
  }

  return <Money.Container>{renderMoney()}</Money.Container>;
}

export default withTranslation("common")(GameMoney);
