import styled from "styled-components";
import { device } from "../../utils/utilConstants";

const Button = styled.button`
  border: 0px;
  font-weight: bold;
  font-size: ${props => (props.sfont ? "13px" : props.xlfont ? "20px" : "16px")};
  color: ${props => (props.primary || props.black ? "#000000" : props.whitefont ? "#ffffff" : "inherit")};
  border-radius: ${props => (props.br15 ? "15px" : "8px")};
  margin: 4px;
  cursor: pointer;
  width: ${props => (props.fullSize ? "100%" :props.dSize ? "80%" : props.sSize ? "50px" : props.mSize ? "80px" : props.lSize ? "110px" : props.xlSize ? "240px" : props.sp1 ? "70px" : props.sp2 ? "35px" : "40px")} ;
  height: ${props => (props.fullSize ? "50px" :props.dSize ? "70px" :props.sSize ? "30px" : props.mSize ? "30px" : props.lSize ? "40px" : props.xlSize ? "40px" : props.sp1 ? "70px" : props.sp2 ? "35px" : "10px")} ;
  padding: 8px;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

  background: ${props =>
    props.primary
      ? "linear-gradient(to bottom, rgba(255,239,162,255) 0%, rgba(255,239,162,255) 50%, rgba(255,239,162,255) 51%, rgba(255,239,162,255) 71%, rgba(255,239,162,255) 100%)"
      : props.secondary
      ? "linear-gradient(to bottom, rgba(107,96,223,255) 20%, rgba(75,81,193,255) 40%, rgba(44,65,164,255) 60%, rgba(24,56,145,255) 80%, rgba(13,50,134,255) 100%)"
      : props.brown 
      ? "linear-gradient(to bottom, rgba(151,79,4,255) 20%, rgba(151,79,4,255) 40%, rgba(151,79,4,255) 60%, rgba(151,79,4,255) 80%, rgba(151,79,4,255) 100%)"
      : props.purple 
      ? "linear-gradient(to bottom, rgba(68,3,146,255) 20%, rgba(68,3,146,255) 40%, rgba(68,3,146,255) 60%, rgba(68,3,146,255) 80%, rgba(68,3,146,255) 100%)"        
      : props.lightyellow 
      ? "linear-gradient(to bottom, rgba(253,246,103,255) 10%, rgba(253,246,103,255) 20%, rgba(253,246,103,255) 50%, rgba(253,246,103,255) 70%, rgba(253,246,103,255) 100%)"
      : props.brightblue 
      ? "linear-gradient(to bottom, rgba(22,119,255,255) 10%, rgba(22,119,255,255) 20%, rgba(22,119,255,255) 50%, rgba(22,119,255,255) 70%, rgba(22,119,255,255) 100%)"
      : props.rosered 
      ? "linear-gradient(to bottom, rgba(255,49,96,255) 10%, rgba(255,49,96,255) 20%, rgba(255,49,96,255) 50%, rgba(255,49,96,255) 70%, rgba(255,49,96,255) 100%)"
      : props.yellow 
      ? "linear-gradient(to bottom, rgba(252,194,0,255) 10%, rgba(255,221,0,255) 20%, rgba(255,223,0,255) 50%, rgba(255,221,0,255) 70%, rgba(252,194,0,255) 100%)"
      : props.white
      ? "linear-gradient(to bottom, rgba(196,196,196,255) 10%, rgba(223,223,223,255) 20%, rgba(249,249,249,255) 50%, rgba(223,223,223,255) 70%, rgba(196,196,196,255) 100%)"
      : props.pink
      ? "linear-gradient(to bottom, rgba(249,13,167,255) 10%, rgba(252,60,176,255) 20%, rgba(253,101,185,255) 50%, rgba(252,60,176,255) 70%, rgba(249,13,167,255) 100%)"
      : props.purplelight
      ? "linear-gradient(to bottom, rgba(173,157,252,255) 10%, rgba(142,101,250,255) 20%, rgba(79,14,248,255) 50%, rgba(75,9,246,255) 70%, rgba(94,35,248,255) 100%)"
      : "linear-gradient(to bottom, rgba(127,127,128,255) 20%, rgba(127,127,128,255) 50%, rgba(127,127,128,255) 51%, rgba(127,127,128,255) 100%)"};
  
`;

export default Button;
