import styled from "styled-components";
import { device } from "../../utils/utilConstants";
import {animated as a} from "react-spring";

const Container = styled(a.div)`
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  
  img {
    width: 100%;
    height: 100%;
  }
`;

export default { Container };
