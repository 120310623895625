import React, {useEffect,useState} from "react";
import {useTranslation, withTranslation} from 'react-i18next';
import Message from "../../components/default/Message";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {store} from "../../index";
import gameConstants from "../../utils/utilConstants";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

function GameMessage(props) {
    const {t} = useTranslation();
    const {showMessage, message, messageType} = useSelector(state => store.getState().gameSpheres, shallowEqual);
    const dispatch = useDispatch();
    const [showMessageAward, setShowMessageAward] = useState(false);
    const [messageAward, setMessageAward] = useState('');
    const [messageTypeAward, setMessageTypeAward] = useState(0);
    useEffect(() => {
        if(showMessage) {
            if(messageType==8||messageType==9){
                makeMessage();
            }else{
                makeNormalMessage();
            }
        }else{
            setShowMessageAward(false);
            setMessageAward('');
            setMessageTypeAward(0);
        }
        async function makeMessage() {
            await delay(3500);
            setShowMessageAward(showMessage);
            setMessageAward(message);
            setMessageTypeAward(messageType);
            // await delay(2500);
            // dispatch({ type: gameConstants.HIDE_MESSAGE });
        }
        async function makeNormalMessage() {
            setShowMessageAward(showMessage);
            setMessageAward(message);
            setMessageTypeAward(messageType);
            await delay(2000);
            dispatch({ type: gameConstants.HIDE_MESSAGE });
        }
    }, [showMessage]);

    return (
        <>
            <Message.Container>
                { messageTypeAward === 1 ?
                    <Message.SpheresHeader mediumfont warning>{ showMessageAward === true ? messageAward : ""}</Message.SpheresHeader>
                    : messageTypeAward === 2 ?
                    <Message.SpheresHeader mediumfont success>{ showMessageAward === true ? messageAward : ""}</Message.SpheresHeader>
                    : <Message.SpheresHeader>{ showMessageAward === true ? messageAward : ""}</Message.SpheresHeader>
                }
            </Message.Container>
        </>
    );
}

export default withTranslation('common')(GameMessage);
