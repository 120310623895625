import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';

import {store} from "../../index";
import BetAmount from "../../components/keno/BetAmount";
import ImageButton from "../../components/keno/ImageButton";
import ArrowYellowLeft from "../../assets/images/keno/ArrowYellowLeft.png";
import ArrowYellowRight from "../../assets/images/keno/ArrowYellowRight.png";
import TextBox from "../../assets/images/keno/Textbox.png";
import ImageNumber from "../../components/keno/ImageNumber";
import GameAward from "../../containers/GameAward";
import {motion} from "framer-motion";
import { TextField } from '@mui/material';

function GameAwardBetAmount(props) {
    const {earnAmount} = useSelector(state => store.getState().gameKeno, shallowEqual);
    const {t} = useTranslation();

    function handleIncreaseScore() {
        if(props.start === false) {
            props.onIncreaseScore();
        }
    }

    function handleDecreaseScore() {
        if(props.start === false) {
            props.onDescreaseScore();
        }
    }
    return (
        <>
            <BetAmount.Container>
                <Row>
                    <Col>
                        <ImageNumber.Container style={{"width": "80px"}}>
                            <img src={TextBox} alt={"TextBox"}/>
                            {props.isDisplay?
                            <ImageNumber.Header>
                                <GameAward from={0} to={earnAmount} />
                            </ImageNumber.Header>
                            :   
                            <ImageNumber.Header>
                                <GameAward from={0} to={0} />
                            </ImageNumber.Header>
                            }
                        </ImageNumber.Container>
                    </Col>
                    <Col>
                        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                            <ImageButton.Container onClick={() => {
                                handleDecreaseScore();
                            }}>
                                <img style={{width: "55%"}} src={ArrowYellowLeft} alt={"Arrow-Left"}/>
                            </ImageButton.Container>
                        </motion.div>
                    </Col>
                    <Col>
                        <ImageNumber.Container style={{"width": "130px", marginLeft: "-18px"}}>
                            <img src={TextBox} alt={"TextBox"}/>
                            <ImageNumber.Header>
                                <TextField 
                                    sx={{input:{color:'white', textAlign:'center'}}}
                                    type='text'
                                    InputProps={{disableUnderline: true}} 
                                    autoComplete="off"
                                    onChange={(event)=>props.onUpdateBetAmount(event.target.value)}  
                                    hiddenLabel 
                                    variant="standard" 
                                    value={props.betAmount} />
                            </ImageNumber.Header>
                        </ImageNumber.Container>
                    </Col>
                    <Col>
                        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                            <ImageButton.Container onClick={() => {
                                handleIncreaseScore();
                            }}>
                                <img style={{width: "55%"}} src={ArrowYellowRight} alt={"Arrow-Right"}/>
                            </ImageButton.Container>
                        </motion.div>
                    </Col>
                </Row>
            </BetAmount.Container>
        </>
    );
}

export default withTranslation('common')(GameAwardBetAmount);
