import Default from "./default";
import styled from "styled-components";

import { device } from "../../utils/utilConstants";

const Container = styled.div`
  border-radius: 8px;
  width: 100%;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export default { Container };
