import React, {useEffect, useState} from "react";
import {useSelector, shallowEqual} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';

import {store} from "../../index";
import Play from "../../components/dice/Play";
import Button from "../../components/default/Button";
import GameAward from "../../containers/GameAward";
import Stack from "react-bootstrap/Stack";
import {motion} from "framer-motion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import btn_play from '../../assets/images/dice/btn_play.png'

function GamePlay(props) {
    const {t} = useTranslation();

    function handleOnClick() {
        props.onStart();
    };

    function handleOnAuto() {
        props.onAutoBet();
    };

    return (
        <>
            <Play.Container>
            <Row>
                <Col style={{maxWidth: 'max-content',marginLeft: '27%',padding: '0px'}}>
                    <div>
                        <Play.Header>
                        {props.autoBet || props.isLock?
                            <div style={{backgroundImage:`url(${btn_play})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',width: '170px',height: '80px',paddingTop: '10%',paddingLeft: '10%', color:'gray'}}>
                                {t("dice_play")}
                            </div>
                            :
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.75 }}
                            >
                                <div style={{backgroundImage:`url(${btn_play})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',width: '170px',height: '80px',paddingTop: '10%',paddingLeft: '10%'}} onClick={handleOnClick}>
                                    {t("dice_play")}
                                </div>
                            </motion.div>
                        }
                        </Play.Header>
                    </div>
                </Col>
                <Col style={{maxWidth: 'max-content',padding: '0px'}}>
                    <div>
                        <Play.Header>
                        {props.pending?
                            props.autoBet || props.pending?
                                <div onClick={handleOnAuto}>
                                    <img style={{width: '60px'}}
                                        src={require(`../../assets/images/dice/btndim_autobet.png`)}/>
                                </div>
                            :
                                <div onClick={handleOnAuto}>
                                    <img style={{width: '60px'}}
                                        src={require(`../../assets/images/dice/btn_autobet.png`)}/>
                                </div>
                        :
                        <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.75 }}
                            >
                            {props.autoBet || props.pending?
                                <div onClick={handleOnAuto}>
                                    <img style={{width: '60px'}}
                                        src={require(`../../assets/images/dice/btndim_autobet.png`)}/>
                                </div>
                            :
                                <div onClick={handleOnAuto}>
                                    <img style={{width: '60px'}}
                                        src={require(`../../assets/images/dice/btn_autobet.png`)}/>
                                </div>
                            }</motion.div>
                        }
                        </Play.Header>
                    </div>
                </Col>
            </Row>
            </Play.Container>
        </>
    );
}

export default withTranslation('common')(GamePlay);
