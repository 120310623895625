import { animated as a } from "react-spring";
import styled, {keyframes} from "styled-components";

import { device } from "../../utils/utilConstants";

const pulse = keyframes`
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
`;

const Container = styled(a.div)`
  width: 100px;
  color: inherit;
  align-self: center;
`;

const Header = styled.h1`
  text-align: center;
  font-size: 25px;
  color: #000000;
  font-weight: bold;
`;

const Value = styled(a.span)`
  text-align: center;
  display: block;
  font-size: 12px;
  color: #000000;
`;

export default { Container, Header, Value };
