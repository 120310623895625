import {useTranslation, withTranslation} from 'react-i18next';

import Play from "../../components/dice/Play";
import Button from "../../components/default/Button";
import {motion} from "framer-motion";
import React, {useEffect, useState} from "react";
import btn_roll from '../../assets/images/dice/btn_roll.png'
import btn_rolldim from '../../assets/images/dice/btn_rolldim.png'

function GameRollUnder(props) {
    const {t} = useTranslation();
    const [rollOverInput, setRollOverInput] = useState(true);

    function handleOnClick() {
        props.onClick();
    }

    useEffect(() => {
        if(props.rollOver === true) {
            setRollOverInput(false);
        } else {
            setRollOverInput(true)
        }
    }, [props.rollOver]);

    return (
        <>
            <Play.Container>
                <Play.Header>
                {props.isLock || props.autoBet?
                 rollOverInput === true ?
                    <div style={{backgroundImage:`url(${btn_roll})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',display: 'flex',width: '100%',height: '70px'}} disabled={props.autoBet?props.autoBet:props.islock}>
                        <div style={{padding: '20px', width:'100%', fontSize:'18px'}}>
                            {t("dice_roll_under")}
                        </div>
                    </div>
                    :
                    <div style={{backgroundImage:`url(${btn_rolldim})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',display: 'flex',width: '100%',height: '70px'}} disabled={props.autoBet?props.autoBet:props.islock}>
                        <div style={{padding: '20px', width:'100%', fontSize:'18px'}}>
                            {t("dice_roll_under")}
                        </div>
                    </div>
                
                :
                    <motion.div whileTap={{ rotate: 45, scale: 0.80 }}>
                    { rollOverInput === true ?
                        <div style={{backgroundImage:`url(${btn_roll})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',display: 'flex',width: '100%',height: '70px'}} onClick={handleOnClick} disabled={props.autoBet?props.autoBet:props.islock}>
                            <div style={{padding: '20px', width:'100%', fontSize:'18px'}}>
                                {t("dice_roll_under")}
                            </div>
                        </div>
                        :
                        <div style={{backgroundImage:`url(${btn_rolldim})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',display: 'flex',width: '100%',height: '70px'}} onClick={handleOnClick} disabled={props.autoBet?props.autoBet:props.islock}>
                            <div style={{padding: '20px', width:'100%', fontSize:'18px'}}>
                                {t("dice_roll_under")}
                            </div>
                        </div>
                    }
                    </motion.div>
                }
                    
                </Play.Header>
            </Play.Container>
        </>
    );
}

export default withTranslation('common')(GameRollUnder);
