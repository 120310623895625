import React from "react";

import ImageResult from "../../components/keno/ImageResult";
import ResultCircle from "../../assets/images/keno/ResultCircle.png";

function GameResult(props) {
    function renderResultNumberImage() {
        let row1 = Object.values(props.ballNumbers).filter((val,i)=>i<10);
        return (
            <>
                {row1.map((ing, j) => (
                <ImageResult.Container key={j}>
                    <img src={ResultCircle} alt={'Result-Circle'}/>
                    <ImageResult.Header>
                        {ing === -1 ? '' : ing}
                    </ImageResult.Header>
                </ImageResult.Container>
                ))}
            </>
        )
    };

    return (
        <>{renderResultNumberImage()}</>
    );
}

export default GameResult;
