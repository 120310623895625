import React, {useEffect, useState, useCallback} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styled from "styled-components";

import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';

import {store} from "../index";
import GamePrediction from "../containers/dice/GamePrediction";
import GameRangeSlider from "../containers/dice/GameRangeSlider";
import GameResultNumber from "../containers/dice/GameResultNumber";
import GamePlay from "../containers/dice/GamePlay";
import GameButton from "../containers/dice/GameButton";
import GameChance from "../containers/dice/GameChance";
import GameMultiplier from "../containers/dice/GameMultiplier";
import GameRollUnder from  "../containers/dice/GameRollUnder";
import GameRollOver from "../containers/dice/GameRollOver";
import GameMessage from "../containers/dice/GameMessage";
import GameDiceObject from "../containers/dice/GameDice";
import GameProfit from "../containers/dice/GameProfit";
import {getMemberBalance} from "../services/HomeServices";
import {getQuota} from "../services/QuotaServices";
import { playGame } from "../services/GameDiceServices";
import '../styles/Dice/Dice.css'
import DiceGameMoney from "../containers/DiceGameMoney";
import gameConstants, {betAmtConstants} from "../utils/utilConstants";
import bg_h5 from '../assets/images/dice/bg_h5.png';
import bg_web from '../assets/images/dice/bg_web.gif';
import white_bg from '../assets/images/dice/white_bg.png';
import snow from '../assets/images/dice/snow.gif';
import {isMobile} from "react-device-detect";

const GameMainContainer = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    overflow: scroll;
`;

const InnerContainer = styled.div`
    display: flex;
    justify-content: center;
    color: white;
`;

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

function GameDice() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const balanceMoney = useSelector(state => store.getState().memberInfo.MemberInfo.balanceMoney, shallowEqual);
    const [start, setStart] = useState(false);
    const [rollOver, setRollOver] = useState(false);
    const [isLock, setIsLock] = useState(false);
    const [betAmount, setBetAmount] = useState(Number(10.00).toFixed(2));
    const [predictionValue, setPredictionValue] = useState(50);
    const [minBetAmount, setMinBetAmount] = useState(Number(betAmtConstants.minAmt).toFixed(2));
    const [maxBetAmount, setMaxBetAmount] = useState(Number(betAmtConstants.maxAmt).toFixed(2));
    const [autoBet, setAutoBet] = useState(false);
    const [minLock, setMinLock] = useState(false);
    const [halfLock, setHalfLock] = useState(false);
    const [doubleLock, setDoubleLock] = useState(false);
    const [maxLock, setMaxLock] = useState(false);
    const [isRolling, setIsRolling] = useState(false);
    const [pending, setPending] = useState(false);
    const [resultNum, setResultNum] = useState(0);

    const handlePrediction = (value) => {
        if(!autoBet){
            if(start === false) {
                setPredictionValue(value);
            }
        }
    };

    const handleRollOver = () => {
        if(!autoBet){
            if(start === false) {
                setRollOver(true);
            }
        }
    };

    const handleRollUnder = () => {
        if(!autoBet){
            if(start === false) {
                setRollOver(false);
            }
        }
    };

    const handleOnMinScore = () => {
        if(start === false && !autoBet) {
            setBetAmount(Number(minBetAmount).toFixed(2));
        }
    };

    const handleOnMaxScore = () => {
        if(start === false && !autoBet) {
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            if(Number(latestBalance) <= Number(maxBetAmount)) {
                setBetAmount(Number(latestBalance).toFixed(2));
            } else {
                setBetAmount(Number(maxBetAmount).toFixed(2));
            }
        }
    };

    const handleOnHalfScore = () => {
        if(start === false && !autoBet) {
            let calculatedAmt = Math.round((betAmount / 2) * 100) / 100;
            if (calculatedAmt > Number(minBetAmount)) {
                setBetAmount(calculatedAmt.toFixed(2));
            } else {
                setBetAmount(Number(minBetAmount).toFixed(2));
                dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_Min_balance"), messageType: 1 });
            }
        }
    };

    const handleOnTimeScore = () => {
        if(start === false && !autoBet) {
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            let calculatedAmt = Math.round((betAmount * 2) * 100) / 100;
            if(calculatedAmt <= Number(maxBetAmount)) {
                if (calculatedAmt <= Number(latestBalance)) {
                    setBetAmount(calculatedAmt.toFixed(2));
                } else {
                    setBetAmount(latestBalance.toFixed(2));
                    dispatch({type: gameConstants.SHOW_MESSAGE, message: t("facade_Max_balance"), messageType: 1});
                }
            } else {
                setBetAmount(Number(maxBetAmount).toFixed(2));
                dispatch({type: gameConstants.SHOW_MESSAGE, message: t("facade_Max_balance"), messageType: 1});
            }
        }
    };

    const onUpdateBetAmount = (amt) => {
        if(start === false) {
            const regex = /^(\d*\.{0,1}\d{0,2}$)/;
            if(regex.test(amt)) {
                setBetAmount(amt);
            }
        }
    };

    const handleOnClick = () => {
        if(start === false) {
            if(Number(betAmount) < Number(minBetAmount)) {
                setAutoBet(false);
                dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_Min_balance") + Number(minBetAmount).toFixed(2), messageType: 1 });
            } else {
                let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
                if(Number(latestBalance) < Number(betAmount)) {
                    setAutoBet(false);
                    dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_insufficient_balance"), messageType: 1 });
                } else {
                    if(!isRolling){
                        setResultNum(0);
                        setIsRolling(true); 
                        dispatch({type: gameConstants.RUN_GAME});
                        setStart(true);
                        setIsLock(true);
                    }
                }
            }
        }
    };

    const handleAutoBet = () => {
        var auto = !autoBet;
        if(!pending){
            setAutoBet(auto);
        }
        
    };

    useEffect(() => {
        const setautoPlayBlock = async () => {
            await delay(2000);
            setPending(false);
          };
        if(autoBet) {
            if(!pending){
                setPending(true);
                handleOnClick();
                setautoPlayBlock();
            }
        }else{
            setautoPlayBlock();
            setIsLock(autoBet);
        }
    }, [autoBet]);

    const handleEndGame = () => {
        setStart(false);
    };

    const updateCallback = (res) => {
        setResultNum(store.getState().gameDice.randomNumber);
        console.log('res:'+res);
        if(!res) {
            setAutoBet(false);
            handleEndGame();
        }
    };

    useEffect(() => {
        const autoPlay = async () => {
            await delay(4000);
            handleOnClick()
          };
        
        if(start === true) {
            fetchGame().then(()=>{
                
            }).catch((err) => {
                console.log(err);
            }).finally( () => {
                //getMemberBalance();
                setIsRolling(false);
            });
        }else{
            if(autoBet){
                autoPlay();
            }else{
                setIsLock(false);
            }
        }
    }, [start]);

    useEffect( () => {
        getQuota("dice", updateQuotaCallback);
    }, []);

    const updateQuotaCallback = (res) => {
        if(res !== '' && res !== undefined) {
            if(res.minBetAmount !== null) {
                setBetAmount(Number(res.minBetAmount).toFixed(2));
                setMinBetAmount(Number(res.minBetAmount).toFixed(2));
            }
            if(res.maxBetAmount !== null) setMaxBetAmount(Number(res.maxBetAmount).toFixed(2));
        } else {
            setBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMinBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMaxBetAmount(Number(betAmtConstants.maxAmt).toFixed(2));
        }
    };

    const fetchGame = useCallback(async () =>{
        await playGame(start, betAmount, rollOver, predictionValue, updateCallback);
    }, [start, betAmount, rollOver, predictionValue]);

    return (
        <GameMainContainer style={{backgroundImage: `url(${isMobile?bg_h5:bg_web})`, height: window.innerHeight,backgroundSize: '100vw '+window.innerHeight+'px', backgroundRepeat: 'no-repeat', overflowX:'hidden'}}>
            <div style={{backgroundImage: isMobile?`url(${snow})`:"none", height: window.innerHeight,backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <InnerContainer className={"content-container-inner"}>
                <Container fluid className={"content-container"} style={{zoom:window.innerWidth>=370?'1':window.innerWidth>=335?'0.9':window.innerWidth>=310?'0.85':window.innerWidth>=300?'0.8':'0.75'}}>
                    <DiceGameMoney gameName={"dices"}/>
                    <Row style={{width: '100%',margin: 'auto',display: 'flex',justifyContent: 'center'}}>
                        <img style={{width: '60%'}} src={require(`../assets/images/dice/Artboard_19.png`)}/></Row>
                    <Row style={{"borderRadius" : "15px", "padding": "15"}}>
                        <Col className="row-col-contents" style={{padding: '0px'}}>
                            <GamePrediction rollOver={rollOver} predictionValue={predictionValue}/>
                            <div  style={{width:'25%', paddingTop:'3%'}}>
                                <img src={require(`../assets/images/dice/penguin.gif`)} style={{width:'100%'}}/>
                            </div>
                            <GameResultNumber resultNum={resultNum}/>
                        </Col>
                    </Row>
                    <div style={{backgroundImage: `url(${white_bg})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',height: '43%',marginLeft: '-3.5%', width:'100%'}}>
                    <div style={{marginLeft:'7%'}}>
                    <Row style={{marginLeft:'0%'}}>
                        <Col className="row-col-contents-rolls"><GameMultiplier rollOver={rollOver} predictionValue={predictionValue}/></Col>
                        <Col className="row-col-contents-rolls" style={{marginLeft:'-10%'}}><GameChance rollOver={rollOver} predictionValue={predictionValue}/></Col>
                        <Col className="row-col-contents-rolls"><GameProfit/></Col>
                    </Row>
                    {/* <Row>
                        <Col style={{textAlign:'center',color:'#1976d2',fontSize: '16px',fontWeight: 'bold'}}>{predictionValue}</Col>
                    </Row> */}
                    <Row style={{padding: '20px 25px 20px'}}>
                        <Col className="row-col-drag-bar"><GameRangeSlider rollOver={rollOver} onSlide={handlePrediction} min={0} max={100} isLock={isLock} autoBet={autoBet}/></Col>
                    </Row>
                    <Row>
                        <Col className="row-col-contents-rolls">
                            <GameRollUnder rollOver={rollOver} isLock={isLock} autoBet={autoBet} onClick={handleRollUnder} />
                         </Col>
                        <Col className="row-col-contents-rolls">
                            <GameRollOver rollOver={rollOver} isLock={isLock} autoBet={autoBet} onClick={handleRollOver} /></Col>
                    </Row>
                    </div>
                    </div>

                    <Row style={{paddingTop:"10px", paddingBottom:"10px", height:'30px',marginTop: '10px'}}>
                        <Col><GameMessage onEndGame={handleEndGame} /></Col>
                    </Row>

                    <Row style={{paddingTop:"10px",paddingBottom:"2px", borderRadius : "8px"}}>
                        <Col sm={12} className="row-col-buttons">
                            <GameButton onMinScore={handleOnMinScore} onMaxScore={handleOnMaxScore}
                                        onHalfScore={handleOnHalfScore} onTimeScore={handleOnTimeScore}
                                        betAmount={betAmount} onUpdateBetAmount={onUpdateBetAmount}
                                        isLock={isLock} autoBet={autoBet} pending={pending}
                            />
                        </Col>
                    </Row>
                    
                    <Row style={{borderRadius : "8px",marginTop: '-10px'}}>
                        <Col sm={12}><GamePlay onStart={handleOnClick} autoBet={autoBet} pending={pending} isLock={isLock} onAutoBet={handleAutoBet}/></Col>
                    </Row>
                </Container>
            </InnerContainer>
            </div>
        </GameMainContainer>
    );
};

export default withTranslation('common')(GameDice);
