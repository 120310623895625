import React from "react";
import {withTranslation} from 'react-i18next';
import ReactDice from 'react-dice-complete'
import 'react-dice-complete/dist/react-dice-complete.css'
import Dice from "../../components/dice/Dice";

function GameDiceObject() {
    return (
        <>
            <Dice.Container>
                <ReactDice
                    numDice={1}
                    dieSize={30}
                    rollTime={60}
                    margin={35}
                    disableIndividual={false}
                    faceColor="#FFF3F3"
                    dotColor="#000000"
                />
            </Dice.Container>
        </>
    );
}

export default withTranslation('common')(GameDiceObject);
