import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {useTranslation, withTranslation} from 'react-i18next';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {store} from "../../index";
import gameConstants from "../../utils/utilConstants";
import Win from "../../assets/images/keno/Win.png";
import Lose from "../../assets/images/keno/Lose.png";
import helpers from "../../utils/helpers";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

function GameModal(props) {
    const {t} = useTranslation();
    const {showMessage, message, messageType} = useSelector(state => store.getState().gameKeno, shallowEqual);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    useEffect(() => {
        let filterBallNumbers = helpers.filterItems(-1, Object.values(props.ballNumbers));
        if(showMessage === true && filterBallNumbers.length === 20) {
            setShow(true);
            makeMessage();
        }
        async function makeMessage() {
            await delay(2000);
            dispatch({ type: gameConstants.HIDE_MESSAGE });
            setShow(false);
            props.onEndGame();
        }
    }, [props.ballNumbers, showMessage]);

    return (
        <>
            <Modal show={show}>
                <Modal.Body style={{"display":"flex", "justifyContent":"center", "background":"white"}}>
                { messageType === 1 ?
                    showMessage === true ? message : ""
                    : messageType === 2 ? <img src={Win} alt={"win"} style={{"width":"50%"}}/>
                    : messageType === 3 ? <img src={Lose} alt={"lose"} style={{"width":"50%"}}/> : ""
                }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default withTranslation('common')(GameModal);
