import {animated as a} from "react-spring";
import styled, {keyframes} from "styled-components";

import {device} from "../../utils/utilConstants";

const Container = styled(a.div)`
   height: ${props => ( props.ignore ? "" : "100%")};
   position: relative;
   width: 41px;
   overflow: hidden;
   
   img {
    width: ${props => (props.imgsizes ? "35%" : props.imgsizel ? "70%" : "100%")};
  }
`;

const Header = styled.h1`
    font-size: 20px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
`;

export default {Container, Header};
