import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";

import Money from "../components/dice/Money";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GameRules from "../containers/GameRules";
import PlayHistory from "../components/default/PlayHistory";
import { store } from "../index";
import { BsSlack } from "react-icons/bs";

function DiceGameMoney(props) {
  const { t } = useTranslation();

  const currency = useSelector(
    (state) => store.getState().memberInfo.MemberInfo.currency,
    shallowEqual
  );
  const balanceMoney = useSelector(
    (state) => store.getState().memberInfo.MemberInfo.balanceMoney,
    shallowEqual
  );

  function renderMoney() {
    return (
      <Row>
        <Col xs={10} className="row-col-money-dice">
          <Money.Item>
            <Money.Image>
              <img
                src={require(`../assets/images/dice/coin.png`)}
                alt={"Money"}
              />
            </Money.Image>
            <Money.Label>
              {currency === undefined ? "" : currency}
              {balanceMoney === undefined ? "0.00" : balanceMoney.toFixed(2)}
            </Money.Label>
          </Money.Item>
        </Col>
        <Col xs={1} className="row-col-money-dice" style={{paddingRight: '40px'}}>
          <GameRules gameName={props.gameName} />
          <PlayHistory gameName={props.gameName} />
        </Col>
      </Row>
    );
  }

  return <Money.Container>{renderMoney()}</Money.Container>;
}

export default withTranslation("common")(DiceGameMoney);
