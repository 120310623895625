import { useState,useEffect,useRef } from "react";
import { withTranslation,useTranslation } from 'react-i18next';
import { store } from '../../index';
import '../../styles/components/PlayHistory.scss';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { RiCloseFill } from 'react-icons/ri';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';

const GameTextField = styled(TextField)({
  backgroundColor: '#191D29',
  borderRadius: '10px',
  border: '2px solid #B79562!important',
  width: '100%'
});

const GameResultTextField = styled(TextField)({
  backgroundColor: '#191D29',
});

const CalculateButton = styled(Button)({
  backgroundColor: '#4754E2!important',
  color: 'white',
  borderRadius: '10px'
});

function PlayHistory({gameName}){
  
  const [isModalShow, setIsModalShow] = useState(false);

  const [isSeedModalShow, setIsSeedModalShow] = useState(false);

  const [containerRows, setContainerRows] = useState([]);

  const [serverSeedTf, setTServerSeedTf] = useState("");
  const [clientSeedTf, setTClientSeedTf] = useState("");
  const [nonceTf, setNonceTf] = useState("");
  const [bombNumberTf, setBombNumberTf] = useState(2);
  const [roundTf, setRoundTf] = useState(0);
  //response result
  const [serverHashTf, setTServerHashTf] = useState("");
  const [awardResultTf, setTAwardResultTf] = useState("");

  const handleClose = () => setIsModalShow(false);
  const handleShow = () => {
    getBetRecordHistory();
    setIsModalShow(true)
  };

  const handleSeedClose = () => {
    setTServerSeedTf("");
    setTClientSeedTf("");
    setNonceTf("");
    setTServerHashTf("");
    setTAwardResultTf("");
    setIsSeedModalShow(false)
  };

  const handleSeedShow = (serverSeed, clientSeed, bombNum) => {
    let memberInfo = store.getState().memberInfo;
    let nonce = memberInfo.MemberInfo.nonce;

    console.log("server seed: " + serverSeed);
    console.log("client seed: " + clientSeed);
    console.log("nonce: " + nonce);

    setTServerSeedTf(serverSeed);
    setTClientSeedTf(clientSeed);
    setNonceTf(nonce);
    setBombNumberTf(bombNum);

    setIsSeedModalShow(true)
  };

  const [customStyle, setCustomStyle] = useState({});

  const {t} = useTranslation();

  const [tooltipsOpen, setTooltipsOpen] = useState([]);

  const handleTooltipClose = () => {
    console.log("--- handleTooltipClose ---");
    setTooltipsOpen([]);
  };

  const handleTooltipOpen = (index) => {
    console.log("--- handleTooltipsOpen --- index : " + index );
    setTooltipsOpen(tooltipsOpen[index] = true);
    console.log("--- handleTooltipsOpen --- end " );
  };

  useEffect(()=>{
    let tempStyle = {};
    tempStyle.height = gameName === "triple"|| gameName ==="spheres"? '35px' : gameName === "mine" ? '40px' : '30px';
    tempStyle.marginTop = gameName === "triple"|| gameName ==="spheres"? '' : '1px';
    tempStyle.marginRight = gameName ==="diamond"? '50%' : '';
    setCustomStyle(tempStyle);    
  },[gameName]);

  const [image, setImage] = useState();
 
  useEffect(() => {
    if (gameName == 'diamond') {
      setImage(require('../../assets/images/diamond/records.png'));
     } else if(gameName == 'triple') {
      setImage(require('../../assets/images/triple/records.png'));
     } else if(gameName == 'dices') {
      setImage(require('../../assets/images/dice/btn_history.png'));
     } else if(gameName == 'spheres') {
      setImage(require('../../assets/images/spheres/records.png'));
     } else if(gameName == 'mine') {
      setImage(require('../../assets/images/mine/mine_btn_history.png'));
     } else if(gameName == '7-11-21') {
      setImage(require('../../assets/images/seven1121/btn_history.png'));
     }else {
      setImage(require('../../assets/images/playHistory.png'));
     }
  }, [image])

  const getBetRecordHistory = () => {
    let memberInfo = store.getState().memberInfo;
    const data = new URLSearchParams();
    data.set('gameId', gameName.toUpperCase());
    fetch(global.config.domain.member + '/api/loadBetRecord?gametoken='+ store.getState().memberInfo.token, {
        method: 'POST',
        headers: new Headers({
            'gametoken': memberInfo.token
        }),
        credentials: 'include',
        body: data,
        referrer: 'h5'
    })
    .then((response) => response.json())
    .then((response) => {
      // console.log("getBetRecordHistory response data : ")
      // console.log(response);

      if(response.status === true && response.code === 200){
        console.log("result success");
        let records = response.result.page.records;
       
        let tempRowsAry = [];
        records.forEach( (element, index) => {
          let clientSeed;
          let bombNum = 2;
          if(element.gameId == 'MINE'){
            let result = element.awardResult;
            bombNum = result.split("1").length - 1;
          }

          if(element.clientSeed){
            clientSeed = element.clientSeed;
          }else{
            clientSeed = memberInfo.MemberInfo.clientSeed;
          }
  
          // let tooltipDiv = <div className="clickable-div" onClick={()=> handleSeedShow(element.serverSeed, clientSeed, bombNum) } key={index}>
            let tooltipDiv = <div className="clickable-div" key={index}>
            {t('server_seed_label')}:<br/>
            {element.serverSeed}<br/>
            <br/>
            {t('client_seed_label')}:<br/>
            {clientSeed}<br/>
          </div>;

          let tempRow = 
          <Row key={index} className="record-data-row">
            <Col ><span>{moment(element.clearingDate).format('MM-DD HH:mm')}<br/>{element.billNo}</span></Col>
            <Col xs={2}><span>{Number(element.betAmount).toFixed(2)}</span></Col>   
            <Col xs={2}>
              <span>{element.winStatus === 5 ? t('win') : 
                element.winStatus === 3 ? t('peace') : t('losed')}</span>
            </Col>
            <Col xs={2}><span>{Number(element.betAward).toFixed(2)}</span></Col> 
            <Col xs={1}>
              {gameName !== 'luckycircle'?
                <Tooltip  
                  disableFocusListener 
                  title={tooltipDiv} 
                  enterTouchDelay={0}
                  placement="bottom-start">

                  <Button ><BsFillInfoCircleFill  style={{color: "white", fontSize: "1.5em"}}/></Button>
                </Tooltip >
              :
              null
              }
              
            </Col>
          </Row>;

          tempRowsAry.push(tempRow);
        });

        setContainerRows(tempRowsAry);

      }
       
    })
    .catch((error) => {
        console.log("error forgotpassword")
        console.error(error);
        // alert("网络问题, 请稍后重试");
    });
  }

  const handleCalculate = () => {
    let memberInfo = store.getState().memberInfo;
    const data = new URLSearchParams();
    data.set('gameId', gameName.toUpperCase());
    data.set('serverSeed', serverSeedTf);
    data.set('clientSeed', clientSeedTf);
    data.set('nonce', nonceTf);

    if(gameName === "mine"){
      data.set('bombNum', bombNumberTf);
    }else if(gameName === "crash"){
      data.set('round', roundTf);
    }

    fetch(global.config.domain.member + '/api-instant/loadFairHash?gametoken='+ store.getState().memberInfo.token, {
        method: 'POST',
        headers: new Headers({
            'gametoken': memberInfo.token
        }),
        credentials: 'include',
        body: data,
        referrer: 'h5'
    })
    .then((response) => response.json())
    .then((response) => {
      console.log("handleCalculate response data : ")
      console.log(response);

      if(response.status === true && response.code === 200){
        let serverHash = response.result.baseObj.serverHash;
        let awardResult;
        
        if(gameName !== "crash"){
          awardResult = response.result.baseObj.awardResult;
        }else{
          awardResult = response.result.baseObj.listResult[response.result.baseObj.round].awardResult;
        }
        setTServerHashTf(serverHash);
        setTAwardResultTf(awardResult);
      }

    })
    .catch((error) => {
        console.log("error forgotpassword")
        console.error(error);
        // alert("网络问题, 请稍后重试");
    });
  }



  return (
    <>
    <img onClick={handleShow} className="play-history-img" alt="play-history" 
      style={{float:'right', ...customStyle }} src={image} />
      <Modal show={isModalShow} onHide={handleClose}
            className="play-history-modal"
            style={{ display: 'block', position: 'absolute' }}
          >
           
        <Modal.Body>
          {/* <p>Modal body text goes here.</p> */}
          <Container fluid >
            <Row className="record-title-row">
              <Col><span>{t('cash_history')}</span></Col>
            </Row>
            <Row className="record-header-row">
              
              <Col >
                <span>{t('label.bet.time')+"/"+t('label.bill.no')}</span>
              </Col>
              <Col xs={2}>
                <span>{t('label.bet.amount')}</span>
              </Col>
              <Col xs={2}>
                <span>{t('label.award.results')}</span>
              </Col>
              <Col xs={2}>
                <span>{t('label.reward.amount')}</span>
              </Col>
              <Col xs={1}>
                <span></span>
              </Col>
            </Row>
            
            {containerRows.length > 0 ?
                containerRows
            :
            <Row className="record-data-row">
              <Col><span>{t('cash_no_record')}</span></Col>
            </Row>
            }
          </Container>
        </Modal.Body>
 
      </Modal>
      <Modal show={isSeedModalShow} onHide={handleSeedClose}
            className="provably-fair-modal"
            style={{ display: 'block', position: 'absolute' }}
          >
        <Modal.Body>
          <Container fluid >
            
            <Row className="record-title-row">
              <Col xs={1} ></Col>
              <Col><span>{t('fair_hash_title')}</span></Col>
              <Col xs={1} ><RiCloseFill onClick={handleSeedClose} style={{color:'white'}} /></Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            <Row className="record-content-row">
              <Col xs={3} >{t('server_seed_label')}</Col>
              <Col ><GameTextField value={serverSeedTf} multiline rows={3} ></GameTextField></Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            {gameName !== 'crash' ? 
            <>
            <Row className="record-content-row">
              <Col xs={3} >{t('client_seed_label')}</Col>
              <Col ><GameTextField value={clientSeedTf} multiline rows={3} ></GameTextField></Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            </>
            :
            null
            }
            <Row className="record-content-row">
              <Col xs={3} >{t('nonce_label')}</Col>
              <Col ><GameTextField value={nonceTf}></GameTextField></Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            {gameName === 'mine' ?
            <>
            <Row className="record-content-row">
              <Col xs={3} >{t('bomb_number_label')}</Col>
              <Col ><GameTextField value={bombNumberTf}></GameTextField></Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            </>
            :
            null
            }
            {gameName === 'crash' ?
            <>
            <Row className="record-content-row">
              <Col xs={3} >{t('rounds_label')}</Col>
              <Col ><GameTextField value={roundTf}></GameTextField></Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            </>
            :
            null
            }
            <Row className="record-content-row">
              <Col ><CalculateButton onClick={handleCalculate}>{t('calculate')}</CalculateButton></Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>

            <Row className="record-content-row">
              <Col >{t('server_hash_label')}</Col>
            </Row>
            <Row className="record-result-row">
              <Col><GameResultTextField value={serverHashTf} multiline rows={2} InputProps={{disableUnderline: true, readOnly:true}} hiddenLabel variant="standard"></GameResultTextField></Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>

            <Row className="record-content-row">
              <Col >{t('award_result_label')}</Col>
            </Row>
            <Row className="record-result-row">
              <Col><GameResultTextField value={awardResultTf} multiline rows={2} InputProps={{disableUnderline: true, readOnly:true}} hiddenLabel variant="standard"></GameResultTextField></Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>

          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withTranslation('common')(PlayHistory);