import React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {store} from '../index';
import { SaveMemberInfo} from '../actions/HomePageAction';
import { checkLogin,doLogin,getMemberInfo,getMemberBalance} from '../services/HomeServices'

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    memberInfo: state.memberInfo,
  };
};

const divform = {
  display: 'flex',
  background: 'white',
  padding: '20px',
  margin: '30px'
};

class LoginPage extends React.Component {

  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      username: "",
      password: "",
      site: "7000232",
      usertype: "0",
      message: "",
      open: false
    };
  }

  componentDidMount() {
    console.log("in login page");
  }
 
  setUsername = event => {
    this.setState({
      username: event.target.value
    });
  };
  setPassword = event => {
    this.setState({
      password: event.target.value
    });
  };
  setSite = event => {
    this.setState({
      site: event.target.value
    });
  };
  setUsertype = event => {
    this.setState({
      usertype: event.target.value
    });
  };

  loginPost = function () {
    let username = this.state.username;
    let password = this.state.password;
    let site = this.state.site;
    let usertype = this.state.usertype;
    let msg = "success";

    const isEmpty = function (value) {
      if (value !== "") {
        value = value.trim();
      }
      return !(value && true && value !== "");
    }

    if ((isEmpty(username) || username.length < 6 || username.length > 14 || /[^a-z0-9]/.test(username))) {
      return this.props.t('loginpage.msg.username');
    }
    if ((isEmpty(password) || password.length < 6 || password.length > 16 || !/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9A-Za-z]{6,16}$/.test(password))) {
      return this.props.t('loginpage.msg.password');
    }
    if (isEmpty(site)) {
      return this.props.t('loginpage.msg.site');
    }

    const data = new URLSearchParams();
    data.set('username', username);
    data.set('password', password);
    data.set('siteid', site);
    data.set('usertype', usertype);

    fetch(global.config.domain.member + '/rest/minigame/login-valid', {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      credentials: 'include',
      body: data,
      referrer: 'h5'
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(response);
        if (response.code) {
          if (response.code === 200) {
            await getMemberInfo();
            await getMemberBalance();
            let token = response.result.token;
            window.location.href = window.location.href.split('#')[0] + "#/?gametoken=" + token;
            let memberInfo = store.getState().memberInfo;
            memberInfo.token = token;
            store.dispatch(SaveMemberInfo(memberInfo));
            window.location.reload();
          } else {
            msg = response.msg;
            this.setState({
              open: true,
              message: msg
            });
          }
        } else {
          msg = response.msg;
          this.setState({
            open: true,
            message: msg
          });
        }
      })
      .catch(() => {
        msg = this.props.t('msg.connectionerror');
        this.setState({
          open: true,
          message: msg
        });
      });
    return msg;
  };

  signIn = () => {
    let msg = this.loginPost();
    if (msg !== "success") {
      this.setState({
        open: true,
        message: msg
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <header style={divform}>
          <Container>
            <Row>
              <InputLabel>{t('Username')}</InputLabel>
              <TextField
                style={{ width: '80%' }}
                labeltext={t("Username")}
                id="username"
                variant="standard"
                placeholder={t("Username")}
                margin="normal"
                required
                onChange={this.setUsername}
                value={this.state.username} />
            </Row>
            <Row>
              <InputLabel>{t('Password')}</InputLabel>
              <TextField
                style={{ width: '80%' }}
                labeltext={t("Password")}
                id="password"
                variant="standard"
                placeholder={t("Password")}
                margin="normal"
                required
                type="password"
                onChange={this.setPassword}
                value={this.state.password} />
            </Row>
            <Row>
              <InputLabel>{t('site')}</InputLabel>
              <Select
                style={{ width: '80%' }}
                labeltext={t("site")}
                id="site"
                variant="standard"
                required
                onChange={this.setSite}
                value={this.state.site}>
                <MenuItem value="7000066">lotto.ybapi8888.com</MenuItem>
                <MenuItem value="7000232">lottoapi.ybapi8888.com</MenuItem>
              </Select>
            </Row>
            <Row>
              <InputLabel>{t('usertype')}</InputLabel>
              <Select
                defaultValue={0}
                style={{ width: '80%' }}
                labeltext={t("usertype")}
                id="usertype"
                variant="standard"
                required
                onChange={this.setUsertype}
                value={this.state.Usertype}>
                <MenuItem value="0">Gaia User</MenuItem>
                <MenuItem value="1">Game User</MenuItem>
              </Select>
            </Row>
            <Row>
              <div className="Button">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { this.signIn(); }}>
                  {t('登录')}
              </Button>
              </div>
            </Row>
          </Container>
          <br></br>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{t('登录')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                {t('确定')}
              </Button>
            </DialogActions>
          </Dialog>
        </header>
      </div>
    );
  }
}

const ConnectedLoginPage = connect(mapStateToProps)(LoginPage);
export default withTranslation('common')(ConnectedLoginPage);

