import {store} from '../index';
import i18next from 'i18next';
import { SaveMemberInfo, UpdateAlert, saveIndexInit} from '../actions/HomePageAction';

const tran = (input) => {
return i18next.t(input);
};

const doLogin = async function(token, platform){
    let success = false;
    let memberInfo = store.getState().memberInfo;
    let subPath = (platform === 'm') ? "doCheckLogin" : "login";
    await fetch(global.config.domain.member + "/rest/minigame/"+subPath+"?key="+token, {
        method: 'get',
        responseType:'json',
        referrer: 'h5'
    })
    .then((response) => response.json())
    .then((response) => {
        if(response.token!==''){
            success = true;
            memberInfo.token = response.token;
            store.dispatch(SaveMemberInfo(memberInfo));
            if(response.jumpGame && response.jumpGame !== 0) store.dispatch({ type: 'SET_JUMP_GAME', input: response.jumpGame });
        }
    })
    .catch((error) => {
        console.error(error);
    });

    return success;
};

const checkLogin = async function(token){
    let isLogin = false;
    await fetch(global.config.domain.member + "/rest/minigame/isLogin", {
      method: "post",
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'gametoken': token,
      }),
      credentials: 'include',
      referrer: 'h5'
    })
    .then((res) => res.json())
    .then((res) => {
       if(res.code===200){
          isLogin = true;
       }
    })
    .catch((error) => {
        console.error(error);
    })

    return isLogin;
}

const getMemberInfo = async function(gametoken) {
    let memberInfo = store.getState().memberInfo;
    if(gametoken==null){
        gametoken = memberInfo.token
    }
    let memberObj = store.getState().memberInfo.MemberInfo;
    await fetch(global.config.domain.member + "/rest/minigame/member/myinfo", {
        method: 'get',
        responseType:'json',
        referrer: 'h5',
        headers: new Headers({
            'gametoken': gametoken
        }),
    })
    .then((info) => info.json())
    .then((info) =>{
        if (info.code === 200) {
            if (info.result.useStatus !== 0) {
                let alertObj = store.getState().alert;
                alertObj.alert.content = tran('app.account.is.terminated');
                alertObj.alert.title = tran('提示');
                alertObj.alert.showAlert = false;
                store.dispatch(UpdateAlert(alertObj));
            } else {
                memberObj.loginName = info.result.loginName;
                memberObj.language = info.result.lang;
                memberObj.engVer = info.result.engVer;
                memberObj.zhVer = info.result.zhVer;
                memberObj.viVer = info.result.viVer;
                memberObj.thVer = info.result.thVer;
                memberObj.inVer = info.result.inVer;
                memberObj.systemLanguage = info.result.avaiLanguage;
                memberObj.currency = info.result.defaultCurr;
                memberObj.currAbbr = info.result.currAbbr;
                memberObj.loginTime = Date.parse(new Date());
                memberObj.memberId = info.result.accountId;

                i18next.changeLanguage(memberObj.language, (err) => {
                    // console.log("myinfo lang:" + memberObj.language);
                    if (err) console.log(err);
                })
                if(info.result.icon){
                   memberObj.userImgExist = true;
                   memberObj.userImg = info.result.icon;
                }

                store.dispatch({
                    type: 'SET_SITE_NAME',
                    input: info.result.siteName
                })
                store.dispatch(SaveMemberInfo(memberObj));

                let indexInit = store.getState().indexInit.indexInit;
                indexInit.categoryList = info.result.categoryList;
                indexInit.marketList = info.result.marketList;
                store.dispatch(saveIndexInit(indexInit));
            }
        }
        else if (info.code === 401) {
            window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
            return { json: () => { return 'MAINTAIN'; } };
        }
        else if (info.code === 500) {
            window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
            return { json: () => { return 'MAINTAIN'; } };
        }
    }).catch((error) => {
        window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
        return { json: () => { return 'MAINTAIN'; } };
    })
};

const getMemberBalance = function(){
    let memberInfo = store.getState().memberInfo;
    let memberObj = store.getState().memberInfo.MemberInfo;
    fetch(global.config.domain.member + "/rest/minigame/member/getmoney", {
        method: 'get',
        responseType:'json',
        referrer: 'h5',
        headers: new Headers({
            'gametoken': memberInfo.token
        }),
    })
    .then((response) => response.json())
    .then((response) => {
        if (response.code === 200) {
            memberObj.balanceMoney = response.result.money;
            memberObj.currency = response.result.defaultcurr;
            memberObj.gamecategory = response.result.gamecategory;
            memberObj.clientSeed = response.result.clientSeed;
            memberObj.nonce = response.result.nonce;
            store.dispatch(SaveMemberInfo(memberObj));
        }else if(response.code === 401){
            window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
            return { json: () => { return 'MAINTAIN'; } };
        }
        else if (response.code === 500) {
            window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
            return { json: () => { return 'MAINTAIN'; } };
        }
    })
    .catch((error) => {
        window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
        return { json: () => { return 'MAINTAIN'; } };
    });
};

const getLang = async function () {
    let memberInfo = store.getState().memberInfo;
    let res = await fetch(global.config.domain.member + "/getlang", {
      method: 'get',
      headers: new Headers({
        'gametoken': memberInfo.token
      }),
      responseType:'json',
      referrer: 'h5'
    })
    let result = await res.json();
    if(result.code !== 200){
        return 'zh';
    }
    else{
        if (result.result.lang && !['en', 'zh', 'vi', 'th', 'id', 'in', 'pt', 'es', 'fr', 'ru'].includes(result.result.lang)) {
            i18next.changeLanguage('zh', (err) => {
                if (err) console.log(err);
            })
            return 'zh';
        } else {
            //console.log("getLang result:" + result);
            i18next.changeLanguage(result.result.lang, (err) => {
                if (err) console.log(err);
            })
            return result.result.lang;
        }
    }
}

const getSiteCurrency = async function(){
    let memberInfo = store.getState().memberInfo;
    let memberObj = store.getState().memberInfo.MemberInfo;
    fetch(window.$gameUrl + "/rest/member/get-site-currency", {
      method: "Get",
      headers: new Headers({
        'Accept':'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'gametoken': memberInfo.token,
      }),
      credentials: 'include'
    })
    .then((response) => response.json())
    .then((response) => {
        var resList = response.result.currency.split("-");
        var currencySymbol = resList[0];
        var defaultShortForm = resList[1];
        memberObj.currAbbr = defaultShortForm;
        memberObj.currency = currencySymbol;
        store.dispatch(SaveMemberInfo(memberObj));

        // 显示货币符号
        // var currencyElement = document.getElementsByClassName("currencySymbol");
        // for (var i = 0; i < currencyElement.length; i++){
        //     currencyElement[i].textContent = currencySymbol;
        // }

        //显示货币缩写
        // if (defaultShortForm === 1){
        //   var moneyValueElement = document.getElementsByClassName("moneyValue");

        //   for (var i = 0; i < moneyValueElement.length; i++){
        //       if (currValue >= 1000){
        //           moneyValueElement[i].textContent = intToString(Math.trunc(currValue),defaultShortForm);
        //       }
        //   }
        // }
    })
    .catch((error) => {
        console.error(error);
    });
};

const intToString = function(number,shortform) {
    number = parseFloat((Math.round(number * 100) / 100).toFixed( 2 ));
    if ( number.toString().length > 3 && shortform === 1) {
        var abs = Math.abs( number );
        if ( abs >= Math.pow( 10, 15) ) {
        // quadrillion
        number = parseFloat(( number / Math.pow( 10, 15 ) ).toFixed( 2 )) + "Q";
        } else if ( abs < Math.pow( 10, 15 ) && abs >= Math.pow( 10, 12 ) ) {
        // trillion
        number = parseFloat(( number / Math.pow( 10, 12 ) ).toFixed( 2 )) + "T";
        } else if ( abs < Math.pow( 10, 12 ) && abs >= Math.pow( 10, 9 ) ) {
        // billion
        number = parseFloat(( number / Math.pow( 10, 9 ) ).toFixed( 2 )) + "B";
        } else if ( abs < Math.pow( 10, 9 ) && abs >= Math.pow( 10, 6 ) ) {
        // million
        number = parseFloat(( number / Math.pow( 10, 6 ) ).toFixed( 2 )) + "M";
        } else if ( abs < Math.pow( 10, 6 ) && abs >= Math.pow( 10, 3 ) ) {
        // thousand
        number = parseFloat(( number / Math.pow( 10, 3 ) ).toFixed( 2 )) + "K";
        }
        return number;
    } else {
        number = (Math.round(number * 100) / 100).toFixed( 2 );
        var parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
};

export { checkLogin, doLogin, getMemberInfo, getMemberBalance,getLang, getSiteCurrency,intToString}