import React, {useState, useEffect} from "react";
import {withTranslation, useTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import {TextField, Button} from '@mui/material';
import {useSelector, shallowEqual} from "react-redux";
import '../styles/Mine/Mine.css';
import io from 'socket.io-client';
import {store} from "../index";
import {getMemberBalance} from "../services/HomeServices";
import GameMoney from "../containers/GameMoney";
import gameConstants, {betAmtConstants} from "../utils/utilConstants";
import {getQuota} from "../services/QuotaServices";
import {isMobile} from "react-device-detect";
import h5_bg from "../assets/images/mine/mines_background.png";
import web_bg from "../assets/images/mine/mines_bg_web.jpg";
import ReactLoading from 'react-loading';

const AppContainer = styled('div')({
    width: '100vw',
    height: '100vh',

    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
});

const InnerContainer = styled('div')({
    // width: '1250px',
    height: 'fit-content',
    // backgroundColor: '#111923',
});

const GameButtonGold = styled(Button)({
    backgroundColor: '#F6BA24!important',
    borderRadius: '5px',
    height: '26px',
    margin: '7px 0 5px 0',
});

const GameButtonBlue = styled(Button)({
    backgroundColor: '#4350E1!important',
    borderRadius: '5px',
    height: '40px',
    width: '100%',
    margin: '7px 0 5px 0',
    fontSize: '20px',
});

// const socket = io('http://localhost:1102/',{
//   transports: ["websocket"],
// });

let socket;
if (global.config.testEnv === true) {
    socket = io(global.config.minigame.socket.protocol + '://' + global.config.domain.instantaward + ':' + global.config.minigame.socket.port.mine + '/', {
        transports: ["websocket"],
    });
} else {
    socket = io(global.config.minigame.socket.protocol + '://' + global.config.minigame.socket.domain.mine + '/', {
        transports: ["websocket"],
    });
}

const defaultImageArray = ["box", "box", "box", "box", "box"
    , "box", "box", "box", "box", "box"
    , "box", "box", "box", "box", "box"
    , "box", "box", "box", "box", "box"
    , "box", "box", "box", "box", "box"]

const defaultIsArrayClick = [0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0]

const randomDiamond = ['diamond1', 'diamond2', 'diamond3', 'diamond4'];

function Mine() {

    const balanceMoney = useSelector(state => store.getState().memberInfo.MemberInfo.balanceMoney, shallowEqual);
    const clientSeed = useSelector(state => store.getState().memberInfo.MemberInfo.clientSeed, shallowEqual);
    const nonce = useSelector(state => store.getState().memberInfo.MemberInfo.nonce, shallowEqual);
    const [tempStarArray, setTempStarArray] = useState([]);
    const [mineNum, setMineNum] = useState(2);
    const [betAmount, setBetAmount] = useState(Number(10.00).toFixed(2));
    const [minBetAmount, setMinBetAmount] = useState(Number(betAmtConstants.minAmt).toFixed(2));
    const [maxBetAmount, setMaxBetAmount] = useState(Number(betAmtConstants.maxAmt).toFixed(2));
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [isGameStarted, setIsGameStarted] = useState(false);
    const [gameStartedData, setGameStartedData] = useState({});
    const [isCardClicked, setIsCardClicked] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false);
    const [modalHeader, setModalHeader] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFooter, setModalFooter] = useState("");
    const [centerImageArray, setCenterImageArray] = useState([]);
    const [periodNo, setPeriodNo] = useState("");
    const [gemNum, setGemNum] = useState(23);
    const [randomArray, setRandomArray] = useState([]);
    const {t} = useTranslation();
    const [resultText, setResultText] = useState("");
    // to indicate which diamond is clicked
    const [isArrayClick, setIsArrayClick] = useState([]);

    useEffect(() => {
        setResultText("");
    }, [betAmount]);

    useEffect(() => {
        console.log("--- useEffect -- []");

        let timeout;
        socket.extraHeaders = {gametoken: store.getState().memberInfo.token};

        socket.on('connect', (e) => {
            console.log("socket on connect event listener");
            // console.log("web socket id : " + socket.id);
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            console.log("socket on disconnect event listener");
            setIsConnected(false);
        });

        socket.on('error message', (res) => {
            console.log("SOCKET ERROR MSG : " + JSON.stringify(res));
            if (res.errMessage === "invalid_token_id") {
                window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
            } else if (res.errMessage.startsWith("facade_Max_balance")) {
                setResultText(t('facade_Max_balance') + Number(maxBetAmount).toFixed(2));
                // setIsLock(false);
            } else if (res.errMessage.startsWith("facade_Min_balance")) {
                setResultText(t('facade_Min_balance') + Number(minBetAmount).toFixed(2));
                // setIsLock(false);
            }else{
                setResultText(t(res.errMessage));
            }
        });

        socket.on('connect_error', (e) => {
            console.log("socket on connect error event listener");
            console.log("web socket connect error : " + e);
            setIsConnected(false);
        });

        socket.io.on("error", (error) => {
            console.log("socket error event listener");
            console.log("web socket error : " + error);
        });

        socket.on('card result', (data) => {
            console.log("socket on card result event listener");
            // console.log("SOCKET card result data : " + JSON.stringify(data));
            handleCardResult(data);
        });

        socket.on('game result', (data) => {
            console.log("socket on game result event listener");
            handleGameResult(data);
        });

        socket.on('mine position', (res) => {
            console.log("socket on mine position event listener");
            handleMinePosition(res);
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('error message');
            socket.off('connect_error');
            socket.off('error');
            socket.off('mine position');
            socket.off('card result');
            socket.off('game result');
            clearTimeout(timeout);
        };

    }, [minBetAmount, maxBetAmount, betAmount, isConnected]);

    useEffect(() => {
        console.log("--- useEffect -- gameStartedData");

        if (gameStartedData?.periodNo !== undefined) {
            setPeriodNo(gameStartedData.periodNo)
        }

        if (gameStartedData?.gemNum !== undefined) {
            setGemNum(gameStartedData.gemNum)
        }

        if (isGameStarted && isCardClicked) {

            if (gameStartedData.isBetting === true && gameStartedData.canCashOut === true) {
                let tempCenterImageArray = centerImageArray;
                // tempIsArrayClick is use to indicate user click which diamond and set into IsArrayClick
                let tempIsArrayClick = isArrayClick;
                let selectedIndex = gameStartedData.posIndex;
                tempCenterImageArray[selectedIndex] = randomdDiamondFunction();
                tempIsArrayClick[selectedIndex] = 1
                setCenterImageArray(prevState => [...prevState, tempCenterImageArray[selectedIndex]]);
                setIsArrayClick(prevState => [...prevState, tempIsArrayClick[selectedIndex]]);
            } else {
                setRandomArray(gameStartedData.randomArray);
                handleGameOverResult();
                handleShow();
            }
        }

    }, [gameStartedData]);

    const randomdDiamondFunction = () => {
        let randomIndex =  Math.floor(Math.random() * randomDiamond.length);
        return randomDiamond[randomIndex];
    }

    useEffect(() => {
        console.log("--- useEffect --- randomArray");

        if (isGameStarted === true && randomArray.length === 25) {

            let resultArray = randomArray;
            let tempCenterImageArray = centerImageArray;
            // tempIsArrayClick is use to indicate user click which diamond and set into IsArrayClick
            let tempIsArrayClick = isArrayClick;
            tempIsArrayClick[gameStartedData.posIndex] = 1;
            for (let x = 0; x < resultArray.length; x++) {
                if (tempCenterImageArray[x] === "box") {
                    if (resultArray[x] === 0) {
                        tempCenterImageArray[x] = randomdDiamondFunction();
                    } else {
                        tempCenterImageArray[x] = "skull";
                        
                    }
                }
            }
            setCenterImageArray(prevState => [...tempCenterImageArray]);
            setIsArrayClick(prevState => [...tempIsArrayClick]);
            setIsGameStarted(false);
            setIsCardClicked(false);
            if(tempCenterImageArray[gameStartedData.posIndex] === "skull"){
                document.getElementById(`boom_${gameStartedData.posIndex+1}`).style.display = 'block';
                setTimeout(() => {
                    document.getElementById(`boom_${gameStartedData.posIndex+1}`).style.display = 'none';
                }, 2000);
            }
        }

    }, [randomArray]);

    useEffect(() => {
        console.log("--- useEffect --- isGameStarted,isModalShow");
        if (isGameStarted === false && isModalShow === false) {
            handleResetGame();
        }
        getMemberBalance();

    }, [isGameStarted, isModalShow]);

    const handleCashoutButton = () => {
        console.log("--- handleCashoutButton ---");
        let memberInfo = store.getState().memberInfo;
        let token = memberInfo.token;

        let jsonData = {
            token: token,
            periodNo: periodNo,
            pressedCashOut: true
        };

        socket.emit("cash out", jsonData);
    }

    const handleGameResult = (res) => {
        console.log("--- handleGameResult ---");
        setRandomArray(res.randomArray);
        setModalHeader(Number(res.totalProfitCross).toFixed(2) + 'X');
        setModalBody(Number(res.totalProfitAmount).toFixed(2));
        let betAmount = Number(res.totalProfitAmount / res.totalProfitCross).toFixed(2);
        let netProfit = Number(res.totalProfitAmount - betAmount).toFixed(2);
        let tempModalFooter = t('mine_earned');
        setModalFooter(tempModalFooter);
        handleShow();
    }

    const handlePlayButton = () => {
        console.log("--- handlePlayButton ---");
        setResultText('');
        if (isConnected === true) {

            if (betAmount <= balanceMoney) {
                let memberInfo = store.getState().memberInfo;
                let token = memberInfo.token;
                let loginName = memberInfo.MemberInfo.loginName;

                let jsonData = {
                    username: loginName,
                    betAmount: betAmount,
                    mineNum: mineNum,
                    token: token,
                    amount: balanceMoney,
                    clientSeed: clientSeed,
                    nonce: nonce
                };

                socket.emit("bet info", jsonData);
            } else {
                setResultText(t('mine_not_enough_balance'));
            }
        } else {
            setResultText(t('mine_cannot_find_server'));
        }
    }

    const handleMinePosition = (res) => {
        console.log("--- handleMinePosition ---");
        setGameStartedData(res);
        setIsGameStarted(true);
    }

    const minBet = () => {
        setBetAmount(Number(minBetAmount).toFixed(2));
    };

    const maxBet = () => {
        if (balanceMoney <= maxBetAmount) {
            setBetAmount(balanceMoney.toFixed(2));
        } else {
            setBetAmount(Number(maxBetAmount).toFixed(2));
        }
    };

    const doubleBet = () => {
        let calculateAmount = betAmount * 2;
        if (calculateAmount <= maxBetAmount) {
            if (calculateAmount > balanceMoney) {
                calculateAmount = balanceMoney;
            }
        } else {
            calculateAmount = Number(maxBetAmount).toFixed(2);
        }
        setBetAmount(Number(calculateAmount).toFixed(2));
    };

    const halfBet = () => {
        let calculateAmount = betAmount / 2;

        if (calculateAmount < minBetAmount) {
            calculateAmount = Number(minBetAmount).toFixed(2);
        }

        setBetAmount(Number(calculateAmount).toFixed(2));
    };

    const handleCardClick = (index) => {
        console.log("--- handleCardClick ---");

        if (isGameStarted) {

            setIsCardClicked(true);

            // console.log("--- image: " + centerImageArray[index]);

            if ("box" === centerImageArray[index]) {

                let memberInfo = store.getState().memberInfo;
                let token = memberInfo.token;

                let jsonData = {
                    token: token,
                    posIndex: index,
                    periodNo: periodNo
                };

                socket.emit("card click", jsonData);
            }
        }
    };

    const handleCardResult = (res) => {
        console.log("--- handleCardResult ---");
        setGameStartedData(res);
        // console.log("--- handleGameResult data --- : " + JSON.stringify(gameStartedData));
    };

    const handleGameOverResult = () => {
        setModalHeader("0.0X");
        setModalBody("0.00");
        setModalFooter(t('mine_lose'));
    };

    const handleResetGame = () => {
        if(gameStartedData.posIndex) {
            document.getElementById(`boom_${gameStartedData.posIndex+1}`).style.display = 'none';
        }
        let tempAry = [];

        for (let x = 0; x < 25; x++) {
            tempAry.push(x);
        }

        setTempStarArray(tempAry);
        setCenterImageArray(prevState => [...defaultImageArray]);
        setIsArrayClick(prevState => [...defaultIsArrayClick]);
    };

    const handleClose = () => setIsModalShow(false);
    const handleShow = () => setIsModalShow(true);

    useEffect(() => {
        getQuota("mine", updateQuotaCallback);
    }, []);

    const updateQuotaCallback = (res) => {
        if(res !== '' && res !== undefined) {
            if(res.minBetAmount !== null) {
                setBetAmount(Number(res.minBetAmount).toFixed(2));
                setMinBetAmount(Number(res.minBetAmount).toFixed(2));
            }
            if(res.maxBetAmount !== null) setMaxBetAmount(Number(res.maxBetAmount).toFixed(2));
        } else {
            setBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMinBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMaxBetAmount(Number(betAmtConstants.maxAmt).toFixed(2));
        }
    };

    const handleBetAmt = (event) => {
        const regex = /^(\d*\.{0,1}\d{0,2}$)/;
        if(regex.test(event.target.value)) {
            setBetAmount(event.target.value);
        }
    };

    return (
        <>
            <AppContainer id="gameMine" className="game-bg-web" style={{ backgroundImage:`url(${isMobile?h5_bg:web_bg})`, backgroundSize:'cover'}}>
                <InnerContainer className="innerContainer">
                    <div className="react-game-mine" onClick={()=>isGameStarted === false && isModalShow === true && setIsModalShow(false)}>
                        <Container fluid>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <GameMoney gameName={"mine"}/>
                            <Row>
                                <Col className="center-stars-panel">
                                    <Container fluid className="center-stars-panel-bg">
                                        <Row>
                                            <Col>
                                                {tempStarArray.filter((filterItem, filterIndex) => (filterIndex + 1) <= 5).map((item, index) => {
                                                return (
                                                        <div className="position-relative box-min-width">
                                                            <img className={`position-relative ${centerImageArray[item] !== 'box' && isArrayClick[item] === 1 ? 'breathing-card-animation':''}`} key={item} alt={item} onClick={() => handleCardClick(item)}
                                                                src={require(`../assets/images/mine/mine_center_${centerImageArray[item]}_btn.png`)}/>
                                                            {centerImageArray[item] !== 'box' && centerImageArray[item] !== 'skull' ? 
                                                                <img className="position-absolute backlight-effect" src={require(`../assets/images/mine/box_backlight.gif`)} />
                                                            : ""}
                                                            {/* centerImageArray[item] === 'skull' && isArrayClick[item] === 1 ? */}
                                                                <img id={`boom_${index+1}`} width="50" className="position-absolute boom-effect" src={require(`../assets/images/mine/boom_effect.gif`)}/> 
                                                        </div>
                                                    )   
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {tempStarArray.filter((filterItem, filterIndex) => (filterIndex + 1) > 5 && (filterIndex + 1) <= 10).map((item, index) => {
                                                    return (
                                                        <div className="position-relative box-min-width">
                                                            <img className={`position-relative ${centerImageArray[item] !== 'box' && isArrayClick[item] === 1 && 'breathing-card-animation'}`} key={item} alt={item} onClick={() => handleCardClick(item)}
                                                                src={require(`../assets/images/mine/mine_center_${centerImageArray[item]}_btn.png`)}/>
                                                            {centerImageArray[item] !== 'box' && centerImageArray[item] !== 'skull' ? 
                                                                <img className="position-absolute backlight-effect" src={require(`../assets/images/mine/box_backlight.gif`)} />
                                                            : ""} 
                                                            {/* centerImageArray[item] === 'skull' && isArrayClick[item] === 1 ? */}
                                                                <img id={`boom_${index+6}`} width="50" className="position-absolute boom-effect" src={require(`../assets/images/mine/boom_effect.gif`)} />
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {tempStarArray.filter((filterItem, filterIndex) => (filterIndex + 1) > 10 && (filterIndex + 1) <= 15).map((item, index) => {
                                                    return (
                                                        <div className="position-relative box-min-width">
                                                            <img className={`position-relative ${centerImageArray[item] !== 'box' && isArrayClick[item] === 1 && 'breathing-card-animation'}`} key={item} alt={item} onClick={() => handleCardClick(item)}
                                                                src={require(`../assets/images/mine/mine_center_${centerImageArray[item]}_btn.png`)}/>
                                                            {centerImageArray[item] !== 'box' && centerImageArray[item] !== 'skull' ? 
                                                                <img className="position-absolute backlight-effect" src={require(`../assets/images/mine/box_backlight.gif`)} />
                                                            : ""} 
                                                            {/* centerImageArray[item] === 'skull' && isArrayClick[item] === 1 ? */}
                                                                <img id={`boom_${index+11}`} width="50" className="position-absolute boom-effect" src={require(`../assets/images/mine/boom_effect.gif`)} />
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {tempStarArray.filter((filterItem, filterIndex) => (filterIndex + 1) > 15 && (filterIndex + 1) <= 20).map((item, index) => {
                                                    return (
                                                        <div className="position-relative box-min-width">
                                                            <img className={`position-relative ${centerImageArray[item] !== 'box' && isArrayClick[item] === 1 && 'breathing-card-animation'}`} key={item} alt={item} onClick={() => handleCardClick(item)}
                                                                src={require(`../assets/images/mine/mine_center_${centerImageArray[item]}_btn.png`)}/>
                                                            {centerImageArray[item] !== 'box' && centerImageArray[item] !== 'skull' ? 
                                                                <img className="position-absolute backlight-effect" src={require(`../assets/images/mine/box_backlight.gif`)} />
                                                            : ""} 
                                                            {/* centerImageArray[item] === 'skull' && isArrayClick[item] === 1 ? */}
                                                                <img id={`boom_${index+16}`} width="50" className="position-absolute boom-effect" src={require(`../assets/images/mine/boom_effect.gif`)} />
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {tempStarArray.filter((filterItem, filterIndex) => (filterIndex + 1) > 20 && (filterIndex + 1) <= 25).map((item, index) => {
                                                    return (
                                                        <div className="position-relative box-min-width">
                                                            <img className={`position-relative ${centerImageArray[item] !== 'box' && isArrayClick[item] === 1 && 'breathing-card-animation'}`} key={item} alt={item} onClick={() => handleCardClick(item)}
                                                                src={require(`../assets/images/mine/mine_center_${centerImageArray[item]}_btn.png`)}/>
                                                            {centerImageArray[item] !== 'box' && centerImageArray[item] !== 'skull' ? 
                                                                <img className="position-absolute backlight-effect" src={require(`../assets/images/mine/box_backlight.gif`)} />
                                                            : ""} 
                                                            {/* centerImageArray[item] === 'skull' && isArrayClick[item] === 1 ? */}
                                                                <img id={`boom_${index+21}`} width="50" className="position-absolute boom-effect" src={require(`../assets/images/mine/boom_effect.gif`)} />
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                            
                            {isModalShow === true ? 
                                <Row>
                                    <Col>
                                        <div style={{ position:'relative', height: '150px' }}>
                                            <img src={require(`../assets/images/mine/box_won.png`)}/>
                                            <div className="win-board-word" style={{ top:'30%', left:'50%', transform:'translate(-50%,-50%)' }}>{modalFooter} &nbsp; {modalHeader}</div    >
                                            <div className="win-board-word total-profit-box" style={{ bottom:'10%', left:'50%', transform:'translate(-50%,-50%)' }}>
                                                <img style={{ marginRight:'5px' }} src={require(`../assets/images/mine/mine_coin.png`)} width='20'/>
                                                {modalBody}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            : 
                                <Row>
                                    <Col>&nbsp;{resultText}</Col>
                                </Row>
                            }
                            
                            <Row>
                                <Col className="mine-bottom-panel">
                                    {/* bottom panel */}

                                    {isGameStarted === false ?
                                        <>
                                            <Container>
                                                {isModalShow === false &&  
                                                <Row className="mine-select-bg" style={{ marginBottom:'9%' }}>
                                                    <Col style={{ margin:'0 0 0 -40px'}}>
                                                        <img
                                                            onClick={() => setMineNum(prevState => (prevState - 1) >= 2 ? prevState - 1 : 2)}
                                                            alt={'mine_minus_image'}
                                                            src={require(`../assets/images/mine/mine_minus_image.png`)}
                                                            width='60'
                                                            className="btn-click-effect"/>
                                                    </Col>
                                                    <Col style={{ flexDirection:'column', paddingBottom:'15px',margin:'0 -40px'}}>
                                                        <div style={{ marginBottom:'-15px' }}>
                                                            <img alt={'skull_image'} style={{width: '60px', height:'60px' }} src={require(`../assets/images/mine/mine_skull_image.png`)}/>
                                                            <span style={{ fontSize:'20px', padding:'0px 15px 0px 0px' }}>{mineNum}</span>
                                                        </div>
                                                        <hr style={{ border:'1px solid #FFB24D', width:'100%', marginBottom:'5px'}}/>
                                                        <div style={{textAlign:'center'}}>
                                                            <span className="bottom-control-label">{t('mine_minenum')}</span>
                                                        </div>
                                                    </Col>
                                                    <Col style={{ margin:'0 -40px 0 0'}}>
                                                        <img
                                                            onClick={() => setMineNum(prevState => (prevState + 1) >= 24 ? 24 : prevState + 1)}
                                                            alt={'mine_plus_image'}
                                                            src={require(`../assets/images/mine/mine_plus_image.png`)}
                                                            width='60'
                                                            className="btn-click-effect"/>
                                                    </Col>
                                                </Row>
                                                }
                                                <Row>
                                                    <Col>
                                                        <Button onClick={minBet} disableRipple className="btn-click-effect bottom-panel-btn">
                                                            <img src={require(`../assets/images/mine/mine_min_image.png`)} width='60' style={{ marginLeft:'-10px '}} />
                                                        </Button>
                                                        <Button onClick={halfBet} disableRipple className="btn-click-effect bottom-panel-btn">
                                                            <img src={require(`../assets/images/mine/mine_minus_image.png`)} width='60' />
                                                        </Button>
                                                        <TextField style={{border:'none'}} value={betAmount} onChange={(event) => handleBetAmt(event)} className="mine-bet-input-background" />
                                                        <Button onClick={doubleBet} disableRipple className="btn-click-effect bottom-panel-btn">
                                                            <img src={require(`../assets/images/mine/mine_plus_image.png`)} width='60' />
                                                        </Button>
                                                        <Button onClick={maxBet} disableRipple className="btn-click-effect bottom-panel-btn">
                                                            <img src={require(`../assets/images/mine/mine_max_image.png`)} width='60' style={{ marginRight:'-10px' }} />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {!isConnected === true ?
                                                            <ReactLoading type={'spin'} className={'loadingSpinner'}
                                                                    style={{ width: '49px', height: '49px', 
                                                                            marginTop: isMobile ? '70%' : '0%' }}/>
                                                        :   null
                                                        }
                                                        <Button onClick={handlePlayButton} className="btn-click-effect" disableRipple disabled={!isConnected}>
                                                            <img src={require(`../assets/images/mine/btn_play.png`)} />
                                                            <span style={{ position:'absolute', fontSize:'25px' }}>{t('mine_play')}</span>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </>
                                        :
                                        <>
                                            <Container fluid className="bottom-control-panel">
                                                <Row>
                                                    <Col>
                                                        <Container className="bottom-table-with-border">
                                                            <Row>
                                                                <Col>
                                                                    <img style={{ height:'60px', width:'50px' }} alt={'mine_skull_2'} src={require(`../assets/images/mine/mine_skull_image.png`)}/>
                                                                </Col>
                                                                <Col style={{ flexDirection:'column' }}>
                                                                    <span>{mineNum}</span>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                    <Col>
                                                        <Container className="bottom-table-with-border">
                                                            <Row>
                                                                <Col>
                                                                    <img style={{ height:'60px', width:'50px' }} alt={'mine_skull_2'} src={require(`../assets/images/mine/mine_gems.png`)}/>
                                                                </Col>
                                                                <Col style={{ flexDirection:'column' }}>
                                                                    <span>{gemNum}</span>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>{t('mine_next_tile_profit')} ({Number(gameStartedData?.nextTileProfitCross).toFixed(2)}X)</Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Container fluid className="bottom-table-with-border">
                                                            <Row className="">
                                                                {/* <Col xs={2}>&nbsp;</Col> */}
                                                                <Col>{Number(gameStartedData?.nextTileProfitAmount).toFixed(2)}</Col>
                                                                {/* <Col className="bottom-table-game-started-coin" xs={2}>
                                                                    <img alt={'mine_bottom_coin_image_1'} src={require(`../assets/images/mine/mine_coin.png`)}/>
                                                                </Col> */}
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>{t('mine_total_profit')} ({Number(gameStartedData?.totalProfitCross).toFixed(2)}X)</Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Container fluid className="bottom-table-with-border">
                                                            <Row className="">
                                                                {/* <Col xs={2}>&nbsp;</Col> */}
                                                                <Col>{Number(gameStartedData?.totalProfitAmount).toFixed(2)}</Col>
                                                                {/* <Col className="bottom-table-game-started-coin" xs={2}>
                                                                    <img alt={'mine_bottom_coin_image_2'} src={require(`../assets/images/mine/mine_coin.png`)}/>
                                                                </Col> */}
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {isCardClicked === true ?
                                                            <Button onClick={handleCashoutButton} className="btn-click-effect" disableRipple>
                                                                <img src={require(`../assets/images/mine/btn_cash out.png`)} />
                                                                <span style={{ position:'absolute', fontSize:'20px' }}>{t('mine_cashout')}</span>
                                                            </Button>
                                                            :
                                                            <>&nbsp;</>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>&nbsp;</Col>
                                                </Row>
                                            </Container>
                                        </>
                                    }

                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                        </Container>

                    </div>

                </InnerContainer>

            </AppContainer>
            {/* <Modal show={isModalShow} onHide={handleClose}
                   className="mine-cashout-modal"
                   style={{display: 'block', position: 'absolute'}}
            >

                <Modal.Body> */}
                    {/* <p>Modal body text goes here.</p> */}
                    {/* <Container fluid>
                        <Row>
                            <Col>{modalHeader}</Col>
                        </Row>
                        <Row className="mine-modal-body">
                            <Col xs={1}>
                                <span>&nbsp;</span>
                            </Col>
                            <Col className="mine-modal-body-textfield">
                                <span>{modalBody}</span>
                            </Col>
                            <Col xs={1}>
                                <img alt={'mine_modal_coin'} src={require(`../assets/images/mine/mine_coin.png`)}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>{modalFooter}</Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal> */}
        </>
    )
}

export default withTranslation('common')(Mine);