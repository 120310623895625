import React, {useCallback, useEffect, useRef, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {motion} from "framer-motion";
import styled from "styled-components";
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';

import {store} from "../index";

import GameMoney from "../containers/GameMoney";
import GameButtons from "../containers/spheres/GameButton";
import GameResultHrz from "../containers/spheres/GameResultHrz"
import {getMemberBalance} from "../services/HomeServices";
import { playGame } from "../services/GameSpheresServices";
import '../styles/Spheres/Spheres.scss'
import gameConstants, {betAmtConstants} from "../utils/utilConstants";
import {getQuota} from "../services/QuotaServices";
import background from '../assets/images/spheres/background.png';
import backgroundWeb from '../assets/images/spheres/backgroundweb.png';
import logo from '../assets/images/spheres/logo.png';
import GameMessage from "../containers/spheres/GameMessage";
import {isMobile} from "react-device-detect";

const GameMainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: scroll;
  background-image: url(${isMobile?background:backgroundWeb});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: 100% 100%;
`;

const InnerContainer = styled.div`
    backgroundColor: #111923;
    display: flex;
    justify-content: center;
    color: white;
`;

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

function GameSpheres() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [start, setStart] = useState(false);
    const [isLock, setIsLock] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);
    const [betAmount, setBetAmount] = useState(Number(10.00).toFixed(2));
    const [minBetAmount, setMinBetAmount] = useState(Number(betAmtConstants.minAmt).toFixed(2));
    const [maxBetAmount, setMaxBetAmount] = useState(Number(betAmtConstants.maxAmt).toFixed(2));
    const balanceMoney = useSelector(state => store.getState().memberInfo.MemberInfo.balanceMoney, shallowEqual);
    const [autoBet, setAutoBet] = useState(false);

    const handleOnHalfScore = () => {
        if(!autoBet){
            if(start === false) {
                let calculatedAmt = Math.round((betAmount / 2) * 100) / 100;
                if (calculatedAmt > minBetAmount) {
                    setBetAmount(calculatedAmt.toFixed(2));
                } else {
                    setBetAmount(Number(minBetAmount).toFixed(2));
                    dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_Min_balance"), messageType: 1 });
                }
            }
        }
    };

    const handleOnTimeScore = () => {
        if(!autoBet){
            if(start === false) {
                let calculatedAmt = Math.round((betAmount * 2) * 100) / 100;
                if(calculatedAmt <= Number(maxBetAmount)) {
                    let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
                    if (calculatedAmt <= Number(latestBalance)) {
                        setBetAmount(calculatedAmt.toFixed(2));
                    } else {
                        setBetAmount(Number(latestBalance).toFixed(2));
                        dispatch({type: gameConstants.SHOW_MESSAGE, message: t("facade_Max_balance"), messageType: 1});
                    }
                } else {
                    setBetAmount(Number(maxBetAmount).toFixed(2));
                    dispatch({type: gameConstants.SHOW_MESSAGE, message: t("facade_Max_balance"), messageType: 1});
                }
            }
        }
    };

    const handleOnMinScore = () => {
        if(start === false) {
            setBetAmount(Number(minBetAmount).toFixed(2));
        }
    };

    const handleOnMaxScore = () => {
        if(start === false) {
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            if(Number(latestBalance) <= Number(maxBetAmount)) {
                setBetAmount(Number(latestBalance).toFixed(2));
            } else {
                setBetAmount(Number(maxBetAmount).toFixed(2));
            }
        }
    };
    const handleOnClick = () => {
         if(isLock === false) {
            hideMsg();
            dispatch({type: gameConstants.RUN_GAME});
            setStart(true);
            setIsLock(true);
            setIsDisplay(false);
            // if(Number(betAmount) < Number(minBetAmount)) {
            //     setAutoBet(false);
            //     dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_Min_balance") + Number(minBetAmount).toFixed(2), messageType: 1 });
            // } else {
            //     let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            //     if(Number(latestBalance) < Number(betAmount)) {
            //         setAutoBet(false);
            //         dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_insufficient_balance"), messageType: 1 });
            //     } else {
            //         dispatch({type: gameConstants.RUN_GAME});
            //         setStart(true);
            //         setIsLock(true);
            //         setIsDisplay(false);
            //         //getMemberBalance();
            //     }
            // }
        }
    };

    const handleAutoBet = () => {
        var auto = !autoBet;
        setAutoBet(auto);
    };

    useEffect(() => {
        if(autoBet){
            if(!isLock){
                handleOnClick();
            }
        }
    }, [autoBet]);

    const handleEndGame = () => {
        finalEndGame();
        setIsDisplay(true);
        async function finalEndGame() {
            await delay(1000);
            setStart(false);
            setIsLock(false);
        }
    };

    const onUpdateBetAmount = (amt) => {
        if(!autoBet){
            if(isLock === false) {
                const regex = /^(\d*\.{0,1}\d{0,2}$)/;
                if(regex.test(amt)) {
                    setBetAmount(amt);
                }
            }
        }
    };

    const updateCallback = (res) => {
        if(!res) {
            setAutoBet(false);
            handleEndGame();
        }else{
            handleEndGame();
        }
    };

    useEffect(() => {
        if(isLock === true) {
            fetchGame().then(()=>{
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                getMemberBalance();
            });
        }
        var interval =null;
        interval = setInterval(() => {
            if(autoBet && !isLock){
                handleOnClick();
                clearInterval(interval);
            }else{
                clearInterval(interval);
            }
        }, 5000);
        return () => clearInterval(interval);
        
    }, [isLock]);

    useEffect( () => {
        getQuota("spheres", updateQuotaCallback);
    }, []);

    const updateQuotaCallback = (res) => {
        if(res !== '' && res !== undefined) {
            if(res.minBetAmount !== null) {
                setBetAmount(Number(res.minBetAmount).toFixed(2));
                setMinBetAmount(Number(res.minBetAmount).toFixed(2));
            }
            if(res.maxBetAmount !== null) setMaxBetAmount(Number(res.maxBetAmount).toFixed(2));
        } else {
            setBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMinBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMaxBetAmount(Number(betAmtConstants.maxAmt).toFixed(2));
        }
    };

    const fetchGame = useCallback(async () =>{
        await playGame(isLock, betAmount, updateCallback);
    },[isLock, betAmount]);

    const hideMsg = () => {
        dispatch({ type: gameConstants.HIDE_MESSAGE });
    };

    return (
        <GameMainContainer>
              {/* <div class="star"></div> */}
              <div className="meteor-1"></div>
                    <div className="meteor-2"></div>
                    <div className="meteor-3"></div>
                    <div className="meteor-4"></div>
                    <div className="meteor-5"></div>
                    <div className="meteor-6"></div>
                    <div className="meteor-7"></div>
                    <div className="meteor-8"></div>
                    <div className="meteor-9"></div>
                    <div className="meteor-10"></div>
                    <div className="meteor-11"></div>
                    <div className="meteor-12"></div>
                    <div className="meteor-13"></div>
                    <div className="meteor-14"></div>
                    <div className="meteor-15"></div>
            <InnerContainer className={"content-container-inner-spheres"}>
                <Container fluid className={"content-container-spheres"}>
                    <GameMoney gameName={"spheres"} style={{paddingTop:10}}/>
                    <Row>
                        <Col className="row-col-content-spheres"><GameResultHrz onEndGame={handleEndGame} /></Col>
                    </Row>
                    <Row>
                        <Col className="row-col-buttons-spheres">
                            <motion.div
                                style={{
                                    // width: 350,
                                    // height: 350,
                                    display: "flex",
                                    placeItems: "center",
                                    placeContent: "center",
                                    borderRadius: 30,
                                    // backgroundColor: "rgba(255, 255, 255, 0.05)",
                                    perspective: 400
                                }}
                            >
                                <GameButtons onMinScore={handleOnMinScore} onMaxScore={handleOnMaxScore}
                                    onHalfScore={handleOnHalfScore} onTimeScore={handleOnTimeScore}
                                    onStart={handleOnClick} betAmount={betAmount} onUpdateBetAmount={onUpdateBetAmount}
                                    isLock={isLock} isDisplay={isDisplay} autoBet={autoBet} onAutoBet={handleAutoBet}
                                    onEndGame={handleEndGame} hideMsg={hideMsg}
                                />
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </InnerContainer>
        </GameMainContainer>
    );
};

export default withTranslation('common')(GameSpheres);
