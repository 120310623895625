import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {useTranslation, withTranslation} from 'react-i18next';
import { RiFileSearchLine } from "react-icons/ri";
import { store } from "../index";
import {shallowEqual, useSelector} from "react-redux";
import helpers from "../utils/helpers";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function GameRules(props) {
    const {t} = useTranslation();
    const userLang = useSelector(state => store.getState().memberInfo.MemberInfo.language, shallowEqual);
    const [showRules, setShowRules] = useState(false);
    const [gameRules, setGameRules] = useState(t('game_rules_not_found'));
    const [gameTitle, setGameTitle] = useState(t('game_rules_not_found'));

    const handleClose = () => setShowRules(false);
    const handleShow = () => setShowRules(true);

    useEffect(()=>{
        GetGameRules(props.gameName).then(r => {
            setGameRules((r[0].content[0] !== null && r[0].content[0] !== undefined) ? r[0].content[0] : t("game_rules_not_found"));
            setGameTitle((r[0].title !== null && r[0].title !== undefined) ? r[0].title : t("game_rules_not_found"))
        });
    },[props.gameName]);

    const GetGameRules = async (gameName) => {
        let finalLang = (userLang !== undefined && userLang !== null) ? userLang : "zh";
        let rules;
        switch (finalLang) {
            case 'en':
                rules = (await import('../gameData/gameRules_en.json'));
                break;
            case 'vi':
                rules = (await import('../gameData/gameRules_vi.json'));
                break;
            case 'th':
                rules = (await import('../gameData/gameRules_th.json'));
                break;
            case 'in':
                rules = (await import('../gameData/gameRules_in.json'));
                break;
            case 'id':
                rules = (await import('../gameData/gameRules_id.json'));
                break;
            case 'pt':
                rules = (await import('../gameData/gameRules_pt.json'));
                break;
            case 'es':
                rules = (await import('../gameData/gameRules_es.json'));
                break;
            case 'fr':
                rules = (await import('../gameData/gameRules_fr.json'));
                break;
            case 'ru':
                rules = (await import('../gameData/gameRules_ru.json'));
                break;
            default:
                rules = (await import('../gameData/gameRules_zh.json'));
        }
        if(gameName == 'diamond') gameName = 'fruitty';
        return rules[0].marketType.filter(item => item.title === gameName.toLowerCase());
    };

    return (
        <>
            <Button variant="link" onClick={handleShow} style={props.gameName === 'mine' ? {paddingRight:'0px'} : {}}>
                {gameTitle == 'fruitty'? 
                    <img src={require(`../assets/images/diamond/rules.png`)} width={30}/>
                :
                gameTitle == 'triple'? 
                    <img src={require(`../assets/images/triple/rules.png`)} width={35}/>
                :
                gameTitle == 'dices'? 
                    <img src={require(`../assets/images/dice/btn_guide.png`)} width={30}/>
                :
                gameTitle == 'spheres'? 
                    <img src={require(`../assets/images/spheres/rules.png`)} width={35} height={35}/>
                :
                gameTitle == 'mine' ? 
                    <img src={require(`../assets/images/mine/mine_btn_guide.png`)} width={40}/>
                :
                gameTitle == '7-11-21' ? 
                    <img src={require(`../assets/images/seven1121/btn_guide.png`)} width={30}/>
                :
                <RiFileSearchLine style={{color: "white", fontSize: "1.5em"}}/>
                }
            </Button>

            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {helpers.capitalizeOnlyFirst(gameTitle)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {gameRules.rule}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={props.onHide}>{t('label.close')}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRules} onHide={handleClose}
                   {...props}
                   size="sm"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {helpers.capitalizeOnlyFirst(gameTitle)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {gameRules.rule}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>{t('label.close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default withTranslation('common')(GameRules);
