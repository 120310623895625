import {store} from '../index';

export function getQuota(gameId, cb) {
    const memberInfo = store.getState().memberInfo;
    const data = new URLSearchParams();
    data.set('gameId', gameId);
    fetch(global.config.domain.member + "/api/loadQuota", {
        method: "Post",
        headers: new Headers({
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'gametoken': memberInfo.token,
        }),
        body: data,
        credentials: 'include'
    })
        .then((res) => res.json())
        .then((res) => {
            cb(res);
        }).catch((error) => {
            cb("");
    })
};