import React, {useState} from "react";
import {useTranslation, withTranslation} from 'react-i18next';
import RangeSlider from 'react-bootstrap-range-slider';
//import Slider from "../../components/dice/Slider";
import Slider from '@mui/material/Slider';
import penguin_closeeye from '../../assets/images/dice/penguin_closeeye.png'
import penguin_openeye from '../../assets/images/dice/penguin_openeye.png'

function GameRangeSlider(props) {
    const {t} = useTranslation();
    const [value, setValue] = useState(50);
    const [sliderValue, setSliderValue] = useState(50);
    const [rotation, setRotation] = useState(50);

    const CustomSliderStyles = {
        '& .MuiSlider-thumb': {
            border: "none",
            height: "50px",
            width: "50px",
            backgroundColor: "transparent",
            backgroundImage: `url(${penguin_closeeye})`,
            backgroundPosition: "0 0",
            backgroundSize: "cover",
            cursor: "pointer",
            boxShadow: 'none',
        },
        '& .MuiSlider-thumb:active': {
            backgroundImage: `url(${penguin_openeye})`,
            boxShadow: 'none'
        },
        '& .MuiSlider-thumb:hover': {
            backgroundImage: `url(${penguin_openeye})`,
            boxShadow: 'none'
        },
        '& .MuiSlider-thumb:after': {
            boxShadow: 'none'
        },
        '& .MuiSlider-thumb:before': {
            boxShadow: 'none'
        },
        '& .MuiSlider-track': {
            color: "#1976d2",
            height: "10px"
        },
        '& .MuiSlider-rail': {
            color: '#1976d2',
            height: "10px"
        },
        '& .MuiSlider-active': {
            color: "#1976d2"
        },
        '& .MuiSlider-valueLabel': {
            background:"transparent",
            color: "#1976d2",
            fontSize: "16px",
            fontWeight : "bold",
            marginTop: '20px'
        }, 
        '& .MuiSlider-markLabel': {
            color: "#1976d2",
            fontSize: "16px",
            fontWeight : "bold"
        },
        '.MuiSlider-root':{
            backgroundColor:'#1976d2',
            opacity: '0.38'
        },
        '& .MuiSlider-root .MuiSlider-rail': {
            backgroundColor:'#1976d2',
            opacity: '0.38',
        },
        '& .MuiSlider-root .MuiSlider-track': {
            backgroundColor:'#1976d2',
            opacity: '0.38',
        }
        
    };

    const marks = [
        {
          value: 0,
          label: '0',
        },
        {
          value: 10
        },
        {
          value: 90
        },
        {
          value: 100,
          label: '100',
        },
      ];
  
    function handleOnChange(e) {
        let slidValue = e.target.value;
        let s = document.getElementsByClassName('MuiSlider-thumb')[0];
        s.style.boxShadow = "none";
        s.style.backgroundImage = `url(${penguin_openeye})`;
        // s.style.marginTop ="-25px";
        // s.style.marginLeft ="-26px";
        //let newR = 0;
        
        //setValue(sliderValue);
        if (slidValue <= 90 && slidValue >= 10){
            
            // if(slidValue < sliderValue ){
            //     newR = rotation - ((sliderValue-slidValue)*10);
            // }else{
            //     newR = rotation + ((slidValue-sliderValue)*10);
            // }
            // if(slidValue == 50){
            //     newR = 0;
            // }
            
            // s.style.transform = 'rotate('+newR+'deg)';
            // setRotation(newR);
            setSliderValue(slidValue);
            props.onSlide(slidValue);
        }
        // else{
            if(slidValue >= 90){
        //         newR = rotation - ((90-sliderValue)*10);
        //         s.style.transform = 'rotate('+newR+'deg)';
        //         setRotation(newR);
                setSliderValue(90);
                props.onSlide(90);
            }else if(slidValue <= 10){
        //         newR = rotation - ((sliderValue-10)*10);
        //         s.style.transform = 'rotate('+newR+'deg)';
        //         setRotation(newR);
                setSliderValue(10);
                props.onSlide(10);
            }
        // }
    };

    function handleOnChangeCommitted(){
        let s = document.getElementsByClassName('MuiSlider-thumb')[0];
        s.style.boxShadow = "none";
        s.style.backgroundImage = `url(${penguin_closeeye})`;
    }

    function onMouseEnter(){
        let s = document.getElementsByClassName('MuiSlider-thumb')[0];
        s.style.boxShadow = "none";
        s.style.backgroundImage = `url(${penguin_openeye})`;
    }

    function onMouseLeave(){
        let s = document.getElementsByClassName('MuiSlider-thumb')[0];
        s.style.boxShadow = "none";
        s.style.backgroundImage = `url(${penguin_closeeye})`;
    }

    function onTouchStart(){
        let s = document.getElementsByClassName('MuiSlider-thumb')[0];
        s.style.boxShadow = "none";
        s.style.backgroundImage = `url(${penguin_openeye})`;
    }

    function onTouchEnd(){
        let s = document.getElementsByClassName('MuiSlider-thumb')[0];
        s.style.boxShadow = "none";
        s.style.backgroundImage = `url(${penguin_closeeye})`;
    }

    return (
        <>
            <Slider
                sx={CustomSliderStyles}
                track={props.rollOver?"inverted":"normal"}
                defaultValue={value}
                min={props.min}
                max={props.max}
                disabled={props.autoBet?props.autoBet:props.isLock}
                onChange={changeEvent => handleOnChange(changeEvent)}
                value={sliderValue}
                marks={marks}
                valueLabelDisplay="auto"
                onChangeCommitted={handleOnChangeCommitted}
                onMouseEnter ={onMouseEnter}
                onMouseLeave ={onMouseLeave}
                onTouchStart ={onTouchStart}
                onTouchEnd = {onTouchEnd}
            />
        </>
    );
}

export default withTranslation('common')(GameRangeSlider);
