import React from "react";
import {useTranslation, withTranslation} from 'react-i18next';

import Logo from "../../components/seven1121/Logo";

function GameLogo() {
  const { t } = useTranslation();

  return (
    <>
      <Logo.Container>
          <Logo.Image>
              <img src={require("../../assets/images/seven1121/logo.png")} alt={"Logo71121"}/>
          </Logo.Image>
      </Logo.Container>
    </>
  );
}

export default withTranslation('common')(GameLogo);
