module.exports = global.config = {
    domain: {
        game: "http://instant.pcai8888.com",
        member: "http://instant.pcai8888.com", 
        gameui: "https://lottoapi.pcai8888.com/openui",
        gameprod: "/game",
        memberprod: "",
        instantaward: "instant.pcai8888.com"
    },
    sample: {
        welcome: {
            en: "Welcome",
            fa: "خوش آمدید"
        }
    },
    window: {
        browser: '',
        height: 0
    },
    testEnv: false,
    minigame: {
        socket:{
            protocol: "http",
            port:{
                hashdice: 1209,
                triple: 1219,
                mine: 1102,
                luckycircle: 1211,
                crash: 1101,
                limbo: 1103,
                diamond: 1108
            },
            domain:{
                hashdice: "minihashdice.pcai8888.com",
                triple: "minitriple.pcai8888.com",
                mine: "minimine.pcai8888.com",
                luckycircle: "miniluckycircle.pcai8888.com",
                crash: "minicrash.pcai8888.com",
                limbo: "minilimbo.pcai8888.com",
                diamond: "minidiamond.pcai8888.com"
            }
        },
    }
    // other global config variables you wish
};