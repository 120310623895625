import update from "immutability-helper";
import gameConstants from "../utils/utilConstants";
import helpers from "../utils/helpers";

const gameInitial = {
    betAmt: 10.00,
    isPlayed: false,
    loading: false,
    earnAmount: 0.0,
    oddAmount: [0, 1, 2, 3, 4, 5, 6],
    showMessage: false,
    message: '',
    messageType: 0,
    userColor: [],
    robotColor: [],
    displayUserColor: [
        {id: 0, num: 0, isShow: false, color: 'default'},
        {id: 1, num: 0, isShow: false, color: 'default'},
        {id: 2, num: 0, isShow: false, color: 'default'},
        {id: 3, num: 0, isShow: false, color: 'default'},
        {id: 4, num: 0, isShow: false, color: 'default'}],
    displayRobotColor: [
        {id: 0, num: 0, isShow: false, color: 'default'},
        {id: 1, num: 0, isShow: false, color: 'default'},
        {id: 2, num: 0, isShow: false, color: 'default'},
        {id: 3, num: 0, isShow: false, color: 'default'},
        {id: 4, num: 0, isShow: false, color: 'default'}],
    reward: 0,
    showResults: false
};

const storeGameSpheres = (state = gameInitial, action) => {
    switch (action.type) {
        case gameConstants.RESET_SPHERES_RESULT: {
            return update(state, {
                showResults: { $set: false }
            });
        }

        case gameConstants.INCREASE_AMT: {
            return update(state, {
                betAmt: { $set: state.betAmt + action.payload }
            });
        }

        case gameConstants.DECREASE_AMT: {
            return update(state, {
                betAmt: { $set: state.betAmt - action.payload }
            });
        }

        case gameConstants.SHOW_MESSAGE: {
            return update(state, {
               showMessage: { $set: true },
               message: { $set: action.message },
               messageType: { $set: action.messageType }
            });
        }

        case gameConstants.HIDE_MESSAGE: {
            return update(state, {
                showMessage: { $set: false },
                message: { $set: '' },
                messageType: { $set: 0 }
            });
        }

        case gameConstants.SET_LOADING: {
            return update(state, {
                loading: { $set: true }
            });
        }

        case gameConstants.SET_AMT: {
            return update(state, {
                betAmt: { $set: action.payload }
            });
        }

        case gameConstants.UPDATE_SPHERES_DATA: {
            return update(state, {
                userColor: {
                    $set: helpers.itemColorBall(action.userColor)
                },
                robotColor: {
                    $set: helpers.itemColorBall(action.robotColor)
                },
                earnAmount: {
                    $set: action.earnAmount
                },
                reward: {
                    $set: action.reward
                },
                showResults: { $set: true },
                isPlayed: { $set: false }
            });
        }

        case gameConstants.UPDATE_SPHERES_RESULT_INDEX: {
            return update(state, {
                displayUserColor: {
                    [action.index]: {
                        id: { $set: action.uid },
                        color: { $set: action.uColor },
                        num: { $set: action.uNum}
                    }},
                displayRobotColor: {
                    [action.index]: {
                        id: { $set: action.rid },
                        color: { $set: action.rColor },
                        num: { $set: action.rNum}
                    }},
            });
        }

        case gameConstants.UPDATE_SPHERES_USER_SHOW_INDEX: {
            return update(state, {
                displayUserColor: {
                    [action.index]: {
                        isShow: { $set: true }
                    }}
            });
        }

        case gameConstants.UPDATE_SPHERES_ROBOT_SHOW_INDEX: {
            return update(state, {
                displayRobotColor: {
                    [action.index]: {
                        isShow: { $set: true }
                    }}
            });
        }

        case gameConstants.RUN_GAME: {
            return update(state, {
                isPlayed: { $set: true },
                userColor: { $set: [] },
                robotColor: { $set: [] },
                displayUserColor: { $set: [
                        {id: 0, num: 0, isShow: false, color: 'default'},
                        {id: 1, num: 0, isShow: false, color: 'default'},
                        {id: 2, num: 0, isShow: false, color: 'default'},
                        {id: 3, num: 0, isShow: false, color: 'default'},
                        {id: 4, num: 0, isShow: false, color: 'default'}] },
                displayRobotColor: { $set: [
                        {id: 0, num: 0, isShow: false, color: 'default'},
                        {id: 1, num: 0, isShow: false, color: 'default'},
                        {id: 2, num: 0, isShow: false, color: 'default'},
                        {id: 3, num: 0, isShow: false, color: 'default'},
                        {id: 4, num: 0, isShow: false, color: 'default'}] }
            });
        }

        case gameConstants.END_GAME: {
            return update(state, {
                isPlayed: { $set: false }
            });
        }
        case "GET_BALANCE":{
            return update(state, {
            });
        }
        case gameConstants.RESTART:
        default:
            return gameInitial;
    }
};

export { storeGameSpheres }