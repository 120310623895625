import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import '../styles/_limbo.scss';
import song from "../assets/audio/rocket_sound.mp3";
import io, { Manager } from 'socket.io-client';
import { store } from '../index';
import { TextField, Button } from '@mui/material';
import {getMemberBalance} from "../services/HomeServices";
import GameMoney from "../containers/GameMoney";
import {isMobile} from "react-device-detect";
import {betAmtConstants} from "../utils/utilConstants";

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    memberInfo: state.memberInfo,
  };
}; 

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

// const socket = io('http://localhost:1103/',{
//   transports: ["websocket"],
// });

let socket;
if(global.config.testEnv === true){  
  socket = io(global.config.minigame.socket.protocol + '://'+ global.config.domain.instantaward+':'+ global.config.minigame.socket.port.limbo +'/',{
    transports: ["websocket"],
  });
}else{
  socket = io(global.config.minigame.socket.protocol + '://'+ global.config.minigame.socket.domain.limbo +'/',{
    transports: ["websocket"],
  });
}

class LimboPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startGame : false,
      gameResult :[],
      gameInterval:null,
      amount:Number(10.00).toFixed(2),
      limboResult:0.00,
      limboTarget:Number(2.00).toFixed(2),
      audio: new Audio(song),
      minBetAmount:Number(betAmtConstants.minAmt).toFixed(2),
      maxBetAmount:Number(betAmtConstants.maxAmt).toFixed(2),
      autobet:false,
      islock:false,
      autoPlay:false
    };
  }

  componentDidMount(){
    socket.extraHeaders = { gametoken:store.getState().memberInfo.token };
    //console.log(socket);
    socket.on('disconnect', () => {
      console.log("socket on disconnect event listener");
    });
    
    socket.on('error message', (res) => {
      //console.log("SOCKET ERROR MSG : " + JSON.stringify(res));
      this.setState({ autobet: false });
      this.errorMsg(res.errMessage);
      if(res.errMessage === "invalid_token_id"){
        window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
      }else if(res.errMessage.startsWith("facade_Max_balance")){
        // setErrorMessage(t('facade_Max_balance'));
        // setIsLock(false);
      }else if(res.errMessage.startsWith("facade_Min_balance")){
        // setErrorMessage(t('facade_Min_balance'));
        // setIsLock(false);
      }
    });

    socket.on('connect', (e) => {
      // console.log("render once socket connected testing");
      //console.log("socket on connect event listener");
      //console.log("web socket id : " + socket.id);
    });

    socket.on('connect_error', (e) => {
      // console.log("render once socket connected testing");
      //console.log("socket on connect error event listener");
      //console.log("web socket connect error : " + e);
    });

    socket.on('data', (data) => {
      // console.log("render once socket connected testing");
      //console.log("socket on data event listener");
      //console.log("web socket data : " + data);
      
    });

    socket.io.on("error", (error) => {
      //console.log("socket error event listener");
      //console.log("web socket error : " + error);
    });

    socket.on('bet result', (data) => {
      //console.log("socket on bet result event listener");
      //console.log("web socket data : " + JSON.stringify(data));
      this.displayResult(data);
    });

    this.getQuotaReturn("limbo");

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('error message');
      socket.off('bet result');
    };
  }

  getQuotaReturn(gameId) {
    let memberInfo = store.getState().memberInfo;
    const data = new URLSearchParams();
    data.set('gameId', gameId);
    fetch(global.config.domain.member + "/api/loadQuota", {
      method: "Post",
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'gametoken': memberInfo.token,
      }),
      body: data,
      credentials: 'include'
    })
        .then((res) => res.json())
        .then((res) => {
          if(res !== undefined) {
            this.setState({
              amount: res.minBetAmount !== null ? Number(res.minBetAmount).toFixed(2) : Number(betAmtConstants.minAmt).toFixed(2),
              minBetAmount: res.minBetAmount !== null ? Number(res.minBetAmount).toFixed(2) : Number(betAmtConstants.minAmt).toFixed(2),
              maxBetAmount: res.maxBetAmount !== null ? Number(res.maxBetAmount).toFixed(2) : Number(betAmtConstants.maxAmt).toFixed(2),
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  startGame(start){
    const { t } = this.props;
    const regex = /^.+[0-9\b]+$/;
    let balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney;
    document.getElementById('game-result').innerHTML ="";
    if(Number(this.state.amount) < Number(this.state.minBetAmount)) {
      this.setState({ autobet: false });
      document.getElementById('game-result').innerHTML = t('facade_Min_balance') + Number(this.state.minBetAmount).toFixed(2);
    } else if(Number(this.state.limboTarget)<1.10){
      this.setState({ autobet: false });
      this.errorMsg('targer_error');
    } else if(!regex.test(this.state.limboTarget)){
      this.setState({ autobet: false });
      this.errorMsg('targer_error_number');
    }else if(Number(balanceMoney) < Number(this.state.amount)){
      this.setState({ autobet: false });
      document.getElementById('game-result').innerHTML = t("facade_insufficient_balance");
    }else {
      this.setState({
        startGame:!start,
      })
      let memberInfo = store.getState().memberInfo;
      let token = memberInfo.token;
      let loginName = memberInfo.MemberInfo.loginName;
      //console.log(memberInfo.MemberInfo);
      //console.log("limbo get token: " + token);
      let jsonData = {
        token: token,
        betAmount: this.state.amount,
        amount: memberInfo.MemberInfo.balanceMoney,
        username: loginName,
        nonce:memberInfo.MemberInfo.nonce,
        clientSeed:memberInfo.MemberInfo.clientSeed,
        targetNum: this.state.limboTarget,
      };
      //console.log("jsonData: " + JSON.stringify(jsonData));
      socket.emit("bet info", jsonData);
    }
  }

  autoBet(){
    var play = !this.state.autobet;
    const setautoPlayBlock = async () => {
      await delay(3500);
      this.setState({ autoPlay: false });
    };
    if(play){
      if(!this.state.islock && !this.state.autobet && !this.state.autoPlay){
        this.setState({ autobet: play, islock: true, autoPlay: true });
        this.startGame(true);
      }
    }else{
      this.setState({ autobet: play, islock: false });
        setautoPlayBlock();
      //clearInterval(this.state.gameInterval);
    }
  }

  autoPlay(){
    if(this.state.autobet){
      this.setState({ autoPlay: true });
      this.startGame(true);
    }
  }

  add(){
    if(!this.state.islock && !this.state.autobet){
      let targetNum = parseFloat(this.state.limboTarget) + 1;
      this.setState({limboTarget: Number(targetNum).toFixed(2)})
    }
  }

  sub(){
    if(!this.state.islock && !this.state.autobet){
      let limboTarget = parseFloat(this.state.limboTarget)-1;
      if(limboTarget <=1){
        limboTarget = 1.10;
      }
      this.setState({limboTarget: Number(limboTarget).toFixed(2)})
    }
  }
  
  resetGame(){
    if(document.getElementsByClassName('rocket-wrap ').length>0){
      document.getElementsByClassName('rocket-wrap')[0].classList.remove('boom');
      document.getElementsByClassName('rocket-boom')[0].classList.remove('boom');
     }
     clearInterval(this.state.gameInterval);
  }

  displayResult(data){
    this.state.audio.play();
    if(document.getElementsByClassName('rocket-wrap ').length>0){
      document.getElementsByClassName('rocket-wrap')[0].classList.add('flying');
      document.getElementsByClassName('rocket-wrap')[0].classList.remove('flying');
      document.getElementsByClassName('rocket-wrap')[0].classList.add('boom');
      document.getElementsByClassName('rocket-boom')[0].classList.add('boom');
    }
    let gameInterval = setInterval(() => {
      this.resetGame();
    },500);
    this.setState({gameInterval: gameInterval});
    let limboNewResult = parseFloat((data.randomNum));
     if(data.gameResult){
      document.getElementsByClassName('rocket-number')[0].style.color = "greenyellow";
     }else{
      document.getElementsByClassName('rocket-number')[0].style.color = "red";
     }
     this.setState({
      limboResult: limboNewResult,
     },()=>getMemberBalance());
    clearInterval(this.state.gameInterval);
      setTimeout(()=>{
        if(this.state.autobet){
          this.autoPlay();
          clearInterval(this.state.gameInterval);
        }else{
          clearInterval(this.state.gameInterval);
          this.setState({ islock: false });
        }
      }
      ,3000);
  }
  maxBet(){
    if(!this.state.islock && !this.state.autobet){
      let balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney;
      let maxBetAmt = this.state.maxBetAmount;
      if (balanceMoney <= maxBetAmt) {
        balanceMoney = balanceMoney;
      } else {
        balanceMoney = maxBetAmt;
      }
      this.setState({amount:Number(balanceMoney).toFixed(2)})
    }
  }

  minBet(){
    if(!this.state.islock && !this.state.autobet){
      this.setState({amount: this.state.minBetAmount})
    }
  }

  doubleBet(){
    if(!this.state.islock){
      let doubleAmount = this.state.amount * 2;
      let balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney;
      let maxBetAmt = this.state.maxBetAmount;
      if (doubleAmount <= maxBetAmt) {
        if (doubleAmount > balanceMoney) {
          doubleAmount = balanceMoney;
        }
      } else {
        doubleAmount = maxBetAmt;
      }
      this.setState({amount:Number(doubleAmount).toFixed(2)})
    }
  }

  halfBet(){
    if(!this.state.islock){
      let halfAmount = this.state.amount / 2;
      let minBetAmt = this.state.minBetAmount;
      if(minBetAmt > halfAmount){
        halfAmount = minBetAmt;
      }
      this.setState({amount:Number(halfAmount).toFixed(2)})
    }
  }

  errorMsg(msg){
    const { t } = this.props;
    this.setState({ autobet: false,autoPlay:false,islock:false });
    if(msg.indexOf("facade_Max_balance")>-1 || msg.indexOf("facade_Min_balance")>-1){
      msg = msg.split("=");
      msg = t(msg[0])+(msg[1]!=undefined?msg[1]:"");
    }
    if(msg.indexOf("game_not_open")>-1){
      if(isMobile){
        document.getElementById("game-result").style.fontSize = "1.625rem";
      }else{
        document.getElementById("game-result").style.fontSize = "1.2rem";
      }
    }
    document.getElementById('game-result').innerHTML = t(msg);
  }

  handleBetAmt = (e) => {
    const regex = /^(\d*\.{0,1}\d{0,2}$)/;
    if(regex.test(e.target.value)) {
      this.setState({amount:e.target.value});
    }
  };

  handleTarget = (e) => {
    const regex = /^(\d*\.{0,1}\d{0,2}$)/;
    if(regex.test(e.target.value)) {
      this.setState({limboTarget:e.target.value});
    }
  };

  render() {
    const { t } = this.props;
    const balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney != null?store.getState().memberInfo.MemberInfo.balanceMoney:0.00;
    return (
      <div className={isMobile?'':'limbo-web'}>
      <div className={isMobile?'limbo-top-panel-h5':'limbo-top-panel'} style={{backgroundColor:'#111923'}}>
      <GameMoney gameName={"limbo"}/>
      </div>
      <div style={{backgroundColor:'#111923',height:'100vh',overflow:'scroll'}}>
      <div className="limbo-bg">
          <div className="limbo-star">
            <div className="bg-star show_1">
              <div className="l-star e-r"></div>
              <div className="l-star s-p"></div>
              <div className="l-star r-p"></div>
            </div>
          </div>
          <div className="limbo auto">
            <div className="notranslate limbo-rocket ">
              <div className="rocket-number">
                <span>{this.state.limboResult.toFixed(2)}</span>
                <span className="rocket-number-x">×</span>
                <div className="rocket-number-result" style={isMobile?null:{lineHeight:'15px'}}><span style={{fontSize: '2.125rem'}} id="game-result"></span>
              </div>
              <div className="rocket-boom"></div>
            </div>
          <div className="rocket-wrap">
            <div className="rocket-img">
              <img src={require(`../assets/images/limbo/rocket.png`)} alt="rocket.png"/></div>
            <div className="rocket-fire">
          </div>
      </div>
      </div>
      </div>
      <div className="limbo-buttons">
      <div className="limbo-body-bet-amount">
        <div className="amount_left">
          <div className="limbo-button" onClick={()=>this.minBet()}>
            <span>{t('dice_min')}</span>
          </div>
          <div className="limbo-button" onClick={()=>this.maxBet()}>
            <span>{t('dice_max')}</span>
          </div>
        </div>
        <div className="amount_center" style={{width:'100%'}}>
          <div className="limbo-bet-amount">
            {/* <span>{this.state.amount.toFixed(2)}</span> */}
            <TextField sx={{input:{color:'white', textAlign:'center',marginTop:'10%'}}}
                type='text'
                InputProps={{disableUnderline: true, readOnly: this.state.islock}} 
                autoComplete="off"
                onChange={(event)=>this.handleBetAmt(event)}
                hiddenLabel 
                variant="standard" 
                value={this.state.amount}/>
          </div>
          {/* <div style={{fontSize:17,color:'white',textAlign:'center',marginBottom:18}}>
            <span>{t('Play Amount')}</span>
          </div> */}
        </div>
        <div className="amount_right">
          <div className="limbo-button" onClick={()=>this.halfBet()}>
            <span>1/2</span>
          </div>
          <div className="limbo-button" onClick={()=>this.doubleBet()}>
            <span>2X</span>
          </div>
       </div>
      </div> 
      {this.state.autobet?
        <div id="betButton" className="limbo-button-blue">
          <span className='buttonState' id="buttonState">{t('Bet')}</span>
        </div>
      :
        <div id="betButton" className="limbo-button-blue" onClick={()=>this.startGame()}>
          <span className='buttonState' id="buttonState">{t('Bet')}</span>
        </div>
      }
      <div id="betButton" className="limbo-button-blue" style={{marginTop:'45%'}} onClick={()=>this.autoBet()}>
          <span className='buttonState' id="buttonState">{this.state.autobet?t('stop_bets'):t('auto_bets')}</span>
      </div>
        
      <div className="limbo-body-bet-target">
        <div className="limbo-bet-auto">
        <div style={{width:'100%',marginTop:'3%'}}>
          <div className="limbo-button" onClick={()=>this.add()}>
            <span>+</span>
          </div>
        </div>
        <div className="limbo-bet-amount-body">
          <div className="limbo-bet-amount">
          <TextField sx={{input:{color:'white', textAlign:'center',marginTop:'10%'}}}
                type='text'
                InputProps={{disableUnderline: true, readOnly: this.state.islock}} 
                autoComplete="off"
                onChange={(event)=>this.handleTarget(event)}
                hiddenLabel 
                variant="standard" 
                value={this.state.limboTarget} />
            {/* <span className="limbo-bet-target_value">{this.state.limboTarget.toFixed(2)}</span> */}
          </div>
        </div>
        <div style={{width:'100%',marginTop:'3%'}}>
          <div className="limbo-button" onClick={()=>this.sub()}>
            <span>-</span>
          </div>
       </div>
       </div>
        <div className="limbo-bet-auto">
          <span className="auto-stop__label">{t('TARGET')}</span>
        </div>
      </div>
      </div>
    </div>
    </div>
    </div> 
    );
  }
}

const ConnectedLimboPage = connect(mapStateToProps)(LimboPage);
export default withTranslation('common')(ConnectedLimboPage);

