import React, {useEffect, useState} from "react";
import {useSelector, shallowEqual} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';

import {store} from "../../index";
import Play from "../../components/dice/Play";
import Button from "../../components/default/Button";
import GameAward from "../../containers/GameAward";
import Stack from "react-bootstrap/Stack";
import {motion} from "framer-motion";
import box_profit from '../../assets/images/dice/box_profit.png'
import Chance from "../../components/dice/Chance";

function GameProfit(props) {
    const {t} = useTranslation();
    const {earnAmount} = useSelector(state => store.getState().gameDice, shallowEqual);

    return (
        <>
            <Chance.Container style={{backgroundImage:`url(${box_profit})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',display: 'flex',width: '145%',height: '95%',marginLeft:'-50%'}}>
                <Stack style={{paddingTop:'17%'}}>
                    <div style={{paddingLeft:'8%'}}><Chance.Value>{t('dice_profit')}</Chance.Value></div>
                    <div style={{paddingTop:'5%',paddingLeft:'8%'}}><Chance.Header><GameAward from={0} to={earnAmount} /></Chance.Header></div>
                </Stack>
            </Chance.Container>
        </>
    );
}

export default withTranslation('common')(GameProfit);
