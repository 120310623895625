import React from "react";
import Stack from "react-bootstrap/Stack"
import {useTranslation, withTranslation} from 'react-i18next';

import Chance from "../../components/dice/Chance";
import helpers from "../../utils/helpers";
import box_multiplier from '../../assets/images/dice/box_multiplier.png'

function GameMultiplier(props) {
    const {t} = useTranslation();

    return (
        <>
            <Chance.Container style={{backgroundImage:`url(${box_multiplier})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',display: 'flex',width: '100%',height: '84%',marginLeft:'-20%'}}>
                <Stack style={{paddingTop:'17%'}}>
                    <div style={{paddingLeft:'8%'}}>
                        <Chance.Value>{t('dice_multiplier')}</Chance.Value>
                    </div>
                    <div style={{paddingTop:'8%',paddingLeft:'8%'}}>
                        <Chance.Header>X{helpers.padDigits( props.rollOver  === true ?
                                (98 / (99 - props.predictionValue)).toFixed(2) :
                                (98 / (props.predictionValue)).toFixed(2), 2)}
                        </Chance.Header>
                    </div>
                </Stack>
            </Chance.Container>
        </>
    );
}

export default withTranslation('common')(GameMultiplier);
