import update from "immutability-helper";
import gameConstants from "../utils/utilConstants";

const gameInitial = {
    loading: false,
    resultBallNumbers: [],
    earnAmount: 0.0,
    showMessage: false,
    message: '',
    messageType: 0,
    showResult: true
};

const storeGameKeno = (state = gameInitial, action) => {
    switch (action.type) {
        case gameConstants.UPDATE_SHOW_KENO_RESULT: {
            return update(state, {
                showResult: { $set: action.show }
            });
        }

        case gameConstants.SHOW_MESSAGE: {
            return update(state, {
               showMessage: { $set: true },
               message: { $set: action.message },
               messageType: { $set: action.messageType }
            });
        }

        case gameConstants.HIDE_MESSAGE: {
            return update(state, {
                showMessage: { $set: false },
                message: { $set: '' },
                messageType: { $set: 0 }
            });
        }

        case gameConstants.SET_LOADING: {
            return update(state, {
                loading: { $set: true }
            });
        }

        case gameConstants.UPDATE_KENO_DATA: {
            return update(state, {
                resultBallNumbers: {
                    $set: action.ballNumbers
                },
                earnAmount: {
                    $set: action.earnAmount
                }
            });
        }

        case gameConstants.RUN_GAME: {
            return update(state, {
                resultBallNumbers: {$set: []}
            });
        }

        case gameConstants.END_GAME: {
            return update(state, {
                isPlayed: { $set: false }
            });
        }
        case "GET_BALANCE":{
            return update(state, {
            });
        }
        case gameConstants.RESTART:
        default:
            return gameInitial;
    }
};

export { storeGameKeno }