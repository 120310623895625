import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { Drawer, List, Divider, ListItem, ListItemText } from '@mui/material';
import eye from 'assets/images/eye.png';
import { store } from 'index';
import '../styles/components/MainSideBar.scss';

const mapStateToProps = (state) => {
  return {
    memberInfo: state.memberInfo
  };
};

class MainSideBar extends React.Component {
  state = {
    open: false,
    showAmount: false,
    currLang: '',
    toggleMenu: false
  };

  componentDidMount() {
    let t_label = this.props.memberInfo.MemberInfo.language;
    switch (t_label) {
      case 'en':
        t_label = 'EN'
        break;
      case 'th':
        t_label = 'TH'
        break;
      case 'vi':
        t_label = 'VI'
        break;
      case 'in':
        t_label = 'IN'
        break;
      case 'id':
        t_label = 'ID'
        break;        
      case 'pt':
        t_label = 'PT'
        break;        
      case 'es':
        t_label = 'ES'
        break;        
      case 'fr':
        t_label = 'FR'
        break;
      case 'ru':
        t_label = 'RU'
        break;
      default:
        t_label = '中文'
        break;
    }
    this.setState({ currLang: t_label });
  }

  toggleDrawer = (exp) => (event) => {
    this.setState({ open: exp });
    if (!exp) this.setState({ toggleMenu: false });
  };

  showAmount() {
    this.setState({ showAmount: !this.state.showAmount });
  }

  refresh = () => {
    window.location.reload(window.location.href.split('#')[0]+ "#/?gametoken=" + store.getState().memberInfo.token);
  }

  toggleLangMenu = () => {
    var x = document.getElementById("langMenu");
    if (x.style.display === "none") {
      x.style.display = "block";
      this.setState({ toggleMenu: true });
    } else {
      x.style.display = "none";
      this.setState({ toggleMenu: false });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <img src={require('assets/images/menu-burger.png')} alt="menu" style={{width:'25px',marginLeft: 15, cursor: 'pointer'}} onClick={this.toggleDrawer(true)}/>
        <Drawer anchor="left" open={this.state.open} onClose={this.toggleDrawer(false)} className="mdrawer">
          <List role="presentation">
            <ListItem>
              <div style={{ width: '30%' }}><img src={require('assets/images/avatar.png')} style={{ width: '40px' }} alt="avatar" /></div>
              <div style={{width: '70%'}}>
                <Row>{this.props.memberInfo.MemberInfo.loginName}</Row>
                <Row>
                  <span style={{flex: '1', padding: 0}}>
                    {this.props.memberInfo.MemberInfo.currency === undefined ? '￥' : this.props.memberInfo.MemberInfo.currency}
                    {this.state.showAmount ? <>{this.props.memberInfo.MemberInfo.balanceMoney === undefined ? '0.00' : this.props.memberInfo.MemberInfo.balanceMoney.toFixed(2)}</> : '******'}
                  </span>
                  <img src={eye} style={{ width: '26px', height: '26px', margin: '0 12px', padding: 0 }} onClick={this.showAmount.bind(this)} alt="eye" />
                </Row>
              </div>
            </ListItem>
            <Divider />
            <ListItem onClick={this.refresh} style={{ height: '54px', cursor: 'pointer' }}>
              <img src={require(`../assets/images/6sx.png`)} className="msidebar-icon" alt="refresh" />
              <ListItemText primary={t('刷新')} style={{ color: '#fffefd', display: 'inline-block' }} />
            </ListItem>
            <Divider />
          </List>
        </Drawer>
      </div>
    );
  }
}

const ConnectedMobileSidebar = connect(mapStateToProps)(MainSideBar);
export default withTranslation('common')(ConnectedMobileSidebar);