import React, {useState, useEffect, useRef} from "react";
import { useSpring, animated as a } from 'react-spring'
import {easeBackOut} from "d3-ease";
import styled from "styled-components";
import box_amount from "../../assets/images/triple/box_amount.png"
import spheres_box_amount from "../../assets/images/spheres/box_amount.png"
import mine_box_amount from "../../assets/images/mine/mine_coin_amount.png"

import {device} from "../../utils/utilConstants";

const Container = styled(a.div)`
  width: 100%;
  position: relative;
  z-index: 10;
  
  @media ${device.mobileL} {
    width: 100%;
  }
  @media ${device.surface} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

const ItemWrapper = styled(a.div)`
  width: 50%;
  height: 20%;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  overflow: visible;
  will-change: transform, height, opacity;
  padding: 7px;
`;

const ItemContent = styled(a.div)`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
`;

const Label = styled.div`
  font-size: 15px;
  color: white;
  @media ${device.surface} {
    font-size: medium;
  }
  @media ${device.tablet} {
    font-size: medium
  }
`;

const Image = styled.div`
  width: 25%;
  // margin-right: 20px;

  img {
    width: 90%;
  }
  
  @media ${device.surface} {
    width: 25%;
  }
  @media ${device.tablet} {
    width: 25%;
  }
`;

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

function Item(props) {
    const [animate, setAnimate] = useState(false);
    const prevCount = usePrevious(props.count);

    const contentProps = useSpring({
        config: {
            duration: 300,
            easing: easeBackOut
        },
        transform: animate ? "scale(1.3)" : "scale(1)",
        background: props.gameName === "triple" || props.gameName === "spheres" || props.gameName === "mine" ? 'none' : '',
        padding: props.gameName === "triple" || props.gameName === "spheres" ? '5px' : props.gameName === "mine" ? '10px 30px 10px 10px' : '',
        boxShadow: props.gameName === "spheres"?'none' : '',
    });

    useEffect(() => {
        let timeout = null;

        if (animate) clearTimeout(timeout);

        if (prevCount > props.count && props.count !== 0) {
            setAnimate(true);
        }

        timeout = setTimeout(() => {
            setAnimate(false);
        }, 200);

        return () => clearTimeout(timeout);
    }, [props.count]);
    
    
    return (
        <ItemWrapper {...props} style={props.gameName === "triple" ? 
        {backgroundImage:`url(${box_amount})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', height:'55px', width:'60%'} 
        : props.gameName === "spheres" ?
        {backgroundImage:`url(${spheres_box_amount})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', height:'100%', width:'60%',padding:'10px 0'}
        : props.gameName === "mine" ? 
        {backgroundImage:`url(${mine_box_amount})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', height:'100%', width:'60%',padding:'10px 0'}
        :
        {}}>
            <ItemContent style={contentProps}>{props.children}</ItemContent>
        </ItemWrapper>
    );
}

export default {Container, Item, Label, Image};
