import PropTypes from 'prop-types';
const PageSpinner = () => {
  return (
    <>
    <div id="div2" className="load_screen">
      <div className="loading">
        <div className="lds-css ng-scope">
          <div style={{ width: '100%', height: '100%',top:'50px'  }} className="lds-double-ring">
            <div style={{ left: '32px', width: '48px', height: '48px' }}></div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

PageSpinner.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
};

export default PageSpinner;
