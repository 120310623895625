import React, {useCallback, useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {motion} from "framer-motion";
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';

import {store} from "../index";

import GameLoading from "../containers/GameLoading";
import Seven1121GameMoney from "../containers/Seven1121GameMoney";
import GameLogo from "../containers/seven1121/GameLogo";
import GamePlay from "../containers/seven1121/GamePlay";
import GameNumbers from "../containers/seven1121/GameNumbers";
import GameResult from "../containers/seven1121/GameResult";
import GameMessage from "../containers/seven1121/GameMessage";
import {getMemberBalance} from "../services/HomeServices";
import {playGame} from '../services/GameSeven1121Services'
import useGameAudio from "../hooks/useGameAudio";
import '../styles/Seven1121/Seven1121.css'
import gameConstants, {betAmtConstants} from "../utils/utilConstants";
import {getQuota} from "../services/QuotaServices";
import background from '../assets/images/seven1121/background.png';
import web_background from '../assets/images/seven1121/web_background.png';
import {isMobile} from "react-device-detect";
import desk4 from "../assets/images/seven1121/desk4.png"

const GameMainContainer = styled.div`
    background-color: #111923;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow: scroll;
`;

const InnerContainer = styled.div`
    backgroundColor: #111923;
    display: flex;
    justify-content: center;
    align-items: center;
`;

function Game71121() {
    const dispatch = useDispatch();
    const balanceMoney = useSelector(state => store.getState().memberInfo.MemberInfo.balanceMoney, shallowEqual);
    const {t} = useTranslation();
    const [start, setStart] = useState(false);
    const [betAmount, setBetAmount] = useState(Number(10.00).toFixed(2));
    const [reveal, setReveal] = useState(false);
    const [manual, setManual] = useState(false);
    const [isLock, setIsLock] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [playingMusic, {toggleAudio}] = useGameAudio("bg7", {
        loop: true
    });
    const [minBetAmount, setMinBetAmount] = useState(Number(betAmtConstants.minAmt).toFixed(2));
    const [maxBetAmount, setMaxBetAmount] = useState(Number(betAmtConstants.maxAmt).toFixed(2));

    const handleOnManual = (input) => {
        setManual(input);
    };

    const handleOnReveal = () => {
        if (start === true) {
            setReveal(true)
            setIsLock(true);
        }
    };

    const handleOnStopReveal = () => {
        if (start === true) {
            setReveal(false)
        }
    };

    const handleOnClick = () => {
        //if (!playingMusic) toggleAudio();
        if (start === false) {
            hideMsg();
            if(Number(betAmount) < Number(minBetAmount)) {
                dispatch({ type: gameConstants.SHOW_MESSAGE, message: t("facade_Min_balance") + Number(minBetAmount).toFixed(2), messageType: 1 });
            } else {
                setStart(true);
                if (Number(balanceMoney) < Number(betAmount)) {
                    setStart(false);
                    dispatch({type: gameConstants.SHOW_MESSAGE, message: t('facade_insufficient_balance'), messageType: 1});
                } else {
                    setIsLoading(true);
                    dispatch({type: gameConstants.RESTART});
                    dispatch({type: gameConstants.RUN_GAME});
                }
            }
        }
    };

    const handleIncreaseScore = () => {
        if (start === false) {
            let calculateAmount = Number(betAmount) * 2;
            if(calculateAmount <= Number(maxBetAmount)) {
                if (calculateAmount > balanceMoney) {
                    setBetAmount(balanceMoney.toFixed(2));
                }else{
                    setBetAmount(calculateAmount.toFixed(2));
                }
            } else {
                setBetAmount(Number(maxBetAmount).toFixed(2));
            }
        }
    };

    const handleDecreaseScore = () => {
        if (start === false) {
            if (Number(betAmount) > Number(minBetAmount)) {
                let calculateAmount = Number(betAmount) / 2;
                setBetAmount(calculateAmount<=Number(minBetAmount) ?
                    Number(minBetAmount).toFixed(2) : calculateAmount.toFixed(2));
            } else {
                dispatch({type: gameConstants.SHOW_MESSAGE, message: t('facade_Min_balance'), messageType: 1});
            }
        }
    };

    const handleOnMinScore = () => {
        if(start === false) {
            setBetAmount(Number(minBetAmount).toFixed(2));
        }
    };

    const handleOnMaxScore = () => {
        if(start === false) {
            let latestBalance = store.getState().memberInfo.MemberInfo.balanceMoney;
            if(Number(latestBalance) <= Number(maxBetAmount)) {
                setBetAmount(Number(latestBalance).toFixed(2));
            } else {
                setBetAmount(Number(maxBetAmount).toFixed(2));
            }
        }
    };

    const onUpdateBetAmount = (amt) => {
        if (start === false) {
            const regex = /^(\d*\.{0,1}\d{0,2}$)/;
            if(regex.test(amt)) {
                setBetAmount(amt);
            }
        }
    };

    const handleEndGame = () => {
        setStart(false);
        setIsLock(false);
        setManual(false);
        handleLoading();
    };

    const handleLoading = () => {
        setIsLoading(false);
    };

    const updateCallback = (res) => {
        if(!res) {
            handleEndGame();
            getMemberBalance();
        }else{
            handleLoading();
        }
    };

    useEffect(() => {
        if (start === true) {
            fetchGame().then(() => {
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                getMemberBalance();
            });
        }
    }, [start]);

    useEffect( () => {
        getQuota("7-11-21", updateQuotaCallback);
    }, []);

    const updateQuotaCallback = (res) => {
        if(res !== '' && res !== undefined) {
            if(res.minBetAmount !== null) {
                setBetAmount(Number(res.minBetAmount).toFixed(2));
                setMinBetAmount(Number(res.minBetAmount).toFixed(2));
            }
            if(res.maxBetAmount !== null) setMaxBetAmount(Number(res.maxBetAmount).toFixed(2));
        } else {
            setBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMinBetAmount(Number(betAmtConstants.minAmt).toFixed(2));
            setMaxBetAmount(Number(betAmtConstants.maxAmt).toFixed(2));
        }
    };

    const fetchGame = useCallback(async () =>{
        await playGame(betAmount, start, updateCallback);
    }, [betAmount,start]);

    const hideMsg = () => {
        dispatch({ type: gameConstants.HIDE_MESSAGE });
    };
    return (
        <GameMainContainer style={{backgroundImage: `url(${isMobile?background:web_background})`, height: window.innerHeight,backgroundSize: '100vw '+window.innerHeight+'px', backgroundRepeat: 'no-repeat', overflowX:'hidden'}}>
            <InnerContainer className={"content-container-inner-seven"} style={{zoom:!isMobile?'0.73':(window.innerHeight/1000)+0.15}}>
                <Container fluid className={"content-container-seven"} style={{marginTop: isMobile?'45%':'60%'}}>
                    <Seven1121GameMoney gameName={"7-11-21"}/>
                    <Row>
                        <Col className="row-col-logo">
                            <GameLogo/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="row-col-contents-seven"><GameResult/></Col>
                    </Row>
                    <Row style={{backgroundImage:`url(${desk4})`, backgroundSize:'cover', backgroundRepeat:'no-repeat',height:'165px',zoom:window.innerWidth>=360?'1':window.innerWidth>=330?'0.9':window.innerWidth>=300?'0.8':window.innerWidth>=280?'0.75':'0.70'}}>
                        <Row style={{paddingLeft: '8%',height: '0px'}}>
                            <Col style={{color: 'white',textAlign: 'center',lineHeight: '75px'}}>{t('deck4')}</Col>
                        </Row>
                        <Row style={{paddingLeft: '15%'}}>
                        <Col className="row-col-order-seven" style={window.innerWidth<=380?{height:'35px'}:null}>
                            <GameNumbers style={{marginLeft: '-10%'}} onManual={handleOnManual} manual={manual}
                                         onStopReveal={handleOnStopReveal} reveal={reveal}
                                         start={start} onEndGame={handleEndGame}/>
                        </Col>
                        </Row>
                    </Row>
                    {/* <Row style={{marginTop:'-32%',height:window.innerWidth>=360?'100px':window.innerWidth>=330?'80px':window.innerWidth>=300?'70px':window.innerWidth>=280?'60px':'50px'}}>
                        
                    </Row> */}
                    <Row>
                        <Col className="row-col-message-seven"><GameMessage/></Col>
                    </Row>
                    <Row style={{marginTop:'20px'}}>
                        <Col className="row-col-order-seven">
                            <GamePlay onClick={handleOnClick} onReveal={handleOnReveal}
                                      start={start}
                                      manual={manual}
                                      onIncreaseScore={handleIncreaseScore}
                                      onDescreaseScore={handleDecreaseScore}
                                      onMinScore={handleOnMinScore}
                                      onMaxScore={handleOnMaxScore}
                                      betAmount={betAmount}
                                      onUpdateBetAmount={onUpdateBetAmount}
                                      isLock={isLock}
                            />
                        </Col>
                    </Row>
                </Container>
            </InnerContainer>
            {isLoading && 
                <GameLoading style={{height:window.innerHeight}}/>
            }
        </GameMainContainer>
    );
};

export default withTranslation('common')(Game71121);
