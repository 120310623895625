import React from "react";
import ImageResult from "../../components/keno/ImageResult";
import ResultCircle from "../../assets/images/keno/ResultCircle.png";

function GameResultRow2(props) {
    function renderResultNumberImageRow2() {
        let row2 = Object.values(props.ballNumbers).filter((val,i)=> (i >=10 && i < 20));
        return row2.map((ing, j) => (
            <ImageResult.Container key={j}>
                <img src={ResultCircle} alt={'Result-Circle'}/>
                <ImageResult.Header>
                    {ing === -1 ? '' : ing}
                </ImageResult.Header>
            </ImageResult.Container>
        ));
    };

    return (
        <>{renderResultNumberImageRow2()}</>
    );
}

export default GameResultRow2;
