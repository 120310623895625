import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import '../styles/_crash.scss';
import song from "../assets/audio/rocket_sound.mp3";
import io, { Manager } from 'socket.io-client';
import { store } from '../index';
import PlayHistory from '../components/default/PlayHistory';
import GameRules from "../containers/GameRules";
import { TextField, Button } from '@mui/material';
import { getMemberBalance } from "../services/HomeServices";
import GameMoney from "../containers/GameMoney";
import {isMobile} from "react-device-detect";
import {betAmtConstants} from "../utils/utilConstants";

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    memberInfo: state.memberInfo,
  };
};

// const socket = io('http://localhost:1101/',{
//   transports: ["websocket"],
// });

let socket;
if (global.config.testEnv === true) {
  socket = io(global.config.minigame.socket.protocol + '://' + global.config.domain.instantaward + ':' + global.config.minigame.socket.port.crash + '/', {
    transports: ["websocket"],
  });
} else {
  socket = io(global.config.minigame.socket.protocol + '://' + global.config.minigame.socket.domain.crash + '/', {
    transports: ["websocket"],
  });
}

class CrashPage extends React.Component {

  constructor(props) {
    super(props);
    this.moveCircle = this.moveCircle.bind(this);
    this.state = {
      move: 0,
      gameInterval: null,
      current: 0,
      gameRotate: null,
      rotate: 0,
      fireRotate: 90,
      amount: Number(10.00).toFixed(2),
      crashTarget:  Number(2.00).toFixed(2),
      crashResult: 0.01,
      finalResult: null,
      path: {
        a: 0,
        x: 0,
        y: 0,
        max_X: 400,
        max_Y: -190,
      },
      prevPositionY: 0.01,
      enterRoom: 0,
      gameStatus: null,
      gameStatusResult: 0,
      startAlready: false,
      gameState: 0,//游戏状态 0下注中，1游戏开始,2游戏运行,3游戏结束
      gamePeriodNo: null,
      playerBetted: false,
      playerCrashed: false,
      gameHistory: [],
      playerTodayBetHistory: [],
      playerList:[],
      gameEnd:false,
      minBetAmount:Number(betAmtConstants.minAmt).toFixed(2),
      maxBetAmount:Number(betAmtConstants.maxAmt).toFixed(2),
      firstTimeComeStartAlready:false,
    };
  }

  componentDidMount() {
    //this.startCircle();
    var stars = document.getElementById('crash_stars')
    var star = document.getElementsByClassName('crash_star')
    // js随机生成流星
    for (var j = 0; j < 10; j++) {
      var newStar = document.createElement("div")
      newStar.className = "crash_star"
      newStar.style.top = Math.floor(Math.random() * (10 - 5 + 1) * 5 + -10) + 'px'
      newStar.style.left = Math.floor(Math.random() * (50 - 20 + 1) * 10 + 20) + 'px'
      stars.appendChild(newStar)
    }
    // 给流星添加动画延时
    for (var i = 0, len = star.length; i < len; i++) {
      if (i % 6 == 0) {
        star[i].style.animationDelay = '1s'
      } else {
        star[i].style.animationDelay = i * 1 + 's'
      }
    }
    socket.extraHeaders = { gametoken: store.getState().memberInfo.token };
    this.startSocket();
    let gameStatus = setInterval(() => {
      socket.emit('state update');
    }, 50);
    this.setState({ gameStatus: gameStatus});
    // this.TodayHistoryResult();
    this.getQuotaReturn("crash");
  }

  getQuotaReturn(gameId) {
    let memberInfo = store.getState().memberInfo;
    const data = new URLSearchParams();
    data.set('gameId', gameId);
    fetch(global.config.domain.member + "/api/loadQuota", {
      method: "Post",
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'gametoken': memberInfo.token,
      }),
      body: data,
      credentials: 'include'
    })
        .then((res) => res.json())
        .then((res) => {
          if(res !== undefined) {
            this.setState({
              amount: res.minBetAmount !== null ? Number(res.minBetAmount).toFixed(2) : Number(betAmtConstants.minAmt).toFixed(2),
              minBetAmount: res.minBetAmount !== null ? Number(res.minBetAmount).toFixed(2) : Number(betAmtConstants.minAmt).toFixed(2),
              maxBetAmount: res.maxBetAmount !== null ? Number(res.maxBetAmount).toFixed(2) : Number(betAmtConstants.maxAmt).toFixed(2),
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  startSocket() {
    let memberInfo = store.getState().memberInfo;
    let token = memberInfo.token;
    let jsonData = {
      token: token,
    };
    socket.emit('enterroom', jsonData, (response) => {
      // console.log("socket on enterroom");
      //console.log(response);
      this.setState({ enterRoom: response.status,firstTimeComeStartAlready:response.gameState> 0?true:false })
      //this.setState({ enterRoom: response.status,firstTimeComeStartAlready:true})

    });

    socket.on('crash', (data) => {
      this.gameProgress(data);
    });

    socket.on('userslist', (data) => {
      if(data){
        if(data.usersMap){
          this.thisGameUserList(data.usersMap)
        }
        if(data.users.length>0){
          this.updateGameUserList(data.users)
        }
      this.playerCashOut(data);
     }
    });

    socket.on('history', (data) => {
      // console.log("socket on history");
      //console.log("web socket data : " + JSON.stringify(data.history));
      if (data.history) {
        let historyList = JSON.stringify(data.history).replace('[', '').replace(']', '').replaceAll('\"', '');
        historyList = historyList.split(',').reverse();
        for(var i = 0; historyList.length>i;i++){
          historyList[i] = parseFloat(historyList[i]) + parseFloat(0.01);
        }
        this.setState({ gameHistory: historyList })
      }
    });

    socket.on('betted', (data) => {
      //console.log("socket on betted event listener");
      // console.log("web socket betted : " + JSON.stringify(data));
    });

    socket.on('error message', (res) => {
      console.log("SOCKET ERROR MSG : " + JSON.stringify(res));
      this.errorMsg(res.errMessage);
      if (res.errMessage == "invalid_token_id") {
        window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
      }else if(res.errMessage.startsWith("facade_Max_balance")){
        // setErrorMessage(t('facade_Max_balance'));
        // setIsLock(false);
      }else if(res.errMessage.startsWith("facade_Min_balance")){
        // setErrorMessage(t('facade_Min_balance'));
        // setIsLock(false);
      }
    });

    socket.on('bet response', (data) => {
      //console.log("socket on bet result event listener");
      //console.log("web socket data : " + JSON.stringify(data));
      this.updateBetStatus(data);
    });

    socket.on('cashout result', (data) => {
      //console.log("cashout result");
      //console.log(JSON.stringify(data));
      this.updatePlayer(data);
    });

    socket.on('connect', (e) => {
      console.log("socket on connect event listener");
      //console.log("web socket id : " + socket.id);
    });

    socket.on('connect_error', (e) => {
      console.log("socket on connect error event listener");
      //console.log("web socket connect error : " + e);
    });

    socket.io.on("error", (error) => {
      console.log("socket error event listener");
      //console.log("web socket error : " + error);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('error message');
    };
  }

  startGame(e) {
    if(e.detail === 1) {
      const { t } = this.props;
      const regex = /^.+[0-9\b]+$/;
      let gameState = this.state.gameState;
      let memberInfo = store.getState().memberInfo;
      let token = memberInfo.token;
      let loginName = memberInfo.MemberInfo.loginName;
      if (!this.state.playerBetted && gameState == 0) {
        if(Number(this.state.amount) < Number(this.state.minBetAmount)) {
          document.getElementById('game-result').innerHTML = t('facade_Min_balance') + Number(this.state.minBetAmount).toFixed(2);
          return;
        }
        if(Number(this.state.crashTarget)<1.10){
          document.getElementById('game-result').innerHTML = t('targer_error');
          return;
        }
        if(!regex.test(this.state.crashTarget)){
          document.getElementById('game-result').innerHTML = t('targer_error_number');
          return;
        }
        // console.log('start game');
        let betData = {
          token: token,
          betAmount: this.state.amount,
          amount: this.state.amount,
          // amount: memberInfo.balanceMoney,
          name: loginName,
          nonce: memberInfo.MemberInfo.nonce,
          clientSeed: memberInfo.MemberInfo.clientSeed,
          betted: true,
        };
        // console.log("betData: " + JSON.stringify(betData));
        socket.emit("playerbet", betData);
      }
      if (this.state.playerBetted && gameState == 2) {
        let checkOutData = {
          token: token,
          multipier: this.state.crashResult,
          cashouted: true,
          periodNo: this.state.gamePeriodNo,
          amount: this.state.amount,
        };
        // console.log("checkOutData: " + JSON.stringify(checkOutData));
        socket.emit("playercashout", checkOutData);
      }
    }
  }

  displayResult() {
    document.getElementsByClassName('canvas-crash')[0].classList.remove('boom');
    this.startCircle();
    let gameInterval = setInterval(() => {
      this.moveCircle();
    }, 1);
    // let crashNewResult = parseFloat((Math.random()*(10-1) + 1)).toFixed(2);
    this.setState({
      startAlready: true,
      gameInterval: gameInterval,
    })
  }

  startCircle() {
    const path = this.state.path;
    let prevPositionY = this.state.prevPositionY;
    const circle = document.getElementById('circle');
    path.a = 0;
    path.x = 0;
    path.y = 0;
    prevPositionY = 0.01;
    const transform = `translateX(${path.x}px) translateY(${path.y}px) rotate(0deg)`;
    circle.style.transform = transform;
    document.getElementsByClassName('rocket-boom')[0].classList.remove('boom');
    document.getElementsByClassName('canvas-crash')[0].classList.remove('boom');
    this.setState({
      current: 0,
      move: 0,
      rotate: 0,
      path: path,
      prevPositionY: prevPositionY,
    });
  }

  moveCircle() {
    let num = this.state.move;
    let stopOrRun = document.getElementsByClassName('canvas-crash boom')[0];
    if (stopOrRun == undefined && num < 500) {
      num = num + 1;
      const circle = document.getElementById('circle');
      const boom = document.getElementById('rocket-boom');
      let path = this.state.path;
      let prevPositionY = this.state.prevPositionY + 0.01;
      let speed = 0.41;
      if (path.x >= path.max_X) {
        path.x = path.max_X;
      } else {
        path.x = path.x + (speed +0.15);
      }
      if (path.y > path.max_Y) {
        let temp = Math.exp(prevPositionY);
        path.y = -(temp);
      } else {
        path.y = path.max_Y;
      }
      path.a = (num/500) * 90;

      const transform = `translateX(${path.x}px) translateY(${path.y}px) rotate(-${path.a}deg)`;
      circle.style.webkitTransform = transform;
      boom.style.webkitTransform = transform;
      this.setState({
        move: num,
        path: path,
        prevPositionY: prevPositionY,
      });
    } else {
      clearInterval(this.state.gameInterval);
      // this.startCircle();
    }
  }

  showResult(nowResult) {
    document.getElementsByClassName('canvas-crash')[0].classList.add('boom');
    document.getElementsByClassName('rocket-boom')[0].classList.add('boom');
    this.setState({ startAlready: false });
    if (this.state.finalResult != null) {
      clearInterval(this.state.finalResult);
      return;
    }
    let finalResult = setInterval(() => {
      this.showResult(nowResult);
    }, 1);
    this.setState({ finalResult: finalResult });
    if (this.state.finalResult != null) {
      clearInterval(this.state.finalResult);
    }
  }

  gameProgress(data) {
    const { t } = this.props;
    let token = store.getState().memberInfo.token;
    var elms = document.querySelectorAll("[id='crash-button']");
    
    this.setState({
      crashResult: parseFloat(data.mul),
      gameState: data.gamestate,
    })
    if(this.state.firstTimeComeStartAlready == true && data.gamestate > 0){
      return
    }
    else{
      this.setState({ firstTimeComeStartAlready:false });
    }
    if (data.gamestate == 0) {
      this.startCircle();
      document.getElementById('buttonState').innerHTML = t('Bet');
      if (this.state.playerBetted) {
        document.getElementById('betButton').style.pointerEvents = 'none';
        document.getElementById('buttonState').innerHTML = t('Bet')+'<br/>';
        document.getElementById('buttonState_2').style.display = 'block';
        document.getElementById('buttonState_2').innerHTML = t('Is_placed');
      } else {
        document.getElementById('betButton').style.pointerEvents = 'auto';
        document.getElementById('buttonState_2').style.display = 'none';
      }
      for(var i = 0; i < elms.length; i++) {
        elms[i].style.pointerEvents='auto';
      }
    } else if (data.gamestate == 2) {
      if (!this.state.startAlready) {
        this.setState({ startAlready: true,gameEnd:false})
        this.displayResult();
      }
      if (!this.state.playerCrashed && this.state.playerBetted) {
        document.getElementById('buttonState').innerHTML = t('CashOut')+'<br/>'+ parseFloat(data.mul * this.state.amount).toFixed(4);
        document.getElementById('buttonState_2').style.display = 'none';
        if(data.mul>=this.state.crashTarget){
        let checkOutData = {
          token: token,
          multipier: this.state.crashTarget,
          cashouted: true,
          periodNo: this.state.gamePeriodNo,
          amount: this.state.amount,
        };
        // console.log("checkOutData: " + JSON.stringify(checkOutData));
        socket.emit("playercashout", checkOutData);
        this.setState({
          playerBetted: false,
          playerCrashed: true,
        })
        }
        document.getElementById('betButton').style.pointerEvents = 'auto';
      } else {
        document.getElementById('buttonState').innerHTML = t('Wait')+'<br/>';
        document.getElementById('buttonState_2').style.display = 'block';
        document.getElementById('buttonState_2').innerHTML = t('Next_Round');
        document.getElementById('betButton').style.pointerEvents = 'none';
      }
      for(var i = 0; i < elms.length; i++) {
        elms[i].style.pointerEvents='none';
      }
    } else if (data.gamestate == 3 && !this.state.gameEnd) {
      this.showResult(-0.00);
      document.getElementById('buttonState').innerHTML = t('Next');
      document.getElementById('buttonState_2').style.display = 'none';
      document.getElementById('betButton').style.pointerEvents = 'none';
      for(var i = 0; i < elms.length; i++) {
        elms[i].style.pointerEvents='none';
      }
      if (!this.state.playerCrashed && this.state.playerBetted) {
        let crashedData = {
          token: token,
          multipier: 0.00,
          periodNo: this.state.gamePeriodNo,
          amount: store.getState().memberInfo.MemberInfo.balanceMoney,
          betAmount:this.state.amount,
        };
        // console.log("crashed: " + JSON.stringify(crashedData));
        socket.emit("crashed", crashedData, (response) => {
          if (response.status == 1) {
            this.setState({
              playerBetted: false,
            })
            // this.TodayHistoryResult();
          }
        })
      }
      this.setState({
        playerList: [],
        gameEnd:true,
        // firstTimeComeStartAlready:false
      })
    }
    if (data.gamestate == 1) {
      document.getElementById('game-result').innerHTML = "";
      document.getElementById('div-loader').style.display = 'block';
      document.getElementById('buttonState').innerHTML = '';
      document.getElementById('buttonState_2').style.display = 'none';
    } else {
      document.getElementById('div-loader').style.display = 'none';
    }
    if (this.state.playerBetted) {
      for(var i = 0; i < elms.length; i++) {
        elms[i].style.pointerEvents='none';
      }
    }else{
      for(var i = 0; i < elms.length; i++) {
        elms[i].style.pointerEvents='auto';
      }
    }
  }

  updateBetStatus(data) {
    if (data.status == 1) {
      this.setState({
        playerBetted: true,
        gamePeriodNo: data.periodNo,
        playerCrashed: false,
      }, () => getMemberBalance());
    }
  }

  updatePlayer(data) {
    if (data.status == 1) {
      this.setState({
        playerBetted: false,
        playerCrashed: true,
      }, () => getMemberBalance());
    }
  }

  playerCashOut(data) {
    data.users.forEach((item) => {
      if (item.cashouted && item.betted) {
        var element = document.createElement("div");
        element.className = "player_cashout";
        element.appendChild(document.createTextNode(item.name + " " + item.multipier));
 //       element.style.webkitTransform = `translateX(${this.state.path.x}px) translateY(${this.state.path.y}px)`;
        document.getElementById('player_name_cashout').appendChild(element);
      }
    })
    // this.TodayHistoryResult();
  }

  add() {
    let crashTarget = parseFloat(this.state.crashTarget) + 1;
    if (crashTarget >= 10) {
      crashTarget = 10.00;
    }
    this.setState({
      crashTarget: crashTarget.toFixed(2),
    })
  }

  sub() {
    let crashTarget = parseFloat(this.state.crashTarget) - 1;
    if (crashTarget <= 1) {
      crashTarget = 1.10;
    }
    this.setState({
      crashTarget: crashTarget.toFixed(2),
    })
  }

  maxBet() {
    let balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney;
    let maxBetAmt = this.state.maxBetAmount;
    if (balanceMoney <= maxBetAmt) {
      balanceMoney = balanceMoney;
    } else {
      balanceMoney = maxBetAmt;
    }
    this.setState({amount:Number(balanceMoney).toFixed(2)})
  }

  minBet() {
    this.setState({amount: this.state.minBetAmount})
  }

  doubleBet() {
    let doubleAmount = this.state.amount * 2;
    let balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney;
    let maxBetAmt = this.state.maxBetAmount;
    if (doubleAmount <= maxBetAmt) {
      if (doubleAmount > balanceMoney) {
        doubleAmount = balanceMoney;
      }
    } else {
      doubleAmount = maxBetAmt;
    }
    this.setState({amount:Number(doubleAmount).toFixed(2)})
  }

  halfBet() {
    let halfAmount = this.state.amount / 2;
    let minBetAmt = this.state.minBetAmount;
    if(minBetAmt > halfAmount){
      halfAmount = minBetAmt;
    }
    this.setState({amount:Number(halfAmount).toFixed(2)})
  }

  errorMsg(msg) {
    const { t } = this.props;
    if(msg.indexOf("facade_Max_balance")>-1 || msg.indexOf("facade_Min_balance")>-1){
      msg = msg.split("=");
      msg = t(msg[0])+(msg[1]!=undefined?msg[1]:"");
    }
    document.getElementById('game-result').innerHTML = t(msg);
  }

  thisGameUserList(userList){
    var gameUserList = this.state.playerList;
    Object.keys(userList).forEach(key => {
        if(userList[key].betted){
          gameUserList.push({"player":userList[key].name,"mul":userList[key].multipier,"betAmount":userList[key].betAmount});
      }
     });
    this.setState({
      playerList:gameUserList,
    })
  }
  updateGameUserList(userList){
    let gameUserList = this.state.playerList;
    for(let u=0; userList.length>u; u++){
      if(userList[u].cashouted){
        gameUserList.forEach((result)=>{
          if(result.token == userList[u].token){
            result.multipier = userList[u].multipier;
          }
        })
      }else if(userList[u].betted){
        gameUserList.push(userList[u]);
      }
    }
    this.setState({
      playerList:gameUserList,
    })
  }

  handleBetAmt = (e) => {
    const regex = /^(\d*\.{0,1}\d{0,2}$)/;
    if(regex.test(e.target.value)) {
      this.setState({amount:e.target.value});
    }
  };

  handleTarget = (e) => {
    const regex = /^(\d*\.{0,1}\d{0,2}$)/;
    if(regex.test(e.target.value)) {
      this.setState({crashTarget:e.target.value});
    }
  };

  // handleChange = (e) => {
  //   const regex = /^.+[0-9\b]+$/;
  //   let number  = 0;
  //   if (e.target.value == ""){
  //     number =0.00;
  //   }else if(regex.test(e.target.value)) {
  //     number = e.target.value;
  //   }
  //   this.setState({crashTarget:number})
  // };

  render() {
    const { t } = this.props;
    const balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney != null ? store.getState().memberInfo.MemberInfo.balanceMoney : 0.00;
    return (
        <div style={{maxWidth:600}} className={isMobile?'':'crash-web'}>
        <div className="crash-top-panel" style={{ backgroundColor: '#111923' }}>
          <GameMoney gameName={"crash"}/>
        </div>
        <div style={{ backgroundColor: '#111923', height: '90vh', overflow: 'scroll' }}>
          <div className="crash-bg">
            <div id="crash_stars">
              <div className="crash_star"></div>
            </div>
            <div className="rules-method-container">
              {this.state.gameHistory ?
                this.state.gameHistory.map((item, index) => {
                  return (
                    <div key={index} className={'rules-method-header history-background-' + index}>{parseFloat(item).toFixed(2)}x</div>
                  )
                })
                : null
              }
            </div>
              <span style={{display:this.state.firstTimeComeStartAlready?'block':'none'}} className="nextGameNotice">{t('Next_Round')}</span>
            <div style={{display:this.state.firstTimeComeStartAlready?'none':'block', height:isMobile?'400px':'250px'}}>
              <div className="limbo auto">
              <div className="notranslate limbo-rocket">
                <div id="rocket-number" className="rocket-number">
                  <span>{this.state.crashResult.toFixed(2)}</span>
                  <span className="rocket-number-x">×</span>
                  <div><span style={{fontSize: '2.125rem'}} id="game-result"></span></div>
                </div>
                <div id="game-state" className="rocket-number">
                </div>
              </div>
            </div>
            <div id="rocket-boom" className="rocket-boom"></div>
            <div id="player_name_cashout">
              {/* <span className="player_cashout">123456</span> */}
            </div>
            <div className="canvas-crash">
              <svg className="crash-game-body">
                {/*<path id="Path" d="M 40 300 C 350,300 350,330 420,150" fill="none" stroke="none" />*/}
                {/* <circle id="circle" cx="0" cy="0" r="10" fill="red" /> */}
              </svg>
              <div id="circle" className="crash-rocket">
                <div id="crash-rocket" className="crash-rocket-div">
                  <div id="fire" className="rocket-fire"></div>
                  <img src={require(`../assets/images/crash/crash_rocket_new.png`)} className="crash-rocket" style={{ width: 90, height: 60 }} />
                </div>
              </div>
            </div>
           </div>
          </div>
          <div className="crash-buttons">
            <div className="crash-body-bet-amount">
              <div className="amount_left">
                <div id="crash-button" className="crash-button" onClick={() => this.minBet()}>
                  <span>{t('dice_min')}</span>
                </div>
                <div id="crash-button" className="crash-button" onClick={() => this.maxBet()}>
                  <span>{t('dice_max')}</span>
                </div>
              </div>
              <div className="amount_center" style={{ width: '100%' }}>
                <div className="crash-bet-amount">
                  {/* <span>{this.state.amount.toFixed(2)}</span> */}
                  <TextField sx={{ input: { color: 'white', textAlign: 'center' } }}
                    type='text'
                    InputProps={{ disableUnderline: true }}
                    autoComplete="off"
                    onChange={(event) => this.handleBetAmt(event)}
                    hiddenLabel
                    variant="standard"
                    value={this.state.amount} />
                </div>
                {/* <div style={{fontSize:17,color:'white',textAlign:'center',marginBottom:18}}>
            <span>{t('Play Amount')}</span>
          </div> */}
              </div>
              <div className="amount_right">
                <div id="crash-button" className="crash-button" onClick={() => this.halfBet()}>
                  <span>1/2</span>
                </div>
                <div id="crash-button" className="crash-button" onClick={() => this.doubleBet()}>
                  <span>2X</span>
                </div>
              </div>
            </div>
            <div id="betButton" className="crash-button-red" onClick={(event) => this.startGame(event)}>
              <div className="div-loader" id="div-loader">
                <div className="loader"></div>
              </div>
              <span className='buttonState' id="buttonState"></span>
              <span className='buttonState_2' id="buttonState_2">{t('Next Round')}</span>
            </div>
            <div className="crash-body-bet-target">
              <div className="crash-bet-auto">
                <div style={{ width: '100%' ,marginTop:'3%'}}>
                  <div id="crash-button" className="crash-button" onClick={() => this.add()}>
                    <span>+</span>
                  </div>
                </div>
                <div className="crash-bet-amount-body">
                  <div className="crash-bet-amount">
                  <TextField sx={{input:{color:'white', textAlign:'center',marginTop:'10%'}}}
                    type='text'
                    InputProps={{disableUnderline: true}} 
                    autoComplete="off"
                    onChange={(event) => this.handleTarget(event)}
                    hiddenLabel 
                    variant="standard" 
                    value={this.state.crashTarget} />
                    {/* <span className="crash-bet-target_value">{this.state.crashTarget.toFixed(2)}</span> */}
                  </div>
                </div>
                <div style={{ width: '100%' ,marginTop:'3%'}}>
                  <div id="crash-button" className="crash-button" onClick={() => this.sub()}>
                    <span>-</span>
                  </div>
                </div>
              </div>
              <div className="crash-bet-auto">
                <span className="auto-stop__label">{t('CASHOUT AT')}</span>
              </div>
            </div>
          </div>
          <div className="crash-bottom-panel">
        <div className="stat_wrapper">
          <div id="stat_inner" className="stat_inner">
          <div className="row-header">
            <div className="td" style={{flex: '3 3'}}>
                <div className="period">
                  <span>{t('User')}</span>
                </div>
            </div>
            <div className="td">
                <div className="cashout">
                  <span>{t('Multipier')}</span>
                </div>
            </div>
            <div className="td">
                <div className="bet">
                  <span>{t('Bet')}</span>
                </div>
            </div>
          </div>
          {this.state.playerList.length>0?
            this.state.playerList.map((item,index)=> {
              return (
                <div className="row">
                  <div className="td" style={{flex: '3 3'}}>
                    <div className="period">
                    {item.name}
                    </div>
                  </div>
                <div className="td">
                  <div className="cashout">
                  {item.multipier}
                  </div>
                </div>
                <div className="td">
                  <div className="bet">
                  {item.betAmount}
                  </div>
                </div>
              </div>
                )
              })
            :null
            }
          </div>
        </div>
        </div>
        </div>
      </div>);
  }
}

const ConnectedCrashPage = connect(mapStateToProps)(CrashPage);
export default withTranslation('common')(ConnectedCrashPage);

