import React from "react";
import {useTranslation, withTranslation} from 'react-i18next';

import TimesTable from "../../components/keno/TimesTable";

function GameTimesTable(props) {
    const {t} = useTranslation();

    function getTimesArray(num) {
        let timesArrDefault;
        switch (num) {
            case 2:
                timesArrDefault = [{idx:1,times:1},{idx:2,times:9},{idx:3,times:0},{idx:4,times:0},{idx:5,times:0}
                    ,{idx:6,times:0},{idx:7,times:0},{idx:8,times:0},{idx:9,times:0},{idx:10,times:0}];
                break;
            case 3:
                timesArrDefault = [{idx:1,times:1},{idx:2,times:2},{idx:3,times:47},{idx:4,times:0},{idx:5,times:0}
                    ,{idx:6,times:0},{idx:7,times:0},{idx:8,times:0},{idx:9,times:0},{idx:10,times:0}];
                break;
            case 4:
                timesArrDefault = [{idx:1,times:1},{idx:2,times:2},{idx:3,times:5},{idx:4,times:91},{idx:5,times:0}
                    ,{idx:6,times:0},{idx:7,times:0},{idx:8,times:0},{idx:9,times:0},{idx:10,times:0}];
                break;
            case 5:
                timesArrDefault = [{idx:1,times:0},{idx:2,times:1},{idx:3,times:3},{idx:4,times:12},{idx:5,times:720}
                    ,{idx:6,times:0},{idx:7,times:0},{idx:8,times:0},{idx:9,times:0},{idx:10,times:0}];
                break;
            case 6:
                timesArrDefault = [{idx:1,times:0},{idx:2,times:1},{idx:3,times:3},{idx:4,times:4},{idx:5,times:70}
                    ,{idx:6,times:1300},{idx:7,times:0},{idx:8,times:0},{idx:9,times:0},{idx:10,times:0}];
                break;
            case 7:
                timesArrDefault = [{idx:1,times:0},{idx:2,times:0},{idx:3,times:1},{idx:4,times:2},{idx:5,times:21}
                    ,{idx:6,times:400},{idx:7,times:5000},{idx:8,times:0},{idx:9,times:0},{idx:10,times:0}];
                break;
            case 8:
                timesArrDefault = [{idx:1,times:0},{idx:2,times:0},{idx:3,times:1},{idx:4,times:2},{idx:5,times:12}
                    ,{idx:6,times:90},{idx:7,times:1200},{idx:8,times:7000},{idx:9,times:0},{idx:10,times:0}];
                break;
            case 9:
                timesArrDefault = [{idx:1,times:0},{idx:2,times:0},{idx:3,times:1},{idx:4,times:1},{idx:5,times:6}
                    ,{idx:6,times:44},{idx:7,times:200},{idx:8,times:3500},{idx:9,times:7000},{idx:10,times:0}];
                break;
            case 10:
                timesArrDefault = [{idx:1,times:0},{idx:2,times:0},{idx:3,times:1},{idx:4,times:1},{idx:5,times:5}
                    ,{idx:6,times:24},{idx:7,times:120},{idx:8,times:800},{idx:9,times:2500},{idx:10,times:7000}];
                break;
            default:
                timesArrDefault = [{idx:1,times:0},{idx:2,times:0},{idx:3,times:0},{idx:4,times:0},{idx:5,times:0}
                    ,{idx:6,times:0},{idx:7,times:0},{idx:8,times:0},{idx:9,times:0},{idx:10,times:0}];
        }
        return timesArrDefault;
    }

    function renderTable() {
        return getTimesArray(props.selectedNum).map((ing) => {
                return (
                    <div key={ing.idx} className="box">
                        <div className="column left">{ing.idx}</div>
                        <div className="column right">{ing.times}</div>
                    </div>
                );
        });
    }

    return <TimesTable.Container>{renderTable()}</TimesTable.Container>;
}

export default withTranslation('common')(GameTimesTable);
