import _ from "lodash";

const helpers = {
  stringSplit: function(str, delimiter) {
    const arr = str.split(delimiter);
    return arr;
  },

  capitalizeOnlyFirst: function(str) {
    return str && str[0].toUpperCase() + str.slice(1);
  },

  groupBy: function(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  },

  groupByReduce: function(lists, key) {
    return lists.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
      );
      return result;
    }, {});
  },

  padDigits: function(number, digits) {
    return Array(Math.max(digits - String(number).length + 1, 0)).join("0") + number;
  },

  randomNumber: function(min, max) {
    return Math.floor(Math.random() * max + min);
  },

  randomizeNumber: function(num, arr) {
    let currentItems = [];
    let shuffledIntegers = _.shuffle(arr);
    let numbers = _.take(shuffledIntegers, num);

    for (let i = 0; i < numbers.length; i++) {
      currentItems.push(shuffledIntegers[i]);
    }
    return currentItems;
  },

  randomizeOrder: function(num, arr) {
    let currentOrders = [];
    let shuffledIntegers = _.shuffle(arr);
    let orders = _.take(shuffledIntegers, num);

    for (let i = 0; i < orders.length; i++) {
      let newOrder = {
        id: i,
        name: shuffledIntegers[i],
        flipped: false
      };
      currentOrders.push(newOrder);
    }
    return currentOrders;
  },

  setNumberOfOrders: function(time) {
    if (time >= 40) {
      return 2;
    } else if (time >= 20 && time < 40) {
      return 4;
    } else return 6;
  },

  itemOrder: function(num, arr) {
    let currentOrders = [];
    for (let i = 0; i < arr.length; i++) {
      let newOrder = {
        id: i,
        name: arr[i],
        flipped: false
      };
      currentOrders.push(newOrder);
    }
    return currentOrders;
  },

  itemColorBall: function(arr) {
    let currentColorBall = [];
    for (let i = 0; i < arr.length; i++) {
      let newBall = {
        id: i,
        num: arr[i]
      };
      currentColorBall.push(newBall);
    }
    return currentColorBall;
  },

  filterItems: function(num, arr){
    let finalResult = arr.filter(function(item) {
      return item !== num;
    });
    return finalResult;
  }
};

export default helpers;
