import React from "react";
import {useTranslation, withTranslation} from 'react-i18next';

import Start from "../../components/keno/Start";
import Button from "../../components/default/Button";
import {motion} from "framer-motion";

function GameButtons(props) {
    const {t} = useTranslation();

    const container = {
        hidden: { rotate: 90 },
        show: {
            rotate: 0,
            transition: {
                staggerChildren: 0.1,
                delayChildren: 0.3
            }
        }
    };

    const itemButton = {
        hidden: { scale: 0, top: 100 },
        show: { scale: 1, top: 30 }
    };

    function handleOnClear() {
        props.onClear();
    }

    function handleOnQuick() {
        props.onQuick();
    }


    function handleOnClick() {
        props.onClick();
    }

    function handleOnAutoBet() {
        props.onAutoBet();
    }

    return (
        <>
            <motion.div
                style={{
                    borderRadius: 30,
                    backgroundColor: "inherit",
                    position: "relative"
                }}
                variants={container}
                initial="hidden"
                animate="show"
            >
                <Start.Container>
                    <motion.div variants={itemButton}>
                        <Button pink black br15 lSize onClick={handleOnQuick} disabled={props.autoBet?true:props.isLock}>
                            {t("keno_quick_pick_lc")}
                        </Button>
                    </motion.div>
                    <motion.div variants={itemButton}>
                        <Button white black br15 lSize onClick={handleOnClear} disabled={props.autoBet?true:props.isLock}>
                            {t("keno_clear")}
                        </Button>
                    </motion.div>
                    <motion.div variants={itemButton}>
                        <Button yellow black br15 lSize onClick={handleOnClick} disabled={props.autoBet?true:props.isLock}>
                            {t("keno_start")}
                        </Button>
                    </motion.div>
                </Start.Container>
                <Start.Container>
                    <motion.div variants={itemButton}>
                        <Button yellow black br15 lSize onClick={handleOnAutoBet}>
                            {props.autoBet?t('stop_bets'):t('auto_bets')}
                        </Button>
                    </motion.div>
                </Start.Container>
            </motion.div>
        </>
    );
}

export default withTranslation('common')(GameButtons);
