import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import io from 'socket.io-client';
import { store } from '../index';
import '../styles/_diamond.scss';
import GameMoney from "../containers/GameMoney";
import styled from "styled-components";
import Container from 'react-bootstrap/Container';
import Button from "../components/default/Button";
import { TextField } from '@mui/material';
import {getMemberBalance} from "../services/HomeServices";
import {betAmtConstants} from "../utils/utilConstants";
import background from '../assets/images/diamond/background.jpg';
import max from '../assets/images/diamond/max.png';
import min from '../assets/images/diamond/min.png';
import minus from '../assets/images/diamond/minus.png';
import plus from '../assets/images/diamond/plus.png';
import logo from '../assets/images/diamond/logo.png';

const GameMainContainer = styled.div`
  background-image: red;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: scroll;
  background-image: url(${background});
  background-size: contain;
`;

const InnerContainer = styled.div`
    display: flex;
    justify-content: center;
    color: white;
`;

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    memberInfo: state.memberInfo,
  };
};

const x50 = [0, 0, 0, 0, 0];
const x5 = [0, 0, 0, 0, 9];
const x4 = [0, 0, 0, 1, 1];
const x3 = [0, 0, 0, 9, 9];
const x2 = [0, 0, 1, 1, 9];
const x1 = [1, 1, 9, 9, 9];
const x0 = [9, 9, 9, 9, 9];

// const socket = io('http://localhost:1108/',{
//   transports: ["websocket"],
// });

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

let socket;
if (global.config.testEnv === true) {
  socket = io(global.config.minigame.socket.protocol + '://' + global.config.domain.instantaward + ':' + global.config.minigame.socket.port.diamond + '/', {
    transports: ["websocket"],
  });
} else {
  socket = io(global.config.minigame.socket.protocol + '://' + global.config.minigame.socket.domain.diamond + '/', {
    transports: ["websocket"],
  });
}

class DiamondPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startGame: true,
      gameResult: [],
      gameInterval: null,
      amount: Number(10.00).toFixed(2),
      minBetAmount:Number(betAmtConstants.minAmt).toFixed(2),
      maxBetAmount:Number(betAmtConstants.maxAmt).toFixed(2),
      autobet: false,
      showingResult:false,
      islock:false,
      autoPlay:false,
      arr:[9,9,9,9,9],
      first:true,
    };
  }

  componentDidMount() {
    socket.extraHeaders = { gametoken: store.getState().memberInfo.token };
    socket.on('disconnect', () => {
      // console.log("socket on disconnect event listener");
    });

    socket.on('error message', (res) => {
      // console.log("SOCKET ERROR MSG : " + JSON.stringify(res));
      this.errorMsg(res.errMessage);
      this.setState({
        autoBet:false,
        autoPlay:false
      });
      if (res.errMessage === "invalid_token_id") {
        window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
      }else if(res.errMessage.startsWith("facade_Max_balance")){
        // setErrorMessage(t('facade_Max_balance'));
        // setIsLock(false);
      }else if(res.errMessage.startsWith("facade_Min_balance")){
        // setErrorMessage(t('facade_Min_balance'));
        // setIsLock(false);
      }
    });

    socket.on('connect', (e) => {
      // console.log("render once socket connected testing");
      // console.log("socket on connect event listener");
      // console.log("web socket id : " + socket.id);
    });

    socket.on('connect_error', (e) => {
      // console.log("render once socket connected testing");
      // console.log("socket on connect error event listener");
      // console.log("web socket connect error : " + e);
    });

    socket.on('data', (data) => {
      // console.log("render once socket connected testing");
      // console.log("socket on data event listener");
      // console.log("web socket data : " + data);

    });

    socket.io.on("error", (error) => {
      // console.log("socket error event listener");
      // console.log("web socket error : " + error);
    });

    socket.on('game result', (data) => {
      // console.log("socket on bet result event listener");
      // console.log("web socket data : " + JSON.stringify(data));
      let gameInterval = setInterval(() => {
        this.getResult(data);
      }, 550);
      this.setState({ gameInterval: gameInterval,first:false },()=>getMemberBalance());
      if(this.state.autobet){
        clearInterval(this.state.gameInterval);
        setTimeout(()=>this.autoPlay(),4500);
      }
      // else{
      //   this.setState({ islock: false });
      // }
    });

    this.getQuotaReturn("diamond");

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('error message');
      socket.off('game result');
    };
  }

  getQuotaReturn(gameId) {
    let memberInfo = store.getState().memberInfo;
    const data = new URLSearchParams();
    data.set('gameId', gameId);
    fetch(global.config.domain.member + "/api/loadQuota", {
      method: "Post",
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'gametoken': memberInfo.token,
      }),
      body: data,
      credentials: 'include'
    })
        .then((res) => res.json())
        .then((res) => {
          if(res !== undefined) {
            this.setState({
              amount: res.minBetAmount !== null ? Number(res.minBetAmount).toFixed(2) : Number(betAmtConstants.minAmt).toFixed(2),
              minBetAmount: res.minBetAmount !== null ? Number(res.minBetAmount).toFixed(2) : Number(betAmtConstants.minAmt).toFixed(2),
              maxBetAmount: res.maxBetAmount !== null ? Number(res.maxBetAmount).toFixed(2) : Number(betAmtConstants.maxAmt).toFixed(2),
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  startGame(start,auto) {
    const { t } = this.props;
    let balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney;
    if(Number(this.state.amount) < Number(this.state.minBetAmount)) {
      document.getElementById('game-result').innerHTML = t('facade_Min_balance') + Number(this.state.minBetAmount).toFixed(2);
    }else if(Number(balanceMoney) < Number(this.state.amount)){
      this.setState({ autobet: false });
      document.getElementById('game-result').innerHTML = t("facade_insufficient_balance");
    }else {
      if (this.state.gameResult.length == 5) {
        this.setState({ gameResult: [],arr:[9,9,9,9,9] });
        this.resetAward();
      }
      if (document.getElementsByClassName('diamond-rate-colWord active').length > 0) {
        document.getElementsByClassName('diamond-rate-colWord active')[0].classList.remove('active');
      }
      if(!auto){
      document.getElementsByClassName('diammond-play-button play')[0].classList.add('active');
      document.getElementsByClassName('diammond-play-button autoPlay')[0].classList.add('active');
    }
      document.getElementById('game-result').innerHTML = "";
      let memberInfo = store.getState().memberInfo;

      let token = memberInfo.token;
      let loginName = memberInfo.MemberInfo.loginName;

      // console.log("diamond get token: " + token);

      let jsonData = {
        token: token,
        betAmount: this.state.amount,
        amount: memberInfo.MemberInfo.balanceMoney,
        username: loginName,
        nonce: memberInfo.MemberInfo.nonce,
        clientSeed: memberInfo.MemberInfo.clientSeed,
      };

      // console.log("jsonData: " + JSON.stringify(jsonData));

      socket.emit("bet info", jsonData);
      var elms = document.querySelectorAll("[id='diamond-button']");
      for(var i = 0; i < elms.length; i++) {
        elms[i].style.pointerEvents='none';
      }
      this.setState({ islock: true });
    }
  }

  autoBet(play){
    var play = !this.state.autobet;
    const setautoPlayBlock = async () => {
      await delay(3500);
      this.setState({ autoPlay: false });
    };
    if(play){
      if((this.state.gameResult.length==0 || this.state.gameResult.length==5) && !this.state.autoPlay){
        this.setState({ islock: true, autobet: play, autoPlay:true });
        this.startGame(true,true);
        document.getElementsByClassName('diammond-play-button play')[0].classList.add('active');
      }
    }else{
      this.setState({autobet: play });
      setautoPlayBlock();
      document.getElementsByClassName('diammond-play-button play active')[0].classList.remove('active');
    }
  }

  autoPlay(){
    if(this.state.autobet){
      this.setState({ autoPlay: true });
      clearInterval(this.state.gameInterval);
      setTimeout(()=>this.startGame(true,true),500);
    }
  }

  getResult(data) {
    const { t } = this.props;
    let gameResult = this.state.gameResult;
    if (gameResult.length == 5) {
      let odds = data.amountCross;
      let classOdds = odds;
      if (odds == 0.5) {
        classOdds = 1;
      }
      var element = document.getElementById('diamond-rate-colWord' + classOdds);
      element.classList.add("active");
      this.displayAward(classOdds,gameResult);
      let totalAmount = parseFloat(odds) * this.state.amount;
      if(odds == 0){
        document.getElementById('game-result').innerHTML = t("dice_lose");
      }else{
        document.getElementById('game-result').innerHTML = t("diamond_win") + totalAmount.toFixed(2);
      }
      clearInterval(this.state.gameInterval);
      setTimeout(()=>this.openButton(),800);
    } else {
    var showLightarray = [];
    for (let i = 0; i < data.randomArray.length; i++) {
      for (let j = i + 1; j < data.randomArray.length; j++) {
        if (data.randomArray[i] === data.randomArray[j] && !showLightarray.includes(data.randomArray[i])) {
          showLightarray.push(data.randomArray[i]);
        }
      }
    }
      var showLight = showLightarray.includes(data.randomArray[gameResult.length]);
      if(showLight){
        this.state.arr[gameResult.length] = data.randomArray[gameResult.length];
      }
      gameResult.push({number:data.randomArray[gameResult.length],showLight:showLight,showBase:showLight});
    }
    this.setState({ gameResult: gameResult });
  }

  maxBet() {
    if(!this.state.islock && !this.state.autobet){
      let balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney;
      let maxBetAmt = this.state.maxBetAmount;
      if (balanceMoney <= maxBetAmt) {
        balanceMoney = balanceMoney;
      } else {
        balanceMoney = maxBetAmt;
      }
      this.setState({amount:Number(balanceMoney).toFixed(2)})
    }
  }

  minBet() {
    if(!this.state.islock && !this.state.autobet){
      this.setState({amount: this.state.minBetAmount})
    }
  }

  doubleBet() {
    if(!this.state.islock && !this.state.autobet){
      let doubleAmount = this.state.amount * 2;
      let balanceMoney = store.getState().memberInfo.MemberInfo.balanceMoney;
      let maxBetAmt = this.state.maxBetAmount;
      if (doubleAmount <= maxBetAmt) {
        if (doubleAmount > balanceMoney) {
          doubleAmount = balanceMoney;
        }
      } else {
        doubleAmount = maxBetAmt;
      }
      this.setState({amount:Number(doubleAmount).toFixed(2)})
    }
  }

  halfBet() {
    if(!this.state.islock && !this.state.autobet){
      let halfAmount = this.state.amount / 2;
      let minBetAmt = this.state.minBetAmount;
      if(minBetAmt > halfAmount){
        halfAmount = minBetAmt;
      }
      this.setState({amount:Number(halfAmount).toFixed(2)})
    }
  }

  errorMsg(msg) {
    const { t } = this.props;
    this.setState({ autobet: false,autoPlay:false,islock:false });
    if(msg.indexOf("facade_Max_balance")>-1 || msg.indexOf("facade_Min_balance")>-1){
      msg = msg.split("=");
      msg = t(msg[0])+(msg[1]!=undefined?msg[1]:"");
    }
    document.getElementById('game-result').innerHTML = t(msg);
    this.openButton();
  }
  
  openButton(){
    var elms = document.querySelectorAll("[id='diamond-button']");
    for(var i = 0; i < elms.length; i++) {
      elms[i].style.pointerEvents='auto';
    }

    var elms2 = document.querySelectorAll("[id='diamond-button-auto']");
    for(var i = 0; i < elms2.length; i++) {
      elms2[i].style.pointerEvents='auto';
    }
    if(!this.state.autobet){
      if(document.getElementsByClassName('diammond-play-button play active').length>0){
        document.getElementsByClassName('diammond-play-button play active')[0].classList.remove('active');}
      if(document.getElementsByClassName('diammond-play-button autoPlay active').length>0){
        document.getElementsByClassName('diammond-play-button autoPlay active')[0].classList.remove('active');}
    }
    console.log('openButton this.state.autobet:'+this.state.autobet);
    this.setState({islock: this.state.autobet});
  }

  handleBetAmt = (e) => {
    const regex = /^(\d*\.{0,1}\d{0,2}$)/;
    if(regex.test(e.target.value)) {
      this.setState({amount:e.target.value});
    }
  };

  displayAward(award,gameResult){
    var elementAward = "";
    var elementAward2 = "";
    var testarray = [];
    for (let i = 0; i < gameResult.length; i++) {
      for (let j = i + 1; j < gameResult.length; j++) {
        if (gameResult[i].number === gameResult[j].number && !testarray.includes(gameResult[i].number)) {
          testarray.push(gameResult[i].number);
        }
      }
    }
    if(award==1){
      elementAward = document.querySelectorAll("[id='row1_first']");
      for(var a=0;elementAward.length>a;a++){
        elementAward[a].style.backgroundColor = this.getColor(testarray[0]);
      }
    }else if(award==2){
      elementAward = document.querySelectorAll("[id='row2_first']");
      elementAward2 = document.querySelectorAll("[id='row2_second']");
      for(var a=0;elementAward.length>a;a++){
        elementAward[a].style.backgroundColor = this.getColor(testarray[0]);
      }
      for(var a=0;elementAward2.length>a;a++){
        elementAward2[a].style.backgroundColor = this.getColor(testarray[1]);
      }
    }else if(award==3){
      elementAward = document.querySelectorAll("[id='row3_first']");
      for(var a=0;elementAward.length>a;a++){
        elementAward[a].style.backgroundColor = this.getColor(testarray[0]);
      }
    }else if(award==4){
      elementAward = document.querySelectorAll("[id='row4_first']");
      elementAward2 = document.querySelectorAll("[id='row4_second']");
      for(var a=0;elementAward.length>a;a++){
        elementAward[a].style.backgroundColor = this.getColor(testarray[0]);
      }
      for(var a=0;elementAward2.length>a;a++){
        elementAward2[a].style.backgroundColor = this.getColor(testarray[1]);
      }
    }else if(award==5){
      elementAward = document.querySelectorAll("[id='row5_first']");
      for(var a=0;elementAward.length>a;a++){
        elementAward[a].style.backgroundColor = this.getColor(testarray[0]);
      }
    }else if(award==50){
      elementAward = document.querySelectorAll("[id='row50_first']");
      for(var a=0;elementAward.length>a;a++){
        elementAward[a].style.backgroundColor = this.getColor(testarray[0]);
      }
    }
  }

  getColor(color){
    switch (color) {
      case 0:
          color = '#FFE250';
          break;
      case 1:
          color = '#FF2D00';
          break;
      case 2:
          color = '#9B00CC';
          break;
      case 3:
          color = '#0010FF';
          break;
      case 4:
          color = '#F15A24';
          break;
      case 5:
          color = '#ED1E79';
          break;
      case 6:
          color = '#11FB50';
          break;
      default:
          color = '#808ea8';
  }
  return color;
  }

  resetAward(){
    var awardClearFirst = document.querySelectorAll("[id='row1_first'], [id='row2_first'], [id='row3_first'], [id='row4_first'], [id='row5_first'], [id='row50_first']");
    for(var a=0;awardClearFirst.length>a;a++){
      awardClearFirst[a].style.backgroundColor = '#808ea8';
    }
    var awardClearSecond = document.querySelectorAll("[id='row4_second'], [id='row2_second']");
    for(var a=0;awardClearSecond.length>a;a++){
      awardClearSecond[a].style.backgroundColor = '#3c4a64';
    }
  }
  
  render() {
    const { t } = this.props;
    const paddingWidth = window.innerWidth >= 360?'0 3%':'0 2%';
    // let arr = Array.apply(null, {length: 5}).map(Number.call, Number);
    return (
      <GameMainContainer>
        <InnerContainer className={"content-container-inner"}>
          <Container fluid className={"content-container"}>
            <GameMoney gameName={"diamond"} />
            <div><br></br></div>
            <div className={`diamond-rate`+ (this.state.first?" active":"")}>
              <Row id="row50" className="diamond-rate-row">
                <Col xs={9} className="diamond-rate-colDiamond">
                  <div className="coefficient-item">
                      <div className="dots">
                        <div className="first" id="row50_first"></div>
                        <div className="first" id="row50_first"></div>
                        <div className="first" id="row50_first"></div>
                        <div className="first" id="row50_first"></div>
                        <div className="first" id="row50_first"></div>
                      </div>
                  </div>
                </Col>
                <Col className="diamond-rate-colWord" id="diamond-rate-colWord50">50.00X</Col>
              </Row>
              <Row id="row5" className="diamond-rate-row">
                <Col xs={9} className="diamond-rate-colDiamond">
                  <div className="coefficient-item">
                      <div className="dots">
                        <div className="first" id="row5_first"></div>
                        <div className="first" id="row5_first"></div>
                        <div className="first" id="row5_first"></div>
                        <div className="first" id="row5_first"></div>
                        <div></div>
                      </div>
                  </div>
                </Col>
                <Col className="diamond-rate-colWord" id="diamond-rate-colWord5">5.00X</Col>
              </Row>
              <Row id="row4" className="diamond-rate-row">
                <Col xs={9} className="diamond-rate-colDiamond">
                  <div className="coefficient-item">
                      <div className="dots">
                        <div className="first" id="row4_first"></div>
                        <div className="first" id="row4_first"></div>
                        <div className="first" id="row4_first"></div>
                        <div className="second" id="row4_second"></div>
                        <div className="second" id="row4_second"></div>
                      </div>
                  </div>
                </Col>
                <Col className="diamond-rate-colWord" id="diamond-rate-colWord4">4.00X</Col>
              </Row>
              <Row id="row3" className="diamond-rate-row">
                <Col xs={9} className="diamond-rate-colDiamond">
                  <div className="coefficient-item">
                      <div className="dots">
                        <div className="first" id="row3_first"></div>
                        <div className="first" id="row3_first"></div>
                        <div className="first" id="row3_first"></div>
                        <div></div>
                        <div></div>
                      </div>
                  </div>
                </Col>
                <Col className="diamond-rate-colWord" id="diamond-rate-colWord3">3.00X</Col>
              </Row>
              <Row id="row2" className="diamond-rate-row">
                <Col xs={9} className="diamond-rate-colDiamond">
                  <div className="coefficient-item">
                      <div className="dots">
                        <div className="first" id="row2_first"></div>
                        <div className="first" id="row2_first"></div>
                        <div className="second" id="row2_second"></div>
                        <div className="second" id="row2_second"></div>
                        <div></div>
                      </div>
                  </div>
                </Col>
                <Col className="diamond-rate-colWord" id="diamond-rate-colWord2">2.00X</Col>
              </Row>
              <Row id="row1" className="diamond-rate-row">
                <Col xs={9} className="diamond-rate-colDiamond">
                  <div className="coefficient-item">
                      <div className="dots">
                        <div className="first" id="row1_first"></div>
                        <div className="first" id="row1_first"></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                  </div>
                </Col>
                <Col className="diamond-rate-colWord" id="diamond-rate-colWord1">0.50X</Col>
              </Row>
              <Row id="row0" className="diamond-rate-row">
                <Col xs={9} className="diamond-rate-colDiamond">
                  <div className="coefficient-item">
                      <div className="dots">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                  </div>
                </Col>
                <Col className="diamond-rate-colWord" id="diamond-rate-colWord0">0.00x</Col>
              </Row>
            </div>
            <Row className="diamond-resultshow">
              {this.state.gameResult.length > 0 ?
                this.state.gameResult.map((item, index) => {
                  return (
                    <div className="result-group" style={{padding:paddingWidth}}>
                    <img className={`result_light_` + (index + 1) +` ${item.showLight ? "show" : "hide"}`} src={require(`../assets/images/diamond/diamond_light_` + item.number + `.png`)} />
                    <img className={"result" + (index + 1)} src={require(`../assets/images/diamond/diamond_` + item.number + `.png`)} />
                    {/* {item.showBase?
                      <img className={`box`} src={require(`../assets/images/diamond/base_`+ item.number + `.png`)} />
                      :
                      <img className="box" src={require(`../assets/images/diamond/base_9.png`)} />
                    } */}
                    </div>
                    )
                })
                :
                null
              }
            </Row>
            <Row className="diamond-resultshow-base" style={{paddingTop:this.state.gameResult.length > 0?0:'50px'}}>
            {this.state.arr.map((item, index) => {
                  return (
                    <Col>
                    <img className="box" src={require(`../assets/images/diamond/base_`+item+`.png`)} />
                    </Col>
                  )
              })}
            </Row>
            <div className="diamond-body-result">
              <span id="game-result"></span>
            </div>
            <div className="diamond-body-bet-amount">
              <Row className="diamond-body-bet-button">
                <Col style={{
                  minWidth: '20px',
                  textAlign: 'center',
                  padding: '0px 0px 0px 10px',
                  width: 'fit-content',
                  marginRight: 'auto',
                  marginLeft: 'auto',
              }}>
                  {/* <div id="diamond-button" className="lg-button" onClick={() => this.minBet()}> */}
                    {/* <span>{t('diamond_min')}</span> */}
                  <div id="diamond-button" onClick={() => this.maxBet()}>
                    <img src={max} style={{width:70}}/>
                  </div>
                  <div id="diamond-button" onClick={() => this.minBet()}>
                    {/* <span>{t('diamond_max')}</span> */}
                    <img src={min} style={{width:70}}/>
                  </div>
                </Col>
                <Col style={{minWidth: "50%"}}>
                  <div className="diamond-bet-amount">
                    {/* <span>{this.state.amount.toFixed(2)}</span> */}
                    <TextField sx={{input:{textAlign:'center',fontSize:'30px',color: '#ffc845',textShadow: '0px 0px 5px #FEF47F',fontWeight: 600}}}
                      type='text'
                      InputProps={{disableUnderline: true, readOnly: this.state.islock}} 
                      autoComplete="off"
                      onChange={(event)=>this.handleBetAmt(event)}
                      hiddenLabel 
                      variant="standard" 
                      value={this.state.amount} />
                  </div>
                  <hr style={{border:'1px solid #F2B357',opacity:1}}/>
                  <div className="lg-text">
                    <span>{t('mine_play_amt')}</span>
                  </div>
                </Col>
                <Col style={{minWidth: '20px',
                      textAlign: 'center',
                      padding: '0 10px 0 0',
                      width: 'fit-content',
                      marginRight: 'auto',
                      marginLeft: 'auto'
                      }}>
                  <div id="diamond-button" onClick={() => this.doubleBet()}>
                    {/* <span>1/2</span> */}
                    <img src={plus} style={{width:70}}/>
                  </div>
                  <div id="diamond-button" onClick={() => this.halfBet()}>
                    {/* <span>2X</span> */}
                    <img src={minus} style={{width:70}}/>
                  </div>
                </Col>
              </Row>
              <Row className="diamond-body-bet-play">
                <div className="diammond-play-button autoPlay" id="diamond-button-auto" rosered xlSize onClick={() => this.autoBet()} disabled={this.state.autobet?false:this.state.islock}>
                  {!this.state.autobet?t('auto_bets'):t('stop_bets')}
                </div>
                <div className="diammond-play-button play" id="diamond-button" rosered xlSize onClick={() => this.startGame(this.state.startGame,false)} disabled={this.state.autobet?this.state.autobet:this.state.islock}>
                  {t("dice_play")}
                </div>
              </Row>
            </div>
          </Container>
        </InnerContainer>
      </GameMainContainer>
    );
  }
}

const ConnectedDiamondPage = connect(mapStateToProps)(DiamondPage);
export default withTranslation('common')(ConnectedDiamondPage);

