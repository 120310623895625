import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';
import ReactCardFlip from "react-card-flip";
import { motion } from "framer-motion"
import Numbers from "../../components/seven1121/Numbers";
import ImageNumber from "../../components/seven1121/ImageNumber";

import helpers from "../../utils/helpers";
import {store} from "../../index";
import gameConstants, {GameSeven1121IndexArray} from "../../utils/utilConstants";
import useGameAudio from "../../hooks/useGameAudio";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

function GameNumbers(props) {
    const dispatch = useDispatch();
    const {orders, ordersOrigin, earnAmount} = useSelector(state => store.getState().gameSeven1121, shallowEqual);
    const {t} = useTranslation();
    const [playingMusic, { playOnEveryInteraction }] = useGameAudio("open7");
    const [startPlayReveal, setStartPlayReveal] = useState(false);
    const [startManual, setStartManual] = useState(true);

    useEffect(() => {
        if(props.start === true) {
            setStartPlayReveal(true);
        } else {
            setStartPlayReveal(false)
        }
    }, [props.start]);

    useEffect(() => {
        if(props.reveal === true) {
            let randomNumbers = helpers.randomizeNumber(9, GameSeven1121IndexArray);
            makeReveal(randomNumbers).then(props.onStopReveal());
            setStartManual(false);
            props.onManual(true);
        }

        async function makeReveal(randomNumbers) {
            for (let i = 0; i < randomNumbers.length; i++) {
                await delay(1000);
                let response = handleRevel(randomNumbers[i], i);
                if(!response) break;
            }
        }

        function handleRevel(id, i) {
            if (startPlayReveal === true) {
                let click = i + 1;
                let getOrder = ordersOrigin[i];
                if(getOrder !== undefined) {
                    dispatch({ type: gameConstants.SWAP_ORDERS, index:id, name: getOrder.name});
                    dispatch({ type: gameConstants.UPDATE_ORDERS, index: id});
                    updateResultBoardRevel(click, getOrder.name);
                    clickNumberCallback(true, "open7");
                    if(click === 9) {
                        if(earnAmount > 0) {
                            dispatch({ type: gameConstants.SHOW_MESSAGE, message: t('reward'), messageType: 2 });
                        }
                        clickNumberCallback(true, earnAmount > 0 ? "win7" : "lose7");
                        props.onEndGame();
                        setStartManual(true);
                        props.onManual(false);
                    }
                    return true;
                } else {
                    props.onManual(false);
                    return false;
                }
            } else {
                dispatch({ type: gameConstants.SHOW_MESSAGE, message: t('please_play'), messageType: 1 });
                return false;
            }
        }

        function updateResultBoardRevel(clickNum, number) {
            switch (clickNum) {
                case 1:
                case 2:
                case 3:
                    dispatch({ type: gameConstants.UPDATE_RESULT_1, payload: number, index: clickNum-1 });
                    break;
                case 4:
                case 5:
                case 6:
                    dispatch({ type: gameConstants.UPDATE_RESULT_2, payload: number, index: clickNum-4 });
                    break;
                case 7:
                case 8:
                case 9:
                    dispatch({ type: gameConstants.UPDATE_RESULT_3, payload: number, index: clickNum-7 });
                    break;
                default:
                    break;
            }
        }

        function clickNumberCallback(res, soundSource) {
            if (res) playOnEveryInteraction(soundSource);
        }
    }, [props.reveal, ordersOrigin]);

    function renderIngredients(props) {
        return orders.map((ing, i) => (
            <Numbers.Item key={i}>
                <ClickableItemNumber data={ing}
                                     onManual={props.onManual} manual={startManual}
                                     onEndGame={props.onEndGame} start={props.start} reveal={startPlayReveal}/>
            </Numbers.Item>
        ));
    }

    return <Numbers.Container>{renderIngredients(props)}</Numbers.Container>;
}

function ClickableItemNumber(props) {
    const dispatch = useDispatch();
    const {click, ordersOrigin, earnAmount} = useSelector(state => store.getState().gameSeven1121, shallowEqual);
    const imgSrc = require(`../../assets/images/seven1121/card_pink.png`);
    const imgCircleSrc = require(`../../assets/images/seven1121/Circle.png`);
    const [playingMusic, { playOnEveryInteraction }] = useGameAudio("open7");
    const {t} = useTranslation();


    function handleOnClick(id) {
        if (props.start === true) {
            if(props.manual === true) {
                props.onManual(true);
                let getOrder = ordersOrigin[click -1];
                if(getOrder == undefined && ordersOrigin.length<1){
                    dispatch({ type: gameConstants.SHOW_MESSAGE, message: t('loading.msg'), messageType: 1 });
                }else{
                dispatch({ type: gameConstants.CLICK_NUMBER });
                dispatch({ type: gameConstants.SWAP_ORDERS, index:id, name: getOrder.name});
                dispatch({ type: gameConstants.UPDATE_ORDERS, index: id});
                updateResultBoard(click, getOrder.name);
                clickNumberCallback(true, "open7");
                if(click === 9) {
                    if(earnAmount > 0) {
                        dispatch({ type: gameConstants.SHOW_MESSAGE, message: t('reward'), messageType: 2 });
                    }
                    clickNumberCallback(true, earnAmount > 0 ? "win7" : "lose7");
                    props.onEndGame();
                }
            }}
        } else {
            dispatch({ type: gameConstants.SHOW_MESSAGE, message: t('please_play'), messageType: 1 });
        }
    }

    function updateResultBoard(clickNum, number) {
        switch (clickNum) {
            case 1:
            case 2:
            case 3:
                dispatch({ type: gameConstants.UPDATE_RESULT_1, payload: number, index: click-1 });
                break;
            case 4:
            case 5:
            case 6:
                dispatch({ type: gameConstants.UPDATE_RESULT_2, payload: number, index: click-4 });
                break;
            case 7:
            case 8:
            case 9:
                dispatch({ type: gameConstants.UPDATE_RESULT_3, payload: number, index: click-7 });
                break;
            default:
                break;
        }
    }

    function clickNumberCallback(res, soundSource) {
        if (res) playOnEveryInteraction(soundSource);
    }

    function renderClickableContents() {
        return (
            <div>
                <div>
                    <img src={imgSrc} alt={"num-" + props.data.name}  style={{display:props.data.flipped?'none':'initial'}} onClick={() => {
                                handleOnClick(props.data.id);
                            }}/>
                </div>
            </div>
            // <ReactCardFlip isFlipped={props.data.flipped} flipDirection="horizontal">
            //     <div>
            //         <ImageNumber.Container>
            //             <ImageNumber.image2>
            //                 <img src={imgSrc} alt={"num-" + props.data.name} onClick={() => {
            //                     handleOnClick(props.data.id);
            //                 }}/>
            //             </ImageNumber.image2>
            //         </ImageNumber.Container>
            //     </div>
            //     <div>
            //         <ImageNumber.Container>
            //             <ImageNumber.image2>
            //                 <img src={imgCircleSrc} alt={props.data.name}/>
            //             </ImageNumber.image2>
            //             <ImageNumber.Header3>
            //                 {props.data.name}
            //             </ImageNumber.Header3>
            //         </ImageNumber.Container>
            //     </div>
            // </ReactCardFlip>
        );
    }

    return renderClickableContents();
}

export default withTranslation('common')(GameNumbers);
