import React from 'react';
import {store} from '../index';
import '../App.css';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
import {BrowserView, MobileView, isMobile} from "react-device-detect";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md';
import {DropdownToggle, UncontrolledButtonDropdown, DropdownMenu, DropdownItem} from 'reactstrap';
import i18next from 'i18next';
import {SaveMemberInfo} from '../actions/HomePageAction';
import {Row, Col, Container} from 'reactstrap';
import MainSideBar from './MainSideBar';
import GameMainSideBar from './GameMainSideBar';
import mobileHeader from '../assets/images/h5/h5-top-header.png'
import mobileBg from '../assets/images/h5/h5-bg.png'
import mobileDetails from '../assets/images/h5/details-bg.png'
import mobilePopUp from '../assets/images/h5/h5-lang-popup.png'
import HeaderWithBack from '../components/layout/HeaderWithBack';
import '../styles/MainPage/MainPage.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import all_slide from '../assets/images/all-cn.png'
import all2_slide from '../assets/images/all2-cn.png'
import en_all_slide from '../assets/images/all-en.png'
import en_all2_slide from '../assets/images/all2-en.png'

const mapStateToProps = (state) => {
    return {
        memberInfo: state.memberInfo,
        selectedGames: state.selectedGames
    };
};


function onChange(lng) {
    let memberObj = store.getState().memberInfo.MemberInfo;
    i18next.changeLanguage(lng, (err) => {
        if (err) console.log(err);
    })
    memberObj.language = lng === "cn" ? "zh" : lng;
    store.dispatch(SaveMemberInfo(memberObj));
    fetch(global.config.domain.member + '/rest/minigame/member/changelang?lang=' + lng, {
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'gametoken': store.getState().memberInfo.token
        }),
        credentials: 'include',
    })
        .then((response) => response.json())
        .then(async (response) => {
            window.location.href = window.location.href.split('#')[0] + "#/?gametoken=" + store.getState().memberInfo.token;
        }).catch((error) => {
        console.error(error);
    });
}

class MainPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            gameList: "",
            languageAvailable: [
                {key: 'zh', imagePath: 'zh.png', displayText: '中文'},
                {key: 'en', imagePath: 'en.png', displayText: 'EN'},
                {key: 'th', imagePath: 'th.png', displayText: 'TH'},
                {key: 'vi', imagePath: 'vi.png', displayText: 'VI'},
                {key: 'in', imagePath: 'in.png', displayText: 'IN'},
                {key: 'id', imagePath: 'id.png', displayText: 'ID'},
                {key: 'pt', imagePath: 'pt.png', displayText: 'PT'},
                {key: 'es', imagePath: 'es.png', displayText: 'ES'},
                {key: 'fr', imagePath: 'fr.png', displayText: 'FR'},
                {key: 'ru', imagePath: 'ru.png', displayText: 'RU'},
            ],
            gameSlice: 18,
            gameSliceOpen: false,
            hall_height: window.innerHeight,
            gameOpenName: store.getState().utils.jumpGame ? store.getState().utils.jumpGame : '',
            gameOpenNameModel: store.getState().utils.jumpGame ? true : false,
            selectedGameName: '',
            mobileGameListStart: 0,
            mobileGameListEnd: 8,
            languageModel: false,
        }
    }

    async componentDidMount() {
        
        await this.getValidGames();
        
        // let gameId = store.getState().utils.jumpGame;
        // if (gameId && isMobile) {
        //     console.log('validgame');
        //     console.log(validgame);
        //     console.log("detected is mobile and jump game");
        //     // this.props.history.push({pathname: '/', key: gameId, gameName: gameId});
        //     window.location.href = window.location.href.split('#')[0] + `#/${gameId}?gametoken=` + store.getState().memberInfo.token +
        //     `&key=${gameId}&gameName=${gameId}`;
        // store.dispatch({type: 'SET_JUMP_GAME', input: null});
        // }
        
    }

    getValidGames(){
        let msg = "success";

        fetch(global.config.domain.member + '/rest/minigame/get-valid-games', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
                'gametoken': store.getState().memberInfo.token
            }),
            credentials: 'include',
            referrer: 'h5'
          })
            .then((response) => response.json())
            .then(async (response) => {
              if (response.code) {
                if(response.result){
                    let validGame = response.result.validGameList;
                    let gameData = (await import('../gameData/gameList.json'));
                    let gameList = gameData[0].gameList;
                    let list = [];
                    for(let i=0;i<gameList.length;i++){
                        for(let j=0;j<validGame.length;j++){
                            // if(validGame[j].gameId ==  'DICE' &&  gameList[i].id.toUpperCase() == 'DICES'){
                            //     list.push(gameList[i]);
                            //     break;
                            // }
                            if(validGame[j].gameId == gameList[i].id.toUpperCase()){
                                list.push(gameList[i]);
                                break;
                            }
                        }
                    }
                    this.setState({gameList: list});
                    let gameId = store.getState().utils.jumpGame;
                    if (gameId && isMobile) {
                        console.log("detected is mobile and jump game");
                        // this.props.history.push({pathname: '/', key: gameId, gameName: gameId});
                        window.location.href = window.location.href.split('#')[0] + `#/${gameId}?gametoken=` + store.getState().memberInfo.token +
                        `&key=${gameId}&gameName=${gameId}`;
                        store.dispatch({type: 'SET_JUMP_GAME', input: null});
                    }
                }
              } else {
                msg = response.msg;
                this.setState({
                  open: true,
                  message: msg
                });
              }
            })
            .catch(() => {
              msg = this.props.t('msg.connectionerror');
              this.setState({
                open: true,
                message: msg
              });
            });
    }

    more(gameCount) {
        this.setState({
            gameSlice: gameCount,
            gameSliceOpen: !this.state.gameSliceOpen,
            hall_height: gameCount > 12 ? (gameCount / 4) * 37 + "%" : '100%',
        });
    }

    less(gameCount) {
        this.setState({
            gameSlice: 18,
            gameSliceOpen: !this.state.gameSliceOpen,
            hall_height: '100%',
        });
    }

    openGame(gameName, lang) {
        console.log("openGame --- gameName : " + gameName);
        this.setState({
            gameOpenName: gameName,
            gameOpenNameModel: true,
            selectedGameName: this.state.gameList.filter(item => gameName === item.id)[0].gameName,
            hall_height: '100%',
        });
        store.dispatch({type: 'SET_JUMP_GAME', input: null});
    }

    refresh = () => {
        window.location.reload(window.location.href.split('#')[0] + "#/?gametoken=" + store.getState().memberInfo.token);
    }

    mobileNextGame() {
        if (this.state.mobileGameListEnd < this.state.gameList.length) {
            this.setState({
                mobileGameListStart: this.state.mobileGameListStart + 8,
                mobileGameListEnd: this.state.mobileGameListEnd + 8,
            });
        }
    }

    mobileBackGame() {
        if (this.state.mobileGameListStart !== 0) {
            this.setState({
                mobileGameListStart: this.state.mobileGameListStart - 8,
                mobileGameListEnd: this.state.mobileGameListEnd - 8,
            });
        }
    }

    mobileLanguage() {
        console.log("close");
        this.setState({
            languageModel: !this.state.languageModel
        })
    }


    render() {
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2500,
            //autoplay: true,
          };
        let siteName = store.getState().utils.siteName;
        let Lang = this.props.memberInfo.MemberInfo.language;
        let langPopup = window.innerHeight <= 700 ? 55 : window.innerHeight > 800 ? 35 : window.innerHeight / 17;
        let iframeUrl = `/#/${this.state.gameOpenName}?gametoken=` + store.getState().memberInfo.token + '&gameName=' + this.state.gameOpenName;
        if (isMobile) {
            iframeUrl = `/#/${this.state.gameOpenName}?gametoken=` + store.getState().memberInfo.token + '&gameName=' + this.state.gameOpenName;
        }
        const {t} = this.props;
        const pageHeight = window.innerHeight;
        const bannerHeight = window.innerHeight < 850?888:'100%';
        return (
            <>
                <BrowserView>
                    <main className="cr-app bg-light" >
                        <div>
                            <Container fluid={true}  className="background-web" style={{height: window.innerHeight < 830?'115%':'104%',position:'absolute'}}>
                                <Row className={"h5-header"}>
                                    {this.state.gameOpenNameModel ?
                                        <Col style={{width: '25%', textAlign: 'left'}}>
                                            <GameMainSideBar openGame={this.openGame.bind(this)}
                                                             gameList={this.state.gameList}/></Col>
                                        :
                                        <Col style={{width: '25%', textAlign: 'left'}}><MainSideBar/></Col>
                                    }

                                    <Col onClick={this.refresh} style={{
                                        width: '50%',
                                        color: 'white',
                                        fontSize: '18px',
                                        textAlign: 'center'
                                    }}>{siteName}</Col>
                                    <Col style={{width: '25%', textAlign: 'right', marginRight: '15px'}}>
                                        <div className="language-and-euro-btn">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle className="lang-dropdown">
                                                    {
                                                        this.state.languageAvailable.filter((e) => this.props.memberInfo.MemberInfo.language?.includes(e.key)).map((value, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <img
                                                                        src={require(`../assets/images/` + value.imagePath)}
                                                                        alt={value.displayText}/>&nbsp;{value.displayText}<MdKeyboardArrowDown/>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </DropdownToggle>
                                                <DropdownMenu className="lang-dropdown-menu">
                                                    {
                                                        this.state.languageAvailable.filter((e) => this.props.memberInfo.MemberInfo.systemLanguage?.includes(e.key)).map((value, index) => {
                                                            return (
                                                                <DropdownItem key={index}
                                                                              onClick={e => onChange(value.key)}>
                                                                    <img
                                                                        src={require(`../assets/images/` + value.imagePath)}
                                                                        alt={value.displayText}/>&nbsp;{value.displayText}
                                                                </DropdownItem>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Col>
                                </Row>
                               
                                {!this.state.gameOpenNameModel ?
                                <>
                                <div>
                                    <Slider {...settings} className="carouselStyle mini">
                                    <div>
                                        <img src={Lang === 'zh'?all_slide:en_all_slide} width={'100%'}></img>
                                    </div>
                                    <div>
                                        <img src={Lang === 'zh'?all2_slide:en_all2_slide} width={'100%'}></img>
                                    </div>
                                    </Slider>
                                </div>
                                    <div className="hall-container">
                                        <Row>
                                            <Col className="hall-container-head">{t('all.games')}</Col>
                                        </Row>
                                        <Row className='hall-row'>
                                            {this.state.gameList.length > 0 ?
                                                this.state.gameList.slice(0, this.state.gameSlice).map((item, index) => {
                                                    let imageUrl;
                                                    try {
                                                        if (Lang === 'zh') {
                                                            imageUrl = require(`../assets/images/zh/${item.id}.png`);
                                                        } else {
                                                            imageUrl = require(`../assets/images/en/${item.id}.png`);
                                                        }
                                                    } catch (ex) {
                                                        imageUrl = imageUrl = require(`../assets/images/h5/zh/crash.png`);
                                                        ;
                                                    }
                                                    return (
                                                        <Col key={index} md={3} lg={2}>
                                                            <div className="hall-col-card"
                                                                 onClick={this.openGame.bind(this, item.id, this.props.memberInfo.MemberInfo.language)}>
                                                                <img src={imageUrl} alt={""}
                                                                     className="hall-col-card-img"/>
                                                            </div>
                                                            {/* </Link> */}
                                                        </Col>
                                                    )
                                                })
                                                : <span>{t('no game now')}</span>
                                            }
                                            {this.state.gameSliceOpen ?
                                                <div style={{color: "white", textAlign: 'center', marginTop: '20px'}}
                                                     onClick={this.less.bind(this, this.state.gameList.length)}>
                                                    <span>{t('Show Less')}<br/><MdKeyboardArrowUp size={20}/></span>
                                                </div> :
                                                <div style={{color: "white", textAlign: 'center', marginTop: '20px'}}
                                                     onClick={this.more.bind(this, this.state.gameList.length)}>
                                                    <span>{t('Show More')}<br/><MdKeyboardArrowDown size={20}/></span>
                                                </div>
                                            }
                                        </Row>
                                    </div>
                                    </>
                                    :
                                    <div className="hall-container-openGame" style={{maxWidth: '1265px'}}>
                                        <Row>
                                            <Col className="hall-container-head">{t(this.state.selectedGameName)}</Col>
                                        </Row>
                                        <Row>
                                            <Col className="hall-container-body">
                                                <iframe title='iframe1' name='iframe1' className='iframeUI'
                                                        key={this.state.gameOpenName} id='iframe1' src={iframeUrl}/>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </Container>
                        </div>
                    </main>
                </BrowserView>
                <MobileView>
                    <main className="cr-app bg-light" style={{
                        backgroundImage: 'linear-gradient(to left top, #161616, #1b1b1b, #202020, #262626, #2b2b2b',
                        backgroundSize: 'cover',
                        height: pageHeight,
                        width: '100vw'
                    }}>
                        {!this.state.gameOpenNameModel ?
                            <>
                                <Container fluid={true}>
                                    <div className="mobile-hall-container" ref={this.scrollx}>
                                        <Col style={{ textAlign:'center', paddingBottom:'20px', paddingTop:'10px',height: '60px',position: 'relative' }}>
                                        <span className="mobile-hall-container-head" style={{float: 'left'}}>{t('all.games')}</span>
                                        <span className="language-and-euro-btn" style={{marginTop: '0px',right: '1%'}}>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle className="lang-dropdown">
                                                    {
                                                        this.state.languageAvailable.filter((e) => this.props.memberInfo.MemberInfo.language?.includes(e.key)).map((value, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <img
                                                                        src={require(`../assets/images/` + value.imagePath)}
                                                                        alt={value.displayText}/>&nbsp;{value.displayText}<MdKeyboardArrowDown/>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </DropdownToggle>
                                                <DropdownMenu className="lang-dropdown-menu">
                                                    {
                                                        this.state.languageAvailable.filter((e) => this.props.memberInfo.MemberInfo.systemLanguage?.includes(e.key)).map((value, index) => {
                                                            return (
                                                                <DropdownItem key={index}
                                                                              onClick={e => onChange(value.key)}>
                                                                    <img
                                                                        src={require(`../assets/images/` + value.imagePath)}
                                                                        alt={value.displayText}/>&nbsp;{value.displayText}
                                                                </DropdownItem>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </span>
                                        </Col>
                                        <Col>    
                                            <div>
                                                <Slider {...settings} className="carouselStyle mini-mobile">
                                                <div>
                                                    <img src={Lang === 'zh'?all_slide:en_all_slide} width={'100%'}></img>
                                                </div>
                                                <div>
                                                    <img src={Lang === 'zh'?all2_slide:en_all2_slide} width={'100%'}></img>
                                                </div>
                                                </Slider>
                                            </div>
                                        </Col>
                                        <Row className='mobile-hall-row'>
                                            {
                                                this.state.gameList.length > 0 ?
                                                    this.state.gameList.map((item, index) => {
                                                        let imageUrl;
                                                        try {
                                                            if (Lang === 'zh') {
                                                                imageUrl = require(`../assets/images/zh/${item.id}.png`);
                                                            } else {
                                                                imageUrl = require(`../assets/images/en/${item.id}.png`);
                                                            }
                                                        } catch (ex) {
                                                            imageUrl = imageUrl = require(`../assets/images/zh/crash.png`);
                                                            ;
                                                        }
                                                        return (
                                                            <Col key={index} md={3} lg={3}
                                                                 style={{maxWidth: '33.333333%', marginBottom: '0px', padding:'5px' }}>
                                                                <div className="mobile-hall-col-card"
                                                                     onClick={this.openGame.bind(this, item.id, this.props.memberInfo.MemberInfo.language)}>
                                                                    {/* <Link key={index} to={{ pathname: '/HomePage', key:item.id, gameName:item.id}}>
                      <img src={imageUrl} alt={item.id} className="mobile-hall-col-card-img" />
                    </Link> */}
                                                                    <img src={imageUrl} alt={item.id}
                                                                         className="mobile-hall-col-card-img"/>
                                                                </div>
                                                                {/* </Link> */}
                                                            </Col>
                                                        )
                                                    })
                                                    : <span>{t('no game now')}</span>
                                            }
                                        </Row>

                                        {/* <div className="mobile-next" onClick={this.mobileNextGame.bind(this)}>
               <img src={require(`../assets/images/h5/h5-next.png`)} alt={""}/>
              </div>
              <div className="mobile-prev" onClick={this.mobileBackGame.bind(this)}>
                <img src={require(`../assets/images/h5/h5-prev.png`)} alt={""}/>
              </div> */}
                                    </div>
                                    {this.state.languageModel ? <>
                                        <div className='window-pop-up'>
                                            <div className='window-pop-up-img' style={{
                                                backgroundImage: `url(${mobilePopUp})`,
                                                height: langPopup + "vh"
                                            }}>
                                                <div className='window-pop-up-back'
                                                     onClick={this.mobileLanguage.bind(this)}>
                                                </div>
                                                <div className='window-pop-up-context'>
                                                    <h2>{t('语言设置')}</h2>
                                                </div>
                                                <div className='window-pop-up-select'>
                                                    <Row>
                                                        <Col
                                                            className={Lang === 'en' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('en')}>EN</Col>
                                                        <Col
                                                            className={Lang === 'zh' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('zh')}>中文</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            className={Lang === 'vi' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('vi')}>VN</Col>
                                                        <Col
                                                            className={Lang === 'th' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('th')}>TH</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            className={Lang === 'in' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('in')}>IN</Col>
                                                        <Col
                                                            className={Lang === 'id' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('id')}>ID</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            className={Lang === 'pt' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('pt')}>PT</Col>
                                                        <Col
                                                            className={Lang === 'es' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('es')}>ES</Col>
                                                        <Col></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            className={Lang === 'fr' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('fr')}>FR</Col>
                                                        <Col
                                                            className={Lang === 'ru' ? 'window-pop-up-selected' : 'window-pop-up-option'}
                                                            onClick={e => onChange('ru')}>RU</Col>
                                                    </Row>
                                                </div>
                                                {/* <img src={require(`../assets/images/h5/h5-lang-popup.png`)} alt={""}/> */}
                                            </div>
                                        </div>
                                    </> : null}
                                </Container>
                            </>
                            :
                            <>
                                <div style={{width: '100%'}}>
                                    <HeaderWithBack headerName={t(this.state.selectedGameName)} {...this.props}/>
                                    <iframe title='iframe1' name='iframe1' className='iframeMobileUI'
                                            style={{height: window.innerHeight - 44, width: "inherit"}} key={this.state.gameOpenName}
                                            id='iframe1' src={iframeUrl}/>
                                </div>
                            </>
                        }
                    </main>
                </MobileView>
            </>
        );
        //   }
    }
}

const ConnectedMainPage = connect(mapStateToProps)(MainPage);
export default withTranslation('common')(ConnectedMainPage)