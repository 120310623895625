import { Children, Component } from 'react'
import PropTypes from 'prop-types'

export class Orientation extends Component {
    render() {
        const { orientation, children, className } = this.props
        return (
            <div className={`${className} react-orientation react-orientation--${orientation}`}>
                {children}
            </div>
        )
    }
}

Orientation.propTypes = {
    alwaysRender: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    orientation: PropTypes.oneOf(['portrait', 'landscape']).isRequired
}

Orientation.defaultProps = {
    className: '',
    alwaysRender: true
}

const noop = () => false

window.screen.lockOrientationUniversal = window.screen.lockOrientation ||
    window.screen.mozLockOrientation ||
    window.screen.msLockOrientation

const lock = (orientation) => {
    const { screen } = window
    if (screen.orientation && typeof screen.orientation.lock === 'function') {
        return window.screen.orientation.lock(orientation)
    } else if (screen.lockOrientationUniversal) {
        return new Promise((resolve, reject) => {
            if (screen.lockOrientationUniversal(orientation)) {
                resolve()
            } else {
                reject()
            }
        })
    } else {
        return new Promise((resolve, reject) => reject())
    }
}

export default class DeviceOrientation extends Component {

    constructor(props) {
        super(props)
        this.lockOrientation(props)
        this.onOrientationChange = this.onOrientationChange.bind(this)

        this.state = {
            orientation: null,
            type: null,
            angle: null
        }
    }

    componentDidMount() {
        this.onOrientationChange(null)
        console.log('DeviceOrientation', 'componentDidMount',window.screen.width)
        if ((window.screen.orientation) && ('onchange' in window.screen.orientation) && window.orientation) {
            console.log('Using screen.orientation.onchange')
            window.screen.orientation.addEventListener('change', this.onOrientationChange)
        } else if ('onorientationchange' in window) {
            console.log('Using window.onorientationchange')
            window.addEventListener('orientationchange', this.onOrientationChange)
        } else {
            window.addEventListener("message", (e)=>{
                var newOrientationValue = e.data.orientation;
                this.onOrientationChange(null,newOrientationValue);
            }, false)
            console.warn('No orientationchange events')
        }
        global.config.window.height = window.innerHeight;
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        console.log('DeviceOrientation', 'componentWillUnmount')
        if ((window.screen.orientation) && ('onchange' in window.screen.orientation) && window.orientation) {
            console.log('Removing screen.orientation.onchange')
            window.screen.orientation.removeEventListener('change', this.onOrientationChange)
        } else if ('onorientationchange' in window) {
            console.log('Removing window.onorientationchange')
            window.removeEventListener('orientationchange', this.onOrientationChange)
        }else{
            window.removeEventListener('message',null)
        }
//        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        // Assign a variable for the device being used
        var nAgt = navigator.userAgent;
 /*       var devices = [
            ['a7', '640x1136', ['iPhone 5', 'iPhone 5s']],
            ['a7', '1536x2048', ['iPad Air', 'iPad Mini 2', 'iPad Mini 3']],
            ['a8', '640x1136', ['iPod touch (6th gen)']],
            ['a8', '750x1334', ['iPhone 6']],
            ['a8', '1242x2208', ['iPhone 6 Plus']],
            ['a8', '1536x2048', ['iPad Air 2', 'iPad Mini 4']],
            ['a9', '640x1136', ['iPhone SE']],
            ['a9', '750x1334', ['iPhone 6s']],
            ['a9', '1242x2208', ['iPhone 6s Plus']],
            ['a9x', '1536x2048', ['iPad Pro (1st gen 9.7-inch)']],
            ['a9x', '2048x2732', ['iPad Pro (1st gen 12.9-inch)']],
            ['a10', '750x1334', ['iPhone 7']],
            ['a10', '1242x2208', ['iPhone 7 Plus']],
            ['a10x', '1668x2224', ['iPad Pro (2th gen 10.5-inch)']],
            ['a10x', '2048x2732', ['iPad Pro (2th gen 12.9-inch)']],
            ['a11', '750x1334', ['iPhone 8']],
            ['a11', '1242x2208', ['iPhone 8 Plus']],
            ['a11', '1125x2436', ['iPhone X']],
            ['a12', '828x1792', ['iPhone Xr']],
            ['a12', '1125x2436', ['iPhone Xs']],
            ['a12', '1242x2688', ['iPhone Xs Max']],
            ['a12x', '1668x2388', ['iPad Pro (3rd gen 11-inch)']],
            ['a12x', '2048x2732', ['iPad Pro (3rd gen 12.9-inch)']]
        ];
        */
        
        if(navigator.platform === 'iPhone' || navigator.platform === 'iPod'){
            if ((nAgt.indexOf('Safari'))!==-1) {
                var mobileSafari = 'Safari';
/*                
                var ratio = window.devicePixelRatio || 1;
                var res = (Math.min(window.screen.width, window.screen.height) * ratio)
                  + 'x' + (Math.max(window.screen.width, window.screen.height) * ratio);
                var models = ['unknown'];
                for (var i = 0; i < devices.length; i ++) {
                    var device = devices[i];
                    if (res === device[1]) {
                        models = device[2];
                        break;
                    }
                }
                */
            }
        }
    
        global.config.window.browser = mobileSafari;
    };

    onOrientationChange(event,parentOrientation) {
        const onOrientationChange = this.props.onOrientationChange || noop;
        var orientation = 'portrait';
        var type = 'primary';
        var angle = 0;
        
        if (window.orientation) {
            angle = window.orientation;
            orientation = Math.abs(angle) === 90 ? 'landscape' : 'portrait';
            
        }

        if (window.screen.orientation) {
            [orientation, type] = window.screen.orientation.type.split('-');
            angle = window.screen.orientation;
        }
        if(window.orientation===undefined){
            if (window.matchMedia("(orientation: portrait)").matches || parentOrientation==0) {
                orientation = 'portrait';
                angle =0;
            }
            else if (window.matchMedia("(orientation: landscape)").matches|| parentOrientation==90) {
                orientation = 'landscape';
                angle =90;
            }

            if(parentOrientation !== undefined){
                if (parentOrientation==0) {
                    orientation = 'portrait';
                    angle =0;
                }
                else if (parentOrientation==90) {
                    orientation = 'landscape';
                    angle =90;
                }
            }
        }
        this.setState({
            orientation,
            type,
            angle
        })
        onOrientationChange(orientation, type, angle)
    }

    lockOrientation({ lockOrientation }) {
        if (typeof lockOrientation !== 'string') {
            return
        }
        const onLockOrientation = this.props.onLockOrientation || noop
        return lock(lockOrientation).then(function () {
            onLockOrientation(true)
        }).catch(function () {
            onLockOrientation(false)
        })
    }

    render() {
        const { children, className } = this.props
        const { orientation } = this.state
        return (
            <div className={`${className}`}>
                {
                    Children.map(children, (child) => {
                        const { props } = child
                        if (props.alwaysRender || props.orientation === orientation) {
                            return child
                            // } else {
                            //   console.log('Skipping child', child)
                        }
                    })
                }
            </div>
        )
    }
}

// https://developer.mozilla.org/en-US/docs/Web/API/screen/lockOrientation
const LOCK_ORIENTATIONS = [
    'portrait-primary',
    'portrait-secondary',
    'landscape-primary',
    'landscape-secondary',
    'portrait',
    'landscape',
    'default'
]

const isOrientation = (props, propName, componentName, location, propFullName) => {
    const propValue = props[propName]
    if (propValue.type !== Orientation) {
        return new Error(`Invalid ${location} '${propFullName}' supplied to '${componentName}', expected 'Orientation' component.`)
    }
}

DeviceOrientation.propTypes = {
    children: PropTypes.oneOfType([
        isOrientation,
        PropTypes.arrayOf(isOrientation)
    ]).isRequired,
    className: PropTypes.string,
    lockOrientation: PropTypes.oneOfType([
        PropTypes.oneOf(LOCK_ORIENTATIONS),
        PropTypes.arrayOf(PropTypes.oneOf(LOCK_ORIENTATIONS))
    ]),
    onLockOrientation: PropTypes.func,
    onOrientationChange: PropTypes.func
}

DeviceOrientation.defaultProps = {
    className: ''
}