import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {useTranslation, withTranslation} from 'react-i18next';
import { motion } from "framer-motion"
import {store} from "../../index";
import RewardAmount from "../../components/spheres/RewardAmount";
import ImageButton from "../../components/spheres/ImageButton";
import ImageNumber from "../../components/spheres/ImageNumber";
import Buttons from "../../components/spheres/Buttons";
import Button from "../../components/default/Button";
import GameMessage from "../../containers/spheres/GameMessage";
import GameAward from "../../containers/spheres/GameAward";
import ButtonTimes from "../../assets/images/spheres/Button1.png";
import ButtonDivide from "../../assets/images/spheres/Button2.png";
import OddBg from "../../assets/images/spheres/Oddsbg.png";
import OddBgWin from "../../assets/images/spheres/ballBoxWin.png";
import { TextField } from '@mui/material';
import Betamount from "../../assets/images/spheres/Betamount.png";
import ButtonMin from "../../assets/images/spheres/Button3.png";
import ButtonMax from "../../assets/images/spheres/Button4.png";
import ButtonAutoBet from "../../assets/images/spheres/autobet.png";
import ButtonAutoBetStart from "../../assets/images/spheres/autobetStart.png";
import ButtonPlay from "../../assets/images/spheres/ButtonPlay.png";

function GameButtons(props) {
    const {earnAmount, oddAmount, reward} = useSelector(state => store.getState().gameSpheres, shallowEqual);
    const {t} = useTranslation();
    function handleOnHalfScore() {
        props.onHalfScore();
    }

    function handleOnTimeScore() {
        props.onTimeScore();
    }

    function handleOnMinScore() {
        props.onMinScore();
    }

    function handleOnMaxScore() {
        props.onMaxScore();
    }

    function handleOnClick() {
        props.onStart();
        // props.hideMsg();
    }
    
    function handleAutoBet() {
        props.onAutoBet();
    }

    function handleEndGame() {
        props.onEndGame();
    }

    return (
        <>
            <Buttons.Container className="background-buttons-container-spheres">
                <Container style={{paddingLeft: "10px",width:'100%'}}>
                    <Row>
                        <Col>
                            <RewardAmount.Container>
                            {t("label.odds")}
                            </RewardAmount.Container>
                        </Col>
                        <Col>
                            <RewardAmount.Container>
                            {t("keno_win")}
                            </RewardAmount.Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ImageNumber.Container>
                                <img src={OddBg} alt={"Odd Bg"}/>
                                {props.isDisplay?
                                <ImageNumber.Header>
                                    {oddAmount[reward].toFixed(2) + " X "}
                                </ImageNumber.Header>
                                :
                                <ImageNumber.Header>
                                    {"0.00 X "}
                                </ImageNumber.Header>
                                }
                            </ImageNumber.Container>
                        </Col>
                        <Col>
                            <RewardAmount.Container>
                            {props.isDisplay?
                            <><GameAward from={0} to={earnAmount} /></>:
                            <><GameAward from={0} to={0}/></>
                            }
                            </RewardAmount.Container>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom:"10px", height:'30px',margin: '15px -10%'}}>
                        {/* <Col><GameMessage onEndGame={handleEndGame}/></Col> */}
                        <Col><GameMessage onEndGame={handleEndGame} /></Col>
                    </Row>
                    <Row style={{marginTop:10}}>
                        <Col>
                            <span>
                                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                                    <ImageButton.Container onClick={handleOnMinScore} disabled={props.autoBet?true:props.isLock}>
                                        <img src={ButtonMin} alt={"mix"}/>
                                    </ImageButton.Container>
                                </motion.div>
                            </span>
                            <span>
                                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                                    <ImageButton.Container onClick={handleOnHalfScore} disabled={props.autoBet?true:props.isLock}>
                                        <img src={ButtonDivide} alt={"1/2"}/>
                                    </ImageButton.Container>
                                </motion.div>
                            </span>
                                <span style={{width: "50%",textAlign:'center',backgroundImage:`url(${Betamount})`,backgroundRepeat:'round'}}>
                                <TextField
                                    sx={{input:{color:'white', textAlign:'center', width:'130px',fontSize:'25px',padding:'10px 0'}}}
                                    type='text'
                                    InputProps={{disableUnderline: true, readOnly: props.autoBet?true:props.isLock}}
                                    autoComplete="off"
                                    onChange={(event)=>props.onUpdateBetAmount(event.target.value)}
                                    hiddenLabel
                                    variant="standard"
                                    value={props.betAmount} />
                            </span>
                                <span>
                                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                                    <ImageButton.Container onClick={handleOnTimeScore} disabled={props.autoBet?true:props.isLock}>
                                        <img src={ButtonTimes} alt={"2X"}/>
                                    </ImageButton.Container>
                                </motion.div>
                            </span>
                            <span>
                                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                                    <ImageButton.Container onClick={handleOnMaxScore} disabled={props.autoBet?true:props.isLock}>
                                        <img src={ButtonMax} alt={"max"}/>
                                    </ImageButton.Container>
                                </motion.div>
                            </span>
                        </Col>
                    </Row>
                    <Row style={{marginTop:5}}>
                        <Col className="row-col-empty">
                            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                                <Button style={{backgroundImage:`url(${ButtonPlay})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundSize: 'contain',
                                color:'#8b0059',
                                width:155,
                                height:50,
                                borderRadius: 50,
                                backgroundSize: '100% 100%',
                              }} 
                                disabled={props.autoBet?true:props.isLock} xlSize onClick={handleOnClick}>
                                    {t("play")}
                                </Button>
                            </motion.div>
                            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                             <ImageButton.Container onClick={handleAutoBet} disabled={props.autoBet?true:props.isLock}>
                                        <img src={props.autoBet?ButtonAutoBetStart:ButtonAutoBet} alt={"max"}/>
                             </ImageButton.Container>
                            </motion.div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col className="row-col-empty">
                            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                                <Button purplelight xlSize whitefont onClick={handleAutoBet}>
                                    {props.autoBet?t('stop_bets'):t('auto_bets')}
                                </Button>
                            </motion.div>
                        </Col>
                    </Row> */}
                </Container>
            </Buttons.Container>
        </>
    );
}

export default withTranslation('common')(GameButtons);
