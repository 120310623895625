import React, {useEffect, useRef} from "react";
import { withTranslation } from 'react-i18next';
import {animate} from "framer-motion";
import OddBgWin from "../../assets/images/spheres/ballBoxWin.png";
import OddBg from "../../assets/images/spheres/Oddsbg.png";

function GameAward({ from, to }) {
    const ref = useRef();
    useEffect(() => {
        const controls = animate(from, to, {
            duration: 0.6,
            onUpdate(value) {
                ref.current.textContent = value.toFixed(2);
            }
        });
        return () => controls.stop();
    }, [from, to]);

    return to>0?<>
        <img src={OddBgWin} alt={"Amount Bg"} width={'100%'}/>
        <h1 style={{fontSize: '17px',
        color: 'white',
        textAlign: 'center',
        position: 'absolute',
        inset: '0px',
        height: 'fit-content',
        margin: 'auto',fontWeight: 600}} ref={ref} /></>
        :
        <>
        <img src={OddBg} alt={"Amount Bg"} width={'100%'}/>
        <h1 style={{fontSize: '17px',
        color: 'white',
        textAlign: 'center',
        position: 'absolute',
        inset: '0px',
        height: 'fit-content',
        margin: 'auto',fontWeight: 600}} ref={ref} /></>;
}

export default withTranslation('common')(GameAward);
