import {animated as a} from "react-spring";
import styled, {keyframes} from "styled-components";

import {device} from "../../utils/utilConstants";

const Container = styled(a.div)`
   height: 80%;
   width: 120%;
   position: relative;
   overflow: hidden;
   img {
      width: 100%;
   }
`;

const Header = styled.h1`
    font-size: 17px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
`;

export default {Container, Header};
