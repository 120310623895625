import { useState, useEffect } from "react";

import SevenBackgroundMusic from "../assets/audio/seven1121/bgm.mp3";
import SevenOpen from "../assets/audio/seven1121/open.mp3";
import SevenWin from "../assets/audio/seven1121/win.mp3";
import SevenLose from "../assets/audio/seven1121/lose.mp3";

const bg7 = new Audio(SevenBackgroundMusic);
const open7 = new Audio(SevenOpen);
const win7 = new Audio(SevenWin);
const lose7 = new Audio(SevenLose);

const gameAudio = {
  bg7, open7, win7, lose7
};

Object.keys(gameAudio).forEach(key => {
  gameAudio[key].preload = "auto";
});

function useGameAudio(name, opt) {
  const [playingMusic, toggle] = useState(false);
  const [audio] = useState(gameAudio[name]);

  if (opt) {
    Object.keys(opt).forEach(i => {
      audio[i] = opt[i];
    });
  }

  useEffect(() => {
    if (playingMusic) {
      audio.play();
    } else audio.pause();
  }, [playingMusic]);

  function playOnEveryInteraction(other) {
    const clonedAudio = (gameAudio[other] || audio).cloneNode();
    clonedAudio.play();
  }

  function restartAudio() {
    audio.currentTime = 0;
  }

  function toggleAudio() {
    toggle(!playingMusic);
  }

  return [
    playingMusic,
    { toggle, playOnEveryInteraction, restartAudio, toggleAudio }
  ];
}

export default useGameAudio;
