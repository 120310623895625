import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { configureStore, persistor } from './utils/redux';
import { PersistGate } from 'redux-persist/integration/react';
import {registerLocale} from  "react-datepicker";

import configureI18n from './utils/i18n';
import IdleCheck from './utils/IdleCheck';
import reportWebVitals from './reportWebVitals';

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import {isMobile} from "react-device-detect";

//Languages
import zhCN from 'date-fns/locale/zh-CN';
import viVN from 'date-fns/locale/vi';
import thTH from 'date-fns/locale/th';
import engEN from 'date-fns/locale/en-US';
import inIN from 'date-fns/locale/hi';
import idID from 'date-fns/locale/id';
import ptPT from 'date-fns/locale/pt';
import esES from 'date-fns/locale/es';
import frFR from 'date-fns/locale/fr';
import ruRU from 'date-fns/locale/ru';

import 'react-app-polyfill/stable';
import './constant';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

registerLocale('cn', zhCN);
registerLocale('en', engEN);
registerLocale('th', thTH);
registerLocale('vi', viVN);
registerLocale('in', inIN);
registerLocale('id', idID);
registerLocale('pt', ptPT);
registerLocale('es', esES);
registerLocale('fr', frFR);
registerLocale('ru', ruRU);

const store = configureStore();
const i18n = configureI18n();
const initedPersistor = persistor(store);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={initedPersistor}>
      <I18nextProvider i18n={i18n}>
        <IdleCheck>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                <App />
            </DndProvider>
        </IdleCheck>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { store, initedPersistor }