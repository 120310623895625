const gameConstants = {
    //GAME STATUS
    UPDATE_ORDERS: "UPDATE_ORDERS",
    RESTART: "RESTART",
    SWAP_ORDERS: "SWAP_ORDERS",
    UPDATE_SCORE: "UPDATE_SCORE",
    UPDATE_TIME: "UPDATE_TIME",
    UPDATE_LIVES: "UPDATE_LIVES",
    UPDATE_WINSTREAK: "UPDATE_WINSTREAK",
    UPDATE_EXACTORDER: "UPDATE_EXACTORDER",

    RUN_GAME: "RUN_GAME",
    PAUSE_GAME: "PAUSE_GAME",
    //REVEAL_GAME: "REVEAL_GAME",
    END_GAME: "END_GAME",
    //END_REVEAL_GAME: "END_REVEAL_GAME",
    SET_PAUSE: "SET_PAUSE",
    TOGGLE_PAUSE: "TOGGLE_PAUSE",
    SET_LOADING: "SET_LOADING",
    UPDATE_SEVEN_DATA: "UPDATE_SEVEN_DATA",
    UPDATE_DICE_DATA: "UPDATE_DICE_DATA",
    UPDATE_KENO_DATA: "UPDATE_KENO_DATA",
    UPDATE_SPHERES_DATA: "UPDATE_SPHERES_DATA",

    //Amount
    INCREASE_AMT: "INCREASE_AMT",
    DECREASE_AMT: "DECREASE_AMT",
    SET_AMT: "SET_AMT",

    //GameDice
    UPDATE_PREDICTION_VALUES: "UPDATE_PREDICTION_VALUES",
    UPDATE_ROLL_UNDER: "UPDATE_ROLL_UNDER",
    UPDATE_ROLL_OVER: "UPDATE_ROLL_OVER",

    //GameSeven1121
    UPDATE_RESULT_1: "UPDATE_RESULT_1",
    UPDATE_RESULT_2: "UPDATE_RESULT_2",
    UPDATE_RESULT_3: "UPDATE_RESULT_3",
    RESET_RESULT_1: "RESET_RESULT_1",
    RESET_RESULT_2: "RESET_RESULT_2",
    RESET_RESULT_3: "RESET_RESULT_3",
    CLICK_NUMBER: "CLICK_NUMBER",

    //Message
    SHOW_MESSAGE: "SHOW_MESSAGE",
    HIDE_MESSAGE: "HIDE_MESSAGE",

    UPDATE_SHOW_KENO_RESULT: "UPDATE_SHOW_KENO_RESULT",
    UPDATE_KENO_RESULT_INDEX: "UPDATE_KENO_RESULT_INDEX",
    UPDATE_SELECTED_NUMBERS: "UPDATE_SELECTED_NUMBERS",
    UPDATE_CHECKED_NUMBERS: "UPDATE_CHECKED_NUMBERS",
    RESET_CHECKED_NUMBERS: "RESET_CHECKED_NUMBERS",
    QUICK_PICK_NUMBERS: "QUICK_PICK_NUMBERS",

    UPDATE_SPHERES_RESULT_INDEX: "UPDATE_SPHERES_RESULT_INDEX",
    RESET_SPHERES_RESULT: "RESET_SPHERES_RESULT",
    UPDATE_SPHERES_USER_SHOW_INDEX: "UPDATE_SPHERES_USER_SHOW_INDEX",
    UPDATE_SPHERES_ROBOT_SHOW_INDEX: "UPDATE_SPHERES_ROBOT_SHOW_INDEX"
};

export const IngredientsArray = [
    "Cheese",
    "Pickles",
    "Lettuce",
    "Tomato",
    "Patty",
    "Bacon"
];

export const GameSeven1121Array = [0, 1, 2, 3, 4, 5, 6, 7, 11];

export const GameSeven1121IndexArray = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export const GameKenoArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
    61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80];

export const GameKenoBallArray = [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1];

export const GameDoubleDataArray = [
    {id: 0, sum: 2, over: "1.01", under: "-"},
    {id: 1, sum: 3, over: "1.07", under: "35.3"},
    {id: 2, sum: 4, over: "1.18", under: "11.8"},
    {id: 3, sum: 5, over: "1.36", under: "5.88"},
    {id: 4, sum: 6, over: "1.68", under: "3.53"},
    {id: 5, sum: 7, over: "2.35", under: "2.35"},
    {id: 6, sum: 8, over: "3.53", under: "1.68"},
    {id: 7, sum: 9, over: "5.88", under: "1.36"},
    {id: 8, sum: 10, over: "11.8", under: "1.18"},
    {id: 9, sum: 11, over: "35.3", under: "1.07"},
    {id: 10, sum: 12, over: "-", under: "1.01"}
];

export const ballColor = [
    {idx: 0, colorName: 'blue'},
    {idx: 1, colorName: 'green'},
    {idx: 2, colorName: 'cyan'},
    {idx: 3, colorName: 'grey'},
    {idx: 4, colorName: 'red'},
    {idx: 5, colorName: 'vivid'},
    {idx: 6, colorName: 'yellow'}
];

const size = {
    mobileXS: "200px",
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "400px",
    surface: "540px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1440px",
    desktop: "2560px"
};

export const device = {
    mobileXS: `(min-width: ${size.mobileXS})`,
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    surface: `(min-width: ${size.surface})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`
};

export const betAmtConstants = {
  maxAmt: 100000.00,
  minAmt: 10.00
};

export const kenoConstants = {
  maxCheckedNumber: 10
};

export default gameConstants;