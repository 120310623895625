import React from "react";
import Stack from "react-bootstrap/Stack"
import {useTranslation, withTranslation} from 'react-i18next';

import Prediction from "../../components/dice/Prediction";
import helpers from "../../utils/helpers";
import box_prediction from '../../assets/images/dice/box_prediction.png'
import {
    FaArrowUp,
    FaArrowDown,
  } from 'react-icons/fa';

function GamePrediction(props) {
    const {t} = useTranslation();

    return (
        <>
            <Prediction.Container style={{backgroundImage:`url(${box_prediction})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover',display: 'flex',width: '40%',height: '70%'}}>
                <Stack style={{paddingTop: '12%'}}>
                    <div>
                        <Prediction.Value>{t('dice_prediction')}</Prediction.Value>
                    </div>
                    <div>
                        <Prediction.Header>{helpers.padDigits(props.predictionValue, 2)} {props.rollOver === true ? <FaArrowUp/> : <FaArrowDown/>}</Prediction.Header>
                    </div>
                </Stack>
            </Prediction.Container>
        </>
    );
}

export default withTranslation('common')(GamePrediction);
