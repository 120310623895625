import React from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import Amount from "../components/default/Amount";

function GameAmount(props) {
  const { t } = useTranslation();

  function renderAmount(props) {
      return (
          <Amount.Item>
              {/* <Amount.Label>{props.amt.toFixed(2)}</Amount.Label> */}
              <TextField 
                sx={{input:{color:'white', textAlign:'center', width:'135px'}}}
                type='text'
                InputProps={{disableUnderline: true, readOnly: props.islock}} 
                autoComplete="off"
                onChange={(event)=>props.onUpdateBetAmount(event.target.value)}  
                hiddenLabel 
                variant="standard" 
                value={props.amt} />
          </Amount.Item>
      );
  }

  return <Amount.Container>{renderAmount(props)}</Amount.Container>;
}

export default withTranslation('common')(GameAmount);
