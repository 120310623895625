import { animated as a } from "react-spring";
import styled from "styled-components";

import { device } from "../../utils/utilConstants";

const Container = styled.div`
  display: flex;
  padding: 5px 5px 5px 5px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  bottom: 1%;
  width: 100%;
  max-width: max-content;
  z-index: 11;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-self: center;
  gap: 3px 3px;

  @media ${device.tablet} {
    padding: 5px;
  }
`;

const Item = styled(a.div)`
  position: relative;
  width: 9%;
  cursor: pointer;

  &:active {
    cursor: pointer;
  }
  
  img {
    will-change: transform;
    width: 100%;
  }
`;

export default { Container, Item };
