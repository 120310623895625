import React, { useState, useEffect, useRef } from "react";
import { withTranslation,useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { styled } from '@stitches/react';
import { useSpring, animated } from '@react-spring/web';
import '../../styles/HashDice/RollingNumberBox.css';

const AppContainer = styled('div', {
  width: '100%',
  height: '150px',
  
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

function RollingNumberBox(props){

  const [num1, setNum1] = useState(0);
  const springs1 = useSpring({ val: num1, from: { val: 0 }});

  
  let interval;

  const count = useRef(0);

  const zeroPad = (num, places) => String(num).padStart(places, '0');

  useEffect( () => {
    // console.log("render once");
  },[]);

  const generateRollingNumberAndUpdate = () => {
    const randomRollingNum = Math.floor(Math.random() * 10000);
      setNum1(randomRollingNum);
  }

  const updateRolling = () => {
    generateRollingNumberAndUpdate();
    
    interval = !interval && setInterval(() => {

      generateRollingNumberAndUpdate();

    }, 500);
  }

  useEffect( () => {
    // console.log("render detect change : props.isRolling");

    if(count.current === 0){
      // console.log("render once isRolling: load once");
      count.current = count.current + 1;
      return;
    }else{
      
      if(props.isRolling){
        updateRolling();
      }else if(!props.isRolling){
        // console.log("stop interval !!! ");
        clearInterval(interval);
        // console.log("set number to final number: " + props.randomNumber);
        setNum1(props.randomNumber);
      }

    }

    return () => clearInterval(interval);

  },[props.isRolling]);

  return (
    <>
      <AppContainer className="background-image">

      <animated.div className="award-number">
        {springs1.val.to(val => zeroPad(Math.floor(val), 4) )}
      </animated.div>
      
      </AppContainer>
    </>
  );
}

export default withTranslation('common')(RollingNumberBox);

