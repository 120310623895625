import { animated as a } from "react-spring";
import styled, {keyframes} from "styled-components";

import { device } from "../../utils/utilConstants";

const pulse = keyframes`
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
`;

const Container = styled(a.div)`
  width: 100%;
  height: 100%;
  color: white;
  align-self: center;
  background: ${props => props.message ? 
    "linear-gradient(to bottom, rgba(55,53,78,255) 0%, rgba(55,53,78,255) 100%)" : "inherit"}
`;

const Header = styled.h1`
  text-align: center;
  font-size: ${props => props.smallfont? "12px" : props.mediumfont ? "20px" : "30px" };
  color: ${props => (props.warning ? "#C70039" : props.success ? "#27ae60" : props.wht ? "#ffffff" : "#3498db")};
  font-weight: bold;
`;

const SpheresHeader = styled.h1`
  text-align: center;
  font-size: ${props => props.smallfont? "12px" : props.mediumfont ? "20px" : "25px" };
  color: 'white';
  font-weight: bold;
`;

export default { Container, Header,SpheresHeader };
